import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { IDesignProduct, IDesignSide } from '@inaripro-nx/catalog';
import { PipesModule as CommonPipesModule } from '@inaripro-nx/common-ui';
import { DesignUiModule, ModalWindowStore } from '@inaripro-nx/design-ui';
import { map, Observable, shareReplay, startWith } from 'rxjs';
import { resizeElements } from '../../../../../../utils/calculate.utils';
import { IElements } from '../../../../interfaces/editor.interface';
import { EPage } from '../../../../interfaces/main.interface';
import {
  IProductTemplate,
  ITemplate,
  ITemplateType,
  VERSION_START,
} from '../../../../interfaces/templates.interface';
import { MyTemplatesService } from '../../../../services/my-templates/my-templates.service';
import { ProductTemplatesService } from '../../../../services/product-templates/product-templates.service';
import { HistoryStore } from '../../../../state/history/history.store';
import { MainStore } from '../../../../state/main/main.store';
import { ProductStore } from '../../../../state/product/product.store';
import { updateTemplateVersion } from '../../../../../../utils/update.utils';

@Component({
  selector: 'painter-content-templates',
  standalone: true,
  imports: [CommonModule, DesignUiModule, CommonPipesModule],
  templateUrl: './content-templates.component.html',
  styleUrls: ['./content-templates.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentTemplatesComponent {
  readonly MODAL_APPLY_TEMPLATE =
    '[ContentTemplatesComponent] MODAL_APPLY_TEMPLATE';
  readonly MODAL_REMOVE_TEMPLATE =
    '[ContentTemplatesComponent] MODAL_REMOVE_TEMPLATE';

  myTemplates$ = this.myTemplatesService.templates$.pipe(
    startWith(this.myTemplatesService.templates$.value),
    map((items) =>
      items ? items.filter((item) => !item.markForDelete) : items
    ),
    shareReplay({ refCount: false, bufferSize: 1 })
  );

  isProductOwner$ = this.productStore.isProductOwner$;

  productTemplates$ = this.productTemplatesService.templates$.pipe(
    startWith(this.productTemplatesService.templates$.value),
    map((items) =>
      items ? items.filter((item) => !item.markForDelete) : items
    ),
    shareReplay({ refCount: false, bufferSize: 1 })
  );

  elements$: Observable<IElements> = this.historyStore.elements$;
  activeDesignProduct$: Observable<IDesignProduct | null> =
    this.productStore.activeDesignProduct$;

  activeDesignSide$: Observable<IDesignSide | null> =
    this.productStore.activeDesignSide$;

  selectedTemplate: ITemplate | null = null;
  selectedType: ITemplateType | null = null;

  constructor(
    private myTemplatesService: MyTemplatesService,
    private productTemplatesService: ProductTemplatesService,
    private modalWindowStore: ModalWindowStore,
    private productStore: ProductStore,
    private historyStore: HistoryStore,
    private mainStore: MainStore
  ) {}

  selectTemplate(type: ITemplateType, template: ITemplate) {
    if (+template.version < +VERSION_START) {
      return;
    }

    this.selectedTemplate = updateTemplateVersion(template);
    this.selectedType = type;
    this.modalWindowStore.patch({ [this.MODAL_APPLY_TEMPLATE]: true });
  }

  removeMyTemplate(id: number) {
    if (!this.myTemplatesService.removeMyTemplate) {
      return;
    }

    this.myTemplatesService.removeMyTemplate(id);
    this.closeModalRemove();
  }

  saveTemplateName(
    type: ITemplateType,
    template: ITemplate | IProductTemplate,
    name: string
  ) {
    if (type === 'my') {
      if (!this.myTemplatesService.updateMyTemplate) {
        return;
      }

      this.myTemplatesService.updateMyTemplate({
        template: {
          ...template,
          name,
        },
        triggerToSaved: false,
      });
    } else {
      if (!this.productTemplatesService.updateProductTemplate) {
        return;
      }

      this.productTemplatesService.updateProductTemplate({
        template: {
          ...(template as IProductTemplate),
          name,
        },
        triggerToSaved: false,
      });
    }
  }

  toggleActiveProductTemplate(template: IProductTemplate, isActive: boolean) {
    if (!this.productTemplatesService.updateProductTemplate) {
      return;
    }

    this.productTemplatesService.updateProductTemplate({
      template: {
        ...template,
        isActive,
      },
      triggerToSaved: false,
    });
  }

  selectForRemove(type: ITemplateType, template: ITemplate) {
    this.selectedTemplate = template;
    this.selectedType = type;
    this.modalWindowStore.patch({ [this.MODAL_REMOVE_TEMPLATE]: true });
  }

  closeModalRemove() {
    this.modalWindowStore.patch({ [this.MODAL_REMOVE_TEMPLATE]: false });
    this.selectedTemplate = null;
    this.selectedType = null;
    this.productStore.setCurrentTemplate(null);
  }

  removeProductTemplate(id: number) {
    if (!this.productTemplatesService.removeProductTemplate) {
      return;
    }

    this.productTemplatesService.removeProductTemplate(id);
    this.closeModalRemove();
  }

  applyTemplate(
    state: {
      activeDesignProduct: IDesignProduct | null;
      activeDesignSide: IDesignSide | null;
      elements: IElements | null;
    },
    replace = true
  ) {
    if (
      !this.selectedTemplate ||
      !state.activeDesignSide ||
      !state.activeDesignProduct
    ) {
      return;
    }

    const { json } = this.selectedTemplate;
    const jsonElements = json.elements || [];
    const jsonZoneColors = json.zoneColors;
    const jsonZonePatterns = json.zonePatterns;
    const jsonZoneTranslates = json.zoneTranslates;

    const templateElements: IElements = resizeElements(
      state.activeDesignProduct.sides,
      jsonElements
    );

    const elements: IElements = [
      ...(replace ? [] : state.elements || []),
      ...templateElements,
    ];

    this.historyStore.updateElements({ elements });

    if (
      (this.isProductOwner$.value && this.selectedType === 'product') ||
      (!this.isProductOwner$.value && this.selectedType === 'my')
    ) {
      this.productStore.setCurrentTemplate(this.selectedTemplate);
    } else {
      this.productStore.setCurrentTemplate(null);
    }

    this.productStore.setZoneColors(jsonZoneColors || {});
    this.productStore.setZonePatterns(jsonZonePatterns || {});
    this.productStore.setZoneTranslates(jsonZoneTranslates || {});

    this.closeModalApply();
    this.mainStore.setPage({ page: EPage.design });
  }

  closeModalApply() {
    this.modalWindowStore.patch({ [this.MODAL_APPLY_TEMPLATE]: false });
    this.selectedTemplate = null;
  }
}
