<svg
  version="1.1"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  preserveAspectRatio="xMidYMid meet"
  [attr.viewBox]="side.sizePX | viewbox"
  [attr.width]="side.sizeMM.x + 'mm'"
  [attr.height]="side.sizeMM.y + 'mm'"
>
  <svg
    style="width: 100%; height: 100%"
    preserveAspectRatio="xMidYMid meet"
    [attr.viewBox]="side.sizePX | viewbox"
  >
    <!-- Рабочая область редактора -->
    <defs>
      <mask id="mask1">
        <rect x="0" y="0" width="100%" height="100%" fill="black" />
        <rect
          *ngFor="let zone of side.zones"
          [attr.x]="zone.start?.x"
          [attr.y]="zone.start?.y"
          [attr.width]="zone.size?.x"
          [attr.height]="zone.size?.y"
          fill="white"
        />
      </mask>
    </defs>

    <ng-container *ngFor="let filterId of elementsFiltersId">
      <defs
        *ngIf="filterId && filters && filters[filterId] as filter"
        [innerHTML]="filter.html | safeHtml"
      ></defs>
    </ng-container>

    <!-- Область фоновой заливки -->
    <g *ngIf="side.hasColorPalette; else fullColorTemplate">
      <rect
        *ngIf="(zoneColors?.[PREFIX_SIDE_FILL + side.id] || BACKGROUND_COLOR_DEFAULT) as fill"
        x="0"
        y="0"
        width="100%"
        height="100%"
        [attr.fill]="fill"
        style="pointer-events: none"
      />
      <g *ngIf="zonePatterns?.[PREFIX_SIDE_FILL + side.id] as pattern">
        <pattern
          id="pattern"
          x="0"
          y="0"
          width="100%"
          height="100%"
          patternUnits="userSpaceOnUse"
        >
          <image
            x="0"
            y="0"
            width="100%"
            height="100%"
            [attr.xlink:href]="pattern.url"
            preserveAspectRatio="xMidYMid slice"
          ></image>
        </pattern>
        <rect
          x="0"
          y="0"
          width="100%"
          height="100%"
          fill="url(#pattern)"
          style="pointer-events: none"
        />
      </g>
    </g>
    <ng-template #fullColorTemplate>
      <rect
        x="0"
        y="0"
        width="100%"
        height="100%"
        [attr.fill]="fullColor || BACKGROUND_COLOR_DEFAULT"
      />
    </ng-template>

    <!-- Мокап -->
    <image
      *ngIf="side.mockupType === EMockupType.down"
      [attr.xlink:href]="side.image.url"
      x="0"
      y="0"
      width="100%"
      height="100%"
    ></image>

    <!-- Фон области нанесения -->
    <g *ngIf="!side.isFullPrint">
      <g *ngFor="let zone of side.zones">
        <g *ngIf="zone.hasColorPalette && zone.start && zone.size">
          <rect
            *ngIf="zoneColors?.['' + zone.id] as fill"
            [attr.x]="zone.start.x"
            [attr.y]="zone.start.y"
            [attr.width]="zone.size.x"
            [attr.height]="zone.size.y"
            [attr.fill]="fill"
          />
          <g *ngIf="zonePatterns?.['' + zone.id] as pattern">
            <pattern
              id="{{ 'pattern-' + zone.id }}"
              [attr.x]="zone.start.x"
              [attr.y]="zone.start.y"
              [attr.width]="zone.size.x"
              [attr.height]="zone.size.y"
              patternUnits="userSpaceOnUse"
            >
              <image
                x="0"
                y="0"
                [attr.width]="zone.size.x"
                [attr.height]="zone.size.y"
                [attr.xlink:href]="pattern.url"
                preserveAspectRatio="xMidYMid slice"
              ></image>
            </pattern>
            <rect
              [attr.x]="zone.start.x"
              [attr.y]="zone.start.y"
              [attr.width]="zone.size.x"
              [attr.height]="zone.size.y"
              [attr.fill]="'url(#pattern-' + zone.id + ')'"
            />
          </g>
        </g>
      </g>
    </g>

    <!-- Объекты дизайна -->
    <g mask="url(#mask1)">
      <g [attr.transform]="zone0Center | svgTranslate">
        <ng-container *ngFor="let el of elements; let i = index">
          <ng-container *ngIf="el.sideIndex === sideIndex">
            <g data-painter-editor-element-transform [el]="el">
              <rect
                [attr.x]="0"
                [attr.y]="0"
                [attr.width]="el.size.x"
                [attr.height]="el.size.y"
                fill-opacity="0"
              />

              <g data-painter-editor-element-content [el]="el" [index]="i"></g>
            </g>
          </ng-container>
        </ng-container>
      </g>
    </g>

    <!-- Мокап -->
    <image
      *ngIf="side.mockupType === EMockupType.up"
      [attr.xlink:href]="side.image.url"
      x="0"
      y="0"
      width="100%"
      height="100%"
    ></image>

    <!-- Область нанесения -->
    <g *ngIf="!side.isFullPrint">
      <g *ngFor="let zone of side.zones">
        <rect
          [attr.x]="zone.start?.x"
          [attr.y]="zone.start?.y"
          [attr.width]="zone.size?.x"
          [attr.height]="zone.size?.y"
          style="
            fill: none;
            stroke: #9e9e9e;
            stroke-width: 1px;
            vector-effect: non-scaling-stroke;
          "
        />
        <rect
          [attr.x]="zone.start?.x"
          [attr.y]="zone.start?.y"
          [attr.width]="zone.size?.x"
          [attr.height]="zone.size?.y"
          style="
            fill: none;
            stroke: #ededed;
            stroke-width: 1px;
            stroke-dasharray: 3 3;
            vector-effect: non-scaling-stroke;
          "
        />
      </g>
    </g>
  </svg>
</svg>
