import { ObjectKey, isValidKey } from './types.utils';

export function reduceToMap<T extends object, K extends keyof T>(
  collection: readonly T[],
  k: K
): { [key: ObjectKey]: T } {
  return collection.reduce((acc, curr) => {
    const valueAtKey = curr[k];

    if (isValidKey(valueAtKey)) {
      return { ...acc, [valueAtKey]: curr };
    }

    throw new Error('T[K] is not a valid object key type');
  }, {} as { [key: ObjectKey]: T });
}

export function sumArray(items: number[]): number | null {
  if (!items) {
    return null;
  }

  return items.reduce((sum, count) => {
    sum += count;
    return sum;
  }, 0);
}

export function sortAsNumber(values: string[]): string[] {
  return values.sort((a, b) => (+a || 0) - (+b || 0));
}
