import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'painter-editor-button',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './editor-button.component.html',
  styleUrls: ['./editor-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditorButtonComponent {
  @Input() active: boolean | null = null;
  @Input() selected: boolean | null = null;
  @Input() disabled: boolean | null = null;
  @Input() title?: string = '';
}
