import { createAction, createReducer, on, props, Store } from '@ngrx/store';
import { Observable } from 'rxjs';

export const updateComponentState = createAction(
  '[Component Store] Update Action',
  props<{ componentName: string; componentState: object }>()
);

export const initialState: object = {};

export const componentStateReducer = createReducer(
  initialState,
  on(updateComponentState, (state, { componentName, componentState }) => {
    return {
      ...state,
      [componentName]: { ...componentState },
    };
  })
);

export const linkToGlobalState = (
  componentState$: Observable<object>,
  componentName: string,
  globalStore: Store
) => {
  componentState$.subscribe((componentState) => {
    globalStore.dispatch(
      updateComponentState({ componentName, componentState })
    );
  });
};
