import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { IPublicPrice } from '@inaripro-nx/catalog';
import { DesignUiModule } from '@inaripro-nx/design-ui';
import { debounceTime, filter, Subscription, tap } from 'rxjs';

@Component({
  selector: 'painter-content-product-order-input',
  standalone: true,
  imports: [CommonModule, DesignUiModule],
  templateUrl: './content-product-order-input.component.html',
  styleUrls: ['./content-product-order-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentProductOrderInputComponent
  implements OnChanges, OnInit, OnDestroy
{
  @Input() publicPrice: IPublicPrice | null = null;
  @Input() orderCount: number | null = null;
  @Input() minRange = 1;
  @Input() lotSize = 1;
  @Input() isDisable = false;

  @Output() inputCount: EventEmitter<number> = new EventEmitter<number>();

  readonly countControl = new FormControl(this.orderCount);

  private _subs: Subscription[] = [];
  set subs(sub: Subscription) {
    this._subs.push(sub);
  }

  ngOnChanges(sc: SimpleChanges) {
    if (sc['orderCount']) {
      const orderCount = this.orderCount || 1;
      this.countControl.setValue(orderCount, { emitEvent: false });
    }

    if (sc['isDisable'] || sc['lotSize']) {
      if (this.isDisable || this.lotSize > 1) {
        this.countControl.disable();
      } else {
        this.countControl.enable();
      }
    }
  }

  ngOnInit() {
    this.subs = this.countControl.valueChanges
      .pipe(
        debounceTime(100),
        filter((val) => !this.isDisable && !!val && val >= this.minRange),
        tap(() => this.changeCount(0))
      )
      .subscribe();
  }

  ngOnDestroy() {
    this._subs.forEach((s) => s.unsubscribe());
  }

  changeCount(coeff: number) {
    if (this.isDisable) {
      return;
    }

    const lotSize = this.lotSize || 1;
    let val = +(this.countControl.value || 1);
    val = val + coeff * lotSize;

    if (val > 0) {
      this.inputCount.emit(val);
    }
  }
}
