<design-colorpicker
  [inColor]="(inColor$ | async) || null"
  (changeModel)="setZoneColor($event)"
  [eyeColor]="eyeDropperService.eyeColor$ | async"
  (startEye)="eyeDropperService.startEye($event)"
  [isDesktop]="isDesktop$ | async"
  [withModal]="false"
  [resizeSafari$]="actionsTextService.resizeSafari$"
  [hasTransparent]="true"
  [useColors]="useColors$ | async"
></design-colorpicker>
