<ng-container
  *ngIf="{
    elements: elements$ | async,
    myTemplates: myTemplates$ | async,
    isProductOwner: isProductOwner$ | async,
    productTemplates: productTemplates$ | async,
    activeDesignSide: activeDesignSide$ | async,
    activeDesignProduct: activeDesignProduct$ | async
  } as state"
>
  <div
    *ngIf="
      (state.myTemplates || []).length === 0 &&
        (state.productTemplates || []).length === 0;
      else contentTemplates
    "
    class="no-templates"
  >
    <span>Вы еще не сохранили ни одного шаблона</span>
  </div>
  <ng-template #contentTemplates>
    <div class="content-templates">
      <ng-container *ngIf="(state.myTemplates || []).length > 0">
        <div
          class="content-templates__mytemplates"
          *ngIf="!state.isProductOwner"
        >
          <div class="text">
            <span>Мои шаблоны</span>
          </div>
          <div class="wrappertemplatesscroll">
            <div class="wrapper" *ngFor="let template of state.myTemplates">
              <design-card-template
                [isShowActive]="false"
                [isShowRemove]="true"
                [isShowEdit]="true"
                [template]="template"
                (selectTemplate)="selectTemplate('my', template)"
                (remove)="selectForRemove('my', template)"
                (editName)="saveTemplateName('my', template, $event)"
              ></design-card-template>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="(state.productTemplates || []).length > 0">
        <div class="content-templates__templates">
          <div class="text">
            <span>{{
              state.isProductOwner
                ? 'Шаблоны, доступные для пользователей'
                : 'Шаблоны'
            }}</span>
          </div>
          <div class="wrappertemplatesscroll">
            <div
              class="wrapper"
              *ngFor="let template of state.productTemplates"
            >
              <design-card-template
                [isShowActive]="!!state.isProductOwner"
                [isShowRemove]="!!state.isProductOwner"
                [isShowEdit]="!!state.isProductOwner"
                [template]="template"
                (selectTemplate)="selectTemplate('product', template)"
                (remove)="selectForRemove('product', template)"
                (editName)="saveTemplateName('product', template, $event)"
                (toggleActive)="
                  state.isProductOwner &&
                    toggleActiveProductTemplate(template, $event)
                "
              ></design-card-template>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </ng-template>
  <design-modal-window
    *ngIf="selectedTemplate"
    [uid]="MODAL_APPLY_TEMPLATE"
    [prefix]="'applytemplate'"
    (selfClose)="closeModalApply()"
  >
    <div modal-header>Применить шаблон?</div>
    <div modal-body>
      <div class="modal-content">Если применить шаблон, несохраненные изменения будут утеряны.</div>
    </div>
    <div modal-footer>
      <div class="g-form-button g-form-button--center">
        <button type="button" class="g-btn-link" (click)="closeModalApply()">
          Отмена
        </button>
        <button type="button" class="g-btn-link g-btn-link--red" (click)="applyTemplate(state)">
          ОК
        </button>
      </div>
    </div>
  </design-modal-window>

  <design-modal-window
    *ngIf="selectedTemplate"
    [uid]="MODAL_REMOVE_TEMPLATE"
    [prefix]="'removetemplate'"
    (selfClose)="closeModalRemove()"
  >
    <div modal-header>Удалить шаблон?</div>
    <div modal-body>
      <div class="modal-content">Вы действительно хотите удалить шаблон “{{ selectedTemplate.name }}”?</div>
    </div>
    <div modal-footer>
      <div class="g-form-button g-form-button--center">
        <button type="button" class="g-btn-link" (click)="closeModalRemove()">
          Отмена
        </button>
        <button
          type="button"
          class="g-btn-link g-btn-link--red"
          (click)="
            selectedType === 'product'
              ? state.isProductOwner &&
                removeProductTemplate(selectedTemplate.id)
              : removeMyTemplate(selectedTemplate.id)
          "
        >
          Удалить
        </button>
      </div>
    </div>
  </design-modal-window>
</ng-container>
