<div class="content-figures">
  <div class="content-figures__filter">
    <design-search
      [externalFormControl]="control"
      [placeholderText]="'Поиск по фигурам'"
      [minSearchQueryLength]="1"
      [disableClear]="true"
    ></design-search>
  </div>
  <div class="content-figures__content" #scrollContainer (scroll)="scrollEvent$.next($event)">
    <div class="content-figures__empty" [style.height.px]="beforeHeight"></div>
    <div class="content-figures__grid" #contentContainer>
        <design-card-picture
          *ngFor="let figure of (figures$ | async); let i = index; trackBy: trackByFigure"
          [image]="figure.image"
          [canDelete]="false"
          [imageToSvg]="true"
          [isFigure]="true"
          [lazyLoad]="false"
          (selectObject)="onSelectObject($event)"
        ></design-card-picture>
    </div>
    <div class="content-figures__empty" [style.height.px]="afterHeight"></div>
  </div>
</div>
