import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  Renderer2,
  ViewChild,
} from '@angular/core';
import {
  combineLatest,
  debounceTime,
  map,
  Subscription,
  withLatestFrom,
} from 'rxjs';
import { CropStore } from '../../store/crop';

@Component({
  selector: 'crop-image',
  standalone: true,
  templateUrl: './crop-image.component.html',
  styleUrls: ['./crop-image.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CropImageComponent implements AfterViewInit, OnDestroy {
  @Input() imageUrl!: string;

  @ViewChild('image') readonly image?: ElementRef<HTMLImageElement>;

  readonly maskStrokeWidth = 2;

  private _subs: Subscription[] = [];
  set subs(sub: Subscription) {
    this._subs.push(sub);
  }

  constructor(
    private readonly renderer: Renderer2,
    private readonly cropStore: CropStore
  ) {}

  ngAfterViewInit() {
    if (this.image) {
      const image = this.image.nativeElement;

      this.subs = combineLatest([
        this.cropStore.cropX$,
        this.cropStore.cropY$,
        this.cropStore.cropWidth$,
        this.cropStore.cropHeight$,
        this.cropStore.maskUrl$,
      ])
        .pipe(
          debounceTime(0),
          withLatestFrom(this.cropStore.imageX$, this.cropStore.imageY$),
          map(([[x, y, width, height, mask], imageX, imageY]) => ({
            x: x - imageX + this.maskStrokeWidth,
            y: y - imageY + this.maskStrokeWidth,
            width: width - this.maskStrokeWidth * 2,
            height: height - this.maskStrokeWidth * 2,
            mask,
          }))
        )
        .subscribe(({ x, y, width, height, mask }) => {
          if (mask) {
            this.clearCSSRectangleClip(image);
            this.setCSSImageMask(image, mask, x, y, width, height);
          } else {
            this.clearCSSImageMask(image);
            this.setCSSRectangleClip(image, x, y, width, height);
          }
        });
    }
  }

  ngOnDestroy() {
    this._subs.forEach((s) => s.unsubscribe());
  }

  private setCSSImageMask(
    image: HTMLImageElement,
    mask: string,
    x: number,
    y: number,
    width: number,
    height: number
  ) {
    this.renderer.setStyle(image, 'mask', `url(${mask}) no-repeat`);
    this.renderer.setStyle(image, '-webkit-mask-image', `url(${mask})`);
    this.renderer.setStyle(image, '-webkit-mask-repeat', `no-repeat`);

    this.renderer.setStyle(image, 'mask-size', `${width}px ${height}px`);
    this.renderer.setStyle(
      image,
      '-webkit-mask-size',
      `${width}px ${height}px`
    );

    this.renderer.setStyle(image, 'mask-position', `${x}px ${y}px`);
    this.renderer.setStyle(image, '-webkit-mask-position', `${x}px ${y}px`);
  }

  private clearCSSImageMask(image: HTMLImageElement) {
    this.renderer.setStyle(image, 'mask', 'none');
    this.renderer.setStyle(image, '-webkit-mask-image', 'none');
  }

  private setCSSRectangleClip(
    image: HTMLImageElement,
    x: number,
    y: number,
    width: number,
    height: number
  ) {
    this.renderer.setStyle(
      image,
      'clip-path',
      `polygon(
                ${x}px ${y}px,
                ${x + width}px ${y}px,
                ${x + width}px ${y + height}px,
                ${x}px ${y + height}px
              )`
    );
  }

  private clearCSSRectangleClip(image: HTMLImageElement) {
    this.renderer.setStyle(image, 'clip-path', 'none');
  }
}
