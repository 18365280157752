<design-modal-window
  *ngIf="{
    currentTemplate: currentTemplate$ | async
  } as state"
  [uid]="MODAL_UID_SAVE_CHANGES_REQUEST"
  [prefix]="'save-changes-request'"
  #modal
>
  <div modal-header>
    <span>Внимание!</span>
  </div>
  <div modal-body>
    <div class="g-form-group">
      <div>
        <i class="icon icon-ic_save_2"></i>
      </div>
      <div class="message">
        При выборе другого товара несохраненные изменения будут утеряны!
      </div>
    </div>
  </div>
  <div modal-footer>
    <div class="g-form-button">
      <button type="button" class="g-btn-link g-btn-link--red" *ngIf="state.currentTemplate"
              (click)="modal.closeModal(); updateTemplate()">
        Сохранить в текущий шаблон
      </button>

      <button type="button" class="g-btn-link g-btn-link--red" (click)="modal.closeModal(); createTemplate()">
        Сохранить как новый шаблон
      </button>

      <button type="button" class="g-btn-link" (click)="modal.closeModal(); selectProduct()">
        Продолжить без сохранения
      </button>
    </div>
  </div>
</design-modal-window>

<design-modal-window
  *ngIf="{
    currentTemplate: currentTemplate$ | async
  } as state"
  [uid]="MODAL_UID_SAVE_CHANGES_SUCCESS"
  [prefix]="'save-changes-success'"
  #modal
>
  <div modal-header>
    <span>Шаблон сохранён</span>
  </div>
  <div modal-body>
    <div class="g-form-group">
      <div>
        <i class="icon icon-ic_done_circle"></i>
      </div>
      <div class="message">
        Изменения успешно сохранены в шаблон “{{ state.currentTemplate?.name || '' }}”
      </div>
    </div>
  </div>
  <div modal-footer>
    <div class="g-form-button">
      <button type="button" class="g-btn-link g-btn-link--red" (click)="modal.closeModal(); selectProduct()">
        Перейти в каталог
      </button>
    </div>
  </div>
</design-modal-window>
