import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';
import { IDesignProduct } from '@inaripro-nx/catalog';
import { EditorSidesDirective } from '../../directives/editor-sides/editor-sides.directive';

const MAX_VISIBLE_ITEMS = 3;

@Component({
  selector: 'painter-editor-product-sides',
  standalone: true,
  imports: [CommonModule, EditorSidesDirective],
  templateUrl: './editor-product-sides.component.html',
  styleUrls: ['./editor-product-sides.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditorProductSidesComponent implements OnChanges {
  @Input() designProduct: IDesignProduct | null = null;
  @Input() activeDesignSideIndex: number | null = null;

  @Output() selectSide = new EventEmitter<number>();

  size = 1;
  isSliderActive = false;

  ngOnChanges(changes: SimpleChanges): void {
      if ('designProduct' in changes && this.designProduct) {
        this.size = this.designProduct.sides.length;
        this.isSliderActive = this.size > MAX_VISIBLE_ITEMS;
      }
  }
}
