import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalWindowComponent } from '@inaripro-nx/design-ui';
import { DownloadService } from '../../services/download/download.service';

@Component({
  selector: 'painter-modal-download-pdf',
  standalone: true,
  imports: [CommonModule, ModalWindowComponent],
  templateUrl: './modal-download-pdf.component.html',
  styleUrl: './modal-download-pdf.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalDownloadPdfComponent {
  constructor(public readonly downloadService: DownloadService) {}
}
