import { IPaginationResponse } from '@inaripro-nx/common-ui';

export interface ICity {
  id: number;
  name: string;
}

export interface ICityResponse {
  id: number;
  city: ICity;
  default: ICity;
  filials?: ICity[];
}

export interface ICityListResponse {
  data: ICity[];
  pageInfo?: IPaginationResponse;
}

export interface ICityUpdateRequest {
  item: ICityUpdateRequestItem;
}

export interface ICityUpdateRequestItem {
  id: number;
  cityId: number;
}
