export function safelyParseJSON<T>(raw: string): T {
  let parsed;

  try {
    parsed = JSON.parse(raw);
  } catch (e) {
    console.error('JSON parse error: ', e);
  }

  return parsed;
}
