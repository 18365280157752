import { Injectable } from '@angular/core';
import { StorageService } from '@inaripro-nx/common-ui';
import { IPicture, IPictureLibrary } from '@inaripro-nx/common-ui';
import { ComponentStore } from '@ngrx/component-store';
import {
  EMPTY,
  Observable,
  catchError,
  exhaustMap,
  mergeMap,
  of,
  tap,
  withLatestFrom,
} from 'rxjs';
import { PicturesLibraryService } from '../../services/pictures-library/pictures-library.service';

const SELECT_PICTURE_LIBRARY_ID = 'SELECT_PICTURE_LIBRARY_ID';

interface IMapOfPicture {
  [id: number]: IPicture[];
}

export interface PicturesLibraryState {
  libraries: IPictureLibrary[];
  currentId: number;
  pictures: IMapOfPicture;
}

const initialState: PicturesLibraryState = {
  libraries: [
    {
      id: 0,
      name: 'Загруженные изображения',
    },
  ],
  currentId: 0,
  pictures: {},
};

@Injectable()
export class PicturesLibraryStore extends ComponentStore<PicturesLibraryState> {
  readonly libraries$ = this.select((state) => state.libraries);
  readonly currentId$ = this.select((state) => state.currentId);
  private readonly pictures$ = this.select((state) => state.pictures);

  readonly currentLibrary$: Observable<IPictureLibrary | null> = this.select(
    this.libraries$,
    this.currentId$,
    (libraries: IPictureLibrary[], currentId: number) =>
      libraries.find((l) => l.id === currentId) || null
  );

  readonly currentPictures$: Observable<IPicture[] | null> = this.select(
    this.pictures$,
    this.currentId$,
    (pictures: IMapOfPicture, currentId: number) => pictures[currentId] || null
  );

  private readonly addLibraries = this.updater(
    (state, libraries: IPictureLibrary[]) => ({
      ...state,
      libraries: [...state.libraries, ...libraries],
    })
  );

  readonly setCurrentId = this.updater((state, currentId: number) => {
    this.saveLibraryId(currentId);

    return {
      ...state,
      currentId,
    };
  });

  private readonly addPictures = this.updater(
    (state, data: { id: number; pictures: IPicture[] }) => ({
      ...state,
      pictures: { ...state.pictures, [data.id]: data.pictures },
    })
  );

  private readonly loadCollection = this.effect((trigger$) =>
    trigger$.pipe(
      exhaustMap(() =>
        this.picturesLibraryService.all().pipe(
          tap((libraries: IPictureLibrary[]) => {
            this.addLibraries(libraries);

            const id = this.getLibraryId();
            const lib = libraries.find((l) => id === -1 || l.id === id);

            if (lib) {
              this.loadPictures(lib.id);
            }
          }),
          catchError(() => EMPTY)
        )
      )
    )
  );

  readonly loadPictures = this.effect((id$: Observable<number>) =>
    id$.pipe(
      withLatestFrom(this.pictures$),
      mergeMap(([id, pictures]) => {
        this.setCurrentId(id);

        if (id === 0 || pictures[id]) {
          return of(null);
        }

        return this.picturesLibraryService.getById(id).pipe(
          tap((pictures: IPicture[]) => this.addPictures({ id, pictures })),
          catchError(() => EMPTY)
        );
      })
    )
  );

  readonly selectAnyLibrary = this.effect<void>((trigger$) =>
    trigger$.pipe(
      withLatestFrom(this.libraries$, this.currentLibrary$),
      tap(([, libraries, currentLibrary]) => {
        if (currentLibrary?.id || 0 > 0) {
          return;
        }

        if (libraries.length > 1) {
          this.loadPictures(libraries[1].id);
          return;
        }

        this.saveLibraryId(-1);
      })
    )
  );

  constructor(
    private picturesLibraryService: PicturesLibraryService,
    private storageService: StorageService
  ) {
    super({ ...initialState });
  }

  init() {
    this.loadCollection();
  }

  saveLibraryId(id: number) {
    this.storageService.local.setItem(SELECT_PICTURE_LIBRARY_ID, '' + id);
  }

  getLibraryId(): number {
    return +(this.storageService.local.getItem(SELECT_PICTURE_LIBRARY_ID) || 0);
  }
}
