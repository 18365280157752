import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

@Component({
  standalone: true,
  imports: [CommonModule],
  selector: 'design-card-figure',
  templateUrl: './card-figure.component.html',
  styleUrls: ['./card-figure.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardFigureComponent {
  @Input() iconClass!: string;
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  @Output() cardCloseEmit: EventEmitter<any> = new EventEmitter();

  public clickCardClose(): void {
    this.cardCloseEmit.emit('click');
  }
}
