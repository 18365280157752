<div class="actions-pictures">
  <ng-template #rangeSize>
    <div class="range-size">
      <div class="name">Размер</div>
      <painter-actions-element-scale
        [element]="element"
        [designSide]="designSide"
        [maxValue]="maxScalePercentage"
      ></painter-actions-element-scale>
    </div>
  </ng-template>

  <ng-template #rangeTurn>
    <div class="range-turn">
      <div class="name">Поворот</div>
      <painter-actions-element-rotate
        [element]="element"
      ></painter-actions-element-rotate>
    </div>
  </ng-template>

  <ng-template #modification>
    <painter-actions-element-flip
      *ngIf="designSide"
      [element]="element"
      [designSide]="designSide"
      (updateElement)="updateElement.emit($event)"
    ></painter-actions-element-flip>
  </ng-template>

  <ng-template #inputstext>
    <painter-actions-element-translate
      [element]="element"
    ></painter-actions-element-translate>
  </ng-template>

  <ng-template #filtersTemplate>
    <div class="filters">
      <div class="name">Фильтры</div>
      <div class="filters-wrapper">
        <design-card-filter
          [filter]="{ name: 'Оригинал', html: '', id: null }"
          [isSelect]="element.filterId === null"
          (click)="selectFilter(null)"
        ></design-card-filter>
        <ng-container *ngFor="let filter of filters">
          <design-card-filter
            *ngIf="filter.isActive"
            [filter]="filter"
            [isSelect]="element.filterId === filter.id"
            (click)="selectFilter(filter.id)"
          ></design-card-filter>
        </ng-container>
      </div>
    </div>
  </ng-template>

  <ng-template #crop>
    <painter-actions-element-crop
      class="crop"
      [element]="element"
      [disabled]="!!isMaxUserFilesLimit"
      [title]="!!isMaxUserFilesLimit ? 'Достигнуто максимальное количество изображений, удалите лишние изображения из вкладки «Картинки»' : null"
      (openCropModal)="!isMaxUserFilesLimit && cropModal.open()"
    ></painter-actions-element-crop>
  </ng-template>

  <div class="actions-pictures-desktop">
    <ng-container *ngTemplateOutlet="modification"></ng-container>
    <ng-container *ngTemplateOutlet="inputstext"></ng-container>
    <ng-container *ngTemplateOutlet="rangeSize"></ng-container>
    <ng-container *ngTemplateOutlet="crop"></ng-container>
    <ng-container *ngTemplateOutlet="rangeTurn"></ng-container>
    <ng-container *ngTemplateOutlet="filtersTemplate"></ng-container>
  </div>
  <div
    class="actions-pictures-mobile"
    [class.actions-pictures-mobile-move]="!isShowActionsSubmenu"
  >
    <ng-content></ng-content>
    <div
      class="tabs"
      [class.tabs-move]="!isShowActionsSubmenu"
      [class.tabs-pictures-active-trasformations]="
        subRow === ESubRow.trasformations
      "
      [class.tabs-pictures-active-filters]="subRow === ESubRow.filters"
    >
      <div
        class="tabs-subrow"
        class="tabs-subrow"
        [class.tabs-subrow-pictures-active-trasformations]="
          subRow === ESubRow.trasformations
        "
        [class.tabs-subrow-pictures-active-filters]="subRow === ESubRow.filters"
      >
        <div class="actions-forms" *ngIf="subRow === ESubRow.trasformations">
          <div class="actions-form actions-form-w50 actions-form-hide-mobile">
            <ng-container *ngTemplateOutlet="rangeSize"></ng-container>
            <ng-container *ngTemplateOutlet="rangeTurn"></ng-container>
          </div>
          <div class="actions-form actions-form-w50">
            <ng-container *ngTemplateOutlet="modification"></ng-container>
            <ng-container *ngTemplateOutlet="crop"></ng-container>
          </div>
        </div>
        <div class="actions-forms" *ngIf="subRow === ESubRow.filters">
          <div class="actions-form">
            <ng-container *ngTemplateOutlet="filtersTemplate"></ng-container>
          </div>
        </div>
      </div>
      <div class="tabs-row">
        <div
          class="tab"
          [class.tab-active]="subRow === ESubRow.trasformations"
          (click)="toggleSubRow(ESubRow.trasformations)"
        >
          <i class="icon icon-ic_transformation"></i>
          <span>Трансформация</span>
        </div>
        <div
          class="tab"
          [class.tab-active]="subRow === ESubRow.filters"
          (click)="toggleSubRow(ESubRow.filters)"
        >
          <i class="icon icon-ic_filters"></i>
          <span>Фильтры</span>
        </div>
      </div>
    </div>
  </div>
</div>

<crop-modal
  #cropModal
  [image]="element | elementImageXLink"
  [imageWidth]="element.size.x"
  [imageHeight]="element.size.y"
  [figures]="figures"
  (save)="cropImage.emit($event)"
></crop-modal>
