<div
  class="card-object"
  [class.card-object--active]="isActive"
  [class.card-object--insert-before]="isBeforeInsert"
  [class.card-object--insert-after]="isAfterInsert"
  [draggable]="draggable"
  (dragend)="draggable = false"
  (dragenter)="dragenter($event)"
>
  <div class="card-object__drag_active" (click)="onSelectEvent($event)">
    <div
      class="card-object__drag"
      (mousedown)="draggable = true"
      (touchstart)="touchStartDrag($event)"
      (mouseup)="draggable = false"
    >
      <i class="icon icon-ic_dots"></i>
    </div>
    <div class="card-object__picture">
      <svg
        *ngIf="element?.svg"
        width="50"
        height="50"
        viewBox="0 0 100 100"
        preserveAspectRatio="xMidYMid meet"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g [innerHTML]="element?.svg | safeHtml"></g>
      </svg>
      <ng-container *ngIf="!element?.svg && element?.text">
        <i class="icon icon-tt"></i>
      </ng-container>
    </div>
    <div class="card-object__text">
      {{ element?.text }}
    </div>
  </div>
  <div class="card-object__drag_noactive" (mousedown)="draggable = false">
    <div class="card-object__copy" (click)="onCopyEvent($event)">
      <i class="icon icon-ic_copy"></i>
    </div>
    <div class="card-object__remove" (click)="onRemoveEvent($event)">
      <i class="icon icon-ic_cross-close"></i>
    </div>
  </div>
</div>
