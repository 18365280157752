import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, ViewContainerRef } from '@angular/core';
import {
  IFileResponse,
  stripHtmlComments,
  svgToXml,
} from '@inaripro-nx/common-ui';
import { IProductDesignSide, OrderCartStore } from '@inaripro-nx/order';
import {
  BehaviorSubject,
  Observable,
  finalize,
  from,
  map,
  mergeMap,
  of,
  switchMap,
  tap,
  toArray,
  withLatestFrom,
} from 'rxjs';
import { ExportComponent } from '../../components/export/export.component';
import {
  IExportRequest,
  IExportSideXML,
  IGetSVGSide,
} from './export.interface';
import { HistoryStore } from '../../state/history/history.store';
import { ProductStore } from '../../state/product/product.store';

@Injectable()
export class ExportService {
  private viewContrainerRef!: ViewContainerRef;

  isProduction$: BehaviorSubject<boolean | null | undefined> =
    new BehaviorSubject<boolean | null | undefined>(null);

  constructor(
    private http: HttpClient,
    private readonly orderCartStore: OrderCartStore,
    private readonly historyStore: HistoryStore,
    private readonly productStore: ProductStore,
    @Inject(DOCUMENT) private document: Document
  ) {}

  init(viewContrainerRef: ViewContainerRef) {
    this.viewContrainerRef = viewContrainerRef;
  }

  public exportSvg(request: IExportRequest): Observable<unknown> {
    if (this.isProduction$.value) {
      throw new Error('Valid token not returned');
    }

    const svgSides = this.getSvgSides(request);

    return from(svgSides).pipe(
      mergeMap((svgSide) => {
        const file = new Blob([svgSide.xml], { type: 'image/svg+xml' });

        const formData: FormData = new FormData();
        formData.append('file', file, `export_${new Date().getTime()}.svg`);

        return this.http
          .post<{ url: string }>(
            '/catalog/public/design/temp_pdf_result_chrome',
            formData
          )
          .pipe(
            tap((response) => {
              //set url value to a element's href attribute.
              const a = this.document.createElement('a');
              a.href = response.url;
              a.target = '_blank';
              a.click();
              a.parentNode?.removeChild(a);
            })
          );
      })
    );
  }

  public getXmlSides(): Observable<IExportSideXML[] | null> {
    return of(null).pipe(
      withLatestFrom(
        this.historyStore.elements$,
        this.productStore.filtersMap$,
        this.productStore.activeDesignProduct$,
        this.productStore.fullColor$,
        this.productStore.zoneColors$,
        this.productStore.zonePatterns$,
        this.productStore.orderCount$
      ),
      map(
        ([
          ,
          elements,
          filters,
          designProduct,
          fullColor,
          zoneColors,
          zonePatterns,
        ]) => {
          if (!designProduct) {
            return null;
          }

          return this.getSvgSides({
            elements,
            filters,
            designProduct,
            fullColor,
            zoneColors,
            zonePatterns,
          });
        }
      )
    );
  }

  public getProductSides(): Observable<IProductDesignSide[] | null> {
    this.orderCartStore.setGetProductSidesStatus(true);

    return this.getXmlSides().pipe(
      switchMap((xmlSides) => {
        if (xmlSides === null) {
          return of(null);
        }

        return from(xmlSides).pipe(
          mergeMap((side) => this.getProductDesignSide(side)),
          toArray()
        );
      }),
      finalize(() => this.orderCartStore.setGetProductSidesStatus(false))
    );
  }

  private getProductDesignSide(
    side: IExportSideXML
  ): Observable<IProductDesignSide> {
    const file = new Blob([side.xml || ''], {
      type: 'image/svg+xml',
    });

    const formData: FormData = new FormData();
    formData.append('file', file, `export_${new Date().getTime()}.svg`);

    return this.http
      .post<IFileResponse>(
        '/catalog/public/design/product_result_svg',
        formData
      )
      .pipe(
        map((file) => {
          const svgSide: IProductDesignSide = {
            id: side.id,
            svg: file,
          };
          return svgSide;
        })
      );
  }

  private getSvgSides(request: IExportRequest): IExportSideXML[] {
    const { designProduct } = request;

    return designProduct.sides
      .filter((side) => side.id)
      .map((side, sideIndex) => {
        return {
          id: side.id || 0,
          xml: svgToXml(
            this.getSvgSide({
              ...request,
              sideIndex,
              side,
            })
          ),
        };
      });
  }

  private getSvgSide(request: IGetSVGSide): string {
    this.viewContrainerRef.clear();

    const componentRef =
      this.viewContrainerRef.createComponent<ExportComponent>(ExportComponent);

    const {
      side,
      sideIndex,
      filters,
      elements,
      fullColor,
      zoneColors,
      zonePatterns,
    } = request;

    componentRef.instance.side = side;
    componentRef.instance.sideIndex = sideIndex;
    componentRef.instance.filters = filters;
    componentRef.instance.elements = elements;
    componentRef.instance.fullColor = fullColor;
    componentRef.instance.zoneColors = zoneColors;
    componentRef.instance.zonePatterns = zonePatterns;
    componentRef.changeDetectorRef.detectChanges();

    const innerHTML = (
      componentRef.instance.elementRef.nativeElement as HTMLElement
    ).innerHTML;

    this.viewContrainerRef.clear();

    const clear: (value: string) => string = (value: string) => {
      value = value.replace(/\sdata-painter-editor-element-transform=""/g, '');
      value = value.replace(/\sdata-painter-editor-element-content=""/g, '');
      value = value.replace(/\sng-reflect-el="\[object\sObject\]"/g, '');
      value = stripHtmlComments(value);
      return value;
    };

    return clear(innerHTML);
  }
}
