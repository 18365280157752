<div
  designAdDropDown
  [dropDownAlignElement]="shareLinksButton"
  [dropDownContentTemplateRef]="shareTemplate"
  [dropDownLeftShift] = "leftShift"
  [dropDownClass]="'drop-down-share-links'"
  [dropDownOpenTo]="'down'"
  [dropDownAlign]="'left'"
  (toggleDropDown)="toggleDropDown($event)"
>
  <div #shareLinksButton>
    <painter-editor-button
      [selected]="active"
      [title] = "'Поделиться ссылкой'"
    >
      <i class="icon icon-ic_share_link"></i>
    </painter-editor-button>
  </div>
</div>

<ng-template #shareTemplate>
  <div class="share-container">
    <a *ngFor="let share of shareList"
      [href]="share.url"
      class="link-button"
      (click)="handleLinkClick($event)"
    >
      <button type="button" (click)="shareOpen(share.type); adDropDownDirective.hideSelf()">
          <i [ngClass]="share.icon" class="icon ic-share-links"></i>
          <span>{{ share.name }}</span>
      </button>
    </a>
    <button type="button" class="link-button" (click)="copyURL(); adDropDownDirective.hideSelf()">
        <i class="icon icon-ic_link_2 ic-copy-links"></i>
        <span>Скопировать ссылку</span>
    </button>
  </div>
</ng-template>
