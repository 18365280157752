import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { User } from '@inaripro-nx/auth';
import { WINDOW } from '@inaripro-nx/common-ui';
import { ClickOutsideDirective } from '@inaripro-nx/design-ui';
import { Subscription } from 'rxjs';
import { HeaderLinkComponent } from '../header-link/header-link.component';

@Component({
  selector: 'shell-header-menu-user',
  standalone: true,
  imports: [CommonModule, HeaderLinkComponent, ClickOutsideDirective],
  templateUrl: './header-menu-user.component.html',
  styleUrls: ['./header-menu-user.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderMenuUserComponent implements OnInit, OnDestroy {
  @Input() user: User | null = null;
  @Output() signout: EventEmitter<void> = new EventEmitter<void>();

  userMenuState = false;

  get isAdmins(): boolean {
    if (!this.user) {
      return false;
    }
    return this.user.isAdministrator || this.user.isModerator;
  }

  get isAuthUsers(): boolean {
    if (!this.user) {
      return false;
    }
    return this.user && (this.user.isPartner || this.user.isCustomer);
  }

  get isPartner(): boolean {
    if (!this.user) {
      return false;
    }
    return this.user.isPartner;
  }

  get accessPartners(): boolean {
    if (!this.user) {
      return false;
    }
    return this.user.accessCalculators || this.user.accessCatalog;
  }

  get accessCalculators(): boolean {
    if (!this.user) {
      return false;
    }
    return this.user.accessCalculators;
  }

  get accessCatalog(): boolean {
    if (!this.user) {
      return false;
    }
    return this.user.accessCatalog;
  }

  get loginUrl() {
    return `/login?returnUrl=${window.location.href}`;
  }

  _routeSub: Subscription | null = null;
  set routeSub(routeSub: Subscription | null) {
    if (this._routeSub) {
      this._routeSub.unsubscribe();
    }

    this._routeSub = routeSub;
  }

  constructor(
    @Inject(WINDOW) public readonly window: Window,
    private route: ActivatedRoute,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.routeSub = this.route.params.subscribe(() => this.cdr.detectChanges());
  }

  ngOnDestroy(): void {
    this.routeSub = null;
  }

  openUserMenu() {
    this.userMenuState = !this.userMenuState;
  }
}
