import { HttpEventType } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IPicture } from '@inaripro-nx/common-ui';
import { ComponentStore } from '@ngrx/component-store';
import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { catchError, EMPTY, exhaustMap, mergeMap, Observable, tap } from 'rxjs';
import { PicturesService } from '../../services/pictures/pictures.service';
import { PicturesLibraryStore } from '../pictures-library/pictures-library.store';

export interface PicturesState extends EntityState<IPicture> {
  currentId: number | null;
}

export const adapter = createEntityAdapter<IPicture>({
  sortComparer: (a: IPicture, b: IPicture) => b.id - a.id,
});

const initialState: PicturesState = adapter.getInitialState({
  currentId: null,
});

const { selectAll, selectEntities } = adapter.getSelectors();

@Injectable()
export class PicturesStore extends ComponentStore<PicturesState> {
  collection$ = this.select(selectAll);
  dictionary$ = this.select(selectEntities);

  loadCollection = this.effect((trigger$) =>
    trigger$.pipe(
      exhaustMap(() =>
        this.collectionService.all().pipe(
          tap((collection) => {
            if (!collection.length) {
              this.picturesLibraryStore.selectAnyLibrary();
            }

            this.setState((state) => adapter.setAll(collection, state));
          }),
          catchError(() => EMPTY)
        )
      )
    )
  );

  addModel = this.effect((model$: Observable<IPicture>) =>
    model$.pipe(
      tap((newModel) =>
        this.setState((state) => adapter.addOne(newModel, state))
      )
    )
  );

  // updateModel = this.effect((model$: Observable<IPicture>) =>
  //   model$.pipe(
  //     concatMap((updateModel) =>
  //       this.collectionService.update(updateModel).pipe(
  //         tap((model) =>
  //           this.setState((state) =>
  //             adapter.updateOne({ id: model.id, changes: model }, state)
  //           )
  //         ),
  //         catchError(() => EMPTY)
  //       )
  //     )
  //   )
  // );

  deleteModel = this.effect((modelId$: Observable<number>) =>
    modelId$.pipe(
      tap((modelId) =>
        this.setState((state) =>
          adapter.updateOne(
            { id: modelId, changes: { markForDelete: true } },
            state
          )
        )
      ),
      mergeMap((modelId) =>
        this.collectionService.delete(modelId).pipe(
          tap(() =>
            this.setState((state) =>
              adapter.removeOne(modelId, {
                ...state,
                currentId: state.currentId !== modelId ? state.currentId : null,
              })
            )
          ),
          catchError(() => {
            this.setState((state) =>
              adapter.updateOne(
                { id: modelId, changes: { markForDelete: false } },
                state
              )
            );

            return EMPTY;
          })
        )
      )
    )
  );

  uploadFile = (file: File) =>
    this.collectionService.uploadFile(file).pipe(
      tap((event) => {
        if (event.type == HttpEventType.Response) {
          const picture = event.body;

          if (!picture) {
            return;
          }

          this.setState((state) => {
            this.picturesLibraryStore.setCurrentId(0);
            return adapter.addOne(picture, state);
          });
        }
      })
    );

  constructor(
    private collectionService: PicturesService,
    private picturesLibraryStore: PicturesLibraryStore
  ) {
    super({ ...initialState });
  }

  init() {
    this.loadCollection();
  }
}
