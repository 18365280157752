import { Pipe, PipeTransform } from '@angular/core';
import { ProductTypeEnum } from '../../enums/catalog-product.enum';

@Pipe({
  name: 'catalog2ProductType',
  standalone: true,
})
export class Catalog2ProductTypePipe implements PipeTransform {
  static getTransform(productType: ProductTypeEnum): string {
    switch (productType) {
      case ProductTypeEnum.BASE_PRODUCT: {
        return 'Базовый товар';
      }

      case ProductTypeEnum.DESIGN: {
        return 'Цветовая гамма';
      }

      case ProductTypeEnum.EXAMPLE: {
        return 'Примеры работы';
      }

      case ProductTypeEnum.ALL: {
        return 'Все';
      }

      default:
        return '';
    }
  }

  transform(productType: ProductTypeEnum, args?: any): string {
    return Catalog2ProductTypePipe.getTransform(productType);
  }
}
