<ng-container *ngIf="externalLink">
  <ng-container
    [ngTemplateOutlet]="externalLinkTemplate"
    [ngTemplateOutletContext]="{ link: externalLink }"
  ></ng-container>
</ng-container>

<ng-container *ngIf="link">
  <ng-container *ngIf="config$ | async as config; else relativeLinkTemplate">
    <ng-container [ngSwitch]="config.publicLinkType">
      <ng-container *ngSwitchCase="linkTypeEnum.External">
        <ng-container
          [ngTemplateOutlet]="externalLinkTemplate"
          [ngTemplateOutletContext]="{ link: config.publicUrl + link }"
        ></ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="linkTypeEnum.Relative">
        <ng-container [ngTemplateOutlet]="relativeLinkTemplate"></ng-container>
      </ng-container>
      <ng-container *ngSwitchDefault>
        <ng-container [ngTemplateOutlet]="relativeLinkTemplate"></ng-container>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-container>

<ng-template #relativeLinkTemplate>
  <a
    [routerLinkActive]="'link-active'"
    [routerLinkActiveOptions]="{ exact }"
    [routerLink]="link"
  >
    <ng-content></ng-content>
    {{ name }}
  </a>
</ng-template>

<ng-template #externalLinkTemplate let-link="link">
  <a [attr.href]="link">
    <ng-content></ng-content>
    {{ name }}
  </a>
</ng-template>
