import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { PipesModule as CommonPipesModule } from '@inaripro-nx/common-ui';
import { Links } from '../../../../../../../links/src/lib/interfaces/links.interface';
import { DropdownDelayHoverDirective } from '../../directives/dropdown-delay-hover.directive';
import { HeaderLinkComponent } from '../header-link/header-link.component';

@Component({
  selector: 'shell-header-link-info',
  standalone: true,
  imports: [
    CommonModule,
    CommonPipesModule,
    DropdownDelayHoverDirective,
    HeaderLinkComponent,
  ],
  templateUrl: './header-link-info.component.html',
  styleUrls: ['./header-link-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderLinkInfoComponent {
  @Input() links?: Links;
}
