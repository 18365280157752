import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { DesignUiModule } from '@inaripro-nx/design-ui';
import { Observable } from 'rxjs';
import { IElement } from '../../../../interfaces/editor.interface';
import { ELEMENT_FILL_MODAL_UID } from '../../../../interfaces/main.interface';
import { ActionsTextService } from '../../../../services/actions-text/actions-text.service';
import { EditorService } from '../../../../services/editor/editor.service';
import { EyeDropperService } from '../../../../services/eye-dropper/eye-dropper.service';
import { WindowToolsService } from '../../../../services/window-tools/window-tools.service';

@Component({
  selector: 'painter-actions-element-fill',
  standalone: true,
  imports: [CommonModule, DesignUiModule],
  templateUrl: './actions-element-fill.component.html',
  styleUrls: ['./actions-element-fill.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActionsElementFillComponent {
  @Input() element!: IElement;
  @Input() withModal = false;

  readonly ELEMENT_FILL_MODAL_UID = ELEMENT_FILL_MODAL_UID;

  isDesktop$: Observable<boolean> = this.windowToolsService.isDesktop$;

  constructor(
    public editorService: EditorService,
    public eyeDropperService: EyeDropperService,
    private windowToolsService: WindowToolsService,
    public actionsTextService: ActionsTextService
  ) {}
}
