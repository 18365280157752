import {
  Directive,
  ElementRef,
  HostListener,
  Inject,
  Input,
} from '@angular/core';
import { WINDOW } from '@inaripro-nx/common-ui';

const DURATION = 400;
const MOBILE_WIDTH = 980;

@Directive({
  selector: '[designDropdownDelayHover]',
  standalone: true,
})
export class DropdownDelayHoverDirective {
  @Input() hoverClass = 'hovered';
  @Input() dropdownElement: HTMLElement | null = null;

  private timer: NodeJS.Timeout | undefined;

  constructor(
    private readonly elementRef: ElementRef,
    @Inject(WINDOW) private window: Window
  ) {}

  @HostListener('mouseover')
  onMouseOver() {
    this.timeoutHandler(() => {
      this.elementRef.nativeElement.classList.add(this.hoverClass);
      if (this.dropdownElement) {
        this.dropdownElement.classList.add(this.hoverClass);
      }
    });
  }

  @HostListener('mouseout')
  onMouseOut() {
    this.timeoutHandler(() => {
      this.elementRef.nativeElement.classList.remove(this.hoverClass);
      if (this.dropdownElement) {
        this.dropdownElement.classList.remove(this.hoverClass);
      }
    });
  }

  private timeoutHandler(callback: () => void) {
    if (this.timer) {
      clearTimeout(this.timer);
    }

    if (this.window.innerWidth < MOBILE_WIDTH) {
      return;
    }

    this.timer = setTimeout(callback, DURATION);
  }
}
