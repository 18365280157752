export interface Links {
  id: number;
  moscowContacts: ContactLink[];
  piterContacts: ContactLink[];
  contacts: ContactLink[];
  nomenclatureList: NomenclatureLink[];
  infoList: Link[];
}

export interface Link {
  id: number;
  name: string;
  slug?: string;
  externalUrl?: string;
}

export interface ContactLink extends Link {
  phone?: string;
  isHeader?: boolean;
  isFooter?: boolean;
}

export interface NomenclatureLink {
  id: number; // link id
  name: string;
  canAddProduct: boolean;
  dictionaryId: number;
  dictionarySlug: string;
}
