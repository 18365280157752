import {
  ChangeDetectionStrategy,
  Component,
  HostListener,
} from '@angular/core';
import { isTouchDevice } from '@inaripro-nx/common-ui';

@Component({
  selector: 'designer-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent {
  private doubleTouchStartTimestamp = 0;

  @HostListener('click', ['$event'])
  onClick($event: UIEvent) {
    if (isTouchDevice()) {
      const now = +new Date();

      if (this.doubleTouchStartTimestamp + 500 > now) {
        $event.preventDefault();
        $event.stopPropagation();
      }

      this.doubleTouchStartTimestamp = now;
    }
  }
}
