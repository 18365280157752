import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IFigure } from '@inaripro-nx/common-ui';
import { Observable, map, shareReplay } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FiguresService {
  figures$: Observable<IFigure[]> = this.http
    .get<{ data: IFigure[] }>('/catalog/public/design/figure/?limit=5000')
    .pipe(
      map((response) => response.data),
      shareReplay({ refCount: false, bufferSize: 1 })
    );

  constructor(private http: HttpClient) {}
}
