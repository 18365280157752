export function isNullOrUndefined(val: unknown): boolean {
  return val === undefined || val === null;
}

export function isObjectsEqual<T = unknown>(obj1: T, obj2: T): boolean {
  if (Array.isArray(obj1) || Array.isArray(obj2)) {
    if (!Array.isArray(obj1) || !Array.isArray(obj2)) {
      return false;
    }
    if (obj1.length !== obj2.length) {
      return false;
    }

    return obj1.every((propVal, i) => isObjectsEqual(propVal, obj2[i]));
  }

  if (isObject(obj1) && isObject(obj2)) {
    const allKeys = Array.from(
      new Set([...Object.keys(obj1), ...Object.keys(obj2)])
    ) as Array<keyof T>;

    if (allKeys.length === 0) {
      return true;
    }

    return allKeys.every((key) => isObjectsEqual(obj1[key], obj2[key]));
  }

  return obj1 === obj2;
}

export function isObject(obj: unknown): obj is object {
  return !Array.isArray(obj) && Object(obj) === obj;
}
