import { Inject, Pipe, PipeTransform } from '@angular/core';
import { EElementType, IElement } from '../../interfaces/editor.interface';
import { DOCUMENT } from '@angular/common';

@Pipe({
  name: 'elementImageXLink',
})
export class ElementImageXlinkPipe implements PipeTransform {
  constructor(@Inject(DOCUMENT) private document: Document) {}

  transform(element: IElement): string | null {
    if (!element || element.type !== EElementType.image) {
      return null;
    }

    const svgElement = this.document.createElementNS(
      'http://www.w3.org/2000/svg',
      'svg'
    );
    svgElement.innerHTML = element.svg;

    const image = svgElement.getElementsByTagName('image')[0];

    return image
      ? image.getAttributeNS('http://www.w3.org/1999/xlink', 'href')
      : null;
  }
}
