import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import {
  PipesModule as CommonPipesModule,
  isDoubleTouchEvent,
  isTouchDevice,
} from '@inaripro-nx/common-ui';
import { IElement } from '../../../../interfaces/editor.interface';
import { PipesModule } from '../../../../pipes/pipes.module';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[data-painter-editor-element-transform]',
  standalone: true,
  imports: [CommonModule, CommonPipesModule, PipesModule],
  templateUrl: './editor-element-transform.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditorElementTransformComponent {
  @Input() el!: IElement;
  @Output() mousedownEvent: EventEmitter<MouseEvent | TouchEvent> =
    new EventEmitter();

  onMouseDown(event: MouseEvent) {
    if (!isTouchDevice()) {
      this.mousedownEvent.emit(event);
    }
  }

  onTouchStart(event: TouchEvent) {
    if (isTouchDevice() && !isDoubleTouchEvent(event)) {
      this.mousedownEvent.emit(event);
    }
  }
}
