<div class="modification">
  <button
    type="button"
    (click)="toggleFontWeight.emit()"
    [class.modification-active]="textElement.fontWeight === IFontWeight.bold"
    title="Жирный"
  >
    <i class="icon icon-ic_text-bold"></i>
  </button>

  <button
    type="button"
    (click)="toggleFontStyle.emit()"
    [class.modification-active]="textElement.fontStyle === IFontStyle.italic"
    title="Курсив"
  >
    <i class="icon icon-ic_text-italic"></i>
  </button>

  <button
    type="button"
    (click)="centerElement.emit({ x: true, y: false })"
    title="Центрировать по вертикали"
  >
    <i class="icon icon-ic_center-vertically"></i>
  </button>

  <button
    type="button"
    (click)="centerElement.emit({ x: false, y: true })"
    title="Центрировать по горизонтали"
  >
    <i class="icon icon-ic_center-horizontally"></i>
  </button>

  <div class="button-letter-spacing" #textArcIconWrapper>
    <button
      type="button"
      title="Закругление текста"
      designAdDropDown
      [dropDownAlignElement]="textArcIconWrapper"
      [dropDownOpenTo]="mode === 'desktop' ? 'down' : 'up'"
      [dropDownAlign]="'right'"
      [dropDownClass]="'drop-down-text-arc'"
      [dropDownContentTemplateRef]="textArcSlider"
    >
      <i class="icon icon-text-arc"></i>
    </button>
  </div>

  <button
    type="button"
    [disabled]="hasAngle"
    (click)="!hasAngle && changeTextAnchor.emit(ITextAnchor.middle)"
    [class.modification-active]="textElement.textAnchor === ITextAnchor.middle"
    title="По центру"
  >
    <i class="icon icon-ic_text-align-center"></i>
  </button>

  <button
    type="button"
    [disabled]="hasAngle"
    (click)="!hasAngle && changeTextAnchor.emit(ITextAnchor.start)"
    [class.modification-active]="textElement.textAnchor === ITextAnchor.start"
    title="По левому краю"
  >
    <i class="icon icon-ic_text-align-left"></i>
  </button>

  <button
    type="button"
    [disabled]="hasAngle"
    (click)="!hasAngle && changeTextAnchor.emit(ITextAnchor.end)"
    [class.modification-active]="textElement.textAnchor === ITextAnchor.end"
    title="По правому краю"
  >
    <i class="icon icon-ic_text-align-right"></i>
  </button>

  <div class="button-line-space" #lineSpaceIconWrapper>
    <button
      type="button"
      [disabled]="hasAngle"
      designAdDropDown
      [dropDownAlignElement]="lineSpaceIconWrapper"
      [dropDownItems]="lineSpaces"
      [dropDownOpenTo]="mode === 'desktop' ? 'down' : 'up'"
      [dropDownAlign]="'left'"
      [dropDownClass]="'drop-down-line-space'"
      [dropDownLabelActive]="elementLineSpaceLabel"
      (selectItem)="!hasAngle && changeLineSpace.emit($event.item)"
      title="Междустрочный интервал"
    >
      <i class="icon icon-line-space"></i>
    </button>
  </div>

  <div class="button-letter-spacing" #letterSpaceIconWrapper>
    <button
      type="button"
      title="Межбуквенный интервал"
      designAdDropDown
      [dropDownAlignElement]="letterSpaceIconWrapper"
      [dropDownOpenTo]="mode === 'desktop' ? 'down' : 'up'"
      [dropDownAlign]="'right'"
      [dropDownClass]="'drop-down-letter-space'"
      [dropDownContentTemplateRef]="letterSpaceSlider"
    >
      <i class="icon icon-text-letter-spacing"></i>
    </button>
  </div>
</div>

<ng-template #letterSpaceSlider>
  <div class="dropdown-slider">
    <div class="dropdown-slider__name">Интервал между символами</div>
    <design-range-slider-wrapper
      class="dropdown-slider__control"
      [unitmeasurement]="''"
      [minValue]="-10"
      [maxValue]="100"
      [value]="textElement.letterSpacing || 0"
      (changeValue)="changeLetterSpacing.emit($event)"
      (stopChange)="stopChangeLetterSpacing.emit()"
      (resetValue)="changeLetterSpacing.emit(0)"
    ></design-range-slider-wrapper>
  </div>
</ng-template>

<ng-template #textArcSlider>
  <div class="dropdown-slider">
    <div class="dropdown-slider__name">Текст по кругу</div>
    <design-range-slider-wrapper
      class="dropdown-slider__control"
      [unitmeasurement]="'°'"
      [minValue]="-360"
      [maxValue]="360"
      [value]="textElement.textArc?.angle || 0"
      (changeValue)="changeTextArc.emit($event)"
      (stopChange)="stopChangeTextArc.emit()"
      (resetValue)="changeTextArc.emit(0)"
    ></design-range-slider-wrapper>
  </div>
</ng-template>
