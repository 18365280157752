<div class="panel">
  <div class="spectrum-map">
    <button
      id="spectrum-cursor"
      class="color-spectrum-cursor"
      #spectrumCursor
    ></button>
    <canvas id="spectrum-canvas" #spectrumCanvas></canvas>
  </div>
  <div class="hue-map-wrapper">
    <div class="hue-map">
      <button id="hue-cursor" class="color-hue-cursor" #hueCursor></button>
      <canvas id="hue-canvas" #hueCanvas></canvas>
    </div>
  </div>
</div>
