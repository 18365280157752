import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CabinetComponent } from './components/cabinet/cabinet.component';
import { MainComponent } from './components/main/main.component';
import { ExportService } from './components/main/services/export/export.service';

@NgModule({
  imports: [CommonModule, MainComponent, CabinetComponent],
  exports: [MainComponent, CabinetComponent],
  providers: [ExportService],
})
export class PainterModule {}
