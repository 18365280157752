import { Inject, Injectable } from '@angular/core';
import { getResizedInlineSvg, loadImage } from '../utils/crop.utils';
import { DOCUMENT } from '@angular/common';

@Injectable()
export class CropService {
  constructor(@Inject(DOCUMENT) private document: Document) {}

  async cutImage(
    imageX: number,
    imageY: number,
    canvasWidth: number,
    canvasHeight: number,
    imageUrl: string,
    inlineSvgMask: string | null
  ) {
    const canvas = this.document.createElement('canvas');
    canvas.width = canvasWidth;
    canvas.height = canvasHeight;

    const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;
    ctx.clearRect(0, 0, canvasWidth, canvasHeight);

    const image = await loadImage(imageUrl);
    ctx.drawImage(image, imageX, imageY);

    if (inlineSvgMask) {
      const mask = getResizedInlineSvg(
        inlineSvgMask,
        canvasWidth,
        canvasHeight
      );
      const svgUrl = URL.createObjectURL(
        new Blob([mask], { type: 'image/svg+xml' })
      );
      const svgImage = await loadImage(svgUrl);

      ctx.globalCompositeOperation = 'destination-in';
      ctx.drawImage(svgImage, 0, 0);

      URL.revokeObjectURL(svgUrl);
    }

    return canvas.toDataURL('image/png');
  }
}
