export function getContrastYIQ(hexcolor: string): string {
  if (hexcolor && hexcolor.indexOf('#') < 0) {
    hexcolor = nameToRGB(hexcolor);
  }
  hexcolor = hexcolor ? hexcolor.replace('#', '') : 'FFFFFF';
  const r = parseInt(hexcolor.substr(0, 2), 16);
  const g = parseInt(hexcolor.substr(2, 2), 16);
  const b = parseInt(hexcolor.substr(4, 2), 16);
  const yiq = (r * 299 + g * 587 + b * 114) / 1000;
  return yiq >= 172 ? '#3b4256' : 'white';
}

export function nameToRGB(name: string) {
  const fakeDiv = document.createElement('div');
  fakeDiv.style.color = name;
  document.body.appendChild(fakeDiv);

  const cs = window.getComputedStyle(fakeDiv);
  const pv = cs.getPropertyValue('color');

  document.body.removeChild(fakeDiv);
  const rgb = pv.substr(4).split(')')[0].split(',');
  let r = (+rgb[0]).toString(16);
  let g = (+rgb[1]).toString(16);
  let b = (+rgb[2]).toString(16);

  if (r.length == 1) r = '0' + r;
  if (g.length == 1) g = '0' + g;
  if (b.length == 1) b = '0' + b;

  return '#' + r + g + b;
}
