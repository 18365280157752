<div class="prices">
  <span
    *ngIf="showDiscount"
    class="prices__price"
    [class.prices__price--old]="product.discountPrice"
  >
    {{ product.basePrice | number:'1.0-2' }}&nbsp;Р
  </span>

  <span class="prices__price" [class.prices__price--new]="showDiscount">
    {{ (product.discountPrice || product.basePrice) | number:'1.0-2' }}&nbsp;Р
  </span>
</div>
