import { Injectable } from '@angular/core';
import { IElement, IXY, IXYb } from '../../interfaces/editor.interface';

@Injectable()
export class ActionsService {
  centerElement(data: { element: IElement; center: IXYb }): IElement | null {
    const { element, center } = data;
    const updateCenter = { x: 0, y: 0 };

    const x = center.x
      ? updateCenter.x - (element.size.x / 2) * element.scale.x
      : element.translate.x;

    const y = center.y
      ? updateCenter.y - (element.size.y / 2) * element.scale.y
      : element.translate.y;

    const translate: IXY = { x, y };

    return {
      ...element,
      translate,
    };
  }
}
