<div
  painterEditorSides
  class="sides"
  [class.sides--slider]="isSliderActive"
  [painterEditorSidesSize]="size"
  [painterEditorSidesSlider]="slider"
  [painterEditorSidesArrowUp]="arrowUp"
  [painterEditorSidesArrowDown]="arrowDown"
  [painterEditorSidesActiveIndex]="activeDesignSideIndex || 0"
>
  <div class="sides__slider-container" #slider>
    <div
      *ngFor="let side of designProduct?.sides; let sideIndex = index"
      class="sides__item"
      [class.sides__item--active]="sideIndex === activeDesignSideIndex"
      (click)="selectSide.emit(sideIndex)"
    >
      <img
        class="sides__item-img-mockup"
        [class.sides__item-img-mockup--up]="side.mockupType === 'up'"
        [src]="side.image.url"
      />
      <span class="sides__item-name">{{ sideIndex + 1 }}</span>
    </div>
  </div>

  <div
    #arrowUp
    class="sides__slider-arrow sides__slider-arrow--up disabled"
    [hidden]="!isSliderActive"
  >
    <i class="icon icon-ic_arrow-up"></i>
  </div>
  <div
    #arrowDown
    class="sides__slider-arrow sides__slider-arrow--down"
    [hidden]="!isSliderActive"
  >
    <i class="icon icon-ic_arrow-down"></i>
  </div>
</div>
