import { ImageSizeEnum } from '../enums';

export interface IFormFileRequest {
  name: string;
  file: File;
  size?: ImageSizeEnum;
  error?: string;
  uploadState?: EUploadState;
  uploadProgress?: number;
}

export interface IFormFileResponse {
  file: IFileResponse;
}

export enum EUploadState {
  'PENDING' = 'PENDING',
  'IN_PROGRESS' = 'IN_PROGRESS',
  'DONE' = 'DONE',
  'ERROR_SERVER' = 'ERROR_SERVER',
  'SAVED' = 'SAVED',
  'ERROR_FRONT' = 'ERROR_FRONT',
}

export interface IFileResponse {
  name: string;
  id: number;
  url: string;
  size?: ImageSizeEnum;
  sizes?: IFileResponse[];
  position?: number;
  title?: string;
  alt?: string;
  comment?: string;
}

export type IFileRR = IFormFileRequest | IFileResponse;
