import { CommonModule, NgIf } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Inject,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { WINDOW } from '@inaripro-nx/common-ui';
import { combineLatest, Observable, Subject } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  map,
  shareReplay,
  startWith,
} from 'rxjs/operators';
import { DesignLightGalleryContentNewImageComponent } from './components/design-light-gallery-content-new-image/design-light-gallery-content-new-image.component';
import { DesignLightGalleryContentNewPreviewComponent } from './components/design-light-gallery-content-new-preview/design-light-gallery-content-new-preview.component';
import { DesignLightGalleryContentNewThumbnailsVerticalComponent } from './components/design-light-gallery-content-new-thumbnails-vertical/design-light-gallery-content-new-thumbnails-vertical.component';
import { DesignLightGalleryContentNewVideoComponent } from './components/design-light-gallery-content-new-video/design-light-gallery-content-new-video.component';
import { IGalleryImage } from './interfaces/design-light-gallery-content-new.interface';
import { isDesktopMode } from './utils/image-utils';

@Component({
  selector: 'design-light-gallery-content-new',
  standalone: true,
  imports: [
    DesignLightGalleryContentNewImageComponent,
    DesignLightGalleryContentNewVideoComponent,
    DesignLightGalleryContentNewPreviewComponent,
    DesignLightGalleryContentNewThumbnailsVerticalComponent,
    NgIf,
    CommonModule,
  ],
  templateUrl: './design-light-gallery-content-new.component.html',
  styleUrls: ['./design-light-gallery-content-new.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DesignLightGalleryContentNewComponent
  implements AfterViewInit, OnChanges
{
  @Input() galleryImages: IGalleryImage[] = [];
  @Input() galleryVideos: string[] = [];
  @Input() initAlbumPhoto: number | null = null;
  @Input() previewEnabled = true;
  @Input() modalView = false;

  private _selectedIndex = 0;
  get selectedIndex(): number {
    return this._selectedIndex;
  }

  private readonly galleryWidthSubject$ = new Subject<number>();
  private readonly galleryHeightSubject$ = new Subject<number>();

  readonly galleryHeight$: Observable<number> = this.galleryHeightSubject$.pipe(
    distinctUntilChanged(),
    debounceTime(100),
    shareReplay({ refCount: true, bufferSize: 1 })
  );

  readonly galleryWidth$: Observable<number> = combineLatest([
    this.galleryHeight$,
    this.galleryWidthSubject$.asObservable().pipe(startWith(0)),
  ]).pipe(
    map(([galleryHeight, maxWidth]) =>
      Math.min((galleryHeight * 2) / 3, maxWidth || 0)
    ),
    shareReplay({ refCount: true, bufferSize: 1 })
  );

  public videoIsActive = false;
  public galleryIsOpened = false;
  public galleryPreviewHeight = 0;

  @ViewChild(DesignLightGalleryContentNewPreviewComponent, { static: false })
  preview?: DesignLightGalleryContentNewPreviewComponent;

  @ViewChild('lightGalleryContentRef', { static: true })
  lightGalleryContentRef?: ElementRef<HTMLDivElement>;

  @ViewChild('lightGalleryPreviewContentRef', { static: false })
  lightGalleryPreviewContentRef?: DesignLightGalleryContentNewPreviewComponent;

  constructor(
    @Inject(WINDOW) private window: Window,
    private cdr: ChangeDetectorRef
  ) {}

  @HostListener('window:resize', ['$event'])
  private onResize() {
    this.updateGalleryHeight();
  }

  ngAfterViewInit() {
    this.updateGalleryHeight();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['initAlbumPhoto']) {
      this._selectedIndex = (this.galleryImages || []).findIndex(
        (img) => img.id === this.initAlbumPhoto
      );

      if (this._selectedIndex < 0) {
        this._selectedIndex = 0;
      }
    }
  }

  public selectFromImage(index: number): void {
    this.select(index);
  }

  public selectFromThumbnails(index: number): void {
    this.select(index);
  }

  public selectFromPreview(index: number): void {
    this.select(index);
  }

  public openPreview(_index: number): void {
    if (isDesktopMode(this.window)) {
      this.preview?.open();
    }
  }

  public toggleVideo(active: boolean): void {
    this.videoIsActive = active;
  }

  public togglePreview(active: boolean): void {
    this.galleryIsOpened = active;
    this.galleryPreviewHeight =
      this.lightGalleryPreviewContentRef?.previewHeight || 0;
  }

  private select(index: number): void {
    this._selectedIndex = index;
    this.cdr.detectChanges();
  }

  private updateGalleryHeight() {
    if (this.lightGalleryContentRef) {
      this.galleryWidthSubject$.next(
        this.lightGalleryContentRef.nativeElement.clientWidth
      );

      this.galleryHeightSubject$.next(
        this.lightGalleryContentRef.nativeElement.clientHeight
      );
    }
  }
}
