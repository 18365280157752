import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { CookieService, WINDOW } from '@inaripro-nx/common-ui';
import {
  BehaviorSubject,
  Observable,
  map,
  of,
  shareReplay,
  switchMap,
} from 'rxjs';
import { IUser } from '../../interfaces/user.interface';
import { User } from '../../user';

const TOKEN_COOKIE_NAME = 'X-User-Token';

@Injectable()
export class AuthService {
  readonly token$: BehaviorSubject<string | null> = new BehaviorSubject(
    this.getTokenFromStorage()
  );

  readonly user$: Observable<User | null> = this.token$.pipe(
    switchMap((token) => {
      if (token) {
        return this.httpClient
          .get<{ user: IUser }>('auth/user/')
          .pipe(map(({ user }) => new User(user)));
      }

      return of(null);
    }),
    shareReplay({ refCount: false, bufferSize: 1 })
  );

  constructor(
    @Inject(WINDOW) private window: Window,
    private cookieService: CookieService,
    private readonly httpClient: HttpClient
  ) {}

  getAuthorizationHeader() {
    const token = this.getTokenFromStorage();
    return token ? `Token ${token}` : null;
  }

  private getTokenFromStorage() {
    return this.cookieService.getCookie(TOKEN_COOKIE_NAME) || null;
  }
}
