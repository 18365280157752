import { AsyncPipe, NgFor, NgIf, NgTemplateOutlet } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  IPaginationRequest,
  PaginationOrderByType,
  PaginationOrderDirectionType,
  WINDOW,
} from '@inaripro-nx/common-ui';
import {
  DesignUiModule,
  ModalWindowComponent,
  ModalWindowStore,
} from '@inaripro-nx/design-ui';
import {
  BehaviorSubject,
  Observable,
  Subject,
  debounceTime,
  filter,
  fromEvent,
  map,
  shareReplay,
  startWith,
  switchMap,
  takeUntil,
} from 'rxjs';
import { SimplebarAngularModule } from 'simplebar-angular';
import {
  ICatalogNomenclatureListItem,
  ICatalogNomenclatureTreeItem,
  ICategoryIdentify,
  IProductPostListRequest,
  IPublicProductForList,
} from '../../interfaces';
import { CatalogNomenclatureStore } from '../../state';
import { CatalogProductListComponent } from '../catalog-product-list/catalog-product-list.component';
import { CatalogTreeComponent } from '../catalog-tree/catalog-tree.component';

interface ISortOption {
  name: string;
  value: PaginationOrderByType;
  direction: PaginationOrderDirectionType;
}

@Component({
  selector: 'catalog-select-product',
  standalone: true,
  imports: [
    DesignUiModule,
    CatalogTreeComponent,
    CatalogProductListComponent,
    SimplebarAngularModule,
    NgIf,
    NgFor,
    NgTemplateOutlet,
    AsyncPipe,
  ],
  templateUrl: './catalog-select-product.component.html',
  styleUrls: ['./catalog-select-product.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CatalogSelectProductComponent implements OnChanges {
  @Input() selectedProductId?: number | null = null;

  @Input() productListRequest?: Partial<IProductPostListRequest> | null = null;

  @Input() filterCatalogNomenclatureTreeItem?: (
    item: ICatalogNomenclatureTreeItem
  ) => boolean;

  @Input() containerDictionaryId: number | null = null;
  @Input() containers: ICategoryIdentify[] = [];
  @Input() linkId: number | null = null;

  @Output() selectProductListItem: EventEmitter<{
    productId: number;
    linkId: number | null;
  }> = new EventEmitter();

  @ViewChild('modalWindow') modalWindow?: ModalWindowComponent;

  public toggleMenu = true;

  readonly modalUid = 'product_select';

  readonly dictionary$ = this.catalogNomenclatureStore.dictionary$;
  readonly treeItemsNoLink$ = this.catalogNomenclatureStore.treeItemsNoLink$;

  private selectedLinkId: number | null = null;

  private readonly containerDictionaryIdSubject$ = new BehaviorSubject<
    number | null
  >(null);
  private readonly containerDictionaryId$: Observable<number | null> =
    this.containerDictionaryIdSubject$.asObservable();

  private readonly selectedItemIdSubject$ = new Subject<number | null>();
  readonly selectedItemId$: Observable<number | null> =
    this.selectedItemIdSubject$.asObservable();

  readonly expandedMap$ = this.containerDictionaryId$.pipe(
    switchMap((containerDictionaryId) => {
      return this.catalogNomenclatureStore.listItemsNoLink$.pipe(
        map((listItemsNoLink) =>
          listItemsNoLink?.find(
            (item) => item.dictionaryId === containerDictionaryId
          )
        ),
        map((item) => {
          if (!item) {
            return {};
          }

          this.selectedItemIdSubject$.next(item.id);
          this.requestSubject$.next(this.getRequest(item.dictionaryId));

          const expandedMap = { [item.id]: true };
          (item as ICatalogNomenclatureListItem).parentId.map((id) => {
            expandedMap[id] = true;
          });
          return expandedMap;
        })
      );
    }),
    shareReplay({ refCount: false, bufferSize: 1 })
  );

  private readonly requestSubject$ = new BehaviorSubject<{
    request: IProductPostListRequest;
    paginationRequest: IPaginationRequest;
  } | null>(null);

  readonly request$ = this.requestSubject$.asObservable();

  readonly title$ = this.request$.pipe(
    filter(Boolean),
    switchMap(({ request }) =>
      this.dictionary$.pipe(
        map((dictionary) =>
          dictionary && request?.nomenclature
            ? dictionary[request.nomenclature].name
            : ''
        )
      )
    )
  );

  readonly modal$ = this.modalWindowStore.modals$.pipe(
    map((modals) => !!modals[this.modalUid]),
    shareReplay({ refCount: false, bufferSize: 1 })
  );

  readonly scrollbarMinSize$ = this.modal$.pipe(
    filter((modal) => modal),
    switchMap(() =>
      fromEvent(this.window, 'resize').pipe(
        startWith(null),
        debounceTime(200),
        takeUntil(this.modal$.pipe(filter((modal) => !modal)))
      )
    ),
    map(() => {
      if (!this.modalWindow || !this.modalWindow.wrapperElement) {
        return 0;
      }

      const rect =
        this.modalWindow.wrapperElement.nativeElement.getBoundingClientRect();

      return rect.height - 120;
    })
  );

  readonly sortOptions: ISortOption[] = [
    {
      name: 'По умолчанию',
      value: 'default',
      direction: 'desc',
    },
    {
      name: 'По популярности',
      value: 'popularity',
      direction: 'desc',
    },
    {
      name: 'Цена по возрастанию',
      value: 'price',
      direction: 'asc',
    },
    {
      name: 'Цена по убыванию',
      value: 'price',
      direction: 'desc',
    },
  ];

  selectedSortOption = this.sortOptions[0];

  constructor(
    private readonly modalWindowStore: ModalWindowStore,
    private readonly catalogNomenclatureStore: CatalogNomenclatureStore,
    @Inject(WINDOW) private readonly window: Window
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (
      'containerDictionaryId' in changes ||
      'containers' in changes ||
      'linkId' in changes
    ) {
      const container = this.containers.find((c) => c.linkId === this.linkId);
      const containerDictionaryId =
        container?.dictionaryId || this.containerDictionaryId || null;
      this.containerDictionaryIdSubject$.next(containerDictionaryId);
      this.selectedLinkId = container?.linkId || null;
    }
  }

  toggleProductSelect(open: boolean) {
    this.modalWindowStore.patch({ [this.modalUid]: open });
  }

  selectTreeItem(item: ICatalogNomenclatureTreeItem) {
    this.selectedLinkId = item.id;
    this.requestSubject$.next(this.getRequest(item.dictionaryId));
  }

  selectProduct(selectedProduct: IPublicProductForList) {
    const productId =
      selectedProduct.autoCreatePropertyId && selectedProduct.baseProductId
        ? selectedProduct.baseProductId
        : selectedProduct.id;

    this.selectProductListItem.emit({
      productId,
      linkId: this.selectedLinkId,
    });

    this.toggleProductSelect(false);
  }

  onSelfCloseModal() {
    const containerDictionaryId = this.containerDictionaryIdSubject$.getValue();
    if (containerDictionaryId) {
      this.containerDictionaryIdSubject$.next(containerDictionaryId);
      this.requestSubject$.next(this.getRequest(containerDictionaryId));
    }
  }

  onChangeSort(option: unknown) {
    this.selectedSortOption = option as ISortOption;

    const request = this.requestSubject$.value;

    if (request) {
      this.requestSubject$.next({
        ...request,
        paginationRequest: {
          offset: 0,
          limit: 100,
          order_by: this.selectedSortOption.value,
          order_direction: this.selectedSortOption.direction,
        },
      });
    }
  }

  private getRequest(dictionaryId: number): {
    request: IProductPostListRequest;
    paginationRequest: IPaginationRequest;
  } {
    this.toggleMenu = false;
    return {
      request: {
        nomenclature: dictionaryId,
        ...this.productListRequest,
      },
      paginationRequest: {
        offset: 0,
        limit: 100,
        order_by: this.selectedSortOption.value,
        order_direction: this.selectedSortOption.direction,
      },
    };
  }
}
