import { Pipe, PipeTransform } from '@angular/core';
import { toDataUrl } from '../../utils';
import { ImageBase64Service } from './service/image-base64.service';

@Pipe({
  name: 'imageBase64',
})
export class ImageBase64Pipe implements PipeTransform {
  constructor(private imageBase64Service: ImageBase64Service) {}

  transform(url: string): Promise<string | ArrayBuffer | null> {
    return new Promise((resolve) => {
      const cache = this.imageBase64Service.cache[url];

      if (cache) {
        resolve(cache);
      } else {
        toDataUrl(url, (base64: string | ArrayBuffer | null) => {
          this.imageBase64Service.cache[url] = base64;
          resolve(base64);
        });
      }
    });
  }
}
