import { IDesignSide, IDesignZone } from '@inaripro-nx/catalog';
import {
  EElementType,
  IBaseElement,
  IElement,
  IElements,
  IXY,
  ELEMENT_MIN_SCALE,
  ELEMENT_MAX_SCALE,
  DEFAULT_TEXT_SIZE,
  DEFAULT_IMAGE_MIN_PPI,
  ISVGElement,
} from '../components/main/interfaces/editor.interface';
import {
  ISVGObject,
  ITextObject,
} from '../components/main/components/content/interfaces/content.interface';

export function resizeElements(
  sides: IDesignSide[],
  currentElements: IElements
): IElements {
  return currentElements.map((el) => {
    let { zone0Size, translate, scale } = el;
    const sideIndex = el.sideIndex;

    if (sideIndex >= sides.length) {
      return {
        ...el,
      };
    }

    const { size: newZone0Size = { x: 1, y: 1 } } = sides[sideIndex].zones[0];

    if (newZone0Size.x !== zone0Size.x || newZone0Size.y !== zone0Size.y) {
      translate = {
        x: (translate.x / zone0Size.x) * newZone0Size.x,
        y: (translate.y / zone0Size.y) * newZone0Size.y,
      };

      scale = {
        x: (scale.x / zone0Size.x) * newZone0Size.x,
        y: (scale.y / zone0Size.y) * newZone0Size.y,
      };

      zone0Size = { ...newZone0Size };
    }

    return {
      ...el,
      zone0Size,
      translate,
      scale,
    };
  });
}

export function getElementScale(
  element: IBaseElement,
  designSide: IDesignSide
): number {
  const { size, scale } = element;
  const { sizePX } = designSide;

  const maxScale = getElementMaxScale(element, designSide);
  const minScale = getElementMinScale(element, designSide);

  const scaleX = (size.x * scale.x) / sizePX.x;
  const scaleY = (size.y * scale.y) / sizePX.y;

  const props: IXY = {
    x: Math.max(Math.min(scaleX, maxScale), minScale) * 100,
    y: Math.max(Math.min(scaleY, maxScale), minScale) * 100,
  };

  return props.x > props.y ? props.x : props.y;
}

export function getZonesBoxData({
  zones,
  strokeWidth,
}: {
  zones: IDesignZone[];
  strokeWidth: number;
}) {
  const start: IXY = { x: 0, y: 0 };
  const end: IXY = { x: 0, y: 0 };

  for (let i = 0; i < zones.length; i++) {
    const startX = zones[i].start?.x ?? 0;
    const startY = zones[i].start?.y ?? 0;
    const sizeX = zones[i].size?.x ?? 0;
    const sizeY = zones[i].size?.y ?? 0;
    const endX = startX + sizeX;
    const endY = startY + sizeY;

    if (i === 0 || startX < start.x) {
      start.x = startX - strokeWidth;
    }

    if (i === 0 || startY < start.y) {
      start.y = startY - strokeWidth;
    }

    if (i === 0 || endX > end.x) {
      end.x = endX + strokeWidth;
    }

    if (i === 0 || endY > end.y) {
      end.y = endY + strokeWidth;
    }
  }

  const width = end.x - start.x;
  const height = end.y - start.y;

  return {
    start,
    end,
    width,
    height,
  };
}

export function fitElementToActiveDesignSide<T extends IBaseElement>(
  element: T,
  activeDesignSide: IDesignSide,
  updateCenter: IXY,
  data?: ITextObject | ISVGObject
) {
  const scale = getElementScale(element, activeDesignSide);
  const maxScale = getElementMaxScale(element, activeDesignSide, data);

  let coeff = 100;

  if (scale > 100 || scale < 20) {
    coeff = scale > 100 ? 100 : 20;
  }

  element.scale = {
    x: Math.min(element.scale.x * (coeff / scale), maxScale),
    y: Math.min(element.scale.y * (coeff / scale), maxScale),
  };

  element.translate = {
    x: updateCenter.x - (element.size.x * element.scale.x) / 2,
    y: updateCenter.y - (element.size.y * element.scale.y) / 2,
  };

  return element;
}

/**
 * @see https://wiki.yandex.ru/homepage/uxui/onlajjn-redaktor/sootvetstvie-trebovanijam-k-maketu/
 */
export function getElementMinScale(
  element: IBaseElement | IElement | undefined,
  activeDesignSide: IDesignSide | null,
  elementType?: EElementType
) {
  if (!element || !activeDesignSide) {
    return ELEMENT_MIN_SCALE;
  }

  const { sizePX, sizeMM } = activeDesignSide;
  const type = isTypedElement(element) ? element.type : elementType;

  if (type === EElementType.text) {
    // 3mm (~8.5pt) - минимально возможный размер текста
    // 0,01 - погрешность шкалы измерения
    const minFontSizeMM = 3 + 0.01;
    const scaleFactor = Math.min(sizePX.x / sizeMM.x, sizePX.y / sizeMM.y);
    return (minFontSizeMM * scaleFactor) / DEFAULT_TEXT_SIZE;
  }

  return ELEMENT_MIN_SCALE;
}

/**
 * @see https://wiki.yandex.ru/homepage/uxui/onlajjn-redaktor/sootvetstvie-trebovanijam-k-maketu/
 */
export function getElementMaxScale(
  element: IBaseElement | IElement | undefined,
  activeDesignSide: IDesignSide | null,
  data?: ITextObject | ISVGObject
) {
  if (!element || !activeDesignSide) {
    return ELEMENT_MAX_SCALE;
  }

  const { sizePX, sizeMM } = activeDesignSide;

  if (isRasterImage(element) || (isSVGObject(data) && data.ext !== 'svg')) {
    const { size } = element;

    const elementMaxSizeMM = {
      x: (size.x * 25.4) / DEFAULT_IMAGE_MIN_PPI,
      y: (size.y * 25.4) / DEFAULT_IMAGE_MIN_PPI,
    };

    const maxScale = {
      x: ((elementMaxSizeMM.x / sizeMM.x) * sizePX.x) / element.size.x,
      y: ((elementMaxSizeMM.y / sizeMM.y) * sizePX.y) / element.size.y,
    };

    return Math.min(maxScale.x, maxScale.y, ELEMENT_MAX_SCALE);
  }

  return ELEMENT_MAX_SCALE;
}

export function isTypedElement(
  element: IBaseElement | IElement
): element is IElement {
  return (element as IElement).type !== undefined;
}

export function isSVGElement(
  element: IBaseElement | IElement | ISVGElement
): element is ISVGElement {
  return (element as ISVGElement).svg !== undefined;
}

export function isSVGObject(
  data?: ITextObject | ISVGObject
): data is ISVGObject {
  return (data as ISVGObject)?.svg !== undefined;
}

export function isRasterImage(
  element: IBaseElement | IElement | ISVGElement
): boolean {
  if (!isSVGElement(element) || element.type !== EElementType.image) {
    return false;
  }

  return element.ext !== 'svg';
}

export function getSVGExtension(svg: string): string {
  const svgElement = document.createElementNS(
    'http://www.w3.org/2000/svg',
    'svg'
  );
  svgElement.innerHTML = svg;

  const url = svgElement
    .getElementsByTagName('image')[0]
    ?.getAttributeNS('http://www.w3.org/1999/xlink', 'href');
  return getExtensionByUrl(url);
}

export function getExtensionByUrl(url: string | null): string {
  return url?.split('.').pop() || '';
}
