import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'design-preloader',
  standalone: true,
  templateUrl: './preloader.component.html',
  styleUrls: ['./preloader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PreloaderComponent {}
