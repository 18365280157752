import { Inject, Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { WINDOW } from '../../providers/window.providers';

export interface IComponentDeactivateGuard {
  canDeactivate: () => boolean;
}

@Injectable({
  providedIn: 'root',
})
export class ComponentDeactivateGuardGuard {
  constructor(private router: Router, @Inject(WINDOW) private window: Window) {}

  canDeactivate(
    component: IComponentDeactivateGuard,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const canDeactivateCondition = component.canDeactivate();

    if (canDeactivateCondition) {
      return true;
    } else {
      // https://stackoverflow.com/questions/46448133/candeactivate-changing-the-window-history
      const currentUrl = currentState.url;

      if (this.window.location.pathname.endsWith(currentUrl)) {
        // https://github.com/angular/angular/issues/13586
        this.router.navigate([currentUrl], { skipLocationChange: true });
      } else {
        // A browser button has been clicked or location.back()/forward() invoked. Restore browser history
        this.window.history.pushState(null, '', this.window.location.href);
      }

      return false;
    }
  }
}
