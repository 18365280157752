import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  ViewEncapsulation,
} from '@angular/core';
import { EMockupType, IDesignSide } from '@inaripro-nx/catalog';
import {
  BACKGROUND_COLOR_DEFAULT,
  IFilter,
  IMapOfString,
  IPattern,
} from '@inaripro-nx/common-ui';
import { PREFIX_SIDE_FILL } from '../../../../constants';
import { IElements, IXY } from '../../interfaces/editor.interface';

@Component({
  selector: 'painter-export',
  templateUrl: './export.component.html',
  styleUrls: ['./export.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class ExportComponent {
  readonly EMockupType = EMockupType;
  readonly BACKGROUND_COLOR_DEFAULT = BACKGROUND_COLOR_DEFAULT;
  readonly PREFIX_SIDE_FILL = PREFIX_SIDE_FILL;

  private _side!: IDesignSide;

  get side(): IDesignSide {
    return this._side;
  }

  @Input() set side(side: IDesignSide) {
    this._side = side;
    this.zone0Center = {
      ...(side?.zones[0].center || { x: 0, y: 0 }),
    };
  }

  @Input() sideIndex: number | null = null;
  @Input() filters!: { [id: number]: IFilter };
  @Input() zoneColors!: IMapOfString;
  @Input() zonePatterns!: { [key: string]: IPattern };

  private _elements!: IElements;

  get elements(): IElements {
    return this._elements;
  }

  @Input() set elements(elements: IElements) {
    this._elements = elements;

    this.elementsFiltersId = elements
      .filter((e) => !!e.filterId)
      .map((e) => e.filterId)
      .filter((value, index, array) => {
        return array.indexOf(value) === index;
      });
  }

  @Input() fullColor: string | null = null;

  zone0Center: IXY = { x: 0, y: 0 };
  elementsFiltersId: (number | null)[] = [];

  constructor(public elementRef: ElementRef) {}
}
