import { DOCUMENT } from '@angular/common';
import {
  AfterViewInit,
  Directive,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  Renderer2,
  SimpleChanges,
} from '@angular/core';
import { isSingleTouchEvent, preventDefault } from '@inaripro-nx/common-ui';
import {
  BehaviorSubject,
  Subscription,
  distinctUntilChanged,
  filter,
  fromEvent,
  interval,
  map,
  merge,
  switchMap,
  takeUntil,
  tap,
  withLatestFrom,
} from 'rxjs';

const DISABLED_CSS_CLASS = 'disabled';
const ITEM_HEIGHT = 100;
const ITEM_GAP = 30;
const MAX_ITEMS = 3;
const STEP = 5;
const ARROW_HEIGHT = 44;

@Directive({
  selector: '[painterEditorSides]',
  standalone: true,
})
export class EditorSidesDirective
  implements OnChanges, AfterViewInit, OnDestroy
{
  @Input()
  set painterEditorSidesSize(size: number) {
    this.isSliderActive = size > MAX_ITEMS;
    this.maxIndex = size - MAX_ITEMS;
    this.maxY = (ITEM_HEIGHT + ITEM_GAP) * this.maxIndex + ARROW_HEIGHT;
  }

  @Input() painterEditorSidesActiveIndex = 0;

  @Input() painterEditorSidesSlider!: HTMLElement;
  @Input() painterEditorSidesArrowUp!: HTMLElement;
  @Input() painterEditorSidesArrowDown!: HTMLElement;

  @Output() dragStart = new EventEmitter<void>();
  @Output() dragEnd = new EventEmitter<void>();

  private _subs: Subscription[] = [];
  set subs(sub: Subscription) {
    this._subs.push(sub);
  }

  private isSliderActive = false;
  private minY = 0;
  private maxY = 0;
  private index = 0;
  private minIndex = 0;
  private maxIndex = 0;

  private readonly ySubject$ = new BehaviorSubject<number>(0);
  private readonly y$ = this.ySubject$
    .asObservable()
    .pipe(distinctUntilChanged());

  constructor(
    private readonly renderer: Renderer2,
    @Inject(DOCUMENT) private readonly document: Document
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (
      'painterEditorSidesActiveIndex' in changes ||
      'painterEditorSidesSize' in changes
    ) {
      this.initSideIndexFocus(this.painterEditorSidesActiveIndex);
    }
  }

  ngAfterViewInit(): void {
    if (this.isSliderActive) {
      this.initSliderMoveSubscription();
      this.initDesktopSliderArrowsSubscription();
      this.initTouchSliderArrowsSubscription();
      this.initSliderTouchSubscription();
    }
  }

  ngOnDestroy(): void {
    this._subs.forEach((s) => s.unsubscribe());
  }

  private initSideIndexFocus(index: number) {
    this.index = index;

    let newY = -this.index * (ITEM_HEIGHT + ITEM_GAP);

    if (this.index > this.maxIndex) {
      newY -= ARROW_HEIGHT;
    }

    this.ySubject$.next(Math.max(newY, -this.maxY));
  }

  private initSliderMoveSubscription() {
    this.subs = this.y$.subscribe((y) => {
      this.renderer.setStyle(
        this.painterEditorSidesSlider,
        'transform',
        `translateY(${y}px)`
      );
      this.toggleArrowDisabled(this.painterEditorSidesArrowUp, y === this.minY);
      this.toggleArrowDisabled(
        this.painterEditorSidesArrowDown,
        y === -this.maxY
      );
    });
  }

  private initDesktopSliderArrowsSubscription() {
    const arrowUpStart$ = fromEvent<PointerEvent>(
      this.painterEditorSidesArrowUp,
      'pointerenter'
    ).pipe(filter((event) => event.pointerType === 'mouse'));

    const arrowDownStart$ = fromEvent<PointerEvent>(
      this.painterEditorSidesArrowDown,
      'pointerenter'
    ).pipe(filter((event) => event.pointerType === 'mouse'));

    const arrowUpEnd$ = fromEvent<PointerEvent>(
      this.painterEditorSidesArrowUp,
      'pointerleave'
    ).pipe(filter((event) => event.pointerType === 'mouse'));

    const arrowDownEnd$ = fromEvent<PointerEvent>(
      this.painterEditorSidesArrowDown,
      'pointerleave'
    ).pipe(filter((event) => event.pointerType === 'mouse'));

    const moveUpDesktop$ = arrowUpStart$.pipe(
      withLatestFrom(this.y$),
      switchMap(([, y]) => {
        let newY = y;
        return interval(10).pipe(
          map(() => (newY = Math.min(newY + STEP, this.minY))),
          takeUntil(arrowUpEnd$)
        );
      })
    );

    const moveDownDesktop$ = arrowDownStart$.pipe(
      withLatestFrom(this.y$),
      switchMap(([, y]) => {
        let newY = y;
        return interval(10).pipe(
          map(() => (newY = Math.max(newY - STEP, -this.maxY))),
          takeUntil(arrowDownEnd$)
        );
      })
    );

    this.subs = merge(moveUpDesktop$, moveDownDesktop$)
      .pipe(tap((y) => this.ySubject$.next(y)))
      .subscribe();
  }

  private initTouchSliderArrowsSubscription() {
    const moveUp$ = fromEvent<PointerEvent>(
      this.painterEditorSidesArrowUp,
      'pointerdown'
    ).pipe(
      preventDefault(),
      filter((event) => event.pointerType === 'touch'),
      map(() => {
        if (this.index > this.minIndex) {
          this.index -= 1;
        }
        const newY = -this.index * (ITEM_HEIGHT + ITEM_GAP);
        return Math.min(newY, this.minY);
      })
    );

    const moveDown$ = fromEvent<PointerEvent>(
      this.painterEditorSidesArrowDown,
      'pointerdown'
    ).pipe(
      preventDefault(),
      filter((event) => event.pointerType === 'touch'),
      map(() => {
        if (this.index < this.maxIndex) {
          this.index += 1;
        }

        let newY = -this.index * (ITEM_HEIGHT + ITEM_GAP);

        if (this.index === this.maxIndex) {
          newY -= ARROW_HEIGHT;
        }

        return Math.max(newY, -this.maxY);
      })
    );

    this.subs = merge(moveUp$, moveDown$)
      .pipe(tap((y) => this.ySubject$.next(y)))
      .subscribe();
  }

  private initSliderTouchSubscription() {
    const start$ = fromEvent<TouchEvent>(
      this.painterEditorSidesSlider,
      'touchstart'
    ).pipe(filter((event) => isSingleTouchEvent(event)));

    const move$ = fromEvent<TouchEvent>(
      this.painterEditorSidesSlider,
      'touchmove'
    ).pipe(filter((event) => isSingleTouchEvent(event)));

    const end$ = fromEvent<TouchEvent>(this.document, 'touchend');

    const drag$ = start$.pipe(
      withLatestFrom(this.y$),
      switchMap(([startEvent, y]) => {
        return move$.pipe(
          preventDefault(),
          map((moveEvent) => {
            let newY =
              y +
              (moveEvent.touches[0].clientY - startEvent.touches[0].clientY);

            if (newY > this.minY) {
              newY = this.minY;
            }

            if (newY < -this.maxY) {
              newY = -this.maxY;
            }

            this.index = Math.floor(-newY / (ITEM_HEIGHT + ITEM_GAP));

            return newY;
          }),
          tap((y) => this.ySubject$.next(y)),
          takeUntil(end$)
        );
      })
    );

    this.subs = drag$.pipe(tap((y) => this.ySubject$.next(y))).subscribe();
  }

  private toggleArrowDisabled(arrow: HTMLElement, disabled: boolean) {
    if (disabled) {
      this.renderer.addClass(arrow, DISABLED_CSS_CLASS);
    } else {
      this.renderer.removeClass(arrow, DISABLED_CSS_CLASS);
    }
  }
}
