<div class="content-qrcodes">
  <div class="content-qrcodes-input-button">
    <ng-container *ngTemplateOutlet="buttonsQrcodes"></ng-container>
  </div>
  <div class="simplebar-qrcode">
    <div class="wrapper" *ngFor="let qrCode of qrCodes$ | async">
      <design-card-qr-code
        [imgSrc]="qrCode.url"
        [text]="qrCode.text"
        (selectObject)="onSelectObject($event)"
        (cardCloseEmit)="onDeleteObject(qrCode.id)"
        (imageLoaded)="imageLoaded(qrCode.id, $event)"
      >
      </design-card-qr-code>
    </div>
  </div>
  <div class="input-button__mobile">
    <ng-container *ngTemplateOutlet="buttonsQrcodes"></ng-container>
  </div>
</div>

<ng-template #buttonsQrcodes>
  <input
    #input
    class="input-qrcode"
    type="text"
    placeholder="Введите текст для кодирования"
    (keyup.enter)="createQRCode(input.value)"
  />
  <button class="g-btn-enter" type="button" (click)="createQRCode(input.value)">
    <span>Создать QR-код</span>
  </button>
</ng-template>
