import { Injectable } from '@angular/core';
import { IMapOfBoolean, linkToGlobalState } from '@inaripro-nx/common-ui';
import { ComponentStore } from '@ngrx/component-store';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

export interface ModalWindowState {
  modals: IMapOfBoolean;
}

const initialState: ModalWindowState = {
  modals: {},
};

@Injectable({
  providedIn: 'root',
})
export class ModalWindowStore extends ComponentStore<ModalWindowState> {
  readonly modals$: Observable<IMapOfBoolean> = this.select(
    (state: ModalWindowState) => state.modals
  );

  readonly patch = this.updater(
    (state: ModalWindowState, payload: IMapOfBoolean) => {
      return {
        ...state,
        modals: {
          ...state.modals,
          ...payload,
        },
      };
    }
  );

  readonly reset = this.updater((state: ModalWindowState) => {
    return {
      ...state,
      modals: {},
    };
  });

  constructor(private globalStore: Store) {
    super({ ...initialState });

    linkToGlobalState(
      this.state$,
      'libs/design-ui/ModalWindowStore',
      this.globalStore
    );
  }
}
