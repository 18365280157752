<div
  class="editor-draw"
  *ngIf="{
    filters: filters$ | async,
    elementsFiltersId: elementsFiltersId$ | async,
    activeDesignSide: activeDesignSide$ | async,
    activeDesignSideIndex: activeDesignSideIndex$ | async,
    fullColor: fullColor$ | async,
    isFillModalOpened: isFillModalOpened$ | async,
    zoneColors: zoneColors$ | async,
    zonePatterns: zonePatterns$ | async,
    zoneTranslates: zoneTranslates$ | async,
    activeDesignZonesWithColor: activeDesignZonesWithColor$ | async,
    elementsEditable: elementsEditable$ | async
  } as state"
  (touchmove)="$event.preventDefault()"
  (mousedown)="$event.stopPropagation()"
  (touchstart)="onEditorDrawTouchStart($event)"
>
  <svg
    *ngIf="activeDesignSide"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    style="width: 100%; height: 100%"
    #svgBase
    id="svgBase"
    preserveAspectRatio="xMidYMid meet"
    [attr.viewBox]="activeDesignSide.sizePX | viewbox"
  >
    <!-- Рабочая область редактора -->
    <g *ngIf="state.isFillModalOpened"></g>

    <defs>
      <mask id="mask1">
        <rect x="0" y="0" width="100%" height="100%" fill="black" />
        <g
          *ngFor="let zone of activeDesignSide.zones"
          [attr.transform]="(updateZone?.id === zone.id && updateZone?._translate || state.zoneTranslates?.['' + zone.id]) | svgTranslate"
        >
          <rect
            [attr.x]="zone.start?.x"
            [attr.y]="zone.start?.y"
            [attr.width]="zone.size?.x"
            [attr.height]="zone.size?.y"
            fill="white"
          />
        </g>
      </mask>
    </defs>

    <ng-container *ngFor="let filterId of state.elementsFiltersId">
      <defs
        *ngIf="filterId && state.filters && state.filters[filterId] as filter"
        [innerHTML]="filter.html | safeHtml"
      ></defs>
    </ng-container>

    <rect
      x="-50%"
      y="-50%"
      width="200%"
      height="200%"
      (mousedown)="clearSelect()"
      (touchstart)="clearSelect()"
      fill-opacity="0"
    />

    <!-- Область фоновой заливки -->
    <g *ngIf="activeDesignSide.hasColorPalette; else fullColorTemplate">
      <rect
        *ngIf="(state.zoneColors?.[PREFIX_SIDE_FILL + activeDesignSide.id] || BACKGROUND_COLOR_DEFAULT) as fill"
        x="0"
        y="0"
        width="100%"
        height="100%"
        [attr.fill]="fill"
        style="pointer-events: none"
      />
      <g
        *ngIf="state.zonePatterns?.[PREFIX_SIDE_FILL + activeDesignSide.id] as pattern"
      >
        <pattern
          id="pattern"
          x="0"
          y="0"
          width="100%"
          height="100%"
          patternUnits="userSpaceOnUse"
        >
          <image
            x="0"
            y="0"
            width="100%"
            height="100%"
            [attr.xlink:href]="pattern.url"
            preserveAspectRatio="xMidYMid slice"
          ></image>
        </pattern>
        <rect
          x="0"
          y="0"
          width="100%"
          height="100%"
          fill="url(#pattern)"
          style="pointer-events: none"
        />
      </g>
    </g>
    <ng-template #fullColorTemplate>
      <rect
        x="0"
        y="0"
        width="100%"
        height="100%"
        [attr.fill]="state.fullColor || BACKGROUND_COLOR_DEFAULT"
        style="pointer-events: none"
      />
    </ng-template>

    <!-- Мокап -->
    <image
      *ngIf="activeDesignSide.mockupType === EMockupType.down"
      [attr.xlink:href]="activeDesignSide.image.url"
      x="0"
      y="0"
      width="100%"
      height="100%"
      style="pointer-events: none"
    ></image>

    <!-- Фон области нанесения -->
    <g *ngIf="!activeDesignSide.isFullPrint">
      <g
        *ngFor="let zone of activeDesignSide.zones"
        [attr.transform]="(updateZone?.id === zone.id && updateZone?._translate || state.zoneTranslates?.['' + zone.id]) | svgTranslate"
      >
        <g *ngIf="zone.hasColorPalette && zone.start && zone.size">
          <rect
            *ngIf="state.zoneColors?.['' + zone.id] as fill"
            [attr.x]="zone.start.x"
            [attr.y]="zone.start.y"
            [attr.width]="zone.size.x"
            [attr.height]="zone.size.y"
            [attr.fill]="fill"
          />
          <g *ngIf="state.zonePatterns?.['' + zone.id] as pattern">
            <pattern
              *ngIf="zone.size"
              id="{{ 'pattern-' + zone.id }}"
              [attr.x]="zone.start.x"
              [attr.y]="zone.start.y"
              [attr.width]="zone.size.x"
              [attr.height]="zone.size.y"
              patternUnits="userSpaceOnUse"
            >
              <image
                x="0"
                y="0"
                [attr.width]="zone.size.x"
                [attr.height]="zone.size.y"
                [attr.xlink:href]="pattern.url"
                preserveAspectRatio="xMidYMid slice"
              ></image>
            </pattern>
            <rect
              [attr.x]="zone.start.x"
              [attr.y]="zone.start.y"
              [attr.width]="zone.size.x"
              [attr.height]="zone.size.y"
              [attr.fill]="'url(#pattern-' + zone.id + ')'"
            />
          </g>
        </g>
      </g>
    </g>

    <!-- Объекты дизайна -->
    <g mask="url(#mask1)">
      <g [attr.transform]="zone0Center | svgTranslate">
        <ng-container *ngFor="let el of elements; let i = index">
          <ng-container *ngIf="el.sideIndex === state.activeDesignSideIndex">
            <g
              *ngIf="
                i === updateElement?.index ? updateElement?.element : el as elem
              "
              data-painter-editor-element-transform
              [el]="elem"
              (mousedownEvent)="
                state.elementsEditable &&
                  startAction(
                    EActionType.move,
                    $event,
                    state.activeDesignSide,
                    i
                  )
              "
            >
              <rect
                [attr.x]="0"
                [attr.y]="0"
                [attr.width]="elem.size.x"
                [attr.height]="elem.size.y"
                fill-opacity="0"
              />

              <g
                data-painter-editor-element-content
                [el]="elem"
                [index]="i"
              ></g>
            </g>
          </ng-container>
        </ng-container>

        <g
          *ngIf="updateElement?.element as el"
          data-painter-editor-element-transform
          [el]="el"
          (mousedownEvent)="
            state.elementsEditable &&
              startAction(
                EActionType.move,
                $event,
                state.activeDesignSide,
                updateElement?.index
              )
          "
        >
          <rect
            [attr.x]="0"
            [attr.y]="0"
            [attr.width]="el.size.x"
            [attr.height]="el.size.y"
            fill-opacity="0"
          />
        </g>
      </g>
    </g>

    <!-- Мокап -->
    <image
      *ngIf="activeDesignSide.mockupType === EMockupType.up"
      [attr.xlink:href]="activeDesignSide.image.url"
      x="0"
      y="0"
      width="100%"
      height="100%"
      style="pointer-events: none"
    ></image>

    <!-- Область затемнения -->
    <rect
      x="0"
      y="0"
      width="100%"
      height="100%"
      style="
        pointer-events: none;
        fill: rgba(0, 0, 0, 0.0352);
        stroke: #f6f6f6;
        stroke-width: 3px;
        vector-effect: non-scaling-stroke;
      "
    />

    <!-- Область нанесения -->
    <g *ngIf="!activeDesignSide.isFullPrint">
      <g *ngFor="let zone of activeDesignSide.zones; let zoneIndex = index">
        <g data-painter-editor-rect-dashed
           *ngIf="zone.moveArea as moveArea"
           [area]="moveArea" [colors]="['#ededed', '#3D3D3D']">
          <text
            *ngIf="moveArea.start"
            x="0" y="-0.5em"
            text-anchor="start"
            [attr.transform]="moveArea.start | svgTranslate"
            [attr.font-size]="fontSize + 'px'"
            style="
              color: #3D3D3D;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
            "
          >Границы перемещения</text>
        </g>
        <g [attr.transform]="(updateZone?.id === zone.id && updateZone?._translate || state.zoneTranslates?.['' + zone.id]) | svgTranslate">
          <g data-painter-editor-rect-dashed
             [area]="zone" [colors]="['#ededed', '#9e9e9e']"></g>
          <image
            *ngIf="zone.moveArea && zone.start && zone.size"
            [attr.transform]="{x: zone.start.x + zone.size.x - sizeActions.x, y: zone.start.y + zone.size.y - sizeActions.y} | svgTranslate"
            [attr.width]="sizeActions.x"
            [attr.height]="sizeActions.y"
            class="pointer zone-action"
            xlink:href="/assets/images/move-zone.jpg"
            (mousedown)="startAction(EActionType.move_zone, $event, state.activeDesignSide, zoneIndex)"
            (touchstart)="startAction(EActionType.move_zone, $event, state.activeDesignSide, zoneIndex)"
          ></image>
          <image
            *ngIf="
              (state.activeDesignZonesWithColor || []).length > 1 &&
              zone.hasColorPalette &&
              zone.start
            "
            [attr.transform]="zone.start | svgTranslate"
            [attr.width]="sizeActions.x"
            [attr.height]="sizeActions.y"
            class="pointer zone-action"
            xlink:href="/assets/images/select-zone.svg"
            (click)="selectZone(activeDesignSide, zone)"
          ></image>
        </g>
      </g>
    </g>

    <!-- Элементы управления выделенным объектом дизайна -->
    <g
      *ngIf="
        state.elementsEditable && updateElement && updateElement?.element as el
      "
      [attr.transform]="zone0Center | svgTranslate"
    >
      <g
        [attr.transform]="
          (el.translate | svgTranslate) +
          ({
            angle: el.rotate,
            point: { size: el.size, scale: el.scale } | elementRotateCenter
          } | svgRotate) +
          (el.scale | svgScale)
        "
      >
        <rect
          [attr.x]="0"
          [attr.y]="0"
          [attr.width]="el.size.x"
          [attr.height]="el.size.y"
          stroke="#0091D9"
          stroke-width="1px"
          stroke-dasharray="5,5"
          vector-effect="non-scaling-stroke"
          fill="none"
        />

        <g [class.hidden]="inAction">
          <image
            [attr.transform]="
              ({
                x: -sizeActions.x / el.scale.x,
                y: -sizeActions.y / el.scale.y
              } | svgTranslate) +
              ({ x: 1 / el.scale.x, y: 1 / el.scale.y } | svgScale)
            "
            [attr.width]="sizeActions.x"
            [attr.height]="sizeActions.y"
            class="pointer"
            xlink:href="/assets/images/box-close.svg"
            (click)="removeElement($event)"
          ></image>
          <image
            [attr.transform]="
              ({
                x: el.size.x,
                y: -sizeActions.y / el.scale.y
              } | svgTranslate) +
              ({ x: 1 / el.scale.x, y: 1 / el.scale.y } | svgScale)
            "
            [attr.width]="sizeActions.x"
            [attr.height]="sizeActions.y"
            class="pointer"
            xlink:href="/assets/images/box-rotate.svg"
            (dblclick)="rotateToDefault()"
            (mousedown)="
              startAction(EActionType.rotate, $event, state.activeDesignSide)
            "
            (touchstart)="
              startAction(EActionType.rotate, $event, state.activeDesignSide)
            "
          ></image>
          <image
            [attr.transform]="
              ({
                x: el.size.x,
                y: el.size.y
              } | svgTranslate) +
              ({ x: 1 / el.scale.x, y: 1 / el.scale.y } | svgScale)
            "
            [attr.width]="sizeActions.x"
            [attr.height]="sizeActions.y"
            class="pointer"
            xlink:href="/assets/images/box-resize.svg"
            (dblclick)="scaleToDefault()"
            (mousedown)="
              startAction(EActionType.scale, $event, state.activeDesignSide)
            "
            (touchstart)="
              startAction(EActionType.scale, $event, state.activeDesignSide)
            "
          ></image>
        </g>
      </g>
    </g>
  </svg>
</div>
