import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IShareResponse, IShareTemplate } from '@inaripro-nx/painter';

@Injectable({
  providedIn: 'root',
})
export class ShareTemplatesService {
  constructor(private http: HttpClient) {}

  getTemplate(uid: string) {
    return this.http.post<IShareTemplate>(
      '/catalog/public/design/get_share_template/',
      { uid }
    );
  }

  createTemplate(model: IShareTemplate) {
    return this.http.post<IShareResponse>(
      '/catalog/public/design/share_template/',
      model
    );
  }
}
