import { DOCUMENT } from '@angular/common';
import {
  Directive,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  Renderer2,
} from '@angular/core';

@Directive({
  selector: '[designMoveToBody]',
  standalone: true,
})
export class MoveToBodyDirective implements OnInit, OnDestroy {
  constructor(
    private renderer: Renderer2,
    private elRef: ElementRef,
    @Inject(DOCUMENT) private document: Document
  ) {}

  ngOnInit(): void {
    const element: HTMLElement = this.elRef.nativeElement;
    const body: HTMLHeadElement = this.document.body;

    if (body.firstChild) {
      this.renderer.insertBefore(body, element, body.firstChild);
    } else {
      this.renderer.appendChild(body, element);
    }

    this.renderer.removeAttribute(element, 'data-inari-move-to-body');
  }

  ngOnDestroy(): void {
    try {
      this.renderer.removeChild(this.document.body, this.elRef.nativeElement);
    } catch (e) {
      console.log();
    }
  }
}
