import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ClickOutsideDirective, MoveToBodyDirective } from '../../directives';
import { DropDownComponent } from './drop-down.component';
import { AdDropDownDirective } from './ad-drop-down/ad-drop-down.directive';

@NgModule({
  imports: [
    CommonModule,
    ClickOutsideDirective,
    MoveToBodyDirective,
    AdDropDownDirective,
  ],
  declarations: [DropDownComponent],
  exports: [DropDownComponent, AdDropDownDirective],
})
export class DropDownModule {}
