<h1>Кнопки</h1>
<div class="section-button">
  <button class="g-btn">Кнопка</button>
</div>

<div class="section-button">
  <button class="g-btn-enter">
    <span>Создать QR-код</span>
  </button>
</div>

<div class="section-button">
  <button class="g-btn-enter">
    <span>
      <i class="icon icon-ic_cross-plus"></i>
      Добавить изображение</span
    >
  </button>
</div>

<div class="section-button">
  <button class="g-btn-link g-btn-link--red">ОК</button>
</div>

<div class="section-button">
  <button class="g-btn-link">Отмена</button>
</div>

<div class="section-button">
  <button class="g-btn-link">
    <i class="icon icon-ic_trash"></i>
    <span>Удалить</span>
  </button>
</div>

<div class="section-button">
  <button class="g-btn-link">
    <i class="icon icon-ic_circle-plus"></i>
    <span>Добавить изделие</span>
  </button>
</div>

<div class="section-button">
  <button class="g-btn-clear"><span>Кнопка как ссылка</span></button>
</div>

<div class="section-button">
  <button class="g-btn-clear">
    <i class="icon icon-ic_question"></i>
    <span>Кнопка как ссылка</span>
  </button>
</div>

<div class="section-button">
  <button class="g-btn-save-template">
    <i class="icon icon-ic_cross-plus"></i>
    <span>Сохранить новый шаблон</span>
  </button>
</div>
