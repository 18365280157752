<design-search
  #inariDesignSearch
  [externalFormControl]="externalFormControl"
  [placeholderText]="'Поиск по каталогу'"
  [canShowError]="true"
  [minSearchQueryLength]="2"
  (searchOut)="search($event)"
  (searchClear)="closeSearchForm()"
>
</design-search>
