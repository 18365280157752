import { IGetResponse } from '../interfaces';

export const BASKET_SUCCESS_MODAL_UID = 'designer-root-basketSuccessModalUid';

export const BASKET_COOKIE_NAME = 'X-Basket-Uid';

export const GET_DEFAULT_EMPTY_BASKET: () => IGetResponse = () => {
  return {
    id: null,
    uid: null,
    products: [],
    calculators: [],
    updatedAt: '',
  };
};
