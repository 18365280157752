<div class="header-nav">
  <div class="header-nav__el header-nav__el--hide1">
    <shell-header-link
      class="header-nav__link"
      [link]="'/catalog/company'"
      [name]="'Поставщики'"
    ></shell-header-link>
  </div>

  <div
    designDropdownDelayHover
    class="header-nav__el"
    [class.header-nav__el--has-dropdown]="links && links.infoList"
    [dropdownElement]="infoDropdown"
  >
    <shell-header-link
      class="header-nav__link"
      [link]="'/info'"
      [name]="'Для&nbsp;клиентов'"
    ></shell-header-link>

    <div class="header-nav__dropdown" #infoDropdown>
      <shell-header-link-info
        *ngIf="links && links.infoList"
        [links]="links"
      ></shell-header-link-info>
    </div>
  </div>

  <div class="header-nav__el header-nav__el--hide2">
    <shell-header-link
      class="header-nav__link"
      [link]="'/media'"
      [name]="'Новости'"
    ></shell-header-link>
  </div>

  <div
    class="header-nav__el"
    [class.header-nav__el--has-dropdown]="links?.contacts"
  >
    <shell-header-link
      class="header-nav__link"
      [link]="'/contacts'"
      [name]="'Контакты'"
    ></shell-header-link>

    <div class="header-nav__dropdown header-nav__dropdown--contacts">
      <shell-header-menu-contacts [links]="links"></shell-header-menu-contacts>

      <div class="header-nav__dropdown-footer footer-link">
        <shell-header-link
          class="header-nav__dropdown-footer-link header-nav__dropdown-footer-link--blue"
          [link]="'/contacts'"
          [name]="'Все филиалы'"
          [exact]="true"
        ></shell-header-link>
      </div>
    </div>
  </div>
</div>
