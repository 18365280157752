<div class="card" (click)="addObject()">
  <div class="card-close" (click)="clickCardClose($event)">
    <i class="icon icon-ic_cross-close"></i>
  </div>
  <div class="card-qrcode">
    <img [src]="imgSrc" />
  </div>
  <div class="card-text">
    <span>{{ text }}</span>
  </div>
  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" style="width: 0; height: 0">
    <g #svgGroup>
      <image [attr.xlink:href]="imgSrc" (load)="imageLoaded.emit(this)"></image>
    </g>
  </svg>
</div>