export const CITY_SELECT_MODAL_UID = 'CITY_SELECT_MODAL_UID';

export const RUSSIA_CITY_NAME = 'Россия';
export const MOSCOW_CITY_NAME = 'Москва';
export const PITER_CITY_NAME = 'Санкт-Петербург';
export const EBURG_CITY_NAME = 'Екатеринбург';

export const MAIN_CITY_NAMES = [
  RUSSIA_CITY_NAME,
  MOSCOW_CITY_NAME,
  PITER_CITY_NAME,
  EBURG_CITY_NAME,
];

export const COOKIES_USER_CITY = 'user-city';
export const COOKIES_USER_FILIAL = 'user-filial';
