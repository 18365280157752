export function sortByField<T extends object & { name?: string }>(
  field: keyof T = 'name'
) {
  return (a: T, b: T) => {
    const aField = a[field];
    const bField = b[field];

    if (aField < bField) {
      return -1;
    }

    if (aField > bField) {
      return 1;
    }

    if (aField === bField) {
      return 0;
    }

    return 0;
  };
}
