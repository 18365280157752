import { Injectable } from '@angular/core';
import { IMapOfBoolean, linkToGlobalState } from '@inaripro-nx/common-ui';
import { ComponentStore } from '@ngrx/component-store';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

export interface PopoverState {
  popovers: IMapOfBoolean;
}

const initialState: PopoverState = {
  popovers: {},
};

@Injectable({
  providedIn: 'root',
})
export class PopoverStore extends ComponentStore<PopoverState> {
  readonly popovers$: Observable<IMapOfBoolean> = this.select(
    (state: PopoverState) => state.popovers
  );

  readonly patch = this.updater(
    (state: PopoverState, payload: IMapOfBoolean) => {
      return {
        ...state,
        popovers: {
          ...state.popovers,
          ...payload,
        },
      };
    }
  );

  readonly reset = this.updater((state: PopoverState) => {
    return {
      ...state,
      popovers: {},
    };
  });

  constructor(private globalStore: Store) {
    super({ ...initialState });

    linkToGlobalState(
      this.state$,
      'libs/design-ui/PopoverState',
      this.globalStore
    );
  }
}
