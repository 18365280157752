import { DOCUMENT } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
} from '@angular/core';
import { WINDOW } from '@inaripro-nx/common-ui';
import { Subscription, filter, fromEvent, tap } from 'rxjs';

@Component({
  selector: 'design-drop-down',
  templateUrl: './drop-down.component.html',
  styleUrls: ['./drop-down.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DropDownComponent<T extends { dropDownLabel: string }>
  implements OnInit, OnDestroy
{
  @Input() dropDownAlignElement!: Element;
  @Input() dropDownItems!: T[];
  @Input() dropDownOpenTo: 'up' | 'down' = 'down';
  @Input() dropDownAlign: 'left' | 'right' = 'right';
  @Input() dropDownClass?: string;
  @Input() dropDownLabelActive?: string;
  @Input() dropDowninnerHhml?: string;
  @Input() dropDownContentTemplateRef: TemplateRef<unknown> | null = null;

  @Input() dropDownLeftShift: number = 0;

  @Output() selectItem: EventEmitter<{ index: number; item: T }> =
    new EventEmitter();

  @Output() toggleDropDown: EventEmitter<boolean> = new EventEmitter();

  position: {
    top: number;
    bottom: number;
    left: number;
    right: number;
  } = {
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  };

  isActive = false;

  private _subs: Subscription[] = [];
  set subs(sub: Subscription) {
    this._subs.push(sub);
  }

  constructor(
    public cdr: ChangeDetectorRef,
    @Inject(WINDOW) private window: Window,
    @Inject(DOCUMENT) private document: Document
  ) {}

  ngOnInit(): void {
    this.createResizeEvent();
  }

  ngOnDestroy(): void {
    this._subs.forEach((s) => s.unsubscribe());
  }

  toggleActive() {
    this.isActive = !this.isActive;

    if (this.isActive) {
      this.calculatePosition();
    }

    this.toggleDropDown.emit(this.isActive);
  }

  hideSelf() {
    if (this.isActive) {
      this.isActive = false;
      this.toggleDropDown.emit(this.isActive);
      this.cdr.markForCheck();
    }
  }

  private createResizeEvent() {
    this.subs = fromEvent(this.window, 'resize')
      .pipe(
        filter(() => this.isActive),
        tap(() => {
          this.hideSelf();
          this.cdr.detectChanges();
        })
      )
      .subscribe();
  }

  private calculatePosition() {
    const DOMRect = this.dropDownAlignElement.getBoundingClientRect();
    this.position = {
      top: DOMRect.bottom,
      bottom: this.document.body.clientHeight - DOMRect.top,
      left: DOMRect.left - this.dropDownLeftShift,
      right: this.document.body.clientWidth - DOMRect.right,
    };
  }
}
