@if ((tabs$ | async); as tabs) {
<div class="tabs">
  @for (mainTab of tabs.main; track mainTab.id) {
  <div
    class="tab"
    [class.tab--active]="mainTab.isActive"
    (click)="mainTab.onClick()"
  >
    <i class="{{ 'icon ' + mainTab.icon }}"></i>
    <span>{{ mainTab.name }}</span>
  </div>
  }

  <div
    class="more"
    designClickOutside
    (clickOutsideEvent)="moreGroupActive = false"
  >
    @if (tabs.more.length > 0) {
    <div
      class="more__btn"
      [class.more__btn--active]="moreGroupActive"
      (click)="moreGroupActive = !moreGroupActive"
    >
      <i class="icon icon-more"></i>
    </div>
    @if (moreGroupActive) {
    <div class="more__group">
      @for (moreTab of tabs.more; track moreTab.id) {
      <div
        class="more__group-item"
        [class.more__group-item--active]="moreTab.isActive"
        (click)="moreTab.onClick(); moreGroupActive = false"
      >
        <i class="{{ 'icon ' + moreTab.icon }}"></i>
        <span>{{ moreTab.name }}</span>
      </div>
      }
    </div>
    } }
  </div>
</div>
}
