import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CookieService } from '@inaripro-nx/common-ui';
import { Observable, of } from 'rxjs';
import { BASKET_COOKIE_NAME, GET_DEFAULT_EMPTY_BASKET } from '../../constants';
import {
  IGetResponse,
  IProductRequest,
} from '../../interfaces/nbasket.interface';

@Injectable({
  providedIn: 'root',
})
export class OrderCartService {
  constructor(
    private readonly httpClient: HttpClient,
    private readonly cookieService: CookieService
  ) {}

  getOrderCart(uid: string | null): Observable<IGetResponse> {
    const cartUid = uid || this.getTokenFromStorage() || null;
    return cartUid
      ? this.httpClient.post<IGetResponse>('orders/ccart', { uid: cartUid })
      : of(GET_DEFAULT_EMPTY_BASKET());
  }

  addProduct(
    uid: string | null,
    product: IProductRequest
  ): Observable<IGetResponse> {
    const cartUid = uid || this.getTokenFromStorage() || null;
    return this.httpClient.post<IGetResponse>('/orders/ccart/product', {
      uid: cartUid,
      product,
    });
  }

  private getTokenFromStorage() {
    let uid = this.cookieService.getCookie(BASKET_COOKIE_NAME) || null;

    if (uid === 'None') {
      uid = null;
    }

    return uid;
  }
}
