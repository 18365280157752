import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'design-light-gallery-content-new-arrows',
  standalone: true,
  templateUrl: './design-light-gallery-content-new-arrows.component.html',
  styleUrls: ['./design-light-gallery-content-new-arrows.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DesignLightGalleryContentNewArrowsComponent implements OnChanges {
  @Input() prevVisible = true;
  @Input() nextVisible = true;

  @Input() prevDisabled = false;
  @Input() nextDisabled = false;
  @Input() modificator:
    | 'image'
    | 'thumbnails'
    | 'preview'
    | 'thumbnails-updown'
    | 'preview-vertical'
    | null = null;

  @Output() prevClicked: EventEmitter<void> = new EventEmitter<void>();
  @Output() nextClicked: EventEmitter<void> = new EventEmitter<void>();

  _isPrevDisabled = false;
  get isPrevDisabled(): boolean {
    return this._isPrevDisabled;
  }

  _isNextDisabled = false;
  get isNextDisabled(): boolean {
    return this._isNextDisabled;
  }

  ngOnChanges(simpleChanges: SimpleChanges) {
    if (simpleChanges['prevDisabled']) {
      this._isPrevDisabled = this.prevDisabled;
    }

    if (simpleChanges['nextDisabled']) {
      this._isNextDisabled = this.nextDisabled;
    }
  }

  public handlePrevClick(): void {
    this.prevClicked.emit();
  }

  public handleNextClick(): void {
    this.nextClicked.emit();
  }
}
