import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'svgScale',
})
export class SvgScalePipe implements PipeTransform {
  transform(value: { x: number; y: number }, revert = false): string {
    return ` scale(${revert ? 1/ value.x : value.x} ${revert ? 1/ value.y : value.y})`;
  }
}
