import { Pipe, PipeTransform } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';

@Pipe({
  name: 'formControlError',
  pure: false,
})
export class FormControlErrorPipe implements PipeTransform {
  transform(value: FormControl | AbstractControl, args?: any): boolean {
    if (isNullOrUndefined(value)) {
      return false;
    }

    const result = value.errors && (value.dirty || value.touched);

    return !!result;
  }
}

function isNullOrUndefined(value: any) {
  return value === null || value === undefined;
}
