import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  Catalog2ProductTypePipe,
  EPropertyType,
  IDesignerProduct,
  IElementInfo,
  IPropertyInfo,
} from '@inaripro-nx/catalog';
import { PipesModule } from '@inaripro-nx/common-ui';
import {
  DesignUiModule,
  IGalleryImage,
  ModalWindowStore,
} from '@inaripro-nx/design-ui';
import { debounceTime, map } from 'rxjs';
import { SimplebarAngularModule } from 'simplebar-angular';

@Component({
  selector: 'painter-content-product-modals',
  standalone: true,
  imports: [
    CommonModule,
    DesignUiModule,
    Catalog2ProductTypePipe,
    PipesModule,
    SimplebarAngularModule,
  ],
  templateUrl: './content-product-modals.component.html',
  styleUrls: ['./content-product-modals.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentProductModalsComponent {
  @Input() product!: IDesignerProduct;
  @Input() propertiesInfo: IPropertyInfo[] | null = null;
  @Input() galleryImages: IGalleryImage[] = [];
  @Input() galleryVideos: string[] = [];
  @Input() currentImageId: number | null = null;
  @Input() MODAL_UID_PROPERTIES!: string;
  @Input() MODAL_UID_DESCRIPTION!: string;
  @Input() MODAL_UID_GALLERY!: string;

  readonly EPropertyType = EPropertyType;

  readonly galleryOpened$ = this.modalWindowStore.modals$.pipe(
    debounceTime(0),
    map((modals) => modals[this.MODAL_UID_GALLERY])
  );

  constructor(private readonly modalWindowStore: ModalWindowStore) {}

  public trackByFn(index: number, item: IPropertyInfo | IElementInfo): number {
    return item.id;
  }

  public isWhite(color: string | undefined): boolean {
    const regex = /^#f{3,6}$/gim;

    if (color === undefined) {
      return false;
    }

    return regex.test(color);
  }

  public getRoundVal(value: number | string): number {
    return Math.round(+value * 100) / 100;
  }
}
