import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
} from '@angular/core';

export interface Tab {
  id: number;
  name: string;
  title?: string;
  template?: TemplateRef<unknown>;
  disabled?: boolean;
}

@Component({
  selector: 'design-tabs',
  standalone: true,
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabsComponent {
  @Input() tabs: Tab[] = [];
  @Input() selectedTabId = 0;
  @Input() disabled = false;

  @Output() tabChanged: EventEmitter<Tab> = new EventEmitter<Tab>();

  selectTab(tab: Tab) {
    if (!(this.disabled || tab.disabled)) {
      this.selectedTabId = tab.id;
      this.tabChanged.emit(tab);
    }
  }
}
