<div
  class="light-gallery-content-new__images"
  #imageList
  designLightGalleryImageList
  [currentIndex]="currentIndex"
  (prev)="showPrev()"
  (next)="showNext()"
>
  @for (image of imageGallery; track trackByFn; let i = $index) {
  <div
    class="light-gallery-content-new__image"
    [class.light-gallery-content-new__image--curr]="i === currentIndex"
    [class.light-gallery-content-new__image--prev]="i < currentIndex"
    [class.light-gallery-content-new__image--next]="i > currentIndex"
    [style.opacity]="i >= currentIndex - 1 && i <= currentIndex + 1 ? 1 : 0"
  >
    <div
      class="light-gallery-content-new__image-container"
      designLightGalleryImage
      [active]="i === currentIndex"
    >
      <design-preloader></design-preloader>
    </div>

    @if (showOverflow) {
    <div
      class="light-gallery-content-new__image-overflow"
      (click)="handleClick($event, i)"
    ></div>
    } @if (description) {
    <div class="light-gallery-content-new__image-description">
      <p>{{ description }}</p>
    </div>
    }
  </div>
  }
</div>

<div class="arrow arrow--left" (click)="showPrev()">
  <i class="icon icon icon-ic_arrow_left_slider_preview"></i>
</div>

<div class="arrow arrow--right" (click)="showNext()">
  <i class="icon icon icon-ic_arrow_rigth_slider_preview"></i>
</div>

<design-light-gallery-content-new-arrows-thumbnails
  [images]="images"
  [selectedIndex]="selectedIndex"
  (prevEmit)="showPrev()"
  (nextEmit)="showNext()"
></design-light-gallery-content-new-arrows-thumbnails>
