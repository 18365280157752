import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CookieService, safelyParseJSON } from '@inaripro-nx/common-ui';
import { Observable, map } from 'rxjs';
import {
  COOKIES_USER_CITY,
  COOKIES_USER_FILIAL,
  RUSSIA_CITY_NAME,
} from '../../constants';
import { ICity } from '../../interfaces/geo.interface';

@Injectable({
  providedIn: 'root',
})
export class GeoService {
  private readonly expires = 60 * 60 * 24 * 180;

  constructor(
    private cookieService: CookieService,
    private readonly httpClient: HttpClient
  ) {}

  getCitiesList(): Observable<ICity[]> {
    return this.httpClient
      .get<{ data: ICity[] }>('/public/geo-ip-cities/')
      .pipe(map(({ data }) => data || []));
  }

  getFilialOrCity(): ICity | null {
    const city = this.getCookiesCity();
    const filial = this.getCookiesFilial();

    return filial || city;
  }

  getCookiesCity(): ICity | null {
    const cookiesCity = this.cookieService.getCookie(COOKIES_USER_CITY);
    const city = (cookiesCity && safelyParseJSON<ICity>(cookiesCity)) || null;
    return city;
  }

  getCookiesFilial(): ICity | null {
    const cookiesFilial = this.cookieService.getCookie(COOKIES_USER_FILIAL);
    const filial =
      (cookiesFilial && safelyParseJSON<ICity>(cookiesFilial)) || null;
    return filial;
  }

  setCookiesCity(city: ICity | null) {
    this.cookieService.setCookie(
      COOKIES_USER_CITY,
      city ? JSON.stringify(city) : '{}',
      { expires: this.expires }
    );
  }

  setCookiesFilial(filial: ICity | null) {
    this.cookieService.setCookie(
      COOKIES_USER_FILIAL,
      filial ? JSON.stringify(filial) : '{}',
      { expires: this.expires }
    );
  }

  isRussia(cityName: string = ''): boolean {
    return cityName.trim().toLowerCase() === RUSSIA_CITY_NAME.toLowerCase();
  }
}
