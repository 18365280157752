import { Injectable } from '@angular/core';
import {
  IDesignProduct,
  IDesignSide,
  IDesignZone,
  IDesignerProduct,
  IDesignerSource,
  IPropertyInfo,
  IPublicPrice,
  getCurrentImage,
  getOrderCount,
  getPublicImages,
  IArea,
} from '@inaripro-nx/catalog';
import {
  IFigure,
  IFilter,
  IMapOfString,
  IPattern,
  decimalRound,
  linkToGlobalState,
} from '@inaripro-nx/common-ui';
import { IGalleryImage, IGalleryImageMap } from '@inaripro-nx/design-ui';
import { ComponentStore } from '@ngrx/component-store';
import { Store } from '@ngrx/store';
import {
  BehaviorSubject,
  Observable,
  Subject,
  combineLatest,
  debounceTime,
  distinctUntilChanged,
  map,
  shareReplay,
} from 'rxjs';
import { PREFIX_SIDE_FILL } from '../../../../constants';
import { IMapXY, IXY } from '../../interfaces/editor.interface';
import {
  IProductTemplate,
  ITemplate,
} from '../../interfaces/templates.interface';

export interface ProductState {
  product: IDesignerProduct | null;
  priceUid: string | null;
  price: IPublicPrice | null;
  updatingPrice: boolean;
  countInBasket: number;
  designerSource: IDesignerSource | null;
  propertiesInfo: IPropertyInfo[] | null;
  activeDesignProduct: IDesignProduct | null;
  activeDesignSideIndex: number | null;
  activeDesignZoneIndex: number | null;
  currentTemplate: ITemplate | IProductTemplate | null;
  filters: IFilter[] | null;
  figures: IFigure[] | null;
  fullColor: string | null;
  zoneColors: IMapOfString;
  zonePatterns: { [key: string]: IPattern };
  zoneTranslates: IMapXY;
  orderCount: number;
  publicImages: IGalleryImageMap | null;
  currentImageId: number | null;
  currentImageUid: string | null;
}

const initialState: ProductState = {
  product: null,
  priceUid: null,
  price: null,
  updatingPrice: false,
  countInBasket: 0,
  designerSource: null,
  propertiesInfo: null,
  activeDesignProduct: null,
  activeDesignSideIndex: null,
  activeDesignZoneIndex: null,
  currentTemplate: null,
  filters: [],
  figures: [],
  fullColor: null,
  zoneColors: {},
  zonePatterns: {},
  zoneTranslates: {},
  orderCount: 1,
  publicImages: null,
  currentImageId: null,
  currentImageUid: null,
};

@Injectable({
  providedIn: 'root',
})
export class ProductStore extends ComponentStore<ProductState> {
  readonly selectProduct$: Subject<boolean> = new Subject(); // send true for skip checks
  readonly changeParams$: Subject<string | null> = new Subject();
  readonly orderProduct$: Subject<void> = new Subject();

  readonly isProductOwner$: BehaviorSubject<boolean | null> =
    new BehaviorSubject<boolean | null>(null);

  readonly product$: Observable<IDesignerProduct | null> = this.select(
    (state: ProductState) => state.product
  );

  readonly price$: Observable<IPublicPrice | null> = this.select(
    (state: ProductState) => state.price
  );

  readonly updatingPrice$: Observable<boolean> = this.select(
    (state: ProductState) => state.updatingPrice
  );

  readonly countInBasket$: Observable<number> = this.select(
    (state: ProductState) => state.countInBasket
  );

  readonly designerSource$: Observable<IDesignerSource | null> = this.select(
    (state: ProductState) => state.designerSource
  );

  readonly propertiesInfo$: Observable<IPropertyInfo[] | null> = this.select(
    (state: ProductState) => state.propertiesInfo
  );

  readonly activeDesignProduct$: Observable<IDesignProduct | null> =
    this.select((state: ProductState) => state.activeDesignProduct);

  readonly activeDesignSideIndex$: Observable<number | null> = this.select(
    (state: ProductState) => state.activeDesignSideIndex
  );

  readonly activeDesignZoneIndex$: Observable<number | null> = this.select(
    (state: ProductState) => state.activeDesignZoneIndex
  );

  readonly currentTemplate$: Observable<ITemplate | IProductTemplate | null> =
    this.select((state: ProductState) => state.currentTemplate);

  readonly filters$: Observable<IFilter[] | null> = this.select(
    (state: ProductState) => state.filters
  );

  readonly filtersMap$: Observable<{ [id: number]: IFilter }> = this.select(
    this.filters$,
    (filters: IFilter[] | null) => {
      return (filters || []).reduce((obj, f) => {
        obj[f.id] = f;
        return obj;
      }, {} as { [id: number]: IFilter });
    }
  );

  readonly figures$: Observable<IFigure[] | null> = this.select(
    (state: ProductState) => state.figures
  );

  readonly fullColor$: Observable<string | null> = this.select(
    (state: ProductState) => state.fullColor
  );

  private readonly allZoneColors$: Observable<IMapOfString> = this.select(
    (state: ProductState) => state.zoneColors
  );

  private readonly allZonePatterns$: Observable<{ [key: string]: IPattern }> =
    this.select((state: ProductState) => state.zonePatterns);

  readonly zoneTranslates$: Observable<IMapXY> = this.select(
    (state: ProductState) => state.zoneTranslates
  );

  readonly activeDesignSide$: Observable<IDesignSide | null> = combineLatest([
    this.activeDesignSideIndex$.pipe(distinctUntilChanged()),
    this.activeDesignProduct$,
  ]).pipe(
    debounceTime(0),
    map(
      ([activeDesignSideIndex, activeDesignProduct]) =>
        (activeDesignProduct &&
          activeDesignSideIndex !== null &&
          activeDesignProduct.sides[activeDesignSideIndex]) ||
        null
    ),
    shareReplay({ refCount: false, bufferSize: 1 })
  );

  readonly zoneColors$: Observable<IMapOfString> = this.select(
    this.activeDesignProduct$,
    this.allZoneColors$,
    (
      activeDesignProduct: IDesignProduct | null,
      allZoneColors: IMapOfString
    ) => {
      if (!activeDesignProduct) {
        return {};
      }

      const keys = activeDesignProduct.sides.reduce((acc, side) => {
        acc = [
          ...acc,
          PREFIX_SIDE_FILL + side.id,
          ...(side.zones || []).map((z) => '' + z.id),
        ];
        return acc;
      }, [] as string[]);

      return keys.reduce((map, key) => {
        const color = allZoneColors[key];
        if (color) {
          map[key] = color;
        }
        return map;
      }, {} as IMapOfString);
    }
  );

  readonly zonePatterns$: Observable<{ [key: string]: IPattern }> = this.select(
    this.activeDesignProduct$,
    this.allZonePatterns$,
    (
      activeDesignProduct: IDesignProduct | null,
      allZonePatterns: { [key: string]: IPattern }
    ) => {
      if (!activeDesignProduct) {
        return {};
      }

      const keys = activeDesignProduct.sides.reduce((acc, side) => {
        acc = [
          ...acc,
          PREFIX_SIDE_FILL + side.id,
          ...(side.zones || []).map((z) => '' + z.id),
        ];
        return acc;
      }, [] as string[]);

      return keys.reduce((map, key) => {
        const pattern = allZonePatterns[key];
        if (pattern) {
          map[key] = pattern;
        }
        return map;
      }, {} as { [key: string]: IPattern });
    }
  );

  readonly useColors$: Observable<string[]> = this.select(
    this.fullColor$,
    this.zoneColors$,
    (fullColor: string | null, zoneColors: IMapOfString) => {
      const colors: string[] = [];

      if (fullColor) {
        colors.push(fullColor);
      }

      Object.keys(zoneColors).forEach((key) => {
        const color = zoneColors[key];

        if (color) {
          colors.push(color);
        }
      });

      return [...new Set(colors.map((c) => c.toLocaleLowerCase()))];
    }
  );

  readonly orderCount$: Observable<number> = this.select(
    (state: ProductState) => state.orderCount
  );

  readonly publicImages$: Observable<IGalleryImageMap | null> = this.select(
    (state: ProductState) => state.publicImages
  );

  readonly currentImageId$: Observable<number | null> = this.select(
    (state: ProductState) => state.currentImageId
  );

  readonly currentImageUid$: Observable<string | null> = this.select(
    (state: ProductState) => state.currentImageUid
  );

  readonly countWithBasket$: Observable<number> = combineLatest([
    this.countInBasket$,
    this.orderCount$,
  ]).pipe(
    map(([countInBasket, orderCount]) =>
      decimalRound(countInBasket + orderCount, 0)
    ),
    shareReplay({ refCount: false, bufferSize: 1 })
  );

  readonly galleryImages$: Observable<IGalleryImage[]> = combineLatest([
    this.publicImages$,
    this.currentImageUid$,
  ]).pipe(
    map(([publicImages, currentImageUid]) => {
      if (!publicImages) {
        return [];
      }

      let album = publicImages['album'] || [];
      const priceAlbum = currentImageUid && publicImages[currentImageUid];

      if (priceAlbum) {
        album = album.concat(priceAlbum);
      }

      return album;
    }),
    shareReplay({ refCount: true, bufferSize: 1 })
  );

  readonly activeDesignZonesWithColor$: Observable<IDesignZone[] | null> =
    this.activeDesignSide$.pipe(
      map((side) =>
        ((side && side.zones) || []).filter((z) => z.hasColorPalette)
      ),
      shareReplay({ refCount: false, bufferSize: 1 })
    );

  readonly activeDesignZone$: Observable<IDesignZone | null> = combineLatest([
    this.activeDesignZoneIndex$.pipe(distinctUntilChanged()),
    this.activeDesignSide$,
  ]).pipe(
    debounceTime(0),
    map(
      ([activeDesignZoneIndex, activeDesignSide]) =>
        (activeDesignSide &&
          activeDesignZoneIndex !== null &&
          activeDesignSide.zones[activeDesignZoneIndex]) ||
        null
    ),
    shareReplay({ refCount: false, bufferSize: 1 })
  );

  readonly setProduct = this.updater(
    (state: ProductState, product: IDesignerProduct | null) => {
      const orderCount = getOrderCount(state.orderCount, product);
      const publicImages = getPublicImages(
        product,
        product?.price || state.price
      );
      const { currentImageId, currentImageUid } = getCurrentImage(
        product?.price.uid || state.priceUid,
        product?.price || state.price,
        publicImages
      );

      return {
        ...state,
        product,
        orderCount,
        publicImages,
        currentImageId,
        currentImageUid,
        currentTemplate: null,
      };
    }
  );

  readonly setUpdatingPrice = this.updater(
    (state: ProductState, updatingPrice: boolean) => {
      return {
        ...state,
        updatingPrice,
      };
    }
  );

  readonly setPrice = this.updater(
    (state: ProductState, price: IPublicPrice | null) => {
      const { currentImageId, currentImageUid } = getCurrentImage(
        price ? price.uid : null,
        price,
        state.publicImages
      );

      return {
        ...state,
        price,
        currentImageId,
        currentImageUid,
      };
    }
  );

  readonly setCountInBasket = this.updater(
    (state: ProductState, payload: number | null) => {
      return {
        ...state,
        countInBasket: payload || 0,
      };
    }
  );

  readonly setDesignerSource = this.updater(
    (state: ProductState, designerSource: IDesignerSource | null) => {
      const fullColor = designerSource?.fullColor || null;

      return {
        ...state,
        designerSource,
        fullColor,
      };
    }
  );

  readonly setPropertiesInfo = this.updater(
    (state: ProductState, propertiesInfo: IPropertyInfo[] | null) => {
      return {
        ...state,
        propertiesInfo,
      };
    }
  );

  readonly setActiveDesignProduct = this.updater(
    (state: ProductState, activeDesignProduct: IDesignProduct | null) => {
      const activeDesignSideIndex = activeDesignProduct !== null ? 0 : null;
      const activeDesignZoneIndex = null;

      if (!activeDesignProduct) {
        return {
          ...state,
          activeDesignProduct,
          activeDesignSideIndex,
          activeDesignZoneIndex,
        };
      }

      const sides = activeDesignProduct.sides.map((side) => {
        const zones = side.isFullPrint
          ? [this.getFullPrintZone(side)]
          : side.zones;

        const calculatePX = (area: IArea): IArea => {
          const start: IXY = {
            x: ((area.startPX.x + 50) * side.sizePX.x) / 100,
            y: ((area.startPX.y + 50) * side.sizePX.y) / 100,
          };

          const size: IXY = {
            x: (area.sizePX.x * side.sizePX.x) / 100,
            y: (area.sizePX.y * side.sizePX.y) / 100,
          };

          const center: IXY = {
            x: start.x + size.x / 2,
            y: start.y + size.y / 2,
          };

          return {
            ...area,
            start,
            size,
            center,
          };
        };

        const calculateZone = (zone: IDesignZone): IDesignZone => {
          const newZone: IDesignZone = zone.moveArea
            ? {
                ...zone,
                moveArea: calculatePX(zone.moveArea),
              }
            : {
                ...zone,
              };

          return {
            ...zone,
            ...calculatePX(newZone),
          };
        };

        return {
          ...side,
          zones: zones.map(calculateZone),
        };
      });

      const newActiveDesignProduct = {
        ...activeDesignProduct,
        sides,
      };

      const zoneTranslates = this.validateZoneTranslates(
        state.zoneTranslates,
        newActiveDesignProduct
      );

      return {
        ...state,
        activeDesignProduct: newActiveDesignProduct,
        activeDesignSideIndex,
        activeDesignZoneIndex,
        zoneTranslates,
      };
    }
  );

  readonly setActiveDesignSideIndex = this.updater(
    (state: ProductState, payload: number | null) => {
      return {
        ...state,
        activeDesignSideIndex: payload,
        activeDesignZoneIndex: null,
      };
    }
  );

  readonly setActiveDesignZoneIndex = this.updater(
    (state: ProductState, payload: number | null) => {
      return {
        ...state,
        activeDesignZoneIndex: payload,
      };
    }
  );

  readonly setCurrentTemplate = this.updater(
    (state: ProductState, payload: ITemplate | IProductTemplate | null) => {
      return {
        ...state,
        currentTemplate: payload,
      };
    }
  );

  readonly setFilters = this.updater(
    (state: ProductState, payload: { filters: IFilter[] | null }) => {
      const { filters } = payload;

      return {
        ...state,
        filters,
      };
    }
  );

  readonly setFigures = this.updater(
    (state: ProductState, payload: { figures: IFigure[] | null }) => {
      const { figures } = payload;

      return {
        ...state,
        figures,
      };
    }
  );

  readonly setZoneColors = this.updater(
    (state: ProductState, payload: IMapOfString) => {
      const zoneColors = payload;

      return {
        ...state,
        zoneColors,
      };
    }
  );

  readonly setZoneColor = this.updater(
    (state: ProductState, payload: { key: string; color: string | null }) => {
      const { key, color } = payload;

      let zoneColors = { ...state.zoneColors };

      if (color) {
        zoneColors = {
          ...zoneColors,
          [key]: color,
        };
      } else {
        delete zoneColors[key];
      }

      return {
        ...state,
        zoneColors,
      };
    }
  );

  readonly setZonePattern = this.updater(
    (
      state: ProductState,
      payload: { key: string; pattern: IPattern | null }
    ) => {
      const { key, pattern } = payload;

      let zonePatterns = { ...state.zonePatterns };

      if (pattern) {
        zonePatterns = {
          ...zonePatterns,
          [key]: pattern,
        };
      } else {
        delete zonePatterns[key];
      }

      return {
        ...state,
        zonePatterns,
      };
    }
  );

  readonly setZonePatterns = this.updater(
    (state: ProductState, payload: { [key: string]: IPattern }) => {
      const zonePatterns = payload;

      return {
        ...state,
        zonePatterns,
      };
    }
  );

  readonly setZoneTranslates = this.updater(
    (state: ProductState, payload: IMapXY) => {
      const zoneTranslates = this.validateZoneTranslates(
        payload,
        state.activeDesignProduct
      );

      return {
        ...state,
        zoneTranslates,
      };
    }
  );

  readonly setZoneTranslate = this.updater(
    (state: ProductState, payload: { key: string; translate: IXY | null }) => {
      const { key, translate } = payload;

      let zoneTranslates = { ...state.zoneTranslates };

      if (translate) {
        zoneTranslates = {
          ...zoneTranslates,
          [key]: translate,
        };
      } else {
        delete zoneTranslates[key];
      }

      return {
        ...state,
        zoneTranslates,
      };
    }
  );

  readonly setOrderCount = this.updater(
    (state: ProductState, payload: number) => {
      return {
        ...state,
        orderCount: payload,
      };
    }
  );

  constructor(private readonly globalStore: Store) {
    super({ ...initialState });
    linkToGlobalState(
      this.state$,
      'libs/painter/ProductStore',
      this.globalStore
    );
  }

  private validateZoneTranslates(
    zoneTranslates: IMapXY,
    designProduct: IDesignProduct | null
  ): IMapXY {
    const translates = { ...zoneTranslates };
    const lockedKeys: string[] = [];

    (designProduct?.sides || []).forEach((side) => {
      (side.zones || []).forEach((zone) => {
        const key = '' + zone.id;
        const translate = zoneTranslates[key];

        if (translate) {
          if (!zone.moveArea) {
            lockedKeys.push(key);
          } else {
            const area = zone.moveArea;

            if (zone.start && zone.size && area.start && area.size) {
              const translateStart = {
                x: zone.start.x + translate.x,
                y: zone.start.y + translate.y,
              };

              if (
                translateStart.x + zone.size.x > area.start.x + area.size.x ||
                translateStart.y + zone.size.y > area.start.y + area.size.y ||
                translateStart.x < area.start.x ||
                translateStart.y < area.start.y
              ) {
                lockedKeys.push(key);
              }
            }
          }
        }
      });
    });

    lockedKeys.forEach((key) => delete translates[key]);
    return translates;
  }

  private getFullPrintZone(side: IDesignSide): IDesignZone {
    const name = '';
    const startPX = { x: -50, y: -50 };

    const sizeMMSide = side.sizeMM;

    const startMM: IXY = {
      x: decimalRound((startPX.x * sizeMMSide.x) / 100, 0),
      y: decimalRound((startPX.y * sizeMMSide.y) / 100, 0),
    };

    const sizePX = { x: 100, y: 100 };
    const sizeMM = { x: sizeMMSide.x, y: sizeMMSide.y };

    return {
      name,
      startPX,
      startMM,
      sizePX,
      sizeMM,
      hasColorPalette: false,
      moveArea: null,
    };
  }
}
