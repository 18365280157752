import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnDestroy,
} from '@angular/core';
import { DropDownModule } from '@inaripro-nx/design-ui';
import {
  combineLatest,
  map,
  Observable,
  of,
  Subscription,
  tap,
  withLatestFrom,
} from 'rxjs';
import { IProductTemplate, ITemplate } from '@inaripro-nx/painter';
import { EditorButtonComponent } from '../editor-button/editor-button.component';
import { ProductStore } from '../../../../state/product/product.store';
import { HistoryStore } from '../../../../state/history/history.store';
import { TemplateFormService } from '../../../../services/template-form/template-form.service';
import { DownloadService } from '../../../../services/download/download.service';
import { AuthService, User } from '@inaripro-nx/auth';
import { DesignerAppShellStore } from '../../../../../../../../../../apps/designer/src/app/state/designer-app-shell/designer-app-shell.store';
import { AppShellConfig, WINDOW } from '@inaripro-nx/common-ui';

enum ESaveType {
  current = 'current',
  new = 'new',
  download = 'download',
}

interface ISaveOption {
  type: ESaveType;
  dropDownLabel: string;
}

@Component({
  selector: 'painter-editor-template-control',
  standalone: true,
  imports: [CommonModule, DropDownModule, EditorButtonComponent],
  templateUrl: './editor-template-control.component.html',
  styleUrls: ['./editor-template-control.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditorTemplateControlComponent implements OnDestroy {
  readonly ESaveType = ESaveType;
  readonly isProductOwner$ = this.productStore.isProductOwner$;

  saveOptions$: Observable<ISaveOption[]> = combineLatest([
    this.historyStore.elements$,
    this.productStore.currentTemplate$,
  ]).pipe(
    map(([elements, currentTemplate]) =>
      elements.length ? this.getSaveOptions(currentTemplate) : []
    )
  );

  isSending$ = this.templateFormService.isSending$;
  isSaved$ = this.templateFormService.isSaved$;
  readonly user$: Observable<User | null> = this.authService.user$;
  readonly appShellConfig$: Observable<AppShellConfig> =
    this.appShellStore.appShellConfig$;

  private _subs: Subscription[] = [];
  set subs(sub: Subscription) {
    this._subs.push(sub);
  }

  constructor(
    private readonly historyStore: HistoryStore,
    private readonly productStore: ProductStore,
    private readonly templateFormService: TemplateFormService,
    private readonly downloadService: DownloadService,
    private readonly authService: AuthService,
    public appShellStore: DesignerAppShellStore,
    @Inject(WINDOW) private window: Window
  ) {}

  ngOnDestroy(): void {
    this._subs.forEach((s) => s.unsubscribe());
  }

  private getSaveOptions(
    currentTemplate: ITemplate | IProductTemplate | null
  ): ISaveOption[] {
    const options: ISaveOption[] = currentTemplate
      ? [
          {
            dropDownLabel: 'Сохранить изменения в текущем шаблоне',
            type: ESaveType.current,
          },
          { dropDownLabel: 'Сохранить как новый шаблон', type: ESaveType.new },
        ]
      : [{ dropDownLabel: 'Сохранить шаблон', type: ESaveType.new }];

    options.push({
      type: ESaveType.download,
      dropDownLabel: 'Скачать макет',
    });

    return options;
  }

  selectSaveOption(saveOption: unknown) {
    const option = saveOption as { type: ESaveType };

    if (option.type === ESaveType.download) {
      this.subs = this.download();
    } else if (option.type === ESaveType.new) {
      this.templateFormService.createOrUpdate$.next('create');
    } else if (option.type === ESaveType.current) {
      this.templateFormService.createOrUpdate$.next('update');
    }
  }

  private download(): Subscription {
    return of(null)
      .pipe(
        withLatestFrom(this.user$, this.isProductOwner$, this.appShellConfig$),
        tap(([, user, isProductOwner, appShellConfig]) => {
          if (user) {
            this.downloadService.downloadPdfOrSvg(isProductOwner);
          } else {
            this.window.location.href = `${appShellConfig?.publicUrl}/logout?returnUrl=${this.window.location.href}`;
          }
        })
      )
      .subscribe();
  }
}
