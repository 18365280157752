import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IProperty } from '../../interfaces';

@Injectable({
  providedIn: 'root',
})
export class CatalogCharacteristicsService {
  constructor(private readonly http: HttpClient) {}

  getCatalogCharacteristics() {
    return this.http.get<{
      data: IProperty[];
    }>(
      'catalog/public/characteristics/?query=&offset=0&limit=1000&order_by=name'
    );
  }
}
