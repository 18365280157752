<div class="inputstext">
  <div class="g-input__container left">
    <span>X: </span>
    <input type="number" class="input-field" [value]="translate.x" (change)="changeX($event)" />
  </div>
  <div class="g-input__container right">
    <span>Y: </span>
    <input type="number" class="input-field" [value]="translate.y" (change)="changeY($event)" />
  </div>
</div>
