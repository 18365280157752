import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fontFamily',
})
export class FontFamilyPipe implements PipeTransform {
  transform(value: string): string {
    return `'${value}'`;
  }
}
