import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IPublicProductForList } from '../../interfaces';

@Component({
  selector: 'catalog-product-card-badges',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './catalog-product-card-badges.component.html',
  styleUrls: ['./catalog-product-card-badges.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CatalogProductCardBadgesComponent {
  @Input() product!: IPublicProductForList;
  @Input() isCardProduct = false;

  get badgeClass() {
    return {
      'product-badge-card': this.isCardProduct,
      'product-badge-list': !this.isCardProduct,
    };
  }
}
