<div class="card-filter">
  <div class="card-filter__main">
    <div class="card-filter__main-check" *ngIf="isSelect">
      <i class="icon icon-ic_check-mark"></i>
    </div>
    <svg
      width="63"
      height="63"
      viewBox="0 0 63 63"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
    >
      <defs [innerHTML]="filter.html | safeHtml"></defs>

      <image
        xlink:href="./assets/images/filter.png"
        [attr.filter]="filter.id && 'url(#filter_' + filter.id + ')'"
      ></image>
    </svg>
  </div>
  <div class="card-filter__name" [class.card-filter__name-active]="isSelect">
    <span>{{ filter.name }}</span>
  </div>
</div>
