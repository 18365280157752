import { Pipe, PipeTransform } from '@angular/core';
import { EPropertyType, IPropertyForList } from '@inaripro-nx/catalog';

@Pipe({
  name: 'propertyColors',
})
export class PropertyColorsPipe implements PipeTransform {
  transform(value: IPropertyForList[] | null): IPropertyForList[] | null {
    if (value === null) {
      return null;
    }

    return value.filter((v) => v.type === EPropertyType.COLOR);
  }
}
