import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  standalone: true,
  imports: [CommonModule],
  selector: 'design-color-button-circle',
  templateUrl: './color-button-circle.component.html',
  styleUrls: ['./color-button-circle.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ColorButtonCircleComponent {
  @Input()
  set color(value: string) {
    if (value.indexOf(',') > -1) {
      const [start, end] = value.split(',');
      this.background = `linear-gradient(135deg, ${start} 50%, ${end} 50%)`;
    } else {
      this.isWhite = new RegExp(/^#f{3,6}$/gim).test(value);
      this.background = value;
    }
  }

  isWhite = false;
  background = 'none';
}
