import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'numberCharacter',
})
export class NumberCharacterPipe implements PipeTransform {
  transform(value: number | null, param?: 'uppercase'): string {
    if (value === null) {
      return '';
    }

    const charCodesArray: number[] = value
      .toString()
      .split('')
      .map((x) => +x + 97);

    const result: string = String.fromCharCode(...charCodesArray);

    if (param === 'uppercase') {
      return result.toUpperCase();
    }

    return result;
  }
}
