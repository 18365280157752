import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { IDesignSide } from '@inaripro-nx/catalog';
import { DesignUiModule } from '@inaripro-nx/design-ui';
import { getElementMinScale } from '../../../../../../../../utils/calculate.utils';
import { IElement } from '../../../../../../interfaces/editor.interface';
import { ActionsElementScaleComponent } from '../../../actions-element-scale/actions-element-scale.component';
import { decimalCeil } from '@inaripro-nx/common-ui';
import { DECIMAL_POINTS_SCALE } from '../../../../../../../../constants';

@Component({
  selector: 'painter-actions-text-scale',
  standalone: true,
  imports: [CommonModule, DesignUiModule, ActionsElementScaleComponent],
  templateUrl: './actions-text-scale.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActionsTextScaleComponent implements OnChanges {
  @Input() element!: IElement;
  @Input() designSide: IDesignSide | null = null;

  readonly DECIMAL_POINTS_SCALE = DECIMAL_POINTS_SCALE;

  minScalePercentage = this.getMinScalePercentage();

  ngOnChanges(simpleChanges: SimpleChanges): void {
    if ('element' in simpleChanges || 'designSide' in simpleChanges) {
      this.minScalePercentage = this.getMinScalePercentage();
    }
  }

  private getMinScalePercentage() {
    if (!this.element || !this.designSide) {
      return 100;
    }

    const elementMinScale = getElementMinScale(this.element, this.designSide);
    const designSideWidth = this.designSide.sizePX.x;
    const designSideHeight = this.designSide.sizePX.y;
    const imageMinScaleWidth = this.element.size.x * elementMinScale;
    const imageMinScaleHeight = this.element.size.y * elementMinScale;

    // проценты от стороны изделия
    if (
      designSideWidth / designSideHeight >
      imageMinScaleWidth / imageMinScaleHeight
    ) {
      return decimalCeil((imageMinScaleHeight / designSideHeight) * 100, 0);
    } else {
      return decimalCeil((imageMinScaleWidth / designSideWidth) * 100, 0);
    }
  }
}
