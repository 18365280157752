import { Injectable } from '@angular/core';
import { StorageService, safelyParseJSON } from '@inaripro-nx/common-ui';
import { ComponentStore } from '@ngrx/component-store';
import { map } from 'rxjs';
import { ICatalogCompare } from '../interfaces/catalog.interface';

export const STORAGE_UID = '[AppCompareStore] STORAGE_UID';

export interface CatalogCompareState {
  compare: ICatalogCompare;
}

const initialState: CatalogCompareState = {
  compare: {},
};

@Injectable({
  providedIn: 'root',
})
export class CatalogCompareStore extends ComponentStore<CatalogCompareState> {
  readonly compare$ = this.select((state) => state.compare);
  readonly compareCount$ = this.compare$.pipe(
    map((compare) => {
      let counter = 0;
      Object.keys(compare || {}).forEach((key) => {
        counter += compare[Number(key)].length;
      });
      return counter;
    })
  );

  readonly setCompare = this.updater((state, payload: ICatalogCompare) => {
    return {
      ...state,
      compare: payload,
    };
  });

  init() {
    const compareString = this.storageService.local.getItem(STORAGE_UID);
    if (compareString) {
      const compare = safelyParseJSON<ICatalogCompare>(compareString);
      this.setCompare(compare);
    }
  }

  constructor(private readonly storageService: StorageService) {
    super({ ...initialState });
  }
}
