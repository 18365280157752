import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { SimplebarAngularModule } from 'simplebar-angular';
import { ClickOutsideDirective } from '../../directives';
import { HelperWindowStore } from './state/helper-window.store';

@Component({
  selector: 'design-helper-window',
  standalone: true,
  imports: [CommonModule, ClickOutsideDirective, SimplebarAngularModule],
  templateUrl: './helper-window.component.html',
  styleUrls: ['./helper-window.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HelperWindowComponent implements OnInit, OnDestroy {
  @ViewChild('stateHelper', { static: false }) stateHelper: unknown;

  @Input() uid!: string | number;
  @Input() title = '';
  @Input() multi = false;
  @Input() position = 'auto';
  @Input() size!: string;
  @Input() okClose = false;

  public helperShowed = false;

  private _subs: Subscription[] = [];

  set subs(sub: Subscription) {
    this._subs.push(sub);
  }

  constructor(private helperWindowStore: HelperWindowStore) {}

  ngOnInit(): void {
    this.subs = this.helperWindowStore.popovers$.subscribe((popovers) => {
      if (popovers[this.uid]) {
        this.showHelper();
      } else {
        this.hideHelper();
      }
    });
  }

  private showHelper(): void {
    this.helperShowed = true;
  }

  private hideHelper(): void {
    this.helperShowed = false;
  }

  public addPopover(): void {
    if (!this.multi) {
      this.helperWindowStore.reset();
    }
    this.helperWindowStore.patch({ [this.uid]: true });
  }

  public removePopover(): void {
    this.helperWindowStore.patch({ [this.uid]: false });
  }

  public popoverToggle(): void | undefined {
    if (this.helperShowed) {
      this.removePopover();
      return;
    }

    this.addPopover();
  }

  ngOnDestroy() {
    this._subs.forEach((s) => s.unsubscribe());
  }
}
