export function decimalRound(
  value: number | string,
  decimalPlaces = 12
): number {
  const roundConstant = Math.pow(10, decimalPlaces);
  return Math.round((+value + Number.EPSILON) * roundConstant) / roundConstant;
}

export function decimalCeil(
  value: number | string,
  decimalPlaces = 12
): number {
  const roundConstant = Math.pow(10, decimalPlaces);
  return Math.ceil((+value + Number.EPSILON) * roundConstant) / roundConstant;
}

export function decimalFloor(
  value: number | string,
  decimalPlaces = 12
): number {
  const roundConstant = Math.pow(10, decimalPlaces);
  return Math.floor((+value + Number.EPSILON) * roundConstant) / roundConstant;
}

export function stringNumberTrunc(
  value: string | number,
  decimalPlaces = 12
): string {
  decimalPlaces = decimalPlaces < 0 ? 0 : decimalPlaces;

  return ('' + value).replace(/(\d+)(\.|,)(\d+)/g, (o, a, b, c) => {
    // Где o = всё совпадение целиком, a, b и с = совпадения из 1-й, 2-й и 3-й скобок.
    // символ \d находит совпадение с любой цифрой. Плюсик после него означает "один или несколько"

    return decimalPlaces ? a + b + c.slice(0, decimalPlaces) : a;
  });
}

export function normalizeFloat(value: number): number {
  return Math.round(value * 100) / 100;
}

export function getRadiusByArc(data: { angle: number; width: number }): number {
  const { width, angle } = data;
  const radius = (width * 180) / (Math.PI * Math.abs(angle));
  return radius;
}

export function polarToCartesian(
  centerX: number,
  centerY: number,
  radius: number,
  angleInDegrees: number
) {
  const angleInRadians = (angleInDegrees * Math.PI) / 180;

  return {
    x: centerX + radius * Math.cos(angleInRadians),
    y: centerY + radius * Math.sin(angleInRadians),
  };
}
