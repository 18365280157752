<div
  class="color-button"
  [class.color-button--new-size]="newSize"
  [class.color-button--active]="isActive"
  [class.color-button--error]="isError"
  [class.color-button--disabled]="isDisabled"
  [class.g-btn-crashed]="crashed"
  [class.color-button--bordered]="isWhite(colorValues)"
  [attr.title]="colorTitle"
  (click)="!isDisabled && changeModel.emit()"
>
  <div class="cross" [class.cross--active]="isDisabled"></div>
  <div
    class="color-button__inner"
    [style.borderTopColor]="colorValues[0]"
    [style.borderLeftColor]="colorValues[0]"
    [style.borderBottomColor]="colorValues[1] || colorValues[0]"
    [style.borderRightColor]="colorValues[1] || colorValues[0]"
  ></div>
  <span *ngIf="isShowRemoveIcon && isActive" class="remove-selected-btn">
    <i class="icon icon-ic_add"></i>
  </span>
</div>
