import { Injectable } from '@angular/core';
import { IQRCode } from '@inaripro-nx/common-ui';
import { ComponentStore } from '@ngrx/component-store';
import { createEntityAdapter, EntityState } from '@ngrx/entity';
import {
  catchError,
  concatMap,
  EMPTY,
  exhaustMap,
  mergeMap,
  Observable,
  Subject,
  tap,
} from 'rxjs';
import { QrCodesService } from '../../services/qr-codes/qr-codes.service';

export interface QrCodesState extends EntityState<IQRCode> {
  currentId: number | null;
}

export const adapter = createEntityAdapter<IQRCode>({
  sortComparer: (a: IQRCode, b: IQRCode) => b.id - a.id,
});

const initialState: QrCodesState = adapter.getInitialState({
  currentId: null,
});

const { selectAll, selectEntities } = adapter.getSelectors();

@Injectable()
export class QrCodesStore extends ComponentStore<QrCodesState> {
  collection$ = this.select(selectAll);
  dictionary$ = this.select(selectEntities);
  newModel$: Subject<IQRCode> = new Subject<IQRCode>();

  loadCollection = this.effect((trigger$) =>
    trigger$.pipe(
      exhaustMap(() =>
        this.collectionService.all().pipe(
          tap((collection) =>
            this.setState((state) => adapter.setAll(collection, state))
          ),
          catchError(() => EMPTY)
        )
      )
    )
  );

  addModel = this.effect((model$: Observable<Partial<IQRCode>>) =>
    model$.pipe(
      concatMap((newModel) =>
        this.collectionService.add(newModel).pipe(
          tap((model) => {
            this.setState((state) => adapter.addOne(model, state));
            this.newModel$.next(model);
          }),
          catchError(() => EMPTY)
        )
      )
    )
  );

  // updateModel = this.effect((model$: Observable<IQRCode>) =>
  //   model$.pipe(
  //     concatMap((updateModel) =>
  //       this.collectionService.update(updateModel).pipe(
  //         tap((model) =>
  //           this.setState((state) =>
  //             adapter.updateOne({ id: model.id, changes: model }, state)
  //           )
  //         ),
  //         catchError(() => EMPTY)
  //       )
  //     )
  //   )
  // );

  deleteModel = this.effect((modelId$: Observable<number>) =>
    modelId$.pipe(
      tap((modelId) =>
        this.setState((state) =>
          adapter.updateOne(
            { id: modelId, changes: { markForDelete: true } },
            state
          )
        )
      ),
      mergeMap((modelId) =>
        this.collectionService.delete(modelId).pipe(
          tap(() =>
            this.setState((state) =>
              adapter.removeOne(modelId, {
                ...state,
                currentId: state.currentId !== modelId ? state.currentId : null,
              })
            )
          ),
          catchError(() => {
            this.setState((state) =>
              adapter.updateOne(
                { id: modelId, changes: { markForDelete: false } },
                state
              )
            );

            return EMPTY;
          })
        )
      )
    )
  );

  constructor(private collectionService: QrCodesService) {
    super({ ...initialState });
  }

  init() {
    this.loadCollection();
  }
}
