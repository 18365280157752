<div class="tabs">
  @for (tab of tabs; track tab.id; let tabIndex = $index) {
  <div
    class="tab"
    [class.tab--active]="selectedTabId === tab.id"
    [class.tab--disabled]="tab?.disabled || disabled"
    (click)="selectTab(tab)"
  >
    @if (tab.template) {
    <ng-container *ngTemplateOutlet="tab.template"></ng-container>
    } @else {
    {{ tab.name }}
    }
  </div>
  }
</div>
