import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { CropModalComponent } from '@inaripro-nx/crop';
import { IElement } from '../../../../interfaces/editor.interface';
import { ActionsButtonComponent } from '../actions-button/actions-button.component';

@Component({
  selector: 'painter-actions-element-crop',
  standalone: true,
  templateUrl: './actions-element-crop.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, ActionsButtonComponent, CropModalComponent],
})
export class ActionsElementCropComponent {
  @Input() element!: IElement;
  @Input() disabled = false;

  @Output() openCropModal = new EventEmitter<void>();
}
