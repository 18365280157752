import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ElementImageXlinkPipe } from './element-image-xlink/element-image-xlink.pipe';
import { ElementNamePipe } from './element-name/element-name.pipe';
import { ElementRotateCenterPipe } from './element-rotate-center/element-rotate-center.pipe';
import { PropertyColorsPipe } from './property-colors/property-colors.pipe';

const pipes = [
  ElementNamePipe,
  ElementRotateCenterPipe,
  PropertyColorsPipe,
  ElementImageXlinkPipe,
];

@NgModule({
  declarations: [...pipes],
  imports: [CommonModule],
  exports: [...pipes],
  providers: [...pipes],
})
export class PipesModule {}
