export enum EFontFamily {
  Arial = 'Arial',
  OpenSans = 'Open Sans',
  Roboto = 'Roboto',
  Oswald = 'Oswald',
  Prata = 'Prata',
  Impact = 'Impact',
  Intro = 'Intro',
  Gilroy = 'Gilroy',
  GilroyExtraBold = 'Gilroy ExtraBold',
  Lobster = 'Lobster',
  Pacifico = 'Pacifico',
  shelleyvolante1 = '1 Shelley Volante',
  Caveat = 'Caveat',
  Pribambas = 'Pribambas',
  Pangolin = 'Pangolin',
  Paper = 'Paper',
  Cheshirskiy_Cat = 'Cheshirskiy Cat',
  Press_Start_2P = 'Press Start 2P',
  Play = 'Play',
  Times = 'Times',
  TimesNewRoman = 'Times New Roman',
  RockwellMT = 'Rockwell MT',
  SportsWorld = 'Sports World',
  Bebas_Neue_Cyrillic = 'Bebas Neue Cyrillic',
  Freeride = 'Freeride',
  PlayfairDisplay = 'Playfair Display',
  Borsok = 'Borsok',
  RuslanDisplay = 'Ruslan Display',
  Baloo = 'Baloo Cyrillic',
  Stencil = 'Stencil BT [Rus by me]',
  Colatemta = 'Colatemta',
  Balkara = 'Balkara Free Condensed - npoekmu.me',
  TsarevichOld = 'Tsarevich old',
  TabulaPeutingeriana = 'Tabula Peutingeriana',
  AnimeAce = 'Anime Ace v05',
  SolomonSans = 'Solomon Sans',
  MountainsOfChristmas = 'Mountains of Christmas',
  Journalism = 'JOURNALISM',
  GoodVibesPro = 'Good Vibes Pro',
  Yokawerad = 'Yokawerad',
  Pobeda = 'Pobeda',
  RedOctober = 'Red October',
  KZCooper = 'KZ Cooper',
  JournalSansNew = 'Journal Sans New',
  FunСityLevel3Basic = 'FunCity Level 3 Basic',
  Antarctic = 'Antarctic',
  BitterBlack = 'Bitter Black',
  SpriteGraffiti = 'Sprite Graffiti',
  DockerTwo = 'DOCKER TWO',
  Campusgrav = 'a_CampusGrav',
  CaesarDressing = 'Caesar Dressing Cyrillic',
  NipponcyrBold = 'NipponCyr-Bold',
  Fundamental3DBrigade = 'Fundamental 3D  Brigade',
  JonnyDecor = 'Jonny Decor',
}

export interface IFontFamily {
  key: EFontFamily;
  label: string;
  old?: boolean;
}

export const FONTS_FAMILIES_KEY_LABEL: IFontFamily[] = [
  { key: EFontFamily.Arial, label: 'Arial' },
  { key: EFontFamily.OpenSans, label: 'Open Sans' },
  { key: EFontFamily.Roboto, label: 'Roboto' },
  { key: EFontFamily.Oswald, label: 'Oswald' },
  { key: EFontFamily.Prata, label: 'Prata' },
  { key: EFontFamily.Impact, label: 'Impact' },
  { key: EFontFamily.Intro, label: 'INTRO' },
  { key: EFontFamily.Gilroy, label: 'Gilroy' },
  { key: EFontFamily.Lobster, label: 'Lobster' },
  { key: EFontFamily.Pacifico, label: 'Pacifico' },
  { key: EFontFamily.Caveat, label: 'Caveat' },
  { key: EFontFamily.Pribambas, label: 'PRIBAMBAS' },
  { key: EFontFamily.Pangolin, label: 'Pangolin' },
  { key: EFontFamily.Paper, label: 'PAPER' },
  { key: EFontFamily.Press_Start_2P, label: 'Press Start 2P' },
  { key: EFontFamily.Play, label: 'Play' },
  { key: EFontFamily.Times, label: 'Times', old: true },
  { key: EFontFamily.TimesNewRoman, label: 'Times New Roman' },
  { key: EFontFamily.RockwellMT, label: 'Rockwell MT' },
  { key: EFontFamily.SportsWorld, label: 'SPORTS WORLD' },
  { key: EFontFamily.Bebas_Neue_Cyrillic, label: 'BEBAS NEUE' },
  { key: EFontFamily.Freeride, label: 'Freeride' },
  { key: EFontFamily.PlayfairDisplay, label: 'Playfair Display' },
  { key: EFontFamily.Borsok, label: 'BORSOK' },
  { key: EFontFamily.RuslanDisplay, label: 'RUSLAN DISPLAY' },
  { key: EFontFamily.Baloo, label: 'Baloo' },
  { key: EFontFamily.Stencil, label: 'STENCILL' },
  { key: EFontFamily.Colatemta, label: 'COLATEMTA' },
  { key: EFontFamily.Balkara, label: 'BALKARA' },
  { key: EFontFamily.TsarevichOld, label: 'TSAREVICH OLD' },
  { key: EFontFamily.TabulaPeutingeriana, label: 'Tabula Peutingeriana' },
  { key: EFontFamily.AnimeAce, label: 'ANIME ACE' },
  { key: EFontFamily.SolomonSans, label: 'Solomon Sans' },
  { key: EFontFamily.MountainsOfChristmas, label: 'Mountains Of Christmas' },
  { key: EFontFamily.Journalism, label: 'JOURNALISM' },
  { key: EFontFamily.GoodVibesPro, label: 'Good Vibes Pro' },
  { key: EFontFamily.Yokawerad, label: 'Yokawerad' },
  { key: EFontFamily.Pobeda, label: 'ПОБЕДА' },
  { key: EFontFamily.RedOctober, label: 'RED OCTOBER' },
  { key: EFontFamily.KZCooper, label: 'Cooper' },
  { key: EFontFamily.JournalSansNew, label: 'Journal Sans New' },
  { key: EFontFamily.FunСityLevel3Basic, label: 'FUNCITY LEVEL 3 BASIC' },
  { key: EFontFamily.Antarctic, label: 'Antarctic' },
  { key: EFontFamily.BitterBlack, label: 'Bitter Black' },
  { key: EFontFamily.SpriteGraffiti, label: 'SPRITE GRAFFITI' },
  { key: EFontFamily.DockerTwo, label: 'DOCKER TWO' },
  { key: EFontFamily.Campusgrav, label: 'CAMPUSGRAV' },
  { key: EFontFamily.CaesarDressing, label: 'CAESAR DRESSING' },
  { key: EFontFamily.NipponcyrBold, label: 'NIPPON' },
  { key: EFontFamily.Fundamental3DBrigade, label: 'Fundamental 3D Brigade' },
  { key: EFontFamily.JonnyDecor, label: 'JONNY DECOR' },
];
