import { AbstractControl } from '@angular/forms';
import { getStringControlValue } from '../utils';

export function ValidatorsMinLength(
  minLength: number,
  shortMessage = false,
  trimValue = true
) {
  return (c: AbstractControl) => {
    const value = getStringControlValue(c, trimValue);

    if (value.length < minLength) {
      return {
        message: shortMessage
          ? `Минимум ${minLength} символов`
          : `Минимальная длина: ${minLength} символов (введено символов: ${value.length})`,
      };
    }

    return null;
  };
}

export function ValidatorsMaxLength(
  maxLength: number,
  shortMessage = false,
  trimValue = true
) {
  return (c: AbstractControl) => {
    const value = getStringControlValue(c, trimValue);

    if (value.length > maxLength) {
      return {
        message: shortMessage
          ? `Не более ${maxLength} символов`
          : `Максимальная длина: ${maxLength} символов (введено символов: ${value.length})`,
      };
    }

    return null;
  };
}
