export interface IFilter {
  id: number;
  name: string;
  html: string;
  isActive: boolean;
}

export interface IImage {
  id: number;
  url: string;
}

export interface IPicture extends IImage {
  preview?: string;
  height?: number; // original. if preview
  width?: number; // original. if preview
  markForDelete?: boolean;
}

export interface IPictureLibrary {
  id: number;
  name: string;
}

export interface IPattern extends IImage {
  preview?: string;
  height?: number; // original. if preview
  width?: number; // original. if preview
}

export interface IMapOfPattern {
  [key: string]: IPattern;
}

export interface IPatternLibrary {
  id: number;
  name: string;
}

export interface IFigureImage extends IImage {
  name: string;
}

export interface IFigure {
  id: number;
  image: IFigureImage;
  name: string;
  tags: string | null;
}

export interface IQRCode {
  id: number;
  url: string;
  text: string;
  markForDelete?: boolean;
}
