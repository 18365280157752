import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { PipesModule } from '@inaripro-nx/common-ui';
import { DesignUiModule, SelectDirectionEnum } from '@inaripro-nx/design-ui';
import { ITextElement } from '../../../../../../interfaces/editor.interface';
import {
  FONTS_FAMILIES_KEY_LABEL,
  IFontFamily,
} from '../../../../../../interfaces/fonts.interface';

@Component({
  selector: 'painter-actions-text-font-select',
  standalone: true,
  imports: [CommonModule, DesignUiModule, PipesModule],
  templateUrl: './actions-text-font-select.component.html',
  styleUrls: ['./actions-text-font-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActionsTextFontSelectComponent implements OnInit {
  @Input() textElement!: ITextElement;
  @Input() dropdownDirection: SelectDirectionEnum = SelectDirectionEnum.DOWN;

  @Output() changeFontFamily = new EventEmitter<IFontFamily>();

  fontFamiliesKeyLabel: IFontFamily[] = [];

  ngOnInit(): void {
    this.fontFamiliesKeyLabel = FONTS_FAMILIES_KEY_LABEL.filter(
      (value) => value.old !== true
    ).sort((a: IFontFamily, b: IFontFamily) => a.label.localeCompare(b.label));
  }

  optionLabel(
    evt: IFontFamily | string,
    key: 'key' | 'label'
  ): IFontFamily | string | undefined {
    const findElement = FONTS_FAMILIES_KEY_LABEL.find(
      (item) => item.key === evt
    );

    let result;
    if (findElement) {
      if (key === 'key') {
        result = findElement.key;
      }
      if (key === 'label') {
        result = findElement.label;
      }
    } else {
      result = evt;
    }

    return result;
  }

  selectOption(fontFamily: unknown) {
    this.changeFontFamily.emit(fontFamily as IFontFamily);
  }
}
