<ul class="menu-list" *ngIf="links?.infoList">
  <li class="menu-list__item" *ngFor="let info of links?.infoList">
    <shell-header-link
      class="menu-list__link"
      [link]="info.slug ? '/info/' + info.slug : null"
      [externalLink]="info.externalUrl ? info.externalUrl : null"
      [name]="info.name"
    ></shell-header-link>
  </li>
</ul>

<div class="header-nav__dropdown-footer">
  <shell-header-link
    class="header-nav__dropdown-footer-link"
    [link]="'/info'"
    [name]="'Смотреть больше'"
  ></shell-header-link>
</div>
