@if (isOpen) {
<div class="light-gallery-content-new__preview" [class.is-active]="isOpen">
  <div class="light-gallery-content-new__preview-modal">
    <div
      class="light-gallery-content-new__preview-modal__close"
      (click)="close()"
    >
      <i class="icon icon-ic_close"></i>
    </div>
    <div
      class="light-gallery-content-new__preview-modal__wrapper-thumbnail-arrow-img"
    >
      <div class="light-gallery-content-new__preview-modal__wrapper-thumbnail">
        <ng-content></ng-content>
      </div>
      <div class="light-gallery-content-new__preview-modal__wrapper-arrow-img">
        <div class="light-gallery-content-new__preview-modal__arrow-wrapper">
          <i class="icon icon icon-ic_arrow_left_slider_preview"></i>
        </div>
        <div
          class="light-gallery-content-new__preview-modal__img-wrapper"
          #imgWrapper
        >
          <img [src]="src" (load)="loaded()" [class.loading]="showPreloader" />

          <design-light-gallery-content-new-arrows
            [modificator]="'preview-vertical'"
            [prevDisabled]="!canShowPrev()"
            [nextDisabled]="!canShowNext()"
            (prevClicked)="handlePrevClick()"
            (nextClicked)="handleNextClick()"
          >
          </design-light-gallery-content-new-arrows>

          @if (showPreloader) {
          <design-preloader></design-preloader>
          } @if (description) {
          <div class="light-gallery-content-new__preview-modal__img-text">
            {{ description }}
          </div>
          }
        </div>
        <div class="light-gallery-content-new__preview-modal__arrow-wrapper">
          <i class="icon icon icon-ic_arrow_rigth_slider_preview"></i>
        </div>
      </div>
    </div>
  </div>
  <div
    class="light-gallery-content-new__preview-overlay"
    (click)="close()"
  ></div>
</div>
}
