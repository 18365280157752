import { Inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ROUTER_NAVIGATION, RouterNavigationAction } from '@ngrx/router-store';
import { Store } from '@ngrx/store';
import { filter, tap } from 'rxjs/operators';
import { RouterStateUrl } from '../../constants';
import { WINDOW } from '../../providers';
import { MetaService } from '../../services/meta/meta.service';
import {
  blockRobots as metaBlockRobots,
  setDescription,
  setKeywords,
} from './meta.actions';

@Injectable()
export class MetaEffects {
  setDefaultMeta$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ROUTER_NAVIGATION),
        tap((action: RouterNavigationAction<RouterStateUrl>) => {
          ['description', 'keywords', 'robots'].forEach((key) => {
            this.metaService.removeByName(key);
          });

          const data = action.payload.routerState.data;
          const { description, keywords, blockRobots } = data;

          if (description) {
            this.store.dispatch(
              setDescription({ payload: description as string })
            );
          }

          if (keywords) {
            this.store.dispatch(setKeywords({ payload: keywords as string }));
          }

          if (blockRobots) {
            this.store.dispatch(metaBlockRobots());
          }
        })
      ),
    { dispatch: false }
  );

  setDescription$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(setDescription),
        filter((action) => !!action.payload),
        tap((action) => {
          this.metaService.addTag({
            name: 'description',
            content: action.payload,
          });
        })
      ),
    { dispatch: false }
  );

  setKeywords$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(setKeywords),
        filter((action) => !!action.payload),
        tap((action) => {
          this.metaService.addTag({
            name: 'keywords',
            content: action.payload,
          });
        })
      ),
    { dispatch: false }
  );

  blockRobots$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(metaBlockRobots),
        tap(() => {
          this.metaService.addTag({
            name: 'robots',
            content: 'noindex, nofollow',
          });
        })
      ),
    { dispatch: false }
  );

  constructor(
    private store: Store,
    private actions$: Actions,
    private metaService: MetaService,
    @Inject(WINDOW) private window: Window
  ) {}
}
