import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { IFigureListItem } from '../../interfaces';

@Component({
  selector: 'crop-crop-figure-list',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './crop-figure-list.component.html',
  styleUrls: ['./crop-figure-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CropFigureListComponent {
  @Input() selectedFigureId: number | null = null;
  @Input() figureList: IFigureListItem[] = [];

  @Output() selectFigure = new EventEmitter<IFigureListItem>();

  onClick(event: Event, figure: IFigureListItem) {
    event.preventDefault();
    event.stopPropagation();
    this.selectFigure.emit(figure);
  }
}
