export type MatchType =
  | 'name' // название
  | 'defaultAddress' // дефолтный адрес
  | 'shortName' // короткое название компании из анонса
  | 'fullName' // название юр. лица из реквизитов
  | 'website' // сайт или соц сеть
  | 'inn' // ИНН
  | 'ogrn'; // ОГРН

export interface IMatches {
  matches?: IMatch[];
}

export interface IMatch {
  fieldType: MatchType;
  fieldValue: string;
}
