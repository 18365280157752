export enum UserRoleEnum {
  Administrator = 'administrator',
  Moderator = 'moderator',
  Partner = 'partner',
  Customer = 'customer',
}

export enum UserPermissionEnum {
  ContentEdit = 'content_edit',
  ContentView = 'content_view',
  DirectoriesEdit = 'directories_edit',
  DirectoriesView = 'directories_view',
  UsersEdit = 'users_edit',
  UsersView = 'users_view',
}

export enum UserMatchTypeEnum {
  Account = 'account', // совпало с карточкой, у которой есть аккаунт
  Card = 'card', // совпадение с карточкой без аккаунта
  Ban = 'ban', // забанили
}
