import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  ViewEncapsulation,
} from '@angular/core';
import { PipesModule as CommonPipesModule } from '@inaripro-nx/common-ui';
import {
  EElementType,
  IElement,
} from '../../../../interfaces/editor.interface';
import { EditorElementTextContentComponent } from '../editor-element-text-content/editor-element-text-content.component';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[data-painter-editor-element-content]',
  standalone: true,
  imports: [CommonModule, CommonPipesModule, EditorElementTextContentComponent],
  templateUrl: './editor-element-content.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditorElementContentComponent {
  @Input() el!: IElement;
  @Input() index!: number;

  readonly EElementType = EElementType;
}
