import { Inject, Injectable } from '@angular/core';
import {
  BehaviorSubject,
  catchError,
  finalize,
  map,
  Observable,
  of,
  Subscription,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs';
import { isArray } from 'lodash';
import { HttpClient } from '@angular/common/http';
import { ExportService } from '../export/export.service';
import { ProductStore } from '../../state/product/product.store';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class DownloadService {
  private readonly _message$: BehaviorSubject<string> =
    new BehaviorSubject<string>('');
  public readonly message$: Observable<string> = this._message$.asObservable();

  private _downloadSub: Subscription | null = null;
  private set downloadSub(sub: Subscription | null) {
    if (this._downloadSub) {
      this._downloadSub.unsubscribe();
    }

    this._downloadSub = sub;
  }

  constructor(
    private readonly http: HttpClient,
    private readonly exportService: ExportService,
    private readonly productStore: ProductStore,
    @Inject(DOCUMENT) private document: Document
  ) {}

  private setMessage(message: string) {
    this._message$.next(message);
  }

  public clearMessage() {
    this.setMessage('');
  }

  public downloadPdf() {
    if (this.downloadSub) {
      this.setMessage('Задача уже запущена');
      return;
    }

    this.clearMessage();

    this.downloadSub = this.exportService
      .getProductSides()
      .pipe(
        withLatestFrom(this.productStore.product$),
        map(([sides, product]) => ({
          design: { sides },
          productId: product?.id,
        })),
        switchMap((request) =>
          this.http.post<{ email: string }>(
            'catalog/public/design/pdf_result',
            request
          )
        ),
        tap((response) => {
          this.setMessage(
            `В течение 10 мин макет будет отправлен на ваш E-mail: ${response.email}.`
          );
        }),
        catchError((e) => {
          this.setMessage('Ошибка');

          if (!e || !e.error || !isArray(e.error) || !e.error[0]) {
            return of(null);
          }

          const data = e.error[0];

          if (data['design-user-uid']) {
            this.setMessage(
              'Заполните E-mail в настройках вашего профиля, для отправки макетов'
            );
          } else if (data['productId'] && isArray(data['productId'])) {
            const message = data['productId'][0];

            if (message === 'INVALID_VALUE') {
              this.setMessage('Доступ запрещен');
            } else if (message === 'TIMEOUT') {
              this.setMessage('Задача уже запущена');
            }
          }

          return of(null);
        }),
        finalize(() => {
          this.downloadSub = null;
        })
      )
      .subscribe();
  }

  public downloadSvg() {
    if (this.downloadSub) {
      this.setMessage('Задача уже запущена');
      return;
    }

    this.clearMessage();

    this.downloadSub = this.exportService
      .getXmlSides()
      .pipe(
        withLatestFrom(this.productStore.product$),
        map(([sides, product]) => ({
          design: { sides },
          productId: product?.id,
        })),
        tap((request) => {
          (request.design.sides || []).forEach((side, index) => {
            const file = new Blob([side.xml], { type: 'image/svg+xml' });

            const a = this.document.createElement('a');
            a.href = URL.createObjectURL(file);
            a.target = '_blank';

            a.download = `product_${request.productId}_side_${index}.svg`;
            a.click();
          });
        }),
        catchError((e) => {
          this.setMessage('Ошибка');
          return of(null);
        }),
        finalize(() => {
          this.downloadSub = null;
        })
      )
      .subscribe();
  }

  public downloadPdfOrSvg(isProductOwner: boolean | null): void {
    return isProductOwner ? this.downloadPdf() : this.downloadSvg();
  }
}
