<div class="wrapper-select">
  <div class="wrapper-select__font-selector">
    <div class="wrapper-select__name">Шрифт</div>
    <div
      class="wrapper-select__font"
      *ngIf="{
        selectedOption: optionLabel(textElement.fontFamily, 'key')
      } as selectState"
    >
      <design-select
        [optionTemplate]="fontFamily"
        [options]="fontFamiliesKeyLabel"
        [selectedOption]="selectState.selectedOption"
        [directionOpen]="dropdownDirection"
        (selectOption)="selectOption($event)"
      ></design-select>
      <ng-template #fontFamily let-option="option">
        <span
          *ngIf="option?.key || option as optionKey"
          [style.font-family]="optionKey | fontFamily"
          class="wrapper-select__label"
          [class.is-active]="optionKey === selectState.selectedOption"
        >
          <ng-container
            *ngIf="
              textElement?.text && textElement.text.length > 10;
              else shortLabel
            "
          >
            {{ textElement.text }}
          </ng-container>
          <ng-template #shortLabel>
            {{ option?.label || optionLabel(option, 'label') }}
          </ng-template>
        </span>
      </ng-template>
    </div>
  </div>
</div>
