<div class="range-slider">
  <input
    type="range"
    class="range-slider__range"
    [formControl]="sliderControl"
    [value]="currentValue"
    [min]="minValue"
    [max]="maxValue"
    (pointerup)="stopChangeEvent($event)"
  />
</div>
