import { AbstractControl, FormGroup } from '@angular/forms';
import { ChangeDetectorRef } from '@angular/core';
import { decimalCeil, decimalFloor, stringNumberTrunc } from './math.utils';

export function detectChangesOnTouchEvent(
  cdr: ChangeDetectorRef,
  control: AbstractControl
) {
  const originalMethod = control.markAsTouched;

  control.markAsTouched = function () {
    originalMethod.apply(this);
    cdr.detectChanges();
  };
}

export function getControlName(c: AbstractControl): string | null {
  if (!c || !c.parent) {
    return null;
  }

  const formControls = (c.parent as FormGroup).controls;

  return (
    Object.keys(formControls).find((name) => c === formControls[name]) || null
  );
}

export function getFormatNumber(data: {
  value: number | null;
  minValue: number | null;
  maxValue: number | null;
  decimalPoints: number | null;
}): {
  value: number | null;
  isChange: boolean;
} {
  const { value, minValue, maxValue, decimalPoints } = data;

  if (value === null) {
    return { value, isChange: false };
  }

  if (isNaN(value)) {
    return { value: null, isChange: true };
  }

  const dp = decimalPoints !== null ? decimalPoints : undefined;

  if (minValue !== null && value < minValue) {
    return { value: decimalCeil(minValue, dp), isChange: true };
  } else if (maxValue !== null && value > maxValue) {
    return { value: decimalFloor(maxValue, dp), isChange: true };
  } else if (decimalPoints !== null) {
    const floorValue = decimalFloor(value, dp);

    if (floorValue !== value) {
      return { value: floorValue, isChange: true };
    }
  }

  return { value, isChange: false };
}
