import { Pipe, PipeTransform } from '@angular/core';
import { EElementType } from '../../../interfaces/editor.interface';

@Pipe({
  name: 'actionsElementTitle',
  standalone: true,
})
export class ActionElementTitlePipe implements PipeTransform {
  transform(elementType: EElementType): string {
    switch (elementType) {
      case EElementType.image:
        return 'Настроить картинку';
      case EElementType.text:
        return 'Настроить текст';
      case EElementType.figure:
        return 'Настроить фигуру';
      case EElementType.qr:
        return 'Настроить qr-код';
      default:
        return 'Настроить';
    }
  }
}
