import { CommonModule, DOCUMENT } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  Output,
} from '@angular/core';
import { PipesModule, WINDOW } from '@inaripro-nx/common-ui';

@Component({
  standalone: true,
  imports: [CommonModule, PipesModule],
  selector: 'design-card-object',
  templateUrl: './card-object.component.html',
  styleUrls: ['./card-object.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardObjectComponent {
  @Input() isActive = false;
  @Input() isBeforeInsert = false;
  @Input() isAfterInsert = false;
  @Input() element: { text: string | null; svg?: string } | null = null;
  @Input() text = '';

  @Output() onremove: EventEmitter<void> = new EventEmitter();
  @Output() oncopy: EventEmitter<void> = new EventEmitter();
  @Output() ondragstart: EventEmitter<void> = new EventEmitter();
  @Output() ondragend: EventEmitter<void> = new EventEmitter();
  @Output() ondragenter: EventEmitter<void> = new EventEmitter();
  @Output() onselect: EventEmitter<void> = new EventEmitter();
  @Output() ontouchmove: EventEmitter<TouchEvent> = new EventEmitter();

  private _draggable = false;

  get draggable() {
    return this._draggable;
  }
  set draggable(draggable: boolean) {
    const wasDraggable = this._draggable && !draggable;

    this._draggable = draggable;

    if (draggable) {
      this.ondragstart.emit();
    } else if (wasDraggable) {
      this.ondragend.emit();
    }
  }

  dragenter(event: DragEvent) {
    event.stopPropagation();
    this.ondragenter.emit();
  }

  onSelectEvent(event: MouseEvent) {
    event.stopPropagation();
    this.onselect.emit();
  }

  onCopyEvent(event: MouseEvent) {
    event.stopPropagation();
    this.oncopy.emit();
  }

  onRemoveEvent(event: MouseEvent) {
    event.stopPropagation();
    this.onremove.emit();
  }

  touchStartDrag(event: TouchEvent) {
    event.preventDefault();
    this.draggable = true;

    const clone: HTMLElement = (
      event.target as HTMLElement
    )?.parentNode?.parentNode?.cloneNode(true) as HTMLElement;

    clone.style.height = '60px';
    clone.style.width = '100%';
    clone.style.opacity = '50%';
    clone.style.position = 'absolute';
    clone.style.display = 'flex';

    const parent = this.document.querySelector('.content-layers');
    parent?.appendChild(clone);

    const dragMoveHandle = (event: TouchEvent) => {
      event.preventDefault();

      const { clientY } = event.touches[0];
      clone.style.top = clientY - 40 + 'px';

      this.ontouchmove.emit(event);
    };

    const touchFinishHandle = () => {
      this.draggable = false;
      parent?.removeChild(clone);

      this.cdr.detectChanges();

      this.window.removeEventListener('touchmove', dragMoveHandle);
      this.window.removeEventListener('touchend', touchFinishHandle);
      this.window.removeEventListener('touchcancel', touchFinishHandle);
    };

    this.window.addEventListener('touchmove', dragMoveHandle);
    this.window.addEventListener('touchend', touchFinishHandle);
    this.window.addEventListener('touchcancel', touchFinishHandle);
  }

  constructor(
    @Inject(WINDOW) private window: Window,
    @Inject(DOCUMENT) private document: Document,
    private cdr: ChangeDetectorRef,
    public elementRef: ElementRef
  ) {}
}
