<div class="header-go-back">
  <ng-container *ngIf="config$ | async as config; else relativeLinkTemplate">
    <ng-container
      [ngTemplateOutlet]="
        config.publicLinkType === linkTypeEnum.External
          ? externalLinkTemplate
          : relativeLinkTemplate
      "
      [ngTemplateOutletContext]="{
        link:
          config.publicLinkType === linkTypeEnum.External
            ? (toBack ? link : config.publicUrl + link)
            : link
      }"
    ></ng-container>
  </ng-container>
</div>

<ng-template #relativeLinkTemplate>
  <a [routerLink]="link">
    <ng-container [ngTemplateOutlet]="goBackTemplate"></ng-container>
  </a>
</ng-template>

<ng-template #externalLinkTemplate let-link="link">
  <a [attr.href]="link" (mousedown)="toBack && goBack($event)">
    <ng-container [ngTemplateOutlet]="goBackTemplate"></ng-container>
  </a>
</ng-template>

<ng-template #goBackTemplate>
  <span class="text">
    <i class="icon icon-ic_arrow"></i>
    Вернуться на сайт
  </span>
</ng-template>
