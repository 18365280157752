import { Inject, Injectable, OnDestroy } from '@angular/core';
import { WINDOW } from '@inaripro-nx/common-ui';
import { BehaviorSubject, Subscription, fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';

const BREAKPOINT_DESKTOP = 1024;

@Injectable({
  providedIn: 'root',
})
export class WindowToolsService implements OnDestroy {
  public isDesktop$: BehaviorSubject<boolean> = new BehaviorSubject(
    this.getIsDesktop()
  );

  private _subs: Subscription[] = [];
  set subs(sub: Subscription) {
    this._subs.push(sub);
  }

  constructor(@Inject(WINDOW) private window: Window) {
    this.init();
  }

  init() {
    this.subs = fromEvent(this.window, 'resize')
      .pipe(
        debounceTime(100),
        map(() => this.getIsDesktop()),
        distinctUntilChanged()
      )
      .subscribe((isDesktop) => this.setIsDesktop(isDesktop));
  }

  ngOnDestroy() {
    this._subs.forEach((s) => s.unsubscribe());
  }

  private setIsDesktop(isDesktop: boolean): void {
    this.isDesktop$.next(isDesktop);
  }

  private getIsDesktop(): boolean {
    return this.window.innerWidth >= BREAKPOINT_DESKTOP ? true : false;
  }
}
