import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import {
  EVideoResource,
  IParseVideo,
  parseVideoURL,
} from '@inaripro-nx/common-ui';

@Component({
  selector: 'design-video-player',
  standalone: true,
  templateUrl: './design-video-player.component.html',
  styleUrls: ['./design-video-player.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DesignVideoPlayerComponent implements OnChanges {
  @Input() url = '';
  @Input() height: number | string = '360';
  @Input() width = '100%';
  @Input() autoplay = false;

  safeURL: SafeResourceUrl | null = null;

  private youtubeURL = 'https://www.youtube.com/embed/';
  private vimeoURL = 'https://player.vimeo.com/video/';

  video: IParseVideo | null = null;

  get VIDEO_RESOURCE_TYPE() {
    return EVideoResource;
  }

  constructor(private sanitizer: DomSanitizer) {}

  ngOnChanges(simpleChanges: SimpleChanges) {
    if (simpleChanges['url'] && this.url) {
      this.parseUrl(this.url);
    }
  }

  private parseUrl(url: string) {
    this.video = parseVideoURL(url);

    if (this.video) {
      let videoURL = '';

      if (this.video.resource === EVideoResource.YUOTUBE) {
        videoURL = this.youtubeURL.concat(this.video.id);
      } else if (this.video.resource === EVideoResource.VIMEO) {
        videoURL = this.vimeoURL.concat(this.video.id);
      }

      if (this.autoplay && videoURL) {
        videoURL =
          videoURL + (videoURL.indexOf('?') > -1 ? '' : '?') + '&autoplay=1';
      }

      if (videoURL) {
        this.safeURL = this.sanitizer.bypassSecurityTrustResourceUrl(videoURL);
      }
    }
  }
}
