import { ChangeDetectionStrategy, Component } from '@angular/core';
import { BACKGROUND_COLOR_DEFAULT } from '@inaripro-nx/common-ui';
import { ColorPickerComponent } from '../../colorpicker.component';

@Component({
  standalone: true,
  imports: [ColorPickerComponent],
  selector: 'design-color-picker-page',
  templateUrl: './color-picker-page.component.html',
  styleUrls: ['./color-picker-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ColorPickerPageComponent {
  color = '#2889e9';

  public onChangeColor(color: string | null) {
    this.color = color || BACKGROUND_COLOR_DEFAULT;
  }
}
