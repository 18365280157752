export interface ICatalogNomenclatureDictionaryItem {
  id: number;
  isActive: boolean;
  isPublish: boolean;
  name: string;
  crashed: boolean;
  isPartner: boolean;
  logo: ICatalogNomenclatureDictionaryItemLogo | null;
  headTitle: string;
  hasProductDesign?: boolean;
}

export type CatalogNomenclatureDictionaryRecords = Record<
  number,
  ICatalogNomenclatureDictionaryItem
>;

export interface ICatalogNomenclatureDictionaryItemLogo {
  id: number;
  name: string;
  url: string;
}

export interface ICatalogNomenclatureListItem {
  id: number;
  parentId: number[];
  dictionaryId: number;
  editable: boolean;
  useAmount: number | null;
  level: number;
  dictionarySlug: string;
  position: number;
  canAddProduct: boolean;
  amount: number | null;
  hasProductDesign?: boolean;
}

export interface ICatalogNomenclatureTreeItem
  extends ICatalogNomenclatureListItem {
  children: ICatalogNomenclatureTreeItem[];
}

export interface ICatalogNomenclatureSeoListItem {
  id: number;
  parentId: number;
  name: string;
  description: string;
  slug: string;
  query: string;
  meta: {
    title: string;
    description: string;
    keywords: string;
  };
  isShow: boolean;
  existProductTypes: [];
  tag: string;
  tag_position: number;
  headTitle: string;
}

export interface ICatalogFavorite {
  [id: number]: number[];
}

export interface ICatalogCompare {
  [id: number]: number[];
}
