import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
} from '@angular/forms';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'design-form-input-number-control',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule],
  templateUrl: './form-input-number-control.component.html',
  styleUrls: ['./form-input-number-control.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormInputNumberControlComponent),
      multi: true,
    },
  ],
})
export class FormInputNumberControlComponent
  implements ControlValueAccessor, AfterViewInit
{
  @Input() isDisabled = false;
  @Input() autoFocus = true;
  @Input() autoSelect = true;
  @Input() controlId = '';
  @Input() classInput: string | null = 'g-form-control';

  @Output() finishInput: EventEmitter<void> = new EventEmitter();

  @ViewChild('inputField', { static: false }) inputField!: ElementRef;

  formControl = new FormControl();
  lastValue: number | null = null;

  onChange: ((val: number) => void) | null = null;
  onTouched: (() => void) | null = null;

  constructor(private cdr: ChangeDetectorRef) {}

  ngAfterViewInit() {
    if (this.autoFocus) {
      this.inputField.nativeElement.focus();
    }
  }

  writeValue(value: number) {
    this.lastValue = value;
    this.formControl.setValue(value);
    this.cdr.markForCheck();
  }

  registerOnChange(fn: (val: number) => void): void {
    this.onChange = (val: number) => {
      fn(val);
      this.lastValue = val;
    };
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  confirmInput(input: HTMLInputElement) {
    this.onChange && this.onChange(+input.value);
    this.finishInput.emit();
  }

  cancelInput(input: HTMLInputElement) {
    this.formControl.setValue(this.lastValue);
  }

  onFocus(event: FocusEvent) {
    if (this.autoSelect) {
      (event.target as HTMLInputElement).select();
    }
  }
}
