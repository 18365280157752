<div class="image-ai">
  <div class="image-ai-input">
    <div class="image-ai-input-text">
      <textarea
        (keydown)="onTextEnter($event)"
        [formControl]="inputText"
        [maxlength]="maxlength"
        [readOnly]="isLoading || isSaving"
        placeholder="Для создания изображения введите текст, например: Парусник в бушующем океане, стиль Ивана Айвазовского, масло"
        #descriptionTextArea
      ></textarea>

      <div class="image-ai-input-text-counter">
        {{valueLength$ | async}} / {{maxlength}}
      </div>
    </div>
    <button (click)="generate()" [disabled]="isLockedForGenerate" class="image-ai-input-button" type="button">
      <i class="icon icon-magic-wand"></i>
      <span>Создать</span>
    </button>
  </div>
  <div class="image-ai-output-wrapper">
    <div class="image-ai-output">
      <img *ngIf="imageResponse?.url" [attr.src]="imageResponse!.url" class="image-ai-output-picture">
      <div *ngIf="isLoading" class="image-ai-output-loading">
        <div class="preloader">
          <design-preloader></design-preloader>
        </div>
        <div class="image-ai-output-loading-comment">
          Подготавливаем изображение, это не займет много времени
        </div>
      </div>
      <div *ngIf="!isLoading && errorMessage" class="image-ai-output-loading">
        <div class="image-ai-output-loading-comment image-ai-output-loading-comment-error">
          {{errorMessage}}
        </div>
      </div>
    </div>
  </div>
</div>
