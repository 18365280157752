import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { IGalleryImage } from '../../interfaces/design-light-gallery-content-new.interface';

@Component({
  selector: 'design-light-gallery-content-new-arrows-thumbnails',
  standalone: true,
  templateUrl:
    './design-light-gallery-content-new-arrows-thumbnails.component.html',
  styleUrls: [
    './design-light-gallery-content-new-arrows-thumbnails.component.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DesignLightGalleryContentNewArrowsThumbnailsComponent
  implements OnChanges
{
  @Input() images: IGalleryImage[] = [];
  @Input() selectedIndex = 0;

  @Output() prevEmit = new EventEmitter();
  @Output() nextEmit = new EventEmitter();

  private _currentIndex = 0;
  get currentIndex(): number {
    return this._currentIndex + 1;
  }

  get lengthImages(): number {
    return this.images.length;
  }

  ngOnChanges(simpleChanges: SimpleChanges) {
    if (simpleChanges['selectedIndex']) {
      this._currentIndex = this.selectedIndex;
    }
  }

  public minus(): void {
    this.prevEmit.emit(this._currentIndex);
  }

  public plus(): void {
    this.nextEmit.emit(this._currentIndex);
  }
}
