import { Injectable } from '@angular/core';
import { IFileResponse } from '@inaripro-nx/common-ui';
import { Observable, of, switchMap, take, withLatestFrom } from 'rxjs';
import {
  CURRENT_TEMPLATE_VERSION,
  IProductTemplate,
  IShareResponse,
  IShareTemplate,
  ITemplate,
} from '../../interfaces/templates.interface';
import { HistoryStore } from '../../state/history/history.store';
import { ProductStore } from '../../state/product/product.store';
import { MyTemplatesService } from '../my-templates/my-templates.service';
import { ProductTemplatesService } from '../product-templates/product-templates.service';
import { ShareTemplatesService } from '../share-templates/share-templates.service';

@Injectable({
  providedIn: 'root',
})
export class TemplatesService {
  savedTemplate$: Observable<ITemplate | IProductTemplate | null> | null = null;

  constructor(
    private historyStore: HistoryStore,
    private productStore: ProductStore,
    private myTemplatesService: MyTemplatesService,
    private productTemplatesService: ProductTemplatesService,
    private shareTemplatesService: ShareTemplatesService
  ) {}

  createTemplate(
    name: string,
    cover: IFileResponse | null
  ): Observable<IProductTemplate | ITemplate | null> {
    return this.productStore.product$.pipe(
      take(1),
      withLatestFrom(
        this.productStore.isProductOwner$,
        this.productStore.activeDesignProduct$,
        this.historyStore.elements$,
        this.productStore.zoneColors$,
        this.productStore.zonePatterns$
      ),
      switchMap(
        ([
          product,
          isProductOwner,
          activeDesignProduct,
          elements,
          zoneColors,
          zonePatterns,
        ]) => {
          if (!product) {
            return of(null);
          }

          const template: Partial<ITemplate> = {
            name,
            cover,
            productId: product.id,
            json: {
              activeDesignProduct,
              elements,
              zoneColors,
              zonePatterns,
            },
            version: CURRENT_TEMPLATE_VERSION,
          };

          const createF = () => {
            if (!isProductOwner) {
              if (!this.myTemplatesService.createMyTemplate) {
                return false;
              }

              this.myTemplatesService.createMyTemplate(template);
            } else {
              if (!this.productTemplatesService.createProductTemplate) {
                return false;
              }

              this.productTemplatesService.createProductTemplate({
                ...template,
                isActive: false,
              });
            }

            return;
          };

          createF();

          return this.savedTemplate$?.pipe(take(1)) || of(null);
        }
      )
    );
  }

  updateTemplateElements(
    patchTemplate: Partial<IProductTemplate | ITemplate>
  ): Observable<IProductTemplate | ITemplate | null> {
    return this.productStore.product$.pipe(
      take(1),
      withLatestFrom(
        this.productStore.currentTemplate$,
        this.productStore.isProductOwner$,
        this.productStore.activeDesignProduct$,
        this.historyStore.elements$,
        this.productStore.zoneColors$,
        this.productStore.zonePatterns$
      ),
      switchMap(
        ([
          product,
          currentTemplate,
          isProductOwner,
          activeDesignProduct,
          elements,
          zoneColors,
          zonePatterns,
        ]) => {
          if (!currentTemplate || !product) {
            return of(null);
          }

          const template: ITemplate | IProductTemplate = {
            ...currentTemplate,
            ...patchTemplate,
            json: {
              activeDesignProduct,
              elements,
              zoneColors,
              zonePatterns,
            },
            version: CURRENT_TEMPLATE_VERSION,
          };

          const updateF = () => {
            if (!isProductOwner) {
              if (!this.myTemplatesService.updateMyTemplate) {
                return of(null);
              }

              this.myTemplatesService.updateMyTemplate({
                template,
                triggerToSaved: true,
              });
            } else {
              if (!this.productTemplatesService.updateProductTemplate) {
                return of(null);
              }

              this.productTemplatesService.updateProductTemplate({
                template: template as IProductTemplate,
                triggerToSaved: true,
              });
            }

            return;
          };

          updateF();

          return this.savedTemplate$?.pipe(take(1)) || of(null);
        }
      )
    );
  }

  createShareTemplate(uid: string): Observable<IShareResponse | null> {
    return this.productStore.product$.pipe(
      take(1),
      withLatestFrom(
        this.productStore.activeDesignProduct$,
        this.historyStore.elements$,
        this.productStore.zoneColors$,
        this.productStore.zonePatterns$
      ),
      switchMap(
        ([
          product,
          activeDesignProduct,
          elements,
          zoneColors,
          zonePatterns,
        ]) => {
          if (!product) {
            return of(null);
          }

          const template: IShareTemplate = {
            uid,
            productId: product.id,
            json: {
              activeDesignProduct,
              elements,
              zoneColors,
              zonePatterns,
            },
            version: CURRENT_TEMPLATE_VERSION,
          };

          return this.shareTemplatesService.createShareTemplate(template);
        }
      )
    );
  }
}
