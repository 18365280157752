import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { DesignUiModule } from '@inaripro-nx/design-ui';
import { IElement } from '../../../../../../interfaces/editor.interface';
import { ActionsElementRotateComponent } from '../../../actions-element-rotate/actions-element-rotate.component';
import { DECIMAL_POINTS_ROTATE } from '../../../../../../../../constants';

@Component({
  selector: 'painter-actions-text-rotate',
  standalone: true,
  imports: [CommonModule, DesignUiModule, ActionsElementRotateComponent],
  templateUrl: './actions-text-rotate.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActionsTextRotateComponent {
  @Input() element!: IElement;

  readonly DECIMAL_POINTS_ROTATE = DECIMAL_POINTS_ROTATE;
}
