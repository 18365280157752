<div
  class="state-helper"
  designClickOutside
  (clickOutsideEvent)="helperShowed && removePopover()"
>
  <span (click)="popoverToggle()">
    <span #statePopoverTitle>
      <ng-content select=".state-popover__toggle"></ng-content>
    </span>

    <i
      class="icon icon-ic_help_outline_24"
      *ngIf="statePopoverTitle.children.length === 0"
      [class.active]="helperShowed"
    ></i>
  </span>

  <div
    class="state-popover"
    #stateHelper
    *ngIf="helperShowed"
    [class.state-popover--xs]="size === 'xs'"
    [class.state-popover--xxs]="size === 'xxs'"
    [class.state-popover--auto]="position === 'auto'"
    [class.state-popover--right]="position === 'right'"
    [class.state-popover--left]="position === 'left'"
    [class.state-popover--bottom]="position === 'bottom'"
    [class.state-popover--left-bottom]="position === 'left-bottom'"
    [class.state-popover--auto-bottom]="position === 'auto-bottom'"
  >
    <span class="state-popover__close-btn" (click)="popoverToggle()">
      <i class="icon icon-ic_close"></i>
    </span>
    <div class="state-popover-header">
      <ng-content select=".state-popover-body__header"></ng-content>
      {{ title || '' }}
    </div>
    <ngx-simplebar style="max-height: 200px">
      <div class="state-popover-body">
        <ng-content select=".state-popover-body__content"></ng-content>
      </div>
    </ngx-simplebar>
    <div class="state-popover-footer">
      <span
        class="state-popover__close-ok"
        *ngIf="okClose"
        (click)="popoverToggle()"
      >
        ok
      </span>
    </div>
  </div>
</div>
