import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { EMPTY, catchError, exhaustMap, tap } from 'rxjs';
import { ICity } from '../interfaces';
import { GeoService } from '../services';

export interface GeoState {
  cities: ICity[];
  currentLocation: ICity | null;
}

const initialState: GeoState = {
  cities: [],
  currentLocation: null,
};

@Injectable()
export class GeoStore extends ComponentStore<GeoState> {
  readonly cities$ = this.select((state) => state.cities);
  readonly currentLocation$ = this.select((state) => state.currentLocation);

  readonly loadCities = this.effect((trigger$) =>
    trigger$.pipe(
      exhaustMap(() => {
        return this.geoService.getCitiesList().pipe(
          tap((cities) => {
            this.setState((state) => {
              return {
                ...state,
                cities,
              };
            });
          }),
          catchError(() => EMPTY)
        );
      })
    )
  );

  readonly setCurrentLocation = this.updater((state, payload: ICity | null) => {
    const cookiesCity = this.geoService.getCookiesCity();
    const currentLocation = payload || cookiesCity;

    this.geoService.setCookiesCity(currentLocation);

    return {
      ...state,
      currentLocation: payload || cookiesCity,
    };
  });

  init() {
    this.loadCities();
  }

  constructor(private readonly geoService: GeoService) {
    super({ ...initialState });
  }
}
