import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { isString } from 'lodash';

@Component({
  standalone: true,
  imports: [CommonModule],
  selector: 'design-color-button',
  templateUrl: './color-button.component.html',
  styleUrls: ['./color-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ColorButtonComponent {
  colorTitle = '';
  @Input() set colorsTitles(colorsTitles: string[] | string) {
    const titles: string[] = isString(colorsTitles)
      ? [colorsTitles as string]
      : (colorsTitles as string[]);
    this.colorTitle = titles.join('/');
  }

  colorValues: string[] = [];
  @Input() set colors(value: string[] | string) {
    const values: string[] = isString(value)
      ? (value as string).split(',')
      : (value as string[]).join(',').split(',');
    this.colorValues = values;
  }

  @Input() isActive = false;
  @Input() isError = false;
  @Input() isDisabled = false;
  @Input() crashed = false;
  @Input() isShowRemoveIcon = false;

  @Input() newSize = false;

  @Output() changeModel: EventEmitter<void> = new EventEmitter();

  isWhite(colors: string[]): boolean {
    const regex = /^#f{3,6}$/gim;
    return colors.findIndex((color) => regex.test(color)) > -1;
  }
}
