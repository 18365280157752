<design-modal-window [uid]="basketSuccessModalUid" [maxWidth]="320">
  <div modal-header>Товар добавлен в корзину</div>
  <div modal-body>
    <div class="content">
      <i class="icon icon-cart"></i>
      <p class="content-text content-text--first">Товар</p>
      <p class="content-text">{{ name }}</p>
      <p class="content-text content-text--last">добавлен в корзину</p>
      <p class="g-form-button--center">
        <a
          class="g-btn-flat g-btn-flat--red"
          [attr.href]="this.publicUrl + '/order'"
        >
          Перейти в Корзину
        </a>
        <button class="g-btn-flat g-btn-flat--white" (click)="modalClose()">
          Заказать еще
        </button>
      </p>
    </div>
  </div>
</design-modal-window>
