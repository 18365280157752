<ng-container *ngIf="{ product: product$ | async } as state">
  <div
    class="no-content"
    *ngIf="!state.product; else content"
    (click)="selectProduct()"
  >
    Выбрать базовый товар
    <i class="icon icon-ic_pencil"></i>
  </div>
</ng-container>

<ng-template #content>
  <div
    class="content"
    *ngIf="{ page: page$ | async, object: object$ | async } as state"
  >
    <painter-content-product
      *ngIf="state.page === EPage.product; else otherContent"
      [config]="config"
    ></painter-content-product>

    <ng-template #otherContent>
      <painter-content-fill
        *ngIf="isActiveObjects(state.page) && state.object === EObject.fill"
      ></painter-content-fill>

      <ng-container *ngIf="activeDesignSide; else selectSide">
        <painter-content-templates
          *ngIf="
            isActiveObjects(state.page) && state.object === EObject.templates
          "
        ></painter-content-templates>

        <painter-content-pictures
          *ngIf="
            isActiveObjects(state.page) && state.object === EObject.pictures
          "
          (selectObject)="addSVGObject($event)"
        ></painter-content-pictures>

        <painter-content-text
          *ngIf="isActiveObjects(state.page) && state.object === EObject.text"
          (addObject)="addTextObject($event)"
          [elements]="elements$ | async"
          [activeSideIndex]="activeDesignSideIndex"
          [activeDesignProduct]="activeDesignProduct$ | async"
        ></painter-content-text>

        <painter-content-figures
          *ngIf="
            isActiveObjects(state.page) && state.object === EObject.figures
          "
          (selectObject)="addSVGObject($event)"
        ></painter-content-figures>

        <painter-content-qrcodes
          *ngIf="
            isActiveObjects(state.page) && state.object === EObject.qrcodes
          "
          (selectObject)="addSVGObject($event)"
        ></painter-content-qrcodes>

        <painter-content-layers
          *ngIf="state.page === EPage.layers"
          [activeDesignProduct]="activeDesignProduct$ | async"
          [activeElementIndex]="activeElementIndex$ | async"
          [activeDesignSideIndex]="activeDesignSideIndex"
          [elements]="elements$ | async"
        ></painter-content-layers>
      </ng-container>

      <ng-template #selectSide>
        <div class="no-content" (click)="setPage(EPage.product)">
          Уточнить параметры продукта
        </div>
      </ng-template>
    </ng-template>
  </div>
</ng-template>
