import {
  UserMatchTypeEnum,
  UserPermissionEnum,
  UserRoleEnum,
} from '../enums/user.enum';

export interface IUser {
  id: number;
  companyId?: string;
  companyName?: string;
  data: IUserData;
  role: UserRoleEnum;
  permissions?: UserPermissionEnum[];
  enabled: boolean;
  createDate: string;
  actionDate: string;
  matchType?: UserMatchTypeEnum;
  editCalculators?: boolean;
  editCatalog?: boolean;
  city?: IUserCity;
  filial?: IUserCity;
}

export interface IUserData {
  login?: string;
  email: string;
  firstName: string;
  lastName: string;
  patronymic?: string;
  phone: string;
  password?: string;
}

export interface IUserCity {
  id: number;
  name: string;
}
