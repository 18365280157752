<div class="header-logo" [class.header-logo--fixed]="fixed">
  <ng-container *ngIf="config$ | async as config; else relativeLinkTemplate">
    <ng-container
      [ngTemplateOutlet]="
        config.publicLinkType === linkTypeEnum.External
          ? externalLinkTemplate
          : relativeLinkTemplate
      "
      [ngTemplateOutletContext]="{
        link:
          config.publicLinkType === linkTypeEnum.External
            ? config.publicUrl + link
            : link
      }"
    ></ng-container>
  </ng-container>
</div>

<ng-template #relativeLinkTemplate>
  <a [routerLink]="link">
    <ng-container [ngTemplateOutlet]="logoTemplate"></ng-container>
  </a>
</ng-template>

<ng-template #externalLinkTemplate let-link="link">
  <a [attr.href]="link">
    <ng-container [ngTemplateOutlet]="logoTemplate"></ng-container>
  </a>
</ng-template>

<ng-template #logoTemplate>
  <img
    src="./assets/images/logo.svg"
    alt=""
    class="header-logo__img"
    [class.header-logo__img--sm]="fixed"
  />
  <span class="header-logo__tagline" *ngIf="!fixed">
    Производственная компания
  </span>
</ng-template>
