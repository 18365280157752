import { Inject, Injectable } from '@angular/core';
import { WINDOW } from '@inaripro-nx/common-ui';
import {
  BehaviorSubject,
  distinctUntilChanged,
  filter,
  fromEvent,
  map,
  merge,
  take,
  takeUntil,
  tap,
} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class EyeDropperService {
  constructor(@Inject(WINDOW) private window: Window) {}

  public eyeColor$ = new BehaviorSubject<string | null>(null);

  public startEye(e: Event) {
    e.stopPropagation();
    const elementSvgBase = document.querySelector('#svgBase');

    if (elementSvgBase !== null) {
      (elementSvgBase as HTMLElement).style.cursor = 'crosshair';

      const elementClick$ = fromEvent<Event>(elementSvgBase, 'click').pipe();

      const windowClick$ = fromEvent<Event>(this.window, 'click').pipe();

      const keyup$ = fromEvent<KeyboardEvent>(this.window, 'keyup').pipe(
        map((evnt: KeyboardEvent) => evnt.key),
        filter((key: string) => key === 'Escape')
      );

      const stopEvent$ = merge(elementClick$, keyup$, windowClick$).pipe(
        take(1),
        tap(() => {
          (elementSvgBase as HTMLElement).style.cursor = 'default';
          this.eyeColor$.next(null);
        })
      );

      const defaultColor = '#000000';

      fromEvent<MouseEvent>(elementSvgBase, 'mouseover')
        .pipe(
          map((el: MouseEvent) => {
            const target = el.target as SVGElement;

            if (!target) return defaultColor;

            if (target === elementSvgBase) {
              return defaultColor;
            }

            const getColor = (element: SVGElement): string => {
              if (element === elementSvgBase) {
                return defaultColor;
              }

              const getFill = element?.getAttribute('fill');

              if (
                getFill === null ||
                getFill === 'inherit' ||
                getFill === 'none'
              ) {
                const parentElement = (element as SVGElement)
                  ?.parentElement as unknown as SVGElement;

                return getColor(parentElement);
              }

              return getFill;
            };

            return getColor(target);
          }),
          distinctUntilChanged(),
          takeUntil(stopEvent$)
        )
        .subscribe((v) => {
          this.eyeColor$.next(v);
        });
    }
  }
}
