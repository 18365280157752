import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnChanges,
  QueryList,
  SimpleChanges,
  ViewChildren,
} from '@angular/core';
import { IDesignProduct } from '@inaripro-nx/catalog';
import { DesignUiModule } from '@inaripro-nx/design-ui';
import { isUndefined } from 'lodash';
import { IElement, IElements } from '../../../../interfaces/editor.interface';
import { HistoryStore } from '../../../../state/history/history.store';

@Component({
  selector: 'painter-content-layers',
  standalone: true,
  imports: [CommonModule, DesignUiModule],
  templateUrl: './content-layers.component.html',
  styleUrls: ['./content-layers.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentLayersComponent implements OnChanges {
  @Input() activeDesignProduct: IDesignProduct | null = null;
  @Input() activeElementIndex: number | null = null;
  @Input() elements: IElements | null = null;
  @Input() activeDesignSideIndex: number | null = null;

  @ViewChildren('card') cards!: QueryList<ElementRef>;

  private _dragStartIndex: number | null = null;

  get dragStartIndex(): number | null {
    return this._dragStartIndex;
  }

  _draggableSideIndex: number | null = null;

  get draggableSideIndex(): number | null {
    return this._draggableSideIndex;
  }

  set draggableSideIndex(index: number | null) {
    this._draggableSideIndex = index;
  }

  private _draggableIndex: number | null = null;

  get draggableIndex(): number | null {
    return this._draggableIndex;
  }

  set draggableIndex(index: number | null) {
    if (this._draggableIndex === null || index === null) {
      this._dragStartIndex = index;
    }

    this._draggableIndex = index;
  }

  copyElements: IElements | null = null;

  hasMoves = false;

  dragActiveSide = -1;
  openedSides: boolean[] = [];

  constructor(private historyStore: HistoryStore) {}

  ngOnChanges(simpleChanges: SimpleChanges) {
    if (this.dragStartIndex === null) {
      this.doCopy();
    }

    if (simpleChanges['activeDesignProduct']) {
      this.openedSides = (this.activeDesignProduct?.sides || []).map(
        (side, index) => index === this.activeDesignSideIndex
      );
    }

    if (simpleChanges['activeDesignSideIndex']) {
      if (
        this.activeDesignSideIndex !== null &&
        !isUndefined(this.openedSides[this.activeDesignSideIndex])
      ) {
        this.openedSides[this.activeDesignSideIndex] = true;
      }
    }
  }

  private doCopy() {
    this.copyElements = this.elements
      ? this.elements.map((el) => ({ ...el })).reverse()
      : this.elements;
  }

  onselect(activeElementIndex: number | null) {
    this.historyStore.setActiveElementIndex({
      activeElementIndex,
    });
  }

  onremove(index: number) {
    this.historyStore.removeElement({ index });
  }

  oncopy(index: number) {
    if (this.activeDesignSideIndex === null) {
      return;
    }

    this.historyStore.copyElement({
      index,
      sideIndex: this.activeDesignSideIndex,
    });
  }

  ondragstart(index: number, sideIndex: number) {
    this.draggableIndex = index;
    this.draggableSideIndex = sideIndex;

    this.hasMoves = false;

    this.historyStore.setActiveElementIndex({
      activeElementIndex: null,
    });
  }

  private getNewOrderElements(): IElements {
    if (
      !this.copyElements?.length ||
      this.dragStartIndex === null ||
      this.draggableIndex === null ||
      this.draggableSideIndex === null
    ) {
      return [];
    }

    const newElements = [...this.copyElements];
    const el = { ...newElements[this.dragStartIndex] };
    el.sideIndex = this.draggableSideIndex;

    if (this.dragStartIndex === this.draggableIndex) {
      return newElements;
    } else if (this.dragStartIndex < this.draggableIndex) {
      newElements.splice(this.draggableIndex + 1, 0, el);
      newElements.splice(this.dragStartIndex, 1);
    } else {
      newElements.splice(this.dragStartIndex, 1);
      newElements.splice(this.draggableIndex + 1, 0, el);
    }

    return newElements;
  }

  ondragend() {
    const newElements = this.getNewOrderElements();
    this.draggableIndex = null;
    this.draggableSideIndex = null;
    this.dragActiveSide = -1;

    if (this.hasMoves) {
      this.hasMoves = false;

      this.renderDragenter(false, newElements);
      this.historyStore.setActiveElementIndex({
        activeElementIndex: null,
      });
    }
  }

  dragenter(index: number, sideIndex: number) {
    if (
      !this.copyElements?.length ||
      this.dragStartIndex === null ||
      this.draggableIndex === null
    ) {
      return;
    }

    this.draggableIndex = index;
    this.draggableSideIndex = sideIndex;

    if (index === -1) {
      this.dragActiveSide = sideIndex;
      this.openedSides[sideIndex] = true;
    } else {
      this.dragActiveSide = -1;
    }

    const newElements = this.getNewOrderElements();
    this.renderDragenter(true, newElements);
  }

  private renderDragenter(historyFreeze: boolean, newElements: IElements) {
    this.historyStore.setHistoryFreeze(historyFreeze);
    this.historyStore.updateElements({ elements: newElements.reverse() });
    this.hasMoves = true;
  }

  ontouchmove(event: TouchEvent) {
    const touch = event.changedTouches[0];
    const clientY = touch.clientY;
    const cards = (this.cards || []).toArray();

    const findCard = cards.find((c, i) => {
      const rect: DOMRect = c.nativeElement.getBoundingClientRect();
      const { top, bottom } = rect;

      return (
        (clientY >= top && clientY <= bottom) ||
        (i === 0 && clientY <= top) ||
        (i === cards.length - 1 && clientY >= bottom)
      );
    });

    if (!findCard) {
      return;
    }

    const elementIndex =
      +findCard.nativeElement.getAttribute('data-element-index');
    const sideIndex = +findCard.nativeElement.getAttribute('data-side-index');

    this.dragenter(elementIndex, sideIndex);
  }

  getElementIndexBySideIndex(el: IElement): number {
    return (this.copyElements || [])
      .filter((e) => e.sideIndex === el.sideIndex)
      .indexOf(el);
  }
}
