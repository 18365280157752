import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  IImageAIGenerateRequest,
  IImageAIGenerateResponse,
} from '../../interfaces/image-ai.interface';
import {
  debounceTime,
  delay,
  EMPTY,
  expand,
  Observable,
  of,
  switchMap,
} from 'rxjs';
import { IPicture } from '@inaripro-nx/common-ui';

const timeForRepeat = 5000;

@Injectable({
  providedIn: 'root',
})
export class ImageAiService {
  constructor(private readonly http: HttpClient) {}

  generate(
    request: IImageAIGenerateRequest
  ): Observable<IImageAIGenerateResponse> {
    return this.http
      .post<IImageAIGenerateResponse>('catalog/public/design/aiimage/', request)
      .pipe(
        delay(timeForRepeat),
        switchMap((resp) => this.waitResult(resp.id))
      );
  }

  saveToLibrary(id: number): Observable<IPicture> {
    return this.http.get<IPicture>(`/catalog/public/design/aiimage/save/${id}`);
  }

  private getResult$ = (id: number) =>
    this.http.get<IImageAIGenerateResponse>(
      `/catalog/public/design/aiimage/${id}`
    );

  private waitResult(id: number) {
    return this.getResult$(id).pipe(
      expand((resp) => {
        return resp.url
          ? EMPTY
          : of(null).pipe(
              delay(timeForRepeat),
              switchMap(() => this.getResult$(id))
            );
      })
    );
  }
}
