import { Injectable } from '@angular/core';
import { AppShellConfig, linkToGlobalState } from '@inaripro-nx/common-ui';
import { ComponentStore } from '@ngrx/component-store';
import { Store } from '@ngrx/store';
import { EMPTY, catchError, exhaustMap, tap } from 'rxjs';
import { Links } from '../interfaces/links.interface';
import { LinksService } from '../services/links.service';

export interface LinksState {
  links: Links | null;
}

const initialState: LinksState = {
  links: null,
};

@Injectable({
  providedIn: 'root',
})
export class LinksStore extends ComponentStore<LinksState> {
  readonly links$ = this.select((state) => state.links);

  readonly loadLinks = this.effect((trigger$) =>
    trigger$.pipe(
      exhaustMap(() => {
        return this.linksService.getLinks().pipe(
          tap((links) => {
            this.setState((state) => {
              return { ...state, links };
            });
          }),
          catchError(() => EMPTY)
        );
      })
    )
  );

  readonly setConfig = this.updater((state, payload: AppShellConfig | null) => {
    return {
      ...state,
      config: payload,
    };
  });

  init() {
    this.loadLinks();
  }

  constructor(
    private readonly linksService: LinksService,
    private readonly globalStore: Store
  ) {
    super({ ...initialState });
    linkToGlobalState(this.state$, 'libs/links', this.globalStore);
  }
}
