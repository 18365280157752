import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IPicture, IPictureLibrary } from '@inaripro-nx/common-ui';

@Injectable({
  providedIn: 'root',
})
export class PicturesLibraryService {
  libraries$!: Observable<IPictureLibrary[]>;
  currentId$!: Observable<number>;
  currentLibrary$!: Observable<IPictureLibrary | null>;
  currentPictures$!: Observable<IPicture[] | null>;

  getPictures!: (id: number) => void;
}
