<div class="order-price" [class.order-price--updating]="updatingPrice">
  <ng-container *ngIf="discountValue; else baseTemplate">
    <ng-container *ngIf="baseValue === discountValue; else twoPrices">
      <div class="order-curr-price">{{ discountValue | number }}&nbsp;Р</div>
    </ng-container>
  </ng-container>
</div>

<ng-template #twoPrices>
  <div class="order-old-price">{{ baseValue | number }}&nbsp;Р</div>
  <div class="order-curr-price">{{ discountValue | number }}&nbsp;Р</div>
</ng-template>

<ng-template #baseTemplate>
  <div class="order-curr-price">{{ baseValue | number }}&nbsp;Р</div>
</ng-template>
