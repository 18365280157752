import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { AdDropDownDirective, DropDownModule } from '@inaripro-nx/design-ui';
import { EShareType, IShare, ShareService } from '@inaripro-nx/common-ui';
import { EditorButtonComponent } from '../editor-button/editor-button.component';
import { EditorProductSidesComponent } from '../editor-product-sides/editor-product-sides.component';
import { HistoryStore } from '../../../../state/history/history.store';
import { Subscription } from 'rxjs';
import { TemplatesService } from '../../../../services/templates/templates.service';

interface DropDownItem {
  dropDownLabel: string;
}

@Component({
  selector: 'editor-share-links-controls',
  standalone: true,
  imports: [
    CommonModule,
    DropDownModule,
    EditorButtonComponent,
    EditorProductSidesComponent,
  ],
  templateUrl: './editor-share-links-controls.component.html',
  styleUrls: ['./editor-share-links-controls.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditorShareLinksControlComponent implements OnInit, OnDestroy {
  @ViewChild(AdDropDownDirective)
  adDropDownDirective!: AdDropDownDirective<any>;

  @ViewChild('shareTemplate', { static: false })
  shareTemplate!: TemplateRef<unknown>;

  active = false;
  leftShift = 91.5;
  shareList: IShare[] = [];
  private hrefParams: { templateUid: string } = { templateUid: '' };
  private isCreatedShareTemplate = false;

  private _subs: Subscription[] = [];
  set subs(sub: Subscription) {
    this._subs.push(sub);
  }

  constructor(
    private shareService: ShareService,
    private historyStore: HistoryStore,
    private templatesService: TemplatesService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.createShareLinks();

    this.subs = this.historyStore.uuid$.subscribe((uuid) => {
      this.hrefParams = { templateUid: uuid };
      this.isCreatedShareTemplate = false;
      this.createShareLinks();
      this.cdr.detectChanges();
    });
  }

  ngOnDestroy(): void {
    this._subs.forEach((s) => s.unsubscribe());
  }

  private createShareLinks(): void {
    this.shareList = this.shareService.getShares(this.hrefParams);
  }

  public copyURL(): void {
    this.shareService.copyUrl(this.hrefParams);
  }

  public shareOpen(type: EShareType): void {
    this.shareService.shareOpen(type, this.hrefParams);
  }

  public handleLinkClick(event: Event): void {
    event.preventDefault();
  }

  toggleDropDown(active: boolean): void {
    this.active = active;

    if (active && this.hrefParams.templateUid && !this.isCreatedShareTemplate) {
      this.subs = this.templatesService
        .createShareTemplate(this.hrefParams.templateUid)
        .subscribe(() => (this.isCreatedShareTemplate = true));
    }
  }
}
