<div
  class="color-view-element"
  [style.borderTopColor]="colors[0]"
  [style.borderLeftColor]="colors[0]"
  [style.borderBottomColor]="colors[1] || colors[0]"
  [style.borderRightColor]="colors[1] || colors[0]"
  [style.borderTopWidth]="borderTopBottom + 'px'"
  [style.borderBottomWidth]="borderTopBottom + 'px'"
  [style.borderLeftWidth]="borderLeftRight + 'px'"
  [style.borderRightWidth]="borderLeftRight + 'px'"
  [style.borderRadius]="borderRadius + 'px'"
  [class.color-view-element--bordered]="isWhite(colors)"
  *ngIf="colors"
>
  <i *ngIf="!hideIcon" class="icon icon-ic_add" (click)="clickOut.emit()"></i>
  <div
    class="color-crashed"
    [style.top]="-(borderTopBottom + 2) + 'px'"
    [style.left]="-(borderLeftRight + 2) + 'px'"
    [style.borderRadius]="borderRadius + 'px'"
    [style.width]="borderLeftRight * 2 + 4 + 'px'"
    [style.height]="borderLeftRight * 2 + 4 + 'px'"
    *ngIf="crashed"
  ></div>
</div>
