import { Injectable } from '@angular/core';
import { HistoryStore } from '../../state/history/history.store';
import { ProductStore } from '../../state/product/product.store';
import {
  BehaviorSubject,
  catchError,
  combineLatest,
  delay,
  EMPTY,
  map,
  Observable,
  of,
  shareReplay,
  Subject,
  Subscription,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs';
import { IDesignSide } from '@inaripro-nx/catalog';
import { MyTemplatesService } from '../my-templates/my-templates.service';
import { ProductTemplatesService } from '../product-templates/product-templates.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import {
  IProductTemplate,
  ITemplate,
} from '../../interfaces/templates.interface';
import {
  IFileResponse,
  svgToPng,
  ValidatorsMaxLength,
  ValidatorsRequired,
} from '@inaripro-nx/common-ui';
import { TemplatesService } from '../templates/templates.service';
import { ITemplateForm } from '../../interfaces/editor.interface';
import { HttpClient } from '@angular/common/http';
import { ModalWindowStore } from '@inaripro-nx/design-ui';
import { MODAL_UID_SAVE_CHANGES_SUCCESS } from '../../interfaces/main.interface';

@Injectable({
  providedIn: 'root',
})
export class TemplateFormService {
  private currentTemplate$ = this.productStore.currentTemplate$;
  private isProductOwner$ = this.productStore.isProductOwner$;
  private defaultTemplateName$ = combineLatest([
    this.isProductOwner$,
    this.myTemplatesService.templates$,
    this.productTemplatesService.templates$,
  ]).pipe(
    map(([isProductOwner, myTemplates, productTemplates]) => {
      const count = isProductOwner
        ? (productTemplates || []).length
        : (myTemplates || []).length;

      return `Шаблон ${count + 1}`;
    }),
    shareReplay({ refCount: false, bufferSize: 1 })
  );

  public createOrUpdate$ = new Subject<
    | 'create'
    | 'update'
    | 'create_with_success_modal'
    | 'update_with_success_modal'
    | null
  >();

  public templateForm$: Observable<FormGroup | null> =
    this.createOrUpdate$.pipe(
      withLatestFrom(this.defaultTemplateName$, this.currentTemplate$),
      map(([action, defaultTemplateName, currentTemplate]) => {
        return (action === 'update' ||
          action === 'update_with_success_modal') &&
          currentTemplate
          ? this.getUpdateForm(
              currentTemplate,
              action === 'update_with_success_modal'
            )
          : action === 'create' || action === 'create_with_success_modal'
          ? this.getCreateForm(
              defaultTemplateName,
              action === 'create_with_success_modal'
            )
          : null;
      })
    );

  public sides$: Observable<(IDesignSide & { sideIndex: number })[]> =
    combineLatest([
      this.historyStore.elements$,
      this.productStore.activeDesignProduct$,
    ]).pipe(
      map(([elements, activeDesignProduct]) =>
        (activeDesignProduct?.sides || [])
          .map((side, sideIndex) => ({ ...side, sideIndex }))
          .filter(
            ({ sideIndex }) =>
              !!elements.find((el) => el.sideIndex === sideIndex)
          )
      ),
      shareReplay({ refCount: false, bufferSize: 1 })
    );

  private readonly _isSending$: BehaviorSubject<boolean> = new BehaviorSubject(
    false
  );
  public readonly isSending$ = this._isSending$.asObservable();

  private readonly _isSaved$: BehaviorSubject<boolean> = new BehaviorSubject(
    false
  );
  public readonly isSaved$ = this._isSaved$.asObservable();

  private _coverConvertSub: Subscription | null = null;
  set coverConvertSub(sub: Subscription | null) {
    if (this._coverConvertSub) {
      this._coverConvertSub.unsubscribe();
    }
    this._coverConvertSub = sub;
  }

  constructor(
    private readonly http: HttpClient,
    private readonly historyStore: HistoryStore,
    private readonly productStore: ProductStore,
    private readonly myTemplatesService: MyTemplatesService,
    private readonly productTemplatesService: ProductTemplatesService,
    private readonly formBuilder: FormBuilder,
    private readonly templatesService: TemplatesService,
    private readonly modalWindowStore: ModalWindowStore
  ) {}

  private getCreateForm(
    defaultTemplateName: string,
    showSuccessModal: boolean
  ) {
    const controlsConfig: { [key: string]: unknown } = {
      id: null,
      name: [
        defaultTemplateName,
        [ValidatorsRequired(), ValidatorsMaxLength(255)],
      ],
      cover: [null, [ValidatorsRequired()]],
      showSuccessModal,
    };

    return this.formBuilder.group(controlsConfig);
  }

  private getUpdateForm(
    currentTemplate: ITemplate | IProductTemplate,
    showSuccessModal: boolean
  ) {
    const { id, name } = currentTemplate;
    const templateForm = this.getCreateForm(name, showSuccessModal);
    templateForm.patchValue({ id });
    return templateForm;
  }

  saveTemplate(templateForm: FormGroup<ITemplateForm>) {
    if (!templateForm || templateForm.invalid) {
      return;
    }

    this._isSending$.next(true);

    const { id, name, cover, showSuccessModal } = templateForm.value;

    const coverSVG: SVGGraphicsElement | null = (
      cover as HTMLElement
    ).querySelector('.svg-zone');

    if (!coverSVG) {
      return;
    }

    this.coverConvertSub = svgToPng({ svg: coverSVG })
      .pipe(
        switchMap((blob: Blob | null) => {
          let flow: Observable<{ file: IFileResponse } | null>;

          if (blob) {
            const formData: FormData = new FormData();
            formData.append(
              'file',
              blob as Blob,
              `cover_${new Date().getTime()}.png`
            );

            flow = this.http.post<{ file: IFileResponse }>(
              '/public/image/upload',
              formData
            );
          } else {
            flow = of(null);
          }

          return flow.pipe(
            switchMap((response) => {
              const cover = response?.file || null;

              if (id) {
                return this.templatesService.updateTemplateElements({
                  id,
                  name,
                  cover,
                });
              }

              return this.templatesService.createTemplate(name || '', cover);
            }),
            tap(() => {
              this.clearForm();
              this._isSending$.next(false);

              if (showSuccessModal) {
                this.modalWindowStore.patch({
                  [MODAL_UID_SAVE_CHANGES_SUCCESS]: true,
                });
              }
            }),
            catchError(() => {
              this._isSending$.next(false);
              return EMPTY;
            }),
            switchMap((t) => {
              return showSuccessModal ? of(null) : this.savingHandler(t);
            })
          );
        })
      )
      .subscribe();
  }

  clearForm() {
    this.createOrUpdate$.next(null);
  }

  private savingHandler(savedTemplate: IProductTemplate | ITemplate | null) {
    if (savedTemplate) {
      this._isSaved$.next(true);

      return of(savedTemplate).pipe(
        delay(2 * 1000),
        tap(() => {
          this._isSaved$.next(false);
        })
      );
    } else {
      return of(null);
    }
  }
}
