import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { DesignUiModule, ModalWindowStore } from '@inaripro-nx/design-ui';
import {
  FigureTypeEnum,
  ICropData,
  IFigure,
  IFigureListItem,
} from '../../interfaces';
import { CropFigureListComponent } from '../crop-figure-list/crop-figure-list.component';
import { CropContainerComponent } from '../crop-container/crop-container.component';
import {
  debounceTime,
  filter,
  from,
  map,
  Subject,
  Subscription,
  switchMap,
  withLatestFrom,
  distinctUntilChanged,
} from 'rxjs';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { DEFAULT_CROP_FIGURES } from '../../constants';
import { CropService } from '../../services/crop.service';
import { CropStore } from '../../store/crop';

@Component({
  selector: 'crop-modal',
  standalone: true,
  templateUrl: './crop-modal.component.html',
  styleUrls: ['./crop-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    DesignUiModule,
    CropFigureListComponent,
    CropContainerComponent,
  ],
  providers: [CropService, CropStore],
})
export class CropModalComponent implements OnChanges, OnInit, OnDestroy {
  @Input() image: string | null = null;
  @Input() imageWidth = 0;
  @Input() imageHeight = 0;

  @Input()
  set figures(data: IFigure[] | null) {
    if (data) {
      this.figureList = [
        ...DEFAULT_CROP_FIGURES,
        ...data.map((f) => ({ ...f, type: FigureTypeEnum.Mask })),
      ];
    }
  }

  @Output() cancel = new EventEmitter<void>();
  @Output() save = new EventEmitter<ICropData>();

  figureList: IFigureListItem[] = [...DEFAULT_CROP_FIGURES];
  selectedFigureForList: IFigureListItem = DEFAULT_CROP_FIGURES[0];
  selectedFigureForMask: IFigureListItem = DEFAULT_CROP_FIGURES[0];

  readonly modalUid = 'crop';
  readonly modalOpened$ = this.modalWindowStore.modals$.pipe(
    debounceTime(0),
    map((modals) => modals[this.modalUid])
  );

  readonly saveProportionsControl = new FormControl(false);

  private _subs: Subscription[] = [];
  set subs(sub: Subscription) {
    this._subs.push(sub);
  }

  private readonly saveSubject$ = new Subject<void>();

  constructor(
    private readonly modalWindowStore: ModalWindowStore,
    private readonly cropService: CropService,
    private readonly cropStore: CropStore,
    private readonly cdr: ChangeDetectorRef
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if ('image' in changes) {
      this.cropStore.setOriginalImage(this.image);
    }

    if ('imageWidth' in changes || 'imageHeight' in changes) {
      this.cropStore.setOriginalImageSize({
        width: this.imageWidth,
        height: this.imageHeight,
      });
    }
  }

  ngOnInit() {
    this.initCheckboxSubscription();
    this.initResizeSubscription();
    this.initSaveSubscription();
  }

  ngOnDestroy() {
    this._subs.forEach((s) => s.unsubscribe());
  }

  open() {
    this.modalWindowStore.patch({ [this.modalUid]: true });
  }

  close() {
    this.modalWindowStore.patch({ [this.modalUid]: false });
    this.reset();
  }

  onCancel() {
    this.cancel.emit();
    this.close();
  }

  onSave() {
    this.saveSubject$.next();
  }

  reset() {
    this.onSelectFigure(DEFAULT_CROP_FIGURES[0]);
  }

  onSelectFigure(selectedFigure: IFigureListItem) {
    this.selectedFigureForList = { ...selectedFigure };
    this.selectedFigureForMask = { ...selectedFigure };
    this.saveProportionsControl.setValue(!!selectedFigure.rectangle, {
      emitEvent: false,
    });

    this.cropStore.loadMask(this.selectedFigureForMask.image?.url || null);
  }

  private initCheckboxSubscription() {
    this.subs = this.saveProportionsControl.valueChanges.subscribe((value) => {
      if (value === false && !!this.selectedFigureForList?.rectangle) {
        this.selectedFigureForList = DEFAULT_CROP_FIGURES[0];
      }
    });
  }

  private initResizeSubscription() {
    this.subs = this.cropStore.resizing$
      .pipe(
        distinctUntilChanged(),
        filter((resizing) => !!resizing),
        filter(
          () => this.selectedFigureForList.type === FigureTypeEnum.Original
        )
      )
      .subscribe(() => {
        this.selectedFigureForList = DEFAULT_CROP_FIGURES[0];
        this.cdr.detectChanges();
      });
  }

  private initSaveSubscription() {
    this.subs = this.saveSubject$
      .asObservable()
      .pipe(
        withLatestFrom(
          this.cropStore.imageScale$,
          this.cropStore.imageX$,
          this.cropStore.imageY$,
          this.cropStore.cropWidth$,
          this.cropStore.cropHeight$,
          this.cropStore.cropX$,
          this.cropStore.cropY$,
          this.cropStore.inlineSvgMask$
        ),
        switchMap(
          ([
            ,
            imageScale,
            imageX,
            imageY,
            cropWidth,
            cropHeight,
            cropX,
            cropY,
            inlineSvgMask,
          ]) => {
            const x = (imageX - cropX) / imageScale;
            const y = (imageY - cropY) / imageScale;
            const width = cropWidth / imageScale;
            const height = cropHeight / imageScale;
            const dataURL = this.cropService.cutImage(
              x,
              y,
              width,
              height,
              this.image as string,
              inlineSvgMask
            );

            return from(dataURL).pipe(
              map((dataURL) => ({ x, y, width, height, dataURL }))
            );
          }
        ),
        filter(({ dataURL }) => dataURL !== null)
      )
      .subscribe(({ x, y, width, height, dataURL }) => {
        this.save.emit({ x, y, width, height, dataURL });
        this.close();
      });
  }
}
