<design-colorpicker
  [inColor]="element.fill || '#000000'"
  (changeModel)="editorService.fill($event)"
  (changeEnd)="editorService.stopFill()"
  [eyeColor]="eyeDropperService.eyeColor$ | async"
  (startEye)="eyeDropperService.startEye($event)"
  [isDesktop]="isDesktop$ | async"
  [withModal]="withModal"
  [resizeSafari$]="actionsTextService.resizeSafari$"
  [modalUid]="ELEMENT_FILL_MODAL_UID"
></design-colorpicker>
