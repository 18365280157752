import { Pipe, PipeTransform } from '@angular/core';
import { IXY } from '../../interfaces/editor.interface';

@Pipe({
  name: 'elementRotateCenter',
})
export class ElementRotateCenterPipe implements PipeTransform {
  transform(data: { size: IXY; scale: IXY }): IXY {
    const { size, scale } = data;

    return {
      x: (size.x / 2) * scale.x,
      y: (size.y / 2) * scale.y,
    };
  }
}
