import { ɵgetDOM as getDOM } from '@angular/common';

export function clearNgAttributes(parent: Element) {
  parent.querySelectorAll('*').forEach((el) => {
    if (el.attributes) {
      Array.from(el.attributes).forEach(({ name }) => {
        if (name.indexOf('_ng') > -1) {
          el.removeAttribute(name);
        }
      });
    }
  });

  return parent;
}

export function stripHtmlComments(html: string) {
  // Remove HTML comments (useful for removing developer-facing comments from production HTML markup).
  return html.replace(/<!--[\s\S]*?(?:-->)/g, '');
}

export function isAndroid(): boolean {
  const userAgent = getDOM() ? getDOM().getUserAgent() : '';
  return /android (\d+)/.test(userAgent.toLowerCase());
}
