import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  TemplateRef,
} from '@angular/core';
import { RouterModule } from '@angular/router';
import {
  PipesModule as CommonPipesModule,
  IMapNumberOfBoolean,
  sortByField,
} from '@inaripro-nx/common-ui';
import {
  CatalogNomenclatureDictionaryRecords,
  ICatalogNomenclatureTreeItem,
} from '../../interfaces';
import { CatalogGroupLinkPipe } from '../../pipes';

@Component({
  selector: 'catalog-tree',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    CommonPipesModule,
    CatalogGroupLinkPipe,
  ],
  templateUrl: './catalog-tree.component.html',
  styleUrls: ['./catalog-tree.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CatalogTreeComponent implements OnChanges {
  private _expandedMap: IMapNumberOfBoolean = {};

  get expandedMap(): IMapNumberOfBoolean {
    return this._expandedMap;
  }

  @Input()
  set expandedMap(map: IMapNumberOfBoolean | null | undefined) {
    if (map) {
      this._expandedMap = map;
    }
  }

  @Input() treeItems: ICatalogNomenclatureTreeItem[] | null = null;
  @Input() dictionary: CatalogNomenclatureDictionaryRecords | null = null;
  @Input() treeItemTemplate: TemplateRef<unknown> | null = null;
  @Input() filterCatalogNomenclatureTreeItem?: (
    item: ICatalogNomenclatureTreeItem
  ) => boolean;

  private _selectedItemId: number | null = null;

  get selectedItemId(): number | null {
    return this._selectedItemId;
  }

  @Input()
  set selectedItemId(id: number | null) {
    this._selectedItemId = id;
  }

  @Output() selectItem: EventEmitter<ICatalogNomenclatureTreeItem> =
    new EventEmitter();

  treeItemsSortedAndFiltered: ICatalogNomenclatureTreeItem[] = [];

  ngOnChanges(changes: SimpleChanges) {
    if ('treeItems' in changes) {
      this.treeItemsSortedAndFiltered = this.sortedAndFilteredItems();
    }
  }

  trackByFn(_index: number, item: ICatalogNomenclatureTreeItem) {
    return item.id;
  }

  toggleExpanded(event: Event, item: ICatalogNomenclatureTreeItem) {
    event.preventDefault();
    event.stopPropagation();

    if (!item.children.length) {
      return;
    }

    if (this._expandedMap[item.id]) {
      delete this._expandedMap[item.id];
    } else {
      this._expandedMap[item.id] = true;
    }
  }

  select(item: ICatalogNomenclatureTreeItem) {
    if (!item.canAddProduct) {
      return;
    }

    if (this._selectedItemId && this._expandedMap[this._selectedItemId]) {
      delete this._expandedMap[this._selectedItemId];
    }

    this._selectedItemId = item.id;
    this.selectItem.emit(item);
  }

  private sortedAndFilteredItems() {
    const items: ICatalogNomenclatureTreeItem[] = this.treeItems || [];
    return [...items]
      .sort(sortByField<ICatalogNomenclatureTreeItem>('position'))
      .filter((item) =>
        this.filterCatalogNomenclatureTreeItem
          ? this.filterCatalogNomenclatureTreeItem(item)
          : true
      );
  }
}
