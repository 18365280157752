import { Injectable } from '@angular/core';
import { linkToGlobalState } from '@inaripro-nx/common-ui';
import { ComponentStore } from '@ngrx/component-store';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { EObject, EPage } from '../../interfaces/main.interface';

export interface MainState {
  _action: string;
  page: EPage;
  object: EObject | null;
  hideAction: boolean;
  hideActionsSubmenu: boolean;
}

const initialState: MainState = {
  _action: 'initialState',
  page: EPage.product,
  object: null,
  hideAction: false,
  hideActionsSubmenu: true,
};

@Injectable({
  providedIn: 'root',
})
export class MainStore extends ComponentStore<MainState> {
  readonly page$: Observable<EPage> = this.select(
    (state: MainState) => state.page
  );

  readonly object$: Observable<EObject | null> = this.select(
    (state: MainState) => state.object
  );

  readonly hideActionsSubmenu$: Observable<boolean> = this.select(
    (state: MainState) => state.hideActionsSubmenu
  );

  readonly hideActions$: Observable<boolean> = this.select(
    (state: MainState) => state.hideAction
  );

  readonly setPage = this.updater(
    (state: MainState, payload: { page: EPage }) => {
      let { object } = state;

      const page: EPage = payload.page;

      if (page === EPage.objects && !object) {
        object = EObject.pictures;
      }

      return {
        ...state,
        _action: 'setPage',
        page,
        object,
      };
    }
  );

  readonly setObject = this.updater(
    (state: MainState, payload: { object: EObject }) => {
      const { object } = payload;
      const page = EPage.objects;

      return {
        ...state,
        _action: 'setObject',
        page,
        object,
      };
    }
  );

  readonly setHideAction = this.updater(
    (state: MainState, payload: { hideAction: boolean }) => {
      const { hideAction } = payload;
      return {
        ...state,
        _action: 'setHideAction',
        hideAction,
      };
    }
  );

  readonly setHideActionsSubmenu = this.updater(
    (state: MainState, payload: { hideActionsSubmenu: boolean }) => {
      const { hideActionsSubmenu } = payload;
      return {
        ...state,
        _action: 'setHideActionsSubmenu',
        hideActionsSubmenu,
      };
    }
  );

  readonly toggleHideActionsSubmenu = this.updater((state: MainState) => {
    const { hideActionsSubmenu } = state;
    return {
      ...state,
      _action: 'toggleHideActionsSubmenu',
      hideActionsSubmenu: !hideActionsSubmenu,
    };
  });

  constructor(private globalStore: Store) {
    super({ ...initialState });
    linkToGlobalState(this.state$, 'libs/painter/MainStore', this.globalStore);
  }
}
