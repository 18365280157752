import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { SimplebarAngularModule } from 'simplebar-angular';
import { ClickOutsideDirective } from '../../directives';
import { PopoverStore } from './state/popover.store';

@Component({
  selector: 'design-popover',
  standalone: true,
  imports: [CommonModule, SimplebarAngularModule, ClickOutsideDirective],
  templateUrl: './popover.component.html',
  styleUrls: ['./popover.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PopoverComponent implements OnInit, OnDestroy {
  @ViewChild('stateHelper', { static: false }) stateHelper: any;

  @Input() uid!: string;
  @Input() title = null;
  @Input() multi = false;
  @Input() position = 'auto';
  @Input() size!: string;
  @Input() okClose = false;

  helperShowed = false;

  private _subs: Subscription[] = [];

  set subs(sub: Subscription) {
    this._subs.push(sub);
  }

  constructor(private readonly popoverStore: PopoverStore) {}

  ngOnInit() {
    this.initStore();
  }

  ngOnDestroy() {
    this._subs.forEach((s) => s.unsubscribe());
  }

  showHelper() {
    this.helperShowed = true;
  }

  hideHelper() {
    this.helperShowed = false;
  }

  addPopover() {
    if (!this.multi) {
      this.popoverStore.reset();
    }

    this.popoverStore.patch({ [this.uid]: true });
  }

  removePopover() {
    this.popoverStore.patch({ [this.uid]: false });
  }

  popoverToggle() {
    if (this.helperShowed) {
      this.removePopover();
      return;
    }

    this.addPopover();
  }

  private initStore() {
    this.subs = this.popoverStore.popovers$.subscribe((popovers) => {
      if (popovers[this.uid]) {
        this.showHelper();
      } else {
        this.hideHelper();
      }
    });
  }
}
