import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { CardQrCodeComponent, DesignUiModule } from '@inaripro-nx/design-ui';
import { Subscription, map, startWith, tap } from 'rxjs';
import { IQRCode } from '@inaripro-nx/common-ui';
import { EElementType } from '../../../../interfaces/editor.interface';
import { QrCodesService } from '../../../../services/qr-codes/qr-codes.service';
import { ISVGObject } from '../../interfaces/content.interface';
import { SimplebarAngularModule } from 'simplebar-angular';

@Component({
  selector: 'painter-content-qrcodes',
  standalone: true,
  imports: [CommonModule, DesignUiModule, SimplebarAngularModule],
  templateUrl: './content-qrcodes.component.html',
  styleUrls: ['./content-qrcodes.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentQrcodesComponent implements OnInit, OnDestroy {
  @Output() selectObject: EventEmitter<ISVGObject> = new EventEmitter();

  @ViewChild('input') inputRef?: ElementRef;

  readonly EElementType = EElementType;

  qrCodes$ = this.qrCodesService.qrCodes$.pipe(
    startWith(this.qrCodesService.qrCodes$.value),
    map((items) =>
      items ? items.filter((item) => !item.markForDelete) : items
    )
  );

  newQRCode?: IQRCode;

  private _subs: Subscription[] = [];
  set subs(sub: Subscription) {
    this._subs.push(sub);
  }

  constructor(public qrCodesService: QrCodesService) {}

  ngOnInit(): void {
    if (this.qrCodesService.newQRCode$) {
      this.subs = this.qrCodesService.newQRCode$
        .pipe(tap((newQRCode) => (this.newQRCode = newQRCode)))
        .subscribe();
    }
  }

  ngOnDestroy(): void {
    this._subs.forEach((s) => s.unsubscribe());
  }

  createQRCode(value: string) {
    if (!this.qrCodesService.createQRCode) {
      return;
    }

    const text = (value || '').trim();

    if (text) {
      this.qrCodesService.createQRCode({ text });
    }

    if (this.inputRef) {
      this.inputRef.nativeElement.value = '';
    }
  }

  onSelectObject(data: {
    text: string;
    svg: string;
    bBox: { width: number; height: number };
  }) {
    const { text, svg, bBox } = data;

    this.selectObject.emit({
      type: EElementType.qr,
      text,
      svg,
      bBox,
    });
  }

  onDeleteObject(id: number) {
    if (!this.qrCodesService.deleteQRCode) {
      return;
    }

    this.qrCodesService.deleteQRCode(id);
  }

  imageLoaded(id: number, card: CardQrCodeComponent) {
    if (this.newQRCode?.id === id) {
      card.addObject();
    }
  }
}
