import { DOCUMENT } from '@angular/common';
import {
  Directive,
  ElementRef,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  Renderer2,
  SimpleChanges,
} from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';
import { filter, map, scan, switchMap, takeUntil } from 'rxjs/operators';
import {
  filterDoubleTouch,
  filterSingleTouch,
  getDistanceBetweenTouches,
  preventDefault,
} from '@inaripro-nx/common-ui';
import { DesignLightGalleryImageService } from '../services/design-light-gallery-image.service';

const TOUCH_SENSITIVITY = 0.005;

@Directive({
  selector: '[designLightGalleryImage]',
  standalone: true,
})
export class DesignLightGalleryImageDirective implements OnChanges, OnDestroy {
  @Input() active = false;

  private width = 0;
  private height = 0;

  private x = 0;
  private y = 0;
  private scale = 1;
  private lastX = 0;
  private lastY = 0;
  private maxX = 0;
  private maxY = 0;

  private _subs: Subscription[] = [];
  set subs(sub: Subscription) {
    this._subs.push(sub);
  }

  private readonly singleTouchStart$ = fromEvent<TouchEvent>(
    this.elementRef.nativeElement,
    'touchstart'
  ).pipe(
    filterSingleTouch(),
    filter(() => this.scale > 1)
  );

  private readonly doubleTouchStart$ = fromEvent<TouchEvent>(
    this.elementRef.nativeElement,
    'touchstart'
  ).pipe(filterDoubleTouch());

  private readonly touchEnd$ = fromEvent<TouchEvent>(
    this.elementRef.nativeElement,
    'touchend'
  );

  private readonly touchMove$ = fromEvent<TouchEvent>(
    this.elementRef.nativeElement,
    'touchmove'
  );

  private readonly doubleTouchZoom$ = this.doubleTouchStart$.pipe(
    preventDefault(),
    switchMap((startEvent) =>
      this.touchMove$.pipe(
        preventDefault(),
        scan(
          (prev, event) => {
            const distance = getDistanceBetweenTouches(event);
            return {
              event,
              distance,
              delta: (distance - prev.distance) * TOUCH_SENSITIVITY,
            };
          },
          {
            event: startEvent,
            distance: getDistanceBetweenTouches(startEvent),
            delta: 0,
          }
        ),
        map(({ delta }) => delta),
        takeUntil(this.touchEnd$)
      )
    )
  );

  private readonly dragging$ = this.singleTouchStart$.pipe(
    switchMap(({ touches: startTouches }) => {
      return this.touchMove$.pipe(
        map(({ touches: moveTouches }) => ({
          deltaX: moveTouches[0].clientX - startTouches[0].clientX,
          deltaY: moveTouches[0].clientY - startTouches[0].clientY,
        })),
        takeUntil(this.touchEnd$)
      );
    })
  );

  constructor(
    private readonly elementRef: ElementRef,
    private readonly renderer: Renderer2,
    @Inject(DOCUMENT) private readonly document: Document,
    private readonly imageService: DesignLightGalleryImageService
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (this.active) {
      this.initActiveImage();
    } else {
      this.reset();
    }
  }

  ngOnDestroy(): void {
    this.reset();
  }

  private initActiveImage() {
    this.width = this.elementRef.nativeElement.clientWidth;
    this.height = this.elementRef.nativeElement.clientHeight;
    this.maxX = Math.ceil(((this.scale - 1) * this.width) / 2);
    this.maxY = Math.ceil(((this.scale - 1) * this.height) / 2);

    this.transformImage();
    this.initZoomEventsSubscription();
    this.initDragEventsSubscription();
  }

  private reset() {
    this.x = 0;
    this.y = 0;
    this.scale = 1;
    this.lastX = 0;
    this.lastY = 0;

    this.transformImage();

    this._subs.forEach((s) => s.unsubscribe());
  }

  private initZoomEventsSubscription() {
    this.subs = this.doubleTouchZoom$
      .pipe(
        map((delta) =>
          delta > 0
            ? Math.min(this.scale + delta, 4)
            : Math.max(this.scale + delta, 1)
        )
      )
      .subscribe((scale) => {
        this.maxX = Math.ceil(((scale - 1) * this.width) / 2);
        this.maxY = Math.ceil(((scale - 1) * this.height) / 2);
        this.scale = scale;

        const { x, y } = this.getNormalizedCoords(
          this.x,
          this.y,
          this.maxX,
          this.maxY
        );

        this.x = x;
        this.y = y;

        this.imageService.setImageData({
          x: this.x,
          y: this.y,
          maxX: this.maxX,
          maxY: this.maxY,
          scale: this.scale,
        });

        this.transformImage();
      });
  }

  private initDragEventsSubscription() {
    this.subs = this.dragging$.subscribe(({ deltaX, deltaY }) => {
      const { x, y } = this.getNormalizedCoords(
        this.lastX + deltaX,
        this.lastY + deltaY,
        this.maxX,
        this.maxY
      );

      this.x = x;
      this.y = y;

      this.imageService.setImageData({ x: this.x, y: this.y });
      this.transformImage();
    });

    this.subs = this.touchEnd$.subscribe(() => {
      this.lastX = this.x < this.maxX ? this.x : this.maxX;
      this.lastY = this.y < this.maxY ? this.y : this.maxY;
    });
  }

  private transformImage() {
    this.renderer.setStyle(
      this.elementRef.nativeElement,
      'transform',
      `translate3d(${this.x}px, ${this.y}px, 0) scale(${this.scale})`
    );

    this.renderer.setStyle(
      this.elementRef.nativeElement,
      'touch-action',
      this.scale === 1 ? 'pan-y' : 'none'
    );
  }

  private getNormalizedCoords(
    x: number,
    y: number,
    maxX: number,
    maxY: number
  ): { x: number; y: number } {
    let normalizedX = x;
    let normalizedY = y;

    if (normalizedX > maxX) {
      normalizedX = maxX;
    }

    if (normalizedX < -maxX) {
      normalizedX = -maxX;
    }

    if (normalizedY > maxY) {
      normalizedY = maxY;
    }

    if (normalizedY < -maxY) {
      normalizedY = -maxY;
    }

    return { x: normalizedX, y: normalizedY };
  }
}
