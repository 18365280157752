import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IPricesRange } from '../../interfaces';

@Component({
  selector: 'catalog-product-price',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './catalog-product-price.component.html',
  styleUrls: ['./catalog-product-price.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CatalogProductPriceComponent {
  @Input() updatingPrice: boolean | null = null;
  @Input() baseValue: number | null = null;
  @Input() discountValue: number | null = null;
  @Input() range: IPricesRange | null = null;
  @Input() unitName: string | null = null;
}
