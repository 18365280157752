<ng-container [ngTemplateOutlet]="loginBtn"></ng-container>
<ng-container [ngTemplateOutlet]="logoutBtn"></ng-container>
<div class="user-menu-btn" *ngIf="isAuthUsers">
  <shell-header-link [link]="'/cabinet'" class="user-menu-btn__title">
    <span><i class="icon icon-ic_account_circle"></i>Кабинет</span>
  </shell-header-link>
  <button
    type="button"
    class="user-menu-btn__arrow"
    designClickOutside
    [class.is-active]="userMenuState"
    (click)="openUserMenu()"
    (clickOutsideEvent)="userMenuState = false"
  >
    <i class="icon icon-ic_keyboard_arrow_down"></i>
  </button>
</div>

<div class="user-menu-dropdown" *ngIf="userMenuState">
  <div class="user-menu-dropdown__el user-menu-dropdown__el--user" *ngIf="user">
    <i class="icon icon-ic_account_circle"></i>
    <span>{{ user.data.email || user.data.phone }}</span>
  </div>
  <shell-header-link
    [link]="'/cabinet/company'"
    *ngIf="isPartner"
    class="user-menu-dropdown__el"
  >
    <i class="icon icon-ic_contact_calendar"></i>
    <span>Карточка компании</span>
  </shell-header-link>
  <shell-header-link
    [link]="'/cabinet/calc'"
    *ngIf="accessCalculators"
    class="user-menu-dropdown__el"
  >
    <i class="icon icon-ic_calc"></i>
    <span>Прайс-лист калькуляторы</span>
  </shell-header-link>
  <shell-header-link
    [link]="'/cabinet/catalog'"
    *ngIf="accessCatalog"
    class="user-menu-dropdown__el"
  >
    <i class="icon icon-ic_box"></i>
    <span>Прайс-лист товары</span>
  </shell-header-link>
  <shell-header-link
    [link]="'/cabinet/price-details'"
    *ngIf="accessPartners"
    class="user-menu-dropdown__el"
  >
    <i class="icon icon-ic_attach_money"></i>
    <span>Тонкие настройки</span>
  </shell-header-link>
  <shell-header-link
    [link]="'/partners'"
    *ngIf="accessPartners"
    class="user-menu-dropdown__el"
  >
    <i class="icon icon-ic_group"></i>
    <span>Мои партнёры</span>
  </shell-header-link>
  <shell-header-link [link]="'/cabinet/orders'" class="user-menu-dropdown__el">
    <i class="icon icon-cart"></i>
    <span>История заказов</span>
  </shell-header-link>
  <shell-header-link [link]="'/cabinet/profile'" class="user-menu-dropdown__el">
    <i class="icon icon-ic_settings"></i>
    <span>Настройки профиля</span>
  </shell-header-link>
  <a
    class="user-menu-dropdown__el"
    href="javascript:"
    title="Выйти"
    (click)="signout.emit()"
  >
    <i class="icon icon-ic_power"></i>
    <span>Выход</span>
  </a>
</div>

<ng-template #loginBtn>
  <shell-header-link [link]="loginUrl" *ngIf="!user" class="login-btn">
    <span><i class="icon icon-ic_account_circle"></i>Войти</span>
  </shell-header-link>
</ng-template>

<ng-template #logoutBtn>
  <a
    *ngIf="isAdmins || (user && !user.role)"
    class="login-btn"
    href="javascript:"
    (click)="signout.emit()"
  >
    <span><i class="icon icon-ic_account_circle"></i>Выйти</span>
  </a>
</ng-template>
