<div class="light-gallery">
  <ng-container
    [ngTemplateOutlet]="thumbnails"
    [ngTemplateOutletContext]="{ galleryHeight: galleryHeight$ | async }"
  ></ng-container>

  <div class="light-gallery__content" #lightGalleryContentRef>
    <div
      class="light-gallery__content-media"
      [style.width.px]="modalView ? (galleryWidth$ | async) || 0 : null"
      [style.marginLeft]="modalView ? 'auto' : null"
      [style.marginRight]="modalView ? 'auto' : null"
    >
      @if (!videoIsActive) {
      <design-light-gallery-content-new-image
        [images]="galleryImages"
        [selectedIndex]="selectedIndex"
        [previewEnabled]="previewEnabled"
        (clicked)="previewEnabled && openPreview($event)"
        (activeChanged)="selectFromImage($event)"
      ></design-light-gallery-content-new-image>
      }
      <design-light-gallery-content-new-video
        [videos]="galleryVideos"
        [selectedIndex]="selectedIndex"
        (videoActivated)="toggleVideo($event)"
      ></design-light-gallery-content-new-video>
    </div>
  </div>

  @if (previewEnabled) {
  <design-light-gallery-content-new-preview
    #lightGalleryPreviewContentRef
    [images]="galleryImages"
    [selectedIndex]="selectedIndex"
    (activeChanged)="selectFromPreview($event)"
    (galleryOpened)="togglePreview($event)"
  >
    <ng-container
      [ngTemplateOutlet]="thumbnails"
      [ngTemplateOutletContext]="{ galleryHeight: galleryPreviewHeight }"
    ></ng-container>
  </design-light-gallery-content-new-preview>
  }
</div>

<ng-template #thumbnails let-galleryHeight="galleryHeight">
  <design-light-gallery-content-new-thumbnails-vertical
    class="light-gallery__thumbnails-vertical"
    [images]="galleryImages"
    [selectedIndex]="selectedIndex"
    [galleryHeight]="galleryHeight"
    (activeChanged)="selectFromThumbnails($event)"
  >
  </design-light-gallery-content-new-thumbnails-vertical>
</ng-template>
