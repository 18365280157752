import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { CommonUiModule } from '@inaripro-nx/common-ui';
import { ColorBoxComponent } from './color-box.component';

@NgModule({
  imports: [CommonModule, CommonUiModule],
  declarations: [ColorBoxComponent],
  exports: [ColorBoxComponent],
})
export class ColorBoxModule {}
