<div class="actions-qrcodes" *ngIf="element">
  <ng-template #rangeSize>
    <div class="range-size">
      <div class="name">Размер</div>
      <painter-actions-element-scale
        [element]="element"
        [designSide]="designSide"
      ></painter-actions-element-scale>
    </div>
  </ng-template>

  <ng-template #rangeTurn>
    <div class="range-turn">
      <div class="name">Поворот</div>
      <painter-actions-element-rotate
        [element]="element"
      ></painter-actions-element-rotate>
    </div>
  </ng-template>

  <ng-template #modification>
    <painter-actions-element-flip
      *ngIf="designSide"
      [element]="element"
      [designSide]="designSide"
      (updateElement)="updateElement.emit($event)"
    ></painter-actions-element-flip>
  </ng-template>

  <ng-template #inputstext>
    <painter-actions-element-translate
      [element]="element"
    ></painter-actions-element-translate>
  </ng-template>

  <ng-template #colorpanel let-withModal="withModal">
    <div class="color-panel">
      <p>Цвет</p>
      <painter-actions-element-fill
        [element]="element"
        [withModal]="withModal"
      ></painter-actions-element-fill>
    </div>
  </ng-template>

  <ng-template #fillopacity>
    <div class="range-fillopacity">
      <div class="range-fillopacity__name"><span>Прозрачность</span></div>
      <div class="range-fillopacity__opacity">
        <painter-actions-element-fill-opacity
          [element]="element"
        ></painter-actions-element-fill-opacity>
      </div>
    </div>
  </ng-template>

  <div class="actions-qrcodes-desktop">
    <ng-container *ngTemplateOutlet="modification"></ng-container>
    <ng-container *ngTemplateOutlet="inputstext"></ng-container>
    <ng-container *ngTemplateOutlet="rangeSize"></ng-container>
    <ng-container *ngTemplateOutlet="rangeTurn"></ng-container>
    <ng-container *ngTemplateOutlet="colorpanel"></ng-container>
    <ng-container *ngTemplateOutlet="fillopacity"></ng-container>
  </div>
  <div
    class="actions-qrcodes-mobile"
    [class.actions-qrcodes-mobile-move]="!isShowActionsSubmenu"
  >
    <ng-content></ng-content>
    <div
      class="tabs"
      [class.tabs-move]="!isShowActionsSubmenu"
      [class.tabs-qrcode-active-trasformations]="
        subRow === ESubRow.trasformations
      "
      [class.tabs-qrcode-active-color]="subRow === ESubRow.color"
    >
      <div
        class="tabs-subrow"
        [class.tabs-subrow-qrcode-active-trasformations]="
          subRow === ESubRow.trasformations
        "
        [class.tabs-subrow-qrcode-active-color]="subRow === ESubRow.color"
      >
        <div class="actions-forms" *ngIf="subRow === ESubRow.trasformations">
          <div class="actions-form actions-form-w50 actions-form-hide-mobile">
            <ng-container *ngTemplateOutlet="rangeSize"></ng-container>
            <ng-container *ngTemplateOutlet="rangeTurn"></ng-container>
          </div>
          <div class="actions-form actions-form-w50">
            <ng-container *ngTemplateOutlet="modification"></ng-container>
            <ng-container *ngTemplateOutlet="inputstext"></ng-container>
          </div>
        </div>
        <div class="actions-forms-block" *ngIf="subRow === ESubRow.color">
          <div class="actions-form actions-form-block">
            <div class="actions-forms-flex">
              <ng-container
                *ngTemplateOutlet="colorpanel; context: { withModal: 'true' }"
              ></ng-container>
            </div>
          </div>
          <div class="actions-form actions-form-block">
            <ng-container *ngTemplateOutlet="fillopacity"></ng-container>
          </div>
        </div>
      </div>
      <div class="tabs-row">
        <div
          class="tab"
          [class.tab-active]="subRow === ESubRow.trasformations"
          (click)="toggleSubRow(ESubRow.trasformations)"
        >
          <i class="icon icon-ic_transformation"></i>
          <span>Трансформация</span>
        </div>
        <div
          class="tab"
          [class.tab-active]="subRow === ESubRow.color"
          (click)="toggleSubRow(ESubRow.color)"
        >
          <i class="icon icon-ic_color"></i>
          <span>Цвет</span>
        </div>
      </div>
    </div>
  </div>
</div>
