import { Injectable } from '@angular/core';
import { ITemplate } from '@inaripro-nx/painter';
import { ComponentStore } from '@ngrx/component-store';
import { EntityState, createEntityAdapter } from '@ngrx/entity';
import {
  EMPTY,
  Observable,
  Subject,
  catchError,
  concatMap,
  exhaustMap,
  mergeMap,
  tap,
} from 'rxjs';
import { MyTemplatesService } from '../../services/my-templates/my-templates.service';
import { ProductService } from '../../services/product/product.service';

export interface MyTemplatesState extends EntityState<ITemplate> {
  currentId: number | null;
}

export const adapter = createEntityAdapter<ITemplate>({
  sortComparer: (a: ITemplate, b: ITemplate) => b.id - a.id,
});

const initialState: MyTemplatesState = adapter.getInitialState({
  currentId: null,
});

const { selectAll, selectEntities } = adapter.getSelectors();

@Injectable()
export class MyTemplatesStore extends ComponentStore<MyTemplatesState> {
  collection$ = this.select(selectAll);
  dictionary$ = this.select(selectEntities);

  savedTemplate$: Subject<ITemplate | null> = new Subject<ITemplate | null>();

  loadCollection = this.effect((trigger$) =>
    trigger$.pipe(
      exhaustMap(() =>
        this.collectionService.all().pipe(
          tap((collection) => {
            this.setState((state) => adapter.setAll(collection, state));
          }),
          catchError(() => EMPTY)
        )
      )
    )
  );

  addModel = this.effect((model$: Observable<Partial<ITemplate>>) =>
    model$.pipe(
      concatMap((newModel) =>
        this.collectionService.add(newModel).pipe(
          tap((model) => {
            this.setState((state) => adapter.addOne(model, state));
            this.savedTemplate$.next(model);
          }),
          catchError(() => {
            this.savedTemplate$.next(null);
            return EMPTY;
          })
        )
      )
    )
  );

  updateModel = this.effect(
    (model$: Observable<{ template: ITemplate; triggerToSaved: boolean }>) =>
      model$.pipe(
        concatMap((updateModel) =>
          this.collectionService.update(updateModel.template).pipe(
            tap((model) => {
              this.setState((state) =>
                adapter.updateOne({ id: model.id, changes: model }, state)
              );

              if (updateModel.triggerToSaved) {
                this.savedTemplate$.next(model);
              }
            }),
            catchError(() => {
              if (updateModel.triggerToSaved) {
                this.savedTemplate$.next(null);
              }

              return EMPTY;
            })
          )
        )
      )
  );

  deleteModel = this.effect((modelId$: Observable<number>) =>
    modelId$.pipe(
      tap((modelId) =>
        this.setState((state) =>
          adapter.updateOne(
            { id: modelId, changes: { markForDelete: true } },
            state
          )
        )
      ),
      mergeMap((modelId) =>
        this.collectionService.delete(modelId).pipe(
          tap(() => {
            this.setState((state) =>
              adapter.removeOne(modelId, {
                ...state,
                currentId: state.currentId !== modelId ? state.currentId : null,
              })
            );
          }),
          catchError(() => {
            this.setState((state) =>
              adapter.updateOne(
                { id: modelId, changes: { markForDelete: false } },
                state
              )
            );

            return EMPTY;
          })
        )
      )
    )
  );

  constructor(
    private collectionService: MyTemplatesService,
    private productService: ProductService
  ) {
    super({ ...initialState });
  }

  init() {
    this.loadCollection();
  }
}
