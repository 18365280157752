import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'design-search',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule],
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchComponent implements OnInit, OnChanges {
  @Input() initValue = '';
  @Input() maxWidth = '100%';
  @Input() placeholderText = 'Поиск';
  @Input() canShowError = false;
  @Input() minSearchQueryLength = 2;
  @Input() maxSearchQueryLength = 100;
  @Input() disableClear = false;
  @Input() externalFormControl: FormControl = new FormControl();

  @Output() searchOut: EventEmitter<string> = new EventEmitter();
  @Output() searchClear: EventEmitter<void> = new EventEmitter();
  @Output() searchHidden: EventEmitter<void> = new EventEmitter();

  @ViewChild('inputElement', { static: false }) inputElement:
    | ElementRef
    | undefined;

  private _isMinSearchLength = false;
  get isMinSearchLength() {
    return this._isMinSearchLength;
  }

  set isMinSearchLength(val) {
    if (val && this.canShowError) {
      // TODO
      // this.toastrService.error(
      //   `Поиск по строке выполняется от ${this.minSearchQueryLength} символов`
      // );
    }

    this._isMinSearchLength = val;
  }

  searchControl: FormControl = new FormControl();

  // TODO
  // constructor(private toastrService: ToastrService) {}

  ngOnInit() {
    this.searchControl =
      this.externalFormControl || new FormControl(this.initValue);
    this.isMinSearchLength =
      !!this.initValue && this.initValue.length < this.minSearchQueryLength;
  }

  ngOnChanges(simpleChanges: SimpleChanges) {
    if (simpleChanges['initValue'] && this.searchControl) {
      this.searchControl.patchValue(this.initValue, { emitEvent: false });
      this.isMinSearchLength =
        !!this.initValue && this.initValue.length < this.minSearchQueryLength;
    }
  }

  search() {
    const searchValue = this.searchControl.value
      ? this.searchControl.value.trim().slice(0, this.maxSearchQueryLength)
      : '';

    if (searchValue.length < this.minSearchQueryLength) {
      this.isMinSearchLength = true;
      this.searchControl.patchValue(searchValue, { emitEvent: false });
    } else {
      this.isMinSearchLength = false;
      this.searchOut.emit(searchValue);
    }
  }

  clearSearch() {
    if (!this.disableClear) {
      this.searchControl.setValue(null);
    }

    this.isMinSearchLength = false;

    this.searchClear.emit();

    this.searchHidden.emit();
  }
}
