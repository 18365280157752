import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { IProductTemplate } from '../../interfaces/templates.interface';

@Injectable({
  providedIn: 'root',
})
export class ProductTemplatesService {
  templates$: BehaviorSubject<IProductTemplate[] | null> = new BehaviorSubject<
    IProductTemplate[] | null
  >(null);

  createProductTemplate?: (template: Partial<IProductTemplate>) => void;
  updateProductTemplate?: (data: {
    template: IProductTemplate;
    triggerToSaved: boolean;
  }) => void;
  removeProductTemplate?: (templateId: number) => void;
}
