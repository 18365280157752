import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { IParseVideo, parseVideoURL } from '@inaripro-nx/common-ui';
import { DesignVideoPlayerComponent } from '../../../design-video-player/design-video-player.component';
import { PreloaderComponent } from '../../../preloader/preloader.component';

@Component({
  selector: 'design-light-gallery-content-new-video',
  standalone: true,
  imports: [DesignVideoPlayerComponent, PreloaderComponent],
  templateUrl: './design-light-gallery-content-new-video.component.html',
  styleUrls: ['./design-light-gallery-content-new-video.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DesignLightGalleryContentNewVideoComponent implements OnChanges {
  @Input() videos: string[] = [];
  @Input() selectedIndex = 0;

  @Output() videoActivated: EventEmitter<boolean> = new EventEmitter<boolean>();

  currentVideo: IParseVideo | null = null;
  playIndex: number | null = null;

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['selectedIndex']) {
      this.closeVideo();
    }
  }

  public getVideo(index: number): void {
    if (this.videos && this.videos[index]) {
      this.currentVideo = this.videos[index]
        ? parseVideoURL(this.videos[index])
        : null;
    }

    this.playIndex = index;

    this.videoActivated.emit(true);
    this.cdr.detectChanges();
  }

  public closeVideo(): void {
    this.currentVideo = null;
    this.playIndex = null;

    this.videoActivated.emit(false);
    this.cdr.detectChanges();
  }
}
