<div class="light-gallery-content-new__thumbnails-vertical">
  <div class="light-gallery-content-new__thumbnails-vertical-container">
    <div
      class="light-gallery-content-new__thumbnails-vertical-wrapper"
      [style.margin-top.px]="thumbnailsTop"
      #thumbnailVerticalRef
    >
      @for (image of images; track image.id; let i = $index) {
      <div
        class="light-gallery-content-new__thumbnail-vertical"
        [class.is-active]="i === selectedIndex"
        (click)="handleClick($event, i)"
      >
        <img [src]="image.small" [alt]="image.description || ''" />
      </div>
      }
    </div>
  </div>

  @if (canShowArrows) {
  <design-light-gallery-content-new-arrows
    [modificator]="'thumbnails-updown'"
    [prevDisabled]="!canMoveUp()"
    [nextDisabled]="!canMoveDown()"
    (prevClicked)="handlePrevClick()"
    (nextClicked)="handleNextClick()"
  ></design-light-gallery-content-new-arrows>
  }
</div>
