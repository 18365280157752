<div
  class="actions"
  *ngIf="{
    filters: filters$ | async,
    side: activeDesignSide$ | async,
    figures: figures$ | async,
    index: activeElementIndex$ | async,
    element: activeElement$ | async,
    isDesktop: isDesktop$ | async,
    hideActionsSubmenu: hideActionsSubmenu$ | async
  } as state"
>
  <div
    class="painters-actions"
    *ngIf="
      state.index !== null && state.element !== null && state.side !== null
    "
    [@animateDestroyAndOnInit]
    [@.disabled]="state.isDesktop"
    (touchstart)="headMobileMenuTouchStart($event)"
    (touchmove)="headMobileMenuTouchMove($event)"
    (touchcancel)="headMobileMenuTouchEnd()"
    (touchend)="headMobileMenuTouchEnd()"
  >
    <ng-template #mobileMenu>
      <div
        class="moveupdown"
        [class.moveupdown-move]="!state.hideActionsSubmenu"
        (click)="headMobileMenuClick()"
      >
        <div class="moveupdown-rectangle" 
            *ngIf="!state.hideActionsSubmenu; else titleTemplate">
        </div>

        <ng-template #titleTemplate>
          <div class="moveupdown-title">
            <i class="icon icon-ic_keyboard_arrow_up"></i>
            <span>
              <span>
                <span>{{ state.element.type | actionsElementTitle }}</span>
              </span>
            </span>
          </div>
        </ng-template>
        
      </div>
    </ng-template>

    <painter-actions-pictures
      *ngIf="state.element?.type === EElementType.image"
      [filters]="state.filters || []"
      [element]="state.element"
      [isShowActionsSubmenu]="!state.hideActionsSubmenu"
      [isMaxUserFilesLimit]="isMaxUserFilesLimit$ | async"
      [designSide]="state.side"
      [figures]="state.figures"
      (updateElement)="updateElement({ index: state.index, element: $event })"
      (cropImage)="
        cropImage($event, { index: state.index, element: state.element })
      "
    >
      <ng-container *ngTemplateOutlet="mobileMenu"></ng-container>
    </painter-actions-pictures>

    <painter-actions-text
      *ngIf="state.element?.type === EElementType.text"
      [element]="state.element"
      [designSide]="state.side"
      [isShowActionsSubmenu]="!state.hideActionsSubmenu"
      [isDesktop]="state.isDesktop"
      (updateElement)="updateElement({ index: state.index, element: $event })"
      (centerElement)="
        centerElement({
          index: state.index,
          element: state.element,
          center: $event
        })
      "
    >
      <ng-container *ngTemplateOutlet="mobileMenu"></ng-container>
    </painter-actions-text>

    <painter-actions-figures
      *ngIf="state.element?.type === EElementType.figure"
      [element]="state.element"
      [designSide]="state.side"
      [isShowActionsSubmenu]="!state.hideActionsSubmenu"
      (updateElement)="updateElement({ index: state.index, element: $event })"
    >
      <ng-container *ngTemplateOutlet="mobileMenu"></ng-container>
    </painter-actions-figures>

    <painter-actions-qrcodes
      *ngIf="state.element?.type === EElementType.qr"
      [element]="state.element"
      [designSide]="state.side"
      [isShowActionsSubmenu]="!state.hideActionsSubmenu"
      (updateElement)="updateElement({ index: state.index, element: $event })"
    >
      <ng-container *ngTemplateOutlet="mobileMenu"></ng-container>
    </painter-actions-qrcodes>
  </div>
</div>
