import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IPattern, IPatternLibrary } from '@inaripro-nx/common-ui';
import { DesignUiModule } from '@inaripro-nx/design-ui';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { BehaviorSubject, map, Observable, switchMap } from 'rxjs';
import { PatternsService } from '../../../../../../services/patterns/patterns.service';
import { ProductStore } from '../../../../../../state/product/product.store';

@Component({
  selector: 'painter-content-fill-pattern',
  standalone: true,
  imports: [CommonModule, DesignUiModule, LazyLoadImageModule],
  templateUrl: './content-fill-pattern.component.html',
  styleUrls: ['./content-fill-pattern.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentFillPatternComponent {
  @Input()
  set zoneKey(key: string) {
    this.zoneKeySubject$.next(key);
  }

  readonly DEFAULT_PREVIEW_SIZE = 200;

  readonly libraries$ = this.patternsService.libraries$;
  readonly currentLibrary$ = this.patternsService.currentLibrary$;
  readonly currentPatterns$ = this.patternsService.currentPatterns$;

  private readonly zoneKeySubject$ = new BehaviorSubject<string | null>(null);

  readonly selectedPattern$: Observable<IPattern | null> = this.zoneKeySubject$
    .asObservable()
    .pipe(
      switchMap((key) => {
        return this.productStore.zonePatterns$.pipe(
          map((zonePatterns) => (key ? zonePatterns[key] : null))
        );
      })
    );

  constructor(
    private readonly patternsService: PatternsService,
    private readonly productStore: ProductStore
  ) {}

  public selectPatternLibrary(item: unknown) {
    this.patternsService.getPatterns((item as IPatternLibrary).id);
  }

  public selectPattern(pattern: IPattern | null) {
    const key = this.zoneKeySubject$.getValue();
    if (key) {
      this.productStore.setZonePattern({
        key,
        pattern,
      });
    }
  }
}
