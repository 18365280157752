import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'painter-content-product-property-wrapper',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './content-product-property-wrapper.component.html',
  styleUrls: ['./content-product-property-wrapper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentProductPropertyWrapperComponent {
  @Input() name = '';
}
