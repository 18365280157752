import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import {
  IProductPostListRequest,
  IPublicProductForList,
} from '../../interfaces/catalog-product.interface';
import { CatalogProductCardComponent } from '../catalog-product-card/catalog-product-card.component';
import { CatalogProductListStore } from './state/catalog-product-list.store';
import { IPaginationRequest } from '@inaripro-nx/common-ui';

@Component({
  selector: 'catalog-product-list',
  standalone: true,
  imports: [CommonModule, CatalogProductCardComponent],
  templateUrl: './catalog-product-list.component.html',
  styleUrls: ['./catalog-product-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [CatalogProductListStore],
})
export class CatalogProductListComponent {
  @Input() selectedProductId?: number | null = null;
  @Input()
  set request(
    data: {
      request: IProductPostListRequest;
      paginationRequest: IPaginationRequest;
    } | null
  ) {
    if (data) {
      this.catalogProductListStore.loadProducts(data);
    }
  }

  @Output() selectProduct = new EventEmitter<IPublicProductForList>();

  readonly loading$ = this.catalogProductListStore.loading$;
  readonly productList$ = this.catalogProductListStore.productList$;

  constructor(
    private readonly catalogProductListStore: CatalogProductListStore
  ) {}
}
