import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  catchError,
  distinctUntilChanged,
  finalize,
  map,
  Observable,
  of,
  startWith,
  Subscription,
  tap,
  throwError,
} from 'rxjs';
import { DesignUiModule } from '@inaripro-nx/design-ui';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import {
  getStringControlValue,
  IPicture,
  isCtrl,
  ValidatorsMaxLength,
  ValidatorsMinLength,
} from '@inaripro-nx/common-ui';
import {
  IImageAIGenerateRequest,
  IImageAIGenerateResponse,
} from '../../interfaces/image-ai.interface';
import { ImageAiService } from '../../services/image-ai/image-ai.service';

const maxlength = 500;

@Component({
  selector: 'image-ai-image-ai',
  standalone: true,
  imports: [CommonModule, DesignUiModule, ReactiveFormsModule],
  templateUrl: './image-ai.component.html',
  styleUrls: ['./image-ai.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImageAiComponent implements OnDestroy {
  @ViewChild('descriptionTextArea', { static: false })
  descriptionTextArea?: ElementRef;

  isSaving = false;
  isLoading = false;
  errorMessage = '';
  imageResponse: IImageAIGenerateResponse | null = null;

  inputText = new FormControl(null, [
    ValidatorsMinLength(3, true),
    ValidatorsMaxLength(maxlength, true),
  ]);

  valueLength$: Observable<number> = this.inputText.valueChanges.pipe(
    startWith(this.inputText.value),
    map(() => getStringControlValue(this.inputText).length),
    distinctUntilChanged()
  );

  readonly maxlength = maxlength;

  constructor(
    private readonly imageAiService: ImageAiService,
    private readonly cdr: ChangeDetectorRef
  ) {}

  private _generateSub: Subscription | null = null;

  set generateSub(generateSub: Subscription | null) {
    if (this._generateSub) {
      this._generateSub.unsubscribe();
    }
    this._generateSub = generateSub;
  }

  get isInvalid() {
    return this.inputText.invalid;
  }

  get isLockedForGenerate() {
    return this.isSaving || this.isLoading || this.isInvalid;
  }

  public get isLockedForSave() {
    return this.isLockedForGenerate || !this.imageResponse;
  }

  ngOnDestroy() {
    this.generateSub = null;
  }

  public textAreaFocus(): void {
    if (!this.descriptionTextArea) {
      return;
    }

    const inputElement = this.descriptionTextArea
      .nativeElement as HTMLTextAreaElement;
    if (inputElement.value) {
      inputElement.setSelectionRange(0, inputElement.value.length);
    }
    inputElement.focus();
  }

  public saveImage(): Observable<IPicture | null> {
    if (this.isLockedForSave || !this.imageResponse) {
      return of(null);
    }

    this.isSaving = true;
    this.errorMessage = '';
    this.cdr.markForCheck();

    return this.imageAiService.saveToLibrary(this.imageResponse.id).pipe(
      catchError((err) => {
        if ((((err.error || [])[0] || {}).file || [])[0] === 'LIMIT_EXCEEDED') {
          this.errorMessage =
            'Достигнуто максимальное количество файлов на одного пользователя, удалите файлы, чтобы загрузить новые';
          this.imageResponse = null;
          this.cdr.markForCheck();
        }
        return throwError(() => err);
      }),
      tap(() => {
        this.imageResponse = null;
        this.cdr.markForCheck();
      }),
      finalize(() => {
        this.isSaving = false;
        this.cdr.markForCheck();
      })
    );
  }

  onTextEnter(event: KeyboardEvent) {
    if (event.key === 'Enter' && isCtrl(event)) {
      this.generate();
    }
  }

  generate() {
    if (this.isLockedForGenerate) {
      return;
    }

    this.isLoading = true;
    this.errorMessage = '';
    this.imageResponse = null;

    const request: IImageAIGenerateRequest = {
      prompt: (this.inputText.value || '').replace(/\n/g, ' ').trim(),
    };

    this.generateSub = this.imageAiService
      .generate(request)
      .pipe(
        catchError((errors) => {
          const error = (errors?.error || []).length ? errors.error[0] : {};

          if (error.user) {
            this.errorMessage = 'Повторите запрос через некоторое время';
          } else if (error.prompt) {
            this.errorMessage =
              'Не удалось сгенерировать изображение к данному запросу';
          }

          return throwError(() => error);
        }),
        finalize(() => {
          this.isLoading = false;
          this.cdr.markForCheck();
        })
      )
      .subscribe((resp) => {
        this.imageResponse = resp;
        this.cdr.markForCheck();
      });
  }
}
