<painter-editor-button
  [disabled]="hasPrev === false"
  [title]="'Отменить последнее изменение в макете'"
  (click)="hasPrev && prevHistory.emit()"
>
  <i class="icon icon-ic_arrow-back"></i>
</painter-editor-button>

<painter-editor-button
  [disabled]="hasNext === false"
  [title]="'Применить последнее изменение в макете'"
  (click)="hasNext && nextHistory.emit()"
>
  <i class="icon icon-ic_arrow-forward"></i>
</painter-editor-button>
