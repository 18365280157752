import {
  ElementRef,
  Injectable,
  Renderer2,
  RendererFactory2,
} from '@angular/core';

@Injectable()
export class DesignLightGalleryContentNewSwipeService {
  private renderer: Renderer2;
  private swipeHandlers: Map<string, Array<() => void>> = new Map<
    string,
    Array<() => void>
  >();

  constructor(rendererFactory: RendererFactory2) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  manageSwipe(
    element: ElementRef,
    id: string,
    nextHandler: () => void,
    prevHandler: () => void
  ): void {
    if (this.getSwipeHandlers(id)) {
      this.removeSwipeHandlers(id);
    }

    const handlers = this.getSwipeHandlers(id);

    if (!handlers) {
      let xDown: number | null = null;
      let yDown: number | null = null;

      const handleTouchStart = (evt: TouchEvent) => {
        xDown = evt.touches[0].clientX;
        yDown = evt.touches[0].clientY;
      };

      const handleTouchMove = (evt: TouchEvent) => {
        if (!xDown || !yDown) {
          return;
        }

        const xUp = evt.touches[0].clientX;
        const yUp = evt.touches[0].clientY;

        const xDiff = xDown - xUp;
        const yDiff = yDown - yUp;

        if (Math.abs(xDiff) > Math.abs(yDiff)) {
          xDiff > 0 ? nextHandler() : prevHandler();
        }

        xDown = null;
        yDown = null;
      };

      this.swipeHandlers.set(id, [
        this.renderer.listen(element.nativeElement, 'touchstart', (event) =>
          handleTouchStart(event)
        ),
        this.renderer.listen(element.nativeElement, 'touchmove', (event) =>
          handleTouchMove(event)
        ),
      ]);
    }
  }

  private getSwipeHandlers(id: string): Array<() => void> | undefined {
    return this.swipeHandlers.get(id);
  }

  private removeSwipeHandlers(id: string): void {
    this.swipeHandlers.delete(id);
  }
}
