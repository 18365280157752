import { IPublicCalc } from '@inaripro-nx/calc';
import { IFileResponse } from '@inaripro-nx/common-ui';
import { ProductTypeEnum } from '../enums/catalog-product.enum';
import { IProperty } from './catalog-product-properties.interface';

export interface IMapOfCatalogNomenclatureCategoryForList {
  [id: string]: ICatalogNomenclatureCategoryForList;
}

export interface ICatalogNomenclatureCategoryForList {
  id: number;
  name: string;
  isActive: boolean;
  isPartner?: boolean;
  isPublish?: boolean;
  logo: IFileResponse; // fileId
  crashed?: boolean;
  unCompletePrice?: boolean;
  queryParams?: object;
  ratingValue?: number;
  ratingCount?: number;
  headTitle?: string; // maxlength 256
}

export interface ICategoryProductProperty {
  id: number; // id самой характеристики
  selectedElements: number[]; // выбранные элементы характеристики
  filter: boolean; // фильтр
  required: boolean; // обязательный
  canUseForPrices: boolean; // может использоваться для ценовых предложений
  position?: number;
}

export interface ICategoryProductPropertyExtended extends IProperty {
  nomenclatureProperty: ICategoryProductProperty;
  crashed?: boolean;
}

export interface ICategoryIdentify {
  dictionaryId: number;
  linkId: number;
}

export interface ICategoryCommon {
  parentId: number;
  description: string; // Контент
  slug: string;
  existProductTypes?: ProductTypeEnum[];
}

export interface ICategoryForList {
  id: number;
  name: string;
  isActive: boolean;
  isPartner?: boolean;
  isPublish?: boolean;
  logo: IFileResponse; // fileId
  crashed?: boolean;
  unCompletePrice?: boolean;
  queryParams?: object;
  ratingValue?: number;
  ratingCount?: number;
  headTitle?: string; // maxlength 256
}

export interface ICategory extends ICategoryForList, ICategoryCommon {
  productProperties: ICategoryProductProperty[]; // список добавленных характеристик
  catalogLinks: number[]; // ссылки номенклатуры, только выбранные linkId, само дерево отдельно
  priceByCount: boolean;
  priceByKit: boolean;
  unit: IUnit;
  calcGroup?: ICalcGroup;
  metaTitle?: string; // maxlength 55
  metaDescription?: string; // maxlength 130
  metaKeywords?: string; // maxlength 250
  defaultRangeFilter?: number;
  defaultProductType?: ProductTypeEnum;
}

export interface ICalcGroup {
  title: string;
  description?: string;
  calculators: IPublicCalc[];
}

export interface IUnit {
  name: string; // maxlength 6
  alias: string; // maxlength 10
  helperText?: string;
  ranges: IUnitRange[];
}

export interface IUnitRange {
  position: number;
  value: number;
}

export interface IMapOfCategory {
  [id: string]: ICategory;
}
