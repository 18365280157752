<!-- https://github.com/zefoy/ngx-color-picker -->
<div class="color-box">
  <div class="design-color-box-wrapper">
    <ng-container *ngTemplateOutlet="colorMapTemplate"></ng-container>
  </div>

  <div class="color-box__info">
    <div class="color-box__picker" (click)="startEye.emit($event)">
      <i class="icon icon-ic_color-picker"></i>
    </div>
    <div class="color-box__wrapperline">
      <div
        class="color-box__transparent"
        *ngIf="hasTransparent"
        (click)="selectColor(null)"
      >
        <i class="icon icon-ic_delete_forever"></i>
      </div>
      <div
        class="color-box__wrapper"
        [class.color-box__wrapper-withtransparent]="hasTransparent"
        (click)="openModalChangeColor()"
      >
        <div
          class="color-box__preview"
          *ngIf="eyeColor || currentColor as color"
          [style.background]="color"
        ></div>
        <div
          class="color-box__preview"
          *ngIf="isTransparent"
          [ngStyle]="{
            'background-image': 'url(\'./assets/images/transparent2.png\')'
          }"
        ></div>
        <div class="color-box__form-element">
          <input
            type="text"
            [value]="
              currentColor ||
              (hasTransparent ? 'Без цвета' : this.colorDefault)
            "
            class="color-box__input"
            [class.color-box__input-trasparent]="isTransparent"
            [attr.readonly]="isTransparent || null"
            (change)="changeInput($event)"
            pattern="^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$"
            maxlength="7"
          />
        </div>
      </div>
    </div>
  </div>

  <div
    class="color-box__wrapper-usecolors"
    [class.color-box__wrapper-usecolors--background-fill]="modificator === 'background-fill'"
    *ngIf="useColors?.length"
  >
    <div class="color-box__usecolors">
      <div class="color-box__usecolors-title">Используемые цвета</div>
      <div
        class="color-box__usecolors-wrapper"
        [class.color-box__usecolors-wrapper--background-fill]="modificator === 'background-fill'"
      >
        <ng-container *ngTemplateOutlet="useColorsTemplate"></ng-container>
      </div>
    </div>
  </div>
</div>

<ng-template #colorMapTemplate>
  <design-color-box
    [inputColorPicker]="currentColor || this.colorDefault"
    [resizeSafari$]="resizeSafari$"
    [modalUid]="modalUid"
    (changeColor)="changeColor($event)"
    (selectColor)="selectColor($event)"
  >
    <ng-content></ng-content>
  </design-color-box>
</ng-template>

<ng-template #useColorsTemplate>
  <div
    *ngFor="let color of (useColors || [])"
    class="color-box__usecolors-color"
    [ngStyle]="{ 'background-color': color }"
    (click)="selectColor(color)"
  ></div>
</ng-template>

<design-modal-window
  [uid]="modalUid"
  [maxWidth]="maxWidthModal"
  [prefix]="prefix"
  [isHideBackDropOnClick]="true"
  *ngIf="!this.isDesktop && this.withModal"
>
  <div modal-header>
    <span>{{ modalTitle }}</span>
  </div>
  <div modal-body>
    <ng-container *ngTemplateOutlet="colorMapTemplate"></ng-container>
    @if (useColors?.length) {
      <div class="color-box__usecolors-title--modal">Используемые цвета</div>
      <design-invisible-horizontally-scroll
        [isShowArrows]="false"
      >
        <ng-container slider>
          <ng-container *ngTemplateOutlet="useColorsTemplate"></ng-container>
        </ng-container>
      </design-invisible-horizontally-scroll>
    }
  </div>
</design-modal-window>
