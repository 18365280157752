<div
  *ngIf="fControl"
  (click)="clickEvent.emit($event)"
  class="g-form-group"
  [ngClass]="classWrapper || ''"
>
  <input
    #inputField
    [formControl]="formControl"
    [attr.autocomplete]="autocomplete"
    [attr.disabled]="isDisabled || null"
    [attr.type]="type"
    [class.g-form-control-error]="fControl.invalid"
    [ngClass]="classInput || ''"
    [attr.id]="controlId"
    (blur)="blurInputEvent.emit()"
    (focus)="onFocus($event)"
    (keydown)="keydown($event)"
  />

  <label [class.active]="!(fControl.value | isNullOrUndefined)" [attr.for]="controlId">
    {{ label }}
  </label>

  <div class="g-alert g-alert-danger" *ngIf="showAlert">
    <ng-container
      *ngIf="
        fControl.errors && (fControl.dirty || fControl.touched);
        else empty
      "
    >
      <div *ngIf="fControl.errors && fControl.errors['message'] as message">
        {{message}}
      </div>
    </ng-container>
    <ng-template #empty>
      <div *ngIf="showEmptyError">&nbsp;</div>
    </ng-template>
  </div>

  <ng-content></ng-content>
</div>
