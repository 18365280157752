<painter-actions-element-rotate
  [element]="element"
  [sliderTemplate]="sliderTemplate"
></painter-actions-element-rotate>

<ng-template
  #sliderTemplate
  let-minValue="minValue"
  let-maxValue="maxValue"
  let-value="value"
  let-changeValue="changeValue"
  let-stopChange="stopChange"
  let-reset="reset"
>
  <design-range-slider-wrapper
    [label]="'Поворот'"
    [unitmeasurement]="'&deg;'"
    [minValue]="minValue"
    [maxValue]="maxValue"
    [value]="value"
    [decimalPoints]="DECIMAL_POINTS_ROTATE"
    (changeValue)="changeValue($event)"
    (stopChange)="stopChange()"
    (resetValue)="reset()"
  ></design-range-slider-wrapper>
</ng-template>
