<div
  #coverItem
  class="cover-item"
  [class.cover-item--active]="cover === coverItem"
  (click)="selectCover.emit(coverItem)"
>
  <ng-content></ng-content>
  <div
    class="cover-item__check"
    [class.cover-item__check--active]="cover === coverItem"
  >
    <i class="icon icon-ic_done"></i>
  </div>
</div>
