<button
  class="button"
  type="button"
  [disabled]="isDisable"
  (click)="!isDisable && handleMinus.emit()"
>
  <i class="icon icon-ic_remove"></i>
</button>
<input
  #input
  [formControl]="control"
  type="text"
  class="control"
  (focus)="!isDisable && onFocus(input)"
  (blur)="!isDisable && blurControl.emit()"
  (keydown.enter)="!isDisable && blurControl.emit()"
/>
<button
  class="button"
  type="button"
  [disabled]="isDisable"
  (click)="!isDisable && handlePlus.emit()"
>
  <i class="icon icon-ic_add"></i>
</button>
