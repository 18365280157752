import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { MetaDefinition } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root',
})
export class MetaService {
  constructor(@Inject(DOCUMENT) private document: Document) {}

  removeByName(nameAttribute: string) {
    const metaSelector = `name="${nameAttribute}"`;
    const metaTag = this.getTag(metaSelector);

    if (metaTag) {
      this.removeTag(metaSelector);
    }
  }

  private getTag(attrSelector: string): HTMLMetaElement | null {
    if (!attrSelector) {
      return null;
    }

    return this.document.querySelector(`meta[${attrSelector}]`) || null;
  }

  private removeTag(attrSelector: string): void {
    const meta: HTMLMetaElement | null = this.getTag(attrSelector);

    if (meta) {
      meta.parentNode?.removeChild(meta);
    }
  }

  addTag(tag: MetaDefinition, replace = true): HTMLMetaElement | null {
    if (!tag) {
      return null;
    }

    return this.getOrCreateElement(tag, replace);
  }

  private getOrCreateElement(
    tag: MetaDefinition,
    replace: boolean
  ): HTMLMetaElement {
    const selector: string = this.parseSelector(tag);
    const elem: HTMLMetaElement | null = this.getTag(selector);

    if (elem) {
      if (replace) {
        this.removeTag(selector);
      } else {
        // It's allowed to have multiple elements with the same name so it's not enough to
        // just check that element with the same name already present on the page. We also need to
        // check if element has tag attributes
        if (this.containsAttributes(tag, elem)) {
          return elem;
        }
      }
    }

    const element: HTMLMetaElement = this.document.createElement('meta');
    this.setMetaElementAttributes(tag, element);

    const head: HTMLHeadElement = this.document.getElementsByTagName('head')[0];

    if (head.firstChild) {
      head.insertBefore(element, head.firstChild);
    } else {
      head.appendChild(element);
    }

    return element;
  }

  private parseSelector(tag: MetaDefinition): string {
    const attr = tag.name ? 'name' : 'property';
    return attr + '="' + tag[attr] + '"';
  }

  private containsAttributes(
    tag: MetaDefinition,
    elem: HTMLMetaElement
  ): boolean {
    return Object.keys(tag).every((key) => {
      return elem.getAttribute(key) === tag[key];
    });
  }

  private setMetaElementAttributes(
    tag: MetaDefinition,
    el: HTMLMetaElement
  ): HTMLMetaElement {
    Object.keys(tag).forEach((prop) => {
      return el.setAttribute(prop, tag[prop]);
    });

    return el;
  }
}
