<div [ngClass]="badgeClass" *ngIf="product.actionIsDiscount">
  <div class="product-badge__icon product-badge__icon--discount">%</div>
  <div class="product-badge__text">SALE</div>
</div>
<div [ngClass]="badgeClass" *ngIf="product.actionBestSeller">
  <div class="product-badge__icon product-badge__icon--bestseller">Хит</div>
  <div class="product-badge__text">Хит продаж</div>
</div>
<div [ngClass]="badgeClass" *ngIf="product.actionIsNew">
  <div class="product-badge__icon product-badge__icon--new">New</div>
  <div class="product-badge__text">Новинка</div>
</div>
