<div class="g-form-group" [ngClass]="classWrapper || ''" *ngIf="fControl">
    <design-form-input-number-control
        #numberControl
        *ngIf="confirmMode; else syncInput"
        [formControl]="formControl"
        [isDisabled]="isDisabled"
        [autoFocus]="autoFocus"
        [autoSelect]="autoSelect"
        [controlId]="controlId"
        [classInput]="classInput"
        (finishInput)="finishInput.emit()"
    ></design-form-input-number-control>

    <ng-template #syncInput>
        <input
            #inputField
            [formControl]="formControl"
            [attr.disabled]="isDisabled || null"
            type="number"
            [ngClass]="classInput || ''"
            [attr.id]="controlId"
            (focus)="onFocus($event)"
            (blur)="finishInput.emit()"
            (keydown)="keydown($event)"
        />
    </ng-template>

    <label
      [class.active]="!(fControl.value | isNullOrUndefined) || numberControl && numberControl.formControl && !(numberControl.formControl.valueChanges | isNullOrUndefined)"
      [attr.for]="controlId"
    >
        {{ label }}
    </label>

    <div class="g-alert g-alert-danger" *ngIf="showAlert">
        <ng-container
            *ngIf="
        fControl.errors && (fControl.dirty || fControl.touched);
        else empty
      "
        >
          <div *ngIf="fControl.errors && fControl.errors['message'] as message">
            {{message}}
          </div>
        </ng-container>
        <ng-template #empty>
            <div *ngIf="showEmptyError">&nbsp;</div>
        </ng-template>
    </div>

    <ng-content></ng-content>
</div>
