import { DOCUMENT } from '@angular/common';
import {
  Directive,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { WINDOW } from '@inaripro-nx/common-ui';
import { Subscription, fromEvent, merge, tap } from 'rxjs';
import { ClickOutsideService } from '../../services/click-outside/click-outside.service';

@Directive({
  selector: '[designClickOutside]',
  standalone: true,
})
export class ClickOutsideDirective implements OnInit, OnDestroy {
  @Input() isDelay = false;
  @Output() clickOutsideEvent: EventEmitter<MouseEvent> = new EventEmitter();

  private _subs: Subscription[] = [];
  set subs(sub: Subscription) {
    this._subs.push(sub);
  }

  private _timeouts: number[] = [];
  set timeouts(sub: number) {
    this._timeouts.push(sub);
  }

  constructor(
    private _elementRef: ElementRef,
    @Inject(WINDOW) private window: Window,
    @Inject(DOCUMENT) private document: Document,
    private readonly clickOutsideService: ClickOutsideService
  ) {}

  ngOnInit() {
    if (this.isDelay) {
      this.timeouts = this.window.setTimeout(() => this.createChecker(), 0);
    } else {
      this.createChecker();
    }
  }

  ngOnDestroy() {
    this._subs.forEach((s) => s.unsubscribe());
    this._timeouts.forEach((t) => this.window.clearTimeout(t));
  }

  createChecker() {
    this.subs = merge(
      fromEvent<MouseEvent>(this.document, 'click'),
      this.clickOutsideService.trigger$
    )
      .pipe(
        tap((event) => {
          const targetElement = event.target;
          const isClickedInside =
            this._elementRef.nativeElement.contains(targetElement);

          if (!isClickedInside) {
            this.clickOutsideEvent.emit(event);
          }
        })
      )
      .subscribe();
  }
}
