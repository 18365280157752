<div class="g-column__search-block" [ngStyle]="{ 'max-width': maxWidth }">
  <input
    #inputElement
    type="text"
    class="g-search"
    [formControl]="searchControl"
    [placeholder]="placeholderText"
    [maxlength]="maxSearchQueryLength"
    (keyup.enter)="search()"
  />
  <button
    *ngIf="!disableClear"
    class="g-column__btn g-column__btn--close"
    [class.g-column__btn--close-active]="inputElement.value"
    (click)="clearSearch()"
  >
    <i class="icon icon-ic_close"></i>
  </button>
  <button class="g-column__btn g-column__btn--search" (click)="search()">
    <i class="icon icon-ic_search"></i>
  </button>
</div>
