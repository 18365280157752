import { HttpClient, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IPicture } from '@inaripro-nx/common-ui';
import { Observable, map, switchMap } from 'rxjs';
import { DesignerAuthLinkService } from '../designer-auth/designer-auth.service';

@Injectable({
  providedIn: 'root',
})
export class PicturesService {
  API_URL = '/catalog/public/design/image/';

  constructor(
    private http: HttpClient,
    private designerAuthLinkService: DesignerAuthLinkService
  ) {}

  all() {
    return this.designerAuthLinkService.loadDesignUser$.pipe(
      switchMap(() => {
        return this.http
          .get<{ data: IPicture[] }>(this.API_URL)
          .pipe(map((res) => res.data));
      })
    );
  }

  // add(model: Partial<IPicture>) {
  //   return this.http.post<IPicture>(this.API_URL, model);
  // }

  // update(model: IPicture) {
  //   return this.http.patch<IPicture>(`${this.API_URL}${model.id}`, model);
  // }

  delete(modelId: number) {
    return this.http.delete(`${this.API_URL}${modelId}`);
  }

  uploadFile(file: File): Observable<HttpEvent<IPicture>> {
    const formData = new FormData();
    formData.append('file', file);

    return this.http.post<IPicture>(this.API_URL, formData, {
      reportProgress: true,
      observe: 'events',
    });
  }
}
