<ng-container *ngFor="let prop of propertySource.properties">
  <painter-content-product-property-wrapper
    *ngIf="prop.elements && prop.elements.length > 1"
    [name]="prop.name"
  >
    <ng-container *ngIf="prop.id === EFilterPropertyKey.kit">
      <div
        class="g-checkboxblue g-checkboxblue--with-text"
        *ngFor="let el of prop.elements"
      >
        <input
          data-e2e="checkbox"
          type="checkbox"
          [attr.id]="'checkbox-' + el.id"
          [disabled]="isElementDisabled(el.id)"
          [ngModel]="isElementSelected(el.id)"
          (click)="onClick($event, el.id)"
        />
        <label [attr.for]="'checkbox-' + el.id">
          <span>{{ el.name }}</span>
        </label>
      </div></ng-container
    >

    <ng-container *ngIf="prop.type === EPropertyType.COLOR">
      <div class="design-color-buttons">
        <design-color-button
          *ngFor="let el of prop.elements || []"
          [colors]="el.color || []"
          [colorsTitles]="el.name || []"
          [isActive]="isElementSelected(el.id)"
          [isDisabled]="isElementDisabled(el.id)"
          [newSize]="true"
          (click)="onClick($event, el.id)"
        ></design-color-button>
      </div>
    </ng-container>

    <ng-container *ngIf="prop.type === EPropertyType.CHECKBOX">
      <div class="checkbox-buttons">
        <button
          *ngFor="let el of prop.elements || []"
          type="button"
          class="g-btn-material"
          [disabled]="isElementDisabled(el.id)"
          [class.g-btn-material--active]="isElementSelected(el.id)"
          (click)="onClick($event, el.id)"
        >
          {{ el.name }}
        </button>
      </div>
    </ng-container>
  </painter-content-product-property-wrapper>
</ng-container>

