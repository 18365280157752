import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ICatalogFavorite } from '../../interfaces/catalog.interface';

@Injectable({
  providedIn: 'root',
})
export class CatalogFavoriteService {
  constructor(private readonly httpClient: HttpClient) {}

  getFavorites() {
    return this.httpClient.get<ICatalogFavorite>('catalog/public/favorites');
  }
}
