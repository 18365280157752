<design-modal-window
  *ngIf="propertiesInfo?.length"
  [uid]="MODAL_UID_PROPERTIES"
  [fullMode]="true"
  [hiddenMode]="true"
  prefix="characteristic"
>
  <div modal-header>Характеристики</div>
  <div modal-body class="wrapper-catalog-product-characteristic-simplebar">
    <ngx-simplebar class="catalog-product-characteristic-simplebar">
      <table class="catalog-product__characteristic-table">
        <tbody>
          <tr>
            <td>
              <div class="catalog-product__characteristic-property-name">
                <strong>Артикул:</strong>
              </div>
            </td>
            <td>
              {{ product.id }}
            </td>
          </tr>
          <!--<tr *ngIf="productArticle">
          <td>
            <div class="catalog-product__characteristic-property-name">
              <strong>Артикул:</strong>
            </div>
          </td>
          <td>
            {{ productArticle }}
          </td>
        </tr>-->
          <tr>
            <td>
              <div class="catalog-product__characteristic-property-name">
                <strong>Тип предложения:</strong>
                <div class="helper-wrapper" *ngIf="product.productTypeHelpText">
                  <design-helper-window
                    [title]="product.productType | catalog2ProductType"
                    [uid]="'productTypeHelpText'"
                    [position]="'bottom'"
                    [size]="'xs'"
                  >
                    <span class="state-popover__toggle">
                      <i class="icon icon-ic_help_fill"></i>
                    </span>
                    <div
                      class="state-popover-body__content"
                      [innerHtml]="product.productTypeHelpText"
                    ></div>
                  </design-helper-window>
                </div>
              </div>
            </td>
            <td>
              {{ product.productType | catalog2ProductType }}
            </td>
          </tr>

          <tr *ngFor="let property of propertiesInfo; trackBy: trackByFn">
            <td>
              <div class="catalog-product__characteristic-property-name">
                <strong>{{ property.name }}:</strong>

                <div
                  class="helper-wrapper"
                  *ngIf="
                    property &&
                    property.id &&
                    property.showHelper &&
                    property.helperText
                  "
                >
                  <design-helper-window
                    [title]="property.name"
                    [uid]="property.id"
                    [position]="'auto-bottom'"
                    [size]="'xs'"
                  >
                    <span class="state-popover__toggle">
                      <i class="icon icon-ic_help_fill"></i>
                    </span>
                    <div
                      class="state-popover-body__content"
                      [innerHtml]="property.helperText"
                    ></div>
                  </design-helper-window>
                </div>
              </div>
            </td>
            <td>
              <ng-container
                *ngFor="
                  let el of property.elements;
                  trackBy: trackByFn;
                  let i = index
                "
              >
              <span *ngIf="property.type === EPropertyType.CHECKBOX">
                {{ el.name }}<ng-container *ngIf="i < property.elements!.length - 1">,&nbsp;</ng-container>
              </span>
                <design-color-button-circle
                  *ngIf="property.type === EPropertyType.COLOR"
                  [color]="el.color || ''"
                  class="color-circle"
                ></design-color-button-circle>
              </ng-container>
              <div *ngIf="property.type === EPropertyType.INPUT_NUMBER">
                {{ getRoundVal(property.val || 0) }}
              </div>
              <div *ngIf="property.type === EPropertyType.INPUT_STRING">
                {{ property.val }}
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </ngx-simplebar>
  </div>
  <div modal-footer></div>
</design-modal-window>

<design-modal-window
  [uid]="MODAL_UID_DESCRIPTION"
  [fullMode]="true"
  [hiddenMode]="true"
  prefix="description"
>
  <div modal-header>Описание</div>
  <div modal-body>
    <ngx-simplebar class="simplebar">
      <div class="catalog-product__description g-seo-text">
        <div itemprop="description">
          <div
            *ngIf="
              product.designSeo?.shortDescription ||
              product.shortDescription as shortDescription
            "
            class="catalog-product__description-content"
            [innerHTML]="shortDescription | safeHtml"
          ></div>
          <div
            *ngIf="product.description"
            class="catalog-product__description-content"
            [innerHTML]="product.description | safeHtml"
          ></div>
        </div>
      </div>
    </ngx-simplebar>
  </div>
  <div modal-footer></div>
</design-modal-window>

<design-modal-window
  [uid]="MODAL_UID_GALLERY"
  [fullScreen]="true"
  prefix="gallery"
>
  <div modal-header></div>
  @if (galleryOpened$ | async) {
  <design-light-gallery-content-new
    modal-body
    [galleryImages]="galleryImages"
    [galleryVideos]="galleryVideos"
    [initAlbumPhoto]="currentImageId"
    [previewEnabled]="false"
    [modalView]="true"
  ></design-light-gallery-content-new>
  }
  <div modal-footer></div>
</design-modal-window>
