import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { IDesignSide } from '@inaripro-nx/catalog';
import { decimalRound } from '@inaripro-nx/common-ui';
import { IElement, IXY, IXYb } from '../../../../interfaces/editor.interface';
import { ActionsService } from '../../../../services/actions/actions.service';

@Component({
  selector: 'painter-actions-element-flip',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './actions-element-flip.component.html',
  styleUrls: ['./actions-element-flip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActionsElementFlipComponent {
  @Input() element!: IElement;
  @Input() designSide!: IDesignSide;

  @Output() updateElement: EventEmitter<IElement> = new EventEmitter();

  constructor(private actionsService: ActionsService) {}

  flip(flip: IXYb) {
    if (!this.element) {
      return;
    }

    this.updateElement.emit({
      ...this.element,
      flip,
    });
  }

  centerElement(center: IXYb) {
    const element = this.actionsService.centerElement({
      center,
      element: this.element,
    });

    if (!element) {
      return;
    }

    this.updateElement.emit(element);
  }

  stretchElement() {
    const element = this.element;
    const side = this.designSide;
    const zone0 = side.zones[0];

    const elementCenter: IXY = {
      x:
        (element.size.x / 2) * element.scale.x +
        element.translate.x +
        (zone0.center?.x || 0),
      y:
        (element.size.y / 2) * element.scale.y +
        element.translate.y +
        (zone0.center?.y || 0),
    };

    let minDiff: number | null = null;
    let minIndex = 0;

    (side.zones || []).forEach((z, zIndex) => {
      const diff = Math.sqrt(
        Math.pow((z.center?.x || 0) - elementCenter.x, 2) +
          Math.pow((z.center?.y || 0) - elementCenter.y, 2)
      );

      if (minDiff === null || diff < minDiff) {
        minDiff = diff;
        minIndex = zIndex;
      }
    });

    const zone = side.zones[minIndex];

    const updateCenter = {
      x: decimalRound((zone.center?.x || 0) - (zone0.center?.x || 0)),
      y: decimalRound((zone.center?.y || 0) - (zone0.center?.y || 0)),
    };

    const scale: IXY = {
      x: (zone.size?.x || 0) / element.size.x,
      y: (zone.size?.y || 0) / element.size.y,
    };

    const translate: IXY = {
      x: updateCenter.x - (element.size.x / 2) * scale.x,
      y: updateCenter.y - (element.size.y / 2) * scale.y,
    };

    this.updateElement.emit({
      ...element,
      translate,
      scale,
      rotate: 0,
    });
  }
}
