import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AppShellLinkTypeEnum } from '@inaripro-nx/common-ui';
import { HeaderStore } from '../../state/header.store';

@Component({
  selector: 'shell-header-logo',
  standalone: true,
  imports: [CommonModule, RouterModule],
  templateUrl: './header-logo.component.html',
  styleUrls: ['./header-logo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderLogoComponent {
  @Input() fixed = false;

  link = '/';

  readonly config$ = this.headerStore.config$;
  readonly linkTypeEnum = AppShellLinkTypeEnum;

  constructor(private headerStore: HeaderStore) {}
}
