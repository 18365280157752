import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { SafeResourceUrl } from '@angular/platform-browser';
import { PreloaderComponent } from '../../../preloader/preloader.component';
import { IGalleryImage } from '../../interfaces/design-light-gallery-content-new.interface';
import { DesignLightGalleryContentNewArrowsComponent } from '../design-light-gallery-content-new-arrows/design-light-gallery-content-new-arrows.component';

@Component({
  selector: 'design-light-gallery-content-new-preview',
  standalone: true,
  imports: [PreloaderComponent, DesignLightGalleryContentNewArrowsComponent],
  templateUrl: './design-light-gallery-content-new-preview.component.html',
  styleUrls: ['./design-light-gallery-content-new-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DesignLightGalleryContentNewPreviewComponent
  implements OnInit, OnChanges
{
  @Input() images: IGalleryImage[] = [];
  @Input() selectedIndex = 0;
  @Input() descriptions: string[] = [];
  @Input() autoOpen = false;

  @Output() activeChanged: EventEmitter<number> = new EventEmitter<number>();
  @Output() galleryOpened: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChild('imgWrapper', { static: false })
  imgWrapper?: ElementRef<HTMLDivElement>;

  private _currentIndex = 0;
  get currentIndex(): number {
    return this._currentIndex;
  }

  private _src: string | SafeResourceUrl = '';
  get src(): string | SafeResourceUrl {
    return this._src;
  }

  get description() {
    return this.images && this.images[this._currentIndex]
      ? this.images[this._currentIndex].description
      : null;
  }

  _previewHeight = 0;
  get previewHeight() {
    return this._previewHeight;
  }

  private _loadedList: string[] = [];
  private _infinityMove = true;

  isOpen = false;
  showPreloader = false;

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit() {
    if (this.autoOpen) {
      this.open();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['selectedIndex'] || changes['images']) {
      this.showPreloader = true;

      this._currentIndex = this.selectedIndex;

      this._src =
        this.images && this.images[this._currentIndex]
          ? this.images[this._currentIndex].medium || ''
          : '';
    }
  }

  @HostListener('window:keydown', ['$event'])
  onKeyDown(e: KeyboardEvent) {
    if (this.isOpen && this.isKeyboardEsc(e)) {
      this.close();
    }
  }

  public open(): void {
    this.show();
    this.isOpen = true;
    this.cdr.detectChanges();

    this._previewHeight = this.imgWrapper?.nativeElement.clientHeight || 0;
    this.galleryOpened.emit(true);
  }

  public close(): void {
    this.isOpen = false;

    this.galleryOpened.emit(false);
    this.cdr.detectChanges();
  }

  public show(): void {
    if (!this._src) {
      return;
    }

    const src = this._src.toString();

    if (this._loadedList.indexOf(src) === -1) {
      this.showPreloader = false;
    }
  }

  public loaded(): void {
    if (!this._src) {
      return;
    }

    const src = this._src.toString();

    this.showPreloader = false;
    this._loadedList.push(src);
  }

  public handleNextClick(): void {
    if (this.canShowNext()) {
      this._currentIndex++;

      if (this._currentIndex === this.images.length) {
        this._currentIndex = 0;
      }

      this.show();
      this.activeChanged.emit(this._currentIndex);
    }
  }

  public handlePrevClick(): void {
    if (this.canShowPrev()) {
      this._currentIndex--;

      if (this._currentIndex < 0) {
        this._currentIndex = this.images.length - 1;
      }

      this.show();
      this.activeChanged.emit(this._currentIndex);
    }
  }

  public canShowNext(): boolean {
    return this.images
      ? this._infinityMove || this._currentIndex < this.images.length - 1
      : false;
  }

  public canShowPrev(): boolean {
    return this.images ? this._infinityMove || this._currentIndex > 0 : false;
  }

  private isKeyboardEsc(e: KeyboardEvent): boolean {
    return e.keyCode === 27;
  }
}
