<design-modal-window [uid]="modalUid" [maxWidth]="850">
  <div modal-header>
    <div class="city-select-title">
      Ваш город: {{ newCity ? newCity.name : currentLocation?.name }}
    </div>
  </div>
  <div modal-body>
    <form
      class="city-select-form"
      *ngIf="cities; else preloader"
      [formGroup]="citiesListForm"
      (submit)="onSubmit()"
    >
      <div class="city-select-search">
        <div class="g-form-group">
          <input
            #query
            class="city-select-search__input"
            type="text"
            formControlName="query"
          />

          <button
            class="city-select-search__btn city-select-search__btn--search"
          >
            <i class="icon icon-ic_search"></i>
          </button>

          <button
            *ngIf="query.value"
            class="city-select-search__btn city-select-search__btn--close"
            (click)="clearSearch()"
          >
            <i class="icon icon-ic_close"></i>
          </button>
        </div>
        <span
          *ngIf="!filteredCities?.length && !mainFilteredCities?.length"
          class="error"
        >
          По вашему запросу ничего не найдено
        </span>
      </div>

      <div class="scroll-content">
        <div
          class="city-select-form__main-cities"
          *ngIf="mainFilteredCities?.length"
        >
          <div
            class="g-form-group"
            *ngFor="let item of mainFilteredCities; trackBy: trackById"
          >
            <div
              class="g-checkbox g-checkbox--no-label g-checkbox--radio g-checkbox--with-text"
            >
              <label class="no-label">
                <input
                  type="radio"
                  formControlName="city"
                  [value]="item.id"
                  (click)="newCity = item"
                />
                <span>{{ item.name }}</span>
              </label>
            </div>
          </div>
        </div>

        <ng-container *ngIf="!queryCities; else findList">
          <div class="city-select-form__rest-container">
            <div
              class="city-select-form__rest-letter"
              *ngFor="let letter of filteredCitiesMap | objectKeys"
              (click)="currentLetter = letter"
            >
              {{ letter }}
            </div>
          </div>

          <div class="city-select-form__rest-container" *ngIf="currentLetter">
            <div class="city-select-form__rest-cities">
              <div
                class="g-form-group"
                *ngFor="
                  let item of filteredCitiesMap[currentLetter];
                  trackBy: trackById
                "
              >
                <div
                  class="g-checkbox g-checkbox--no-label g-checkbox--radio g-checkbox--with-text"
                >
                  <label class="no-label">
                    <input
                      type="radio"
                      formControlName="city"
                      [value]="item.id"
                      (click)="newCity = item"
                    />
                    <span>{{ item.name }}</span>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </ng-container>

        <ng-template #findList>
          <div
            class="city-select-form__rest-cities"
            *ngIf="filteredCities?.length"
          >
            <div
              class="g-form-group"
              *ngFor="let item of filteredCities; trackBy: trackById"
            >
              <div
                class="g-checkbox g-checkbox--no-label g-checkbox--radio g-checkbox--with-text"
              >
                <label class="no-label">
                  <input
                    type="radio"
                    formControlName="city"
                    [value]="item.id"
                    (click)="newCity = item"
                  />
                  <span>{{ item.name }}</span>
                </label>
              </div>
            </div>
          </div>
        </ng-template>
      </div>
      <div class="g-form-button g-form-button--right">
        <button
          class="g-btn-link g-btn-link--red"
          type="submit"
          [disabled]="!isValid()"
        >
          OK
        </button>
      </div>
    </form>
  </div>
  <div modal-footer></div>
</design-modal-window>

<ng-template #preloader>
  <div class="preloader">
    <design-preloader></design-preloader>
  </div>
</ng-template>
