import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IListResponse, IPaginationRequest } from '@inaripro-nx/common-ui';
import { Observable, map } from 'rxjs';
import {
  IProductPostListRequest,
  IPublicProductForList,
} from '../../interfaces/catalog-product.interface';

@Injectable({
  providedIn: 'root',
})
export class CatalogProductListService {
  constructor(private readonly httpClient: HttpClient) {}

  getProducts(
    request: IProductPostListRequest,
    paginationRequest: IPaginationRequest
  ): Observable<IPublicProductForList[]> {
    const {
      offset = 0,
      limit = 100,
      order_direction = 'desc',
      order_by = 'default',
    } = paginationRequest;

    const url = `catalog/public/goods/?offset=${offset}&limit=${limit}&order_by=${order_by}&order_direction=${order_direction}`;
    return this.httpClient
      .post<IListResponse<IPublicProductForList>>(url, request)
      .pipe(map(({ data }) => data));
  }
}
