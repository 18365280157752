import { Injectable } from '@angular/core';
import { AppShellConfig, linkToGlobalState } from '@inaripro-nx/common-ui';
import { ComponentStore } from '@ngrx/component-store';
import { Store } from '@ngrx/store';

export interface HeaderState {
  config: AppShellConfig | null;
}

const initialState: HeaderState = {
  config: null,
};

@Injectable({
  providedIn: 'root',
})
export class HeaderStore extends ComponentStore<HeaderState> {
  readonly config$ = this.select((state) => state.config);

  readonly setConfig = this.updater((state, payload: AppShellConfig | null) => {
    return {
      ...state,
      config: payload,
    };
  });

  constructor(private readonly globalStore: Store) {
    super({ ...initialState });

    linkToGlobalState(this.state$, 'libs/shell/header', this.globalStore);
  }
}
