import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { PipesModule } from '@inaripro-nx/common-ui';

@Component({
  standalone: true,
  imports: [CommonModule, PipesModule],
  selector: 'design-card-text',
  templateUrl: './card-text.component.html',
  styleUrls: ['./card-text.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardTextComponent {
  @Input() text!: string;
  @Input() needSVG = false;

  @Output() createObject: EventEmitter<{
    text: string;
    bBox: { width: number; height: number };
    textLength: number;
  }> = new EventEmitter();
  @Output() selectObject: EventEmitter<void> = new EventEmitter();
  @Output() cardCloseEmit: EventEmitter<void> = new EventEmitter();

  @ViewChild('svgGroup') svgGroup!: ElementRef<SVGGraphicsElement>;

  public clickCardClose(): void {
    this.cardCloseEmit.emit();
  }

  addObject() {
    if (this.needSVG) {
      const el = this.svgGroup.nativeElement;
      const bBox = el.getBBox();

      let textLength = 0;

      el.querySelectorAll('tspan').forEach((tspan) => {
        const length = tspan.getComputedTextLength();

        if (length > textLength) {
          textLength = length;
        }
      });

      this.createObject.emit({
        text: this.text,
        bBox,
        textLength,
      });
    } else {
      this.selectObject.emit();
    }
  }
}
