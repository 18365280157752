export enum EPropertyType {
  CHECKBOX = 'CHECKBOX',
  INPUT_NUMBER = 'INPUT_NUMBER',
  INPUT_STRING = 'INPUT_STRING',
  COLOR = 'COLOR',
}

export interface IPropertyForList {
  id: number;
  name: string;
  isActive: boolean;
  isPublish: boolean;
  showHelper: boolean;
  type: EPropertyType;
}

export interface IProperty extends IPropertyForList {
  alias: string;
  helperText: string;
  isRequired?: boolean;
  typeElements?: ITypeElement[];
  showSelectbox?: boolean;
  canUseForOrder?: boolean;
  canUseForDesigner?: boolean;
  isSeparateProductCount?: boolean;
  useCount?: number;
}

export interface ITypeElement {
  id: number;
  name: string;
  isActive: boolean;
  isPublish: boolean;
  color?: string;
  crashed?: boolean;
}
