import { BACKEND_ERROR_TYPES, IBackendTranslateError } from '../interfaces';

export const mapErrorMessage: IBackendTranslateError = {
  email: {
    email: 'Ошибка',
  },
  login: {
    login: 'Ошибка',
  },
  password: {
    password: 'Ошибка',
  },
  phoneNumber: {
    phoneNumber: 'Ошибка',
  },
  fullName: {
    fullName: 'Ошибка',
  },
  firstName: {
    firstName: 'Ошибка',
  },
  lastName: {
    lastName: 'Ошибка',
  },
  companySuggestionData: {
    companySuggestionData: 'Ошибка',
  },
  captchaValue: {
    captchaValue: 'Ошибка',
  },
  agreement: {
    agreement: 'Ошибка',
  },
  city: {
    city: 'Ошибка',
  },
  date: {
    date: 'Ошибка',
  },
  name: {
    name: 'Ошибка',
  },
  phone: {
    phone: 'Ошибка',
  },
  oldPassword: {
    oldPassword: 'Ошибка',
  },
};

mapErrorMessage['email'][BACKEND_ERROR_TYPES.DUPLICATED] =
  'Пользователь с таким email уже существует';
mapErrorMessage['email'][BACKEND_ERROR_TYPES.IS_BLANK] =
  'Заполните обязательные поля';
mapErrorMessage['email'][BACKEND_ERROR_TYPES.INVALID_VALUE] =
  'Заполните поля правильно';
mapErrorMessage['email'][BACKEND_ERROR_TYPES.NOT_FOUND] =
  'Пользователь с таким email не был найден';
mapErrorMessage['email'][BACKEND_ERROR_TYPES.USER_BANNED] =
  'Пользователь забанен';

// mapErrorMessage['login'][BACKEND_ERROR_TYPES.REQUIRED] = 'Заполните обязательные поля';
// mapErrorMessage['login'][BACKEND_ERROR_TYPES.DUPLICATED] = 'Пользователь с таким логином уже существует';

mapErrorMessage['password'][BACKEND_ERROR_TYPES.IS_BLANK] =
  'Заполните обязательные поля';
mapErrorMessage['password'][BACKEND_ERROR_TYPES.INVALID_VALUE] =
  'Заполните поля правильно';

mapErrorMessage['phoneNumber'][BACKEND_ERROR_TYPES.DUPLICATED] =
  'Пользователь с таким номером телефона уже существует';
mapErrorMessage['phoneNumber'][BACKEND_ERROR_TYPES.IS_BLANK] =
  'Заполните обязательные поля';
mapErrorMessage['phoneNumber'][BACKEND_ERROR_TYPES.INVALID_VALUE] =
  'Заполните поля правильно';

mapErrorMessage['fullName'][BACKEND_ERROR_TYPES.IS_BLANK] =
  'Заполните обязательные поля';
mapErrorMessage['fullName'][BACKEND_ERROR_TYPES.INVALID_VALUE] =
  'Заполните поля правильно';

mapErrorMessage['firstName'][BACKEND_ERROR_TYPES.IS_BLANK] =
  'Заполните обязательные поля';
mapErrorMessage['firstName'][BACKEND_ERROR_TYPES.INVALID_VALUE] =
  'Заполните поля правильно';

mapErrorMessage['lastName'][BACKEND_ERROR_TYPES.IS_BLANK] =
  'Заполните обязательные поля';
mapErrorMessage['lastName'][BACKEND_ERROR_TYPES.INVALID_VALUE] =
  'Заполните поля правильно';

mapErrorMessage['companySuggestionData'][BACKEND_ERROR_TYPES.INVALID_VALUE] =
  'Заполните обязательные поля';
mapErrorMessage['companySuggestionData'][BACKEND_ERROR_TYPES.REQUIRED] =
  'Заполните поля правильно';

mapErrorMessage['captchaValue'][BACKEND_ERROR_TYPES.REQUIRED] =
  'Требуется пройти верификацию капчей';
mapErrorMessage['captchaValue'][BACKEND_ERROR_TYPES.INVALID_VALUE] =
  'Ошибка верификации капчи. Попробуйте еще раз';
mapErrorMessage['captchaValue'][BACKEND_ERROR_TYPES.IS_NULL] =
  'Требуется пройти верификацию капчей';

mapErrorMessage['agreement'][BACKEND_ERROR_TYPES.AGREEMENT_NOT_ACCEPTED] =
  'Проставьте согласие на обработку данных';

mapErrorMessage['city'][BACKEND_ERROR_TYPES.IS_NULL] =
  'Заполните обязательные поля';
mapErrorMessage['city'][BACKEND_ERROR_TYPES.IS_BLANK] =
  'Заполните обязательные поля';
mapErrorMessage['city'][BACKEND_ERROR_TYPES.INVALID_VALUE] =
  'Заполните поля правильно';

mapErrorMessage['date'][BACKEND_ERROR_TYPES.IS_BLANK] =
  'Заполните обязательные поля';
mapErrorMessage['date'][BACKEND_ERROR_TYPES.INVALID_VALUE] =
  'Заполните поля правильно';

mapErrorMessage['name'][BACKEND_ERROR_TYPES.IS_BLANK] =
  'Заполните обязательные поля';
mapErrorMessage['name'][BACKEND_ERROR_TYPES.INVALID_VALUE] =
  'Заполните поля правильно';

mapErrorMessage['phone'][BACKEND_ERROR_TYPES.IS_BLANK] =
  'Заполните обязательные поля';
mapErrorMessage['phone'][BACKEND_ERROR_TYPES.INVALID_VALUE] =
  'Заполните поля правильно';

mapErrorMessage['oldPassword'][BACKEND_ERROR_TYPES.INVALID_VALUE] =
  'Старый пароль не совпадает';
