import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IPattern, IPatternLibrary } from '@inaripro-nx/common-ui';
import { Observable, switchMap } from 'rxjs';
import { DesignerAuthLinkService } from '../designer-auth/designer-auth.service';

@Injectable({
  providedIn: 'root',
})
export class PatternsLibraryService {
  private readonly API_URL_LIBRARIES = '/catalog/public/design/texture_group';
  private readonly API_URL_PATTERNS = '/catalog/public/design/texture';

  constructor(
    private http: HttpClient,
    private designerAuthLinkService: DesignerAuthLinkService
  ) {}

  all(): Observable<IPatternLibrary[]> {
    return this.designerAuthLinkService.loadDesignUser$.pipe(
      switchMap(() => {
        return this.http.get<IPatternLibrary[]>(this.API_URL_LIBRARIES);
      })
    );
  }

  getById(id: number): Observable<IPattern[]> {
    return this.designerAuthLinkService.loadDesignUser$.pipe(
      switchMap(() => {
        return this.http.get<IPattern[]>(
          `${this.API_URL_PATTERNS}?group=${id}`
        );
      })
    );
  }
}
