import { Pipe, PipeTransform } from '@angular/core';
import { getRadiusByArc, polarToCartesian } from '../../utils';

@Pipe({
  name: 'svgCirclePath',
})
export class SvgCirclePathPipe implements PipeTransform {
  transform(dimensions: {
    width: number;
    height: number;
    angle: number;
  }): string {
    const { width, height } = dimensions;
    let { angle } = dimensions;

    if (!angle) {
      return '';
    }

    if (angle === 360) {
      angle -= 0.01;
    } else if (angle === -360) {
      angle += 0.01;
    }

    const topAngle = 270;
    const downAngle = 90;
    const centerAngle = angle > 0 ? topAngle : downAngle;

    const angleSector = {
      start: centerAngle - angle / 2,
      end: centerAngle + angle / 2,
    };

    const radius = getRadiusByArc({ width, angle });

    const cx = width / 2;
    const cy = height + (angle > 0 ? 1 : -1) * radius;

    const start = polarToCartesian(cx, cy, radius, angleSector.start);
    const end = polarToCartesian(cx, cy, radius, angleSector.end);

    const xAxisRotation = 0;
    const largeArcFlag =
      Math.abs(angleSector.end - angleSector.start) <= 180 ? 0 : 1;
    const sweepFlag = angle > 0 ? 1 : 0;

    const pathD = [
      'M',
      start.x,
      start.y,
      'A',
      radius,
      radius,
      xAxisRotation,
      largeArcFlag,
      sweepFlag,
      end.x,
      end.y,
    ].join(' ');

    return pathD;
  }
}
