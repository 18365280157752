import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'svgTranslate',
})
export class SvgTranslatePipe implements PipeTransform {
  transform(value: { x: number | string; y: number | string }): string {
    return ` translate(${value.x} ${value.y})`;
  }
}
