@if (el.type === EElementType.text) {
<svg:g
  data-painter-editor-element-text-content
  [el]="el"
  [index]="index"
></svg:g>
} @else {
<svg:g
  [innerHTML]="el.svg | safeHtml"
  [attr.transform]="
    ({ x: el.flip.x ? -1 : 1, y: el.flip.y ? -1 : 1 } | svgScale) +
    ({ x: +el.flip.x * -el.size.x, y: +el.flip.y * -el.size.y } | svgTranslate)
  "
></svg:g>
}
