import { Pipe, PipeTransform } from '@angular/core';
import { isNullOrUndefined } from '../../utils';

@Pipe({
  name: 'isNullOrUndefined',
})
export class IsNullOrUndefinedPipe implements PipeTransform {
  transform(value: any, args?: any): boolean {
    return isNullOrUndefined(value);
  }
}
