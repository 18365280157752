export function toDataUrl(
  url: string | URL | null,
  callback: (res: string | ArrayBuffer | null) => void
) {
  if (url) {
    const xhr = new XMLHttpRequest();
    xhr.onload = function () {
      const reader = new FileReader();
      reader.onloadend = function () {
        callback(reader.result);
      };
      reader.readAsDataURL(xhr.response);
    };
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();
  } else {
    callback(null);
  }
}

export function getTextFromUrl(
  url: string | URL | null,
  callback: (res: string | null) => void
) {
  if (url) {
    const xhr = new XMLHttpRequest();
    xhr.onload = function () {
      callback(xhr.response);
    };
    xhr.open('GET', url);
    xhr.responseType = 'text';
    xhr.send();
  } else {
    callback(null);
  }
}

export function getFileExtension(fileName: string): string | null {
  const index = fileName.lastIndexOf('.');

  if (index === -1) {
    return null;
  }
  const res = fileName?.slice(index);
  return res ? res.toLowerCase() : fileName;
}

export function checkExtension(accept: string, fileName: string): boolean {
  const validExtensions = accept
    .split(',')
    .filter((v) => !!v)
    .map((v) => v?.toLowerCase());

  if (!validExtensions.length) {
    return false;
  }

  return validExtensions.indexOf(getFileExtension(fileName) || '') > -1;
}

export function dataURLtoBlob(dataURL: string): Blob {
  // convert base64/URLEncoded data component to raw binary data
  const decodeFn =
    dataURL.split(',')[0].indexOf('base64') >= 0 ? atob : decodeURI;
  const byteString = decodeFn(dataURL.split(',')[1]);

  const mime = dataURL.split(',')[0].split(':')[1].split(';')[0];

  // write the bytes of the string to a typed array
  const blobParts = new Uint8Array(byteString.length);

  for (let i = 0; i < byteString.length; i++) {
    blobParts[i] = byteString.charCodeAt(i);
  }

  return new Blob([blobParts], { type: mime });
}

export function blobToFile(blob: Blob, fileName: string): File {
  return new File([blob], fileName, {
    lastModified: new Date().getTime(),
    type: blob.type,
  });
}
