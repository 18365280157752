import { Injectable } from '@angular/core';
import { IPattern, IPatternLibrary } from '@inaripro-nx/common-ui';
import { ComponentStore } from '@ngrx/component-store';
import {
  EMPTY,
  Observable,
  catchError,
  exhaustMap,
  mergeMap,
  of,
  tap,
  withLatestFrom,
} from 'rxjs';
import { PatternsLibraryService } from '../../services/patterns/patterns.service';

interface IMapOfPatterns {
  [id: number]: IPattern[];
}

export interface PatternsState {
  libraries: IPatternLibrary[];
  currentId: number;
  patterns: IMapOfPatterns;
}

const initialState: PatternsState = {
  libraries: [],
  currentId: 0,
  patterns: {},
};

@Injectable()
export class PatternsStore extends ComponentStore<PatternsState> {
  readonly libraries$ = this.select((state) => state.libraries);
  readonly currentId$ = this.select((state) => state.currentId);
  private readonly patterns$ = this.select((state) => state.patterns);

  readonly currentLibrary$: Observable<IPatternLibrary | null> = this.select(
    this.libraries$,
    this.currentId$,
    (libraries: IPatternLibrary[], currentId: number) =>
      libraries.find((l) => l.id === currentId) || null
  );

  readonly currentPatterns$: Observable<IPattern[] | null> = this.select(
    this.patterns$,
    this.currentId$,
    (patters: IMapOfPatterns, currentId: number) => patters[currentId] || null
  );

  private readonly addLibraries = this.updater(
    (state, libraries: IPatternLibrary[]) => ({
      ...state,
      libraries: [...state.libraries, ...libraries],
    })
  );

  readonly setCurrentId = this.updater((state, currentId: number) => {
    return {
      ...state,
      currentId,
    };
  });

  private readonly addPatterns = this.updater(
    (state, data: { id: number; patterns: IPattern[] }) => ({
      ...state,
      patterns: { ...state.patterns, [data.id]: data.patterns },
    })
  );

  private readonly loadCollection = this.effect((trigger$) =>
    trigger$.pipe(
      exhaustMap(() =>
        this.patternsLibraryService.all().pipe(
          tap((libraries: IPatternLibrary[]) => {
            this.addLibraries(libraries);
            const lib = libraries[0];

            if (lib) {
              this.loadPatterns(lib.id);
            }
          }),
          catchError(() => EMPTY)
        )
      )
    )
  );

  readonly loadPatterns = this.effect((id$: Observable<number>) =>
    id$.pipe(
      withLatestFrom(this.patterns$),
      mergeMap(([id, patterns]) => {
        this.setCurrentId(id);

        if (id === 0 || patterns[id]) {
          return of(null);
        }

        return this.patternsLibraryService.getById(id).pipe(
          tap((patterns: IPattern[]) => this.addPatterns({ id, patterns })),
          catchError(() => EMPTY)
        );
      })
    )
  );

  readonly selectAnyLibrary = this.effect<void>((trigger$) =>
    trigger$.pipe(
      withLatestFrom(this.libraries$, this.currentLibrary$),
      tap(([, libraries, currentLibrary]) => {
        if (currentLibrary?.id || 0 > 0) {
          return;
        }

        if (libraries.length > 1) {
          this.loadPatterns(libraries[1].id);
          return;
        }
      })
    )
  );

  constructor(private readonly patternsLibraryService: PatternsLibraryService) {
    super({ ...initialState });
  }

  init() {
    this.loadCollection();
  }
}
