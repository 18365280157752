import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { EMPTY, catchError, exhaustMap, tap } from 'rxjs';
import {
  IProductPostListRequest,
  IPublicProductForList,
} from '../../../interfaces/catalog-product.interface';
import { CatalogProductListService } from '../../../services';
import { IPaginationRequest } from '@inaripro-nx/common-ui';

export interface CatalogProductListState {
  loading: boolean;
  productList: IPublicProductForList[];
}

const initialState: CatalogProductListState = {
  loading: false,
  productList: [],
};

@Injectable()
export class CatalogProductListStore extends ComponentStore<CatalogProductListState> {
  readonly loading$ = this.select((state) => state.loading);
  readonly productList$ = this.select((state) => state.productList);

  readonly loadProducts = this.effect<{
    request: IProductPostListRequest;
    paginationRequest: IPaginationRequest;
  }>((data$) =>
    data$.pipe(
      tap(() => this.setLoading(true)),
      exhaustMap(({ request, paginationRequest }) => {
        return this.catalogProductListService
          .getProducts(request, paginationRequest)
          .pipe(
            tap((productList) => {
              this.setProductsList(productList);
              this.setLoading(false);
            }),
            catchError(() => EMPTY)
          );
      })
    )
  );

  private readonly setLoading = this.updater((state, payload: boolean) => ({
    ...state,
    loading: payload,
  }));

  private readonly setProductsList = this.updater(
    (state, payload: IPublicProductForList[]) => ({
      ...state,
      productList: payload,
    })
  );

  constructor(
    private readonly catalogProductListService: CatalogProductListService
  ) {
    super({ ...initialState });
  }
}
