import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalWindowComponent, ModalWindowStore } from '@inaripro-nx/design-ui';
import { WindowToolsService } from '../../services/window-tools/window-tools.service';
import { Subscription } from 'rxjs';
import { AuthService } from '@inaripro-nx/auth';
import {
  REACH_FILE_LIMIT_NO_USER_SHORT_TEXT,
  REACH_FILE_LIMIT_USER_TEXT,
} from '@inaripro-nx/painter';
import { AppShellConfig } from '@inaripro-nx/common-ui';

export const MODAL_REACH_FILE_LIMIT = 'MODAL_REACH_FILE_LIMIT';

@Component({
  selector: 'painter-modal-reach-file-limit',
  standalone: true,
  imports: [CommonModule, ModalWindowComponent],
  templateUrl: './modal-reach-file-limit.component.html',
  styleUrl: './modal-reach-file-limit.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalReachFileLimitComponent implements OnInit, OnDestroy {
  @Input() config: AppShellConfig | null = null;

  readonly MODAL_REACH_FILE_LIMIT = MODAL_REACH_FILE_LIMIT;
  readonly REACH_FILE_LIMIT_USER_TEXT = REACH_FILE_LIMIT_USER_TEXT;
  readonly REACH_FILE_LIMIT_NO_USER_SHORT_TEXT =
    REACH_FILE_LIMIT_NO_USER_SHORT_TEXT;

  readonly user$ = this.authService.user$;

  get loginUrl() {
    return `${this.config?.publicUrl}/login?returnUrl=${window.location.href}`;
  }

  private _subs: Subscription[] = [];
  set subs(sub: Subscription) {
    this._subs.push(sub);
  }

  constructor(
    private readonly modalWindowStore: ModalWindowStore,
    private readonly windowToolsService: WindowToolsService,
    private readonly authService: AuthService
  ) {}

  ngOnInit() {
    this.subs = this.windowToolsService.isDesktop$.subscribe((value) => {
      if (value) {
        this.closeModal();
      }
    });
  }

  ngOnDestroy() {
    this._subs.forEach((s) => s.unsubscribe());
  }

  closeModal() {
    this.modalWindowStore.patch({ [MODAL_REACH_FILE_LIMIT]: false });
  }
}
