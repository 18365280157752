import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { DesignUiModule } from '@inaripro-nx/design-ui';
import { IElement } from '../../../../interfaces/editor.interface';
import { EditorService } from '../../../../services/editor/editor.service';

@Component({
  selector: 'painter-actions-element-fill-opacity',
  standalone: true,
  imports: [CommonModule, DesignUiModule],
  templateUrl: './actions-element-fill-opacity.component.html',
  styleUrls: ['./actions-element-fill-opacity.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActionsElementFillOpacityComponent {
  @Input() element!: IElement;

  readonly minValue = 0;
  readonly maxValue = 100;

  constructor(private editorService: EditorService) {}

  changeValue(percent: number): void {
    this.editorService.fillOpacity(percent);
  }

  stopChange() {
    this.editorService.stopFillOpacity();
  }

  reset() {
    this.editorService.fillOpacity(100);
    this.editorService.stopFillOpacity();
  }
}
