import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'design-input-plus-minus',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule],
  templateUrl: './input-plus-minus.component.html',
  styleUrls: ['./input-plus-minus.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DesignInputPlusMinusComponent {
  @Input() control: FormControl = new FormControl(1);
  @Input() isDisable = false;
  @Input() selectOnFocus = true;

  @Output() handleMinus: EventEmitter<void> = new EventEmitter<void>();
  @Output() handlePlus: EventEmitter<void> = new EventEmitter<void>();
  @Output() focusControl: EventEmitter<void> = new EventEmitter<void>();
  @Output() blurControl: EventEmitter<void> = new EventEmitter<void>();

  onFocus(input: HTMLInputElement) {
    if (this.selectOnFocus && input) {
      input.select();
    }
    this.focusControl.emit();
  }
}
