import { CommonModule } from '@angular/common';
import { HttpClient, HttpEvent, HttpEventType } from '@angular/common/http';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import {
  FileSizePipe,
  PipesModule,
  checkExtension,
} from '@inaripro-nx/common-ui';
import {
  Observable,
  Subscription,
  catchError,
  concat,
  finalize,
  of,
  takeWhile,
  tap,
  toArray,
} from 'rxjs';
import { IFile } from '../../interfaces';
import { ModalWindowComponent } from '../modal-window/modal-window.component';
import { ModalWindowStore } from '../modal-window/state/modal-window/modal-window.store';
interface IUploadProgress {
  percent: number;
  error: string;
  cancel: boolean;
  success: boolean;
}

interface IUploadProcess {
  uploading: boolean;
  files: File[];
  progress: IUploadProgress[];
}

const initProcess: IUploadProcess = {
  uploading: false,
  files: [],
  progress: [],
};

@Component({
  selector: 'design-file-uploader',
  standalone: true,
  templateUrl: './file-uploader.component.html',
  styleUrls: ['./file-uploader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, ModalWindowComponent, PipesModule],
})
export class FileUploaderComponent {
  @Input() uid = 'file_uploader';
  @Input() api = '/public/image/upload';
  @Input() uploadFile?: (file: File) => Observable<HttpEvent<unknown>>;
  @Input() accept = '*/*';
  @Input() maxFiles = 25; // ограничение на общее количество загруженных файлов
  @Input() maxFileSize = 25 * 1024 * 1024; // ограничение по размеру
  @Input() maxSimultaneousUploads = 10; // ограничение на одновременную загрузку
  @Input() multiple = true;

  @Output() uploadedFile: EventEmitter<IFile> = new EventEmitter(); // по одиночке
  @Output() uploadedFiles: EventEmitter<IFile[]> = new EventEmitter(); // все вместе

  @ViewChild('upload') upload!: ElementRef;

  uploadSub?: Subscription;
  uploadProcess: IUploadProcess = { ...initProcess };

  get modalUid(): string {
    return `${this.uid}_modal`;
  }

  constructor(
    private cdr: ChangeDetectorRef,
    private modalWindowStore: ModalWindowStore,
    private readonly httpClient: HttpClient,
    private fileSizePipe: FileSizePipe
  ) {}

  fuSelectFiles() {
    if (this.uploadProcess?.uploading) {
      return;
    }

    this.upload.nativeElement.click();
  }

  fuFilesDrop(event: DragEvent) {
    if (this.uploadProcess?.uploading) {
      return;
    }

    const files = event.dataTransfer?.files || null;

    this.uploadFiles(files);

    event.preventDefault();
    event.stopPropagation();
  }

  fuFilesDropOver(event: Event): void {
    event.preventDefault();
  }

  filesChange(event: Event) {
    const input = event.target as HTMLInputElement;
    const files = input.files || null;

    this.uploadFiles(files);

    input.value = '';
  }

  private sendFile(file: File) {
    const formData = new FormData();
    formData.append('file', file);

    return this.httpClient.post(this.api, formData, {
      reportProgress: true,
      observe: 'events',
    });
  }

  private uploadFiles(fileList: FileList | null) {
    if (!fileList) {
      return;
    }

    const files: File[] = [];
    const progress: IUploadProgress[] = [];

    for (let i = 0; i < fileList.length; i++) {
      files.push(fileList[i]);
      progress.push({
        percent: 0,
        error: '',
        cancel: false,
        success: false,
      });
    }

    this.uploadProcess = {
      uploading: true,
      progress,
      files,
    };

    this.changeModal(true);

    const maxFileSizeStr = this.fileSizePipe.transform(this.maxFileSize, 2);

    const uploadedFiles: IFile[] = [];

    this.uploadSub = concat(
      ...files.map((file, index) => {
        if (index + 1 > this.maxSimultaneousUploads) {
          progress[index].error =
            'Превышено максимальное количество файлов для одновременной загрузки';
          return of(null);
        } else if (file.size > this.maxFileSize) {
          const fileSizeStr = this.fileSizePipe.transform(file.size, 2);
          const error = `Размер файла ${fileSizeStr} превышает максимально допустимый ${maxFileSizeStr}`;
          progress[index].error = error;
          return of(null);
        } else if (!checkExtension(this.accept, file.name)) {
          progress[index].error = 'Недопустимое расширение файла';
          return of(null);
        }

        const upload$ = this.uploadFile
          ? this.uploadFile(file)
          : this.sendFile(file);

        return upload$.pipe(
          tap((event: HttpEvent<unknown>) => {
            if (event.type == HttpEventType.UploadProgress) {
              progress[index].percent = Math.round(
                100 * (event.loaded / (event.total || event.loaded))
              );
              this.cdr.detectChanges();
            } else if (event.type == HttpEventType.Response) {
              progress[index].success = true;

              const file = (event.body as { file: IFile }).file;
              uploadedFiles.push(file);
              this.uploadedFile.emit(file);

              this.cdr.detectChanges();
            }
          }),
          takeWhile(
            (event: HttpEvent<unknown>) =>
              event.type !== HttpEventType.Response && !progress[index].cancel
          ),
          catchError((err) => {
            if (
              (((err.error || [])[0] || {}).file || [])[0] === 'LIMIT_EXCEEDED'
            ) {
              progress[index].error =
                'Достигнуто максимальное количество файлов на одного пользователя, удалите файлы, чтобы загрузить новые';
            } else {
              progress[index].error = 'Ошибка загрузки файла';
            }
            return of(null);
          })
        );
      })
    )
      .pipe(
        toArray(),
        finalize(() => {
          this.uploadedFiles.emit(uploadedFiles);
        })
      )
      .subscribe(() => {
        this.uploadProcess.uploading = false;

        const isAllFilesUpload = this.uploadProcess.progress.every(
          (v) => v.success
        );

        if (isAllFilesUpload) {
          this.changeModal(false);
        }

        this.cdr.detectChanges();
      });
  }

  cancelUpload() {
    this.changeModal(false);

    this.uploadSub?.unsubscribe();
    this.uploadProcess = { ...initProcess };
  }

  private changeModal(open: boolean) {
    this.modalWindowStore.patch({ [this.modalUid]: open });
  }
}
