<div class="card" [class.card-figure]="isFigure" (click)="addObject()" *ngIf="{imageLoad: false} as state">
  <div class="card-close" *ngIf="canDelete" (click)="clickCardClose($event)">
    <i class="icon icon-ic_cross-close" title="Удалить это изображение"></i>
  </div>
  <img *ngIf="lazyLoad" [lazyLoad]="image.preview || image.url" (load)="state.imageLoad = true"/>
  <img *ngIf="!lazyLoad" [attr.src]="image.preview || image.url" (load)="state.imageLoad = true"/>
  <svg
    *ngIf="!(image.preview && image.height && image.width)"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    style="width: 0; height: 0"
  >
    <g #svgGroup>
      <image *ngIf="state.imageLoad" [attr.xlink:href]="image.url"></image>
    </g>
  </svg>
</div>
