import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'viewbox',
})
export class ViewboxPipe implements PipeTransform {
  transform(
    size: { x: number | string; y: number | string },
    args?: [point: { x: number | string; y: number | string }]
  ): string {
    return (
      (args && args[0] ? `${args[0].x} ${args[0].y}` : '0 0') +
      ` ${size.x} ${size.y}`
    );
  }
}
