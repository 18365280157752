import { CommonModule, DOCUMENT } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Observable, map } from 'rxjs';
import { ClickOutsideDirective } from '../../directives';
import { ModalWindowStore } from './state/modal-window/modal-window.store';

@Component({
  selector: 'design-modal-window',
  standalone: true,
  imports: [CommonModule, ClickOutsideDirective],
  templateUrl: './modal-window.component.html',
  styleUrls: ['./modal-window.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalWindowComponent
  implements OnChanges, OnInit, AfterViewInit, OnDestroy
{
  @Input() uid!: string;
  @Input() prefix = 'default';
  @Input() maxWidth = 500;
  @Input() isHideBackDropOnClick = false;
  @Input() fullMode = false;
  @Input() fullScreen = false;
  @Input() hiddenMode = false;
  @Input() openOnCreate = false;

  @Output() selfClose: EventEmitter<void> = new EventEmitter();

  @ViewChild('wrapper') wrapperElement?: ElementRef;

  modal$: Observable<boolean> = this.modalWindowStore.modals$.pipe(
    map((modals) => !!modals[this.uid])
  );

  classes: {
    backdrop: string;
    wrapper: string;
    sidebar: string;
    content: string;
    header: string;
    title: string;
    close: string;
    body: string;
    footer: string;
  } | null = null;

  constructor(
    private modalWindowStore: ModalWindowStore,
    private elementRef: ElementRef,
    @Inject(DOCUMENT) private document: Document
  ) {}

  ngOnChanges() {
    this.classes = {
      backdrop: `${this.prefix}-modal-backdrop`,
      wrapper: `${this.prefix}-modal-wrapper`,
      sidebar: `${this.prefix}-modal-sidebar`,
      content: `${this.prefix}-modal-content`,
      header: `${this.prefix}-modal-header`,
      title: `${this.prefix}-modal-title`,
      close: `${this.prefix}-modal-close`,
      body: `${this.prefix}-modal-body`,
      footer: `${this.prefix}-modal-footer`,
    };
  }

  ngOnInit() {
    if (this.openOnCreate) {
      this.modalWindowStore.patch({ [this.uid]: true });
    }
  }

  ngAfterViewInit() {
    this.document.body.insertBefore(
      this.elementRef.nativeElement,
      this.document.body.firstChild
    );
  }

  ngOnDestroy() {
    try {
      this.document.body.removeChild(this.elementRef.nativeElement);
    } catch (e) {
      // eslint-disable-next-line no-empty
    }
  }

  public closeModal(selfClose = false) {
    this.modalWindowStore.patch({ [this.uid]: false });
    if (selfClose) {
      this.selfClose.emit();
    }
  }
}
