<design-modal-window
  [uid]="MODAL_AI_UID"
  [fullMode]="true"
  [prefix]="'modal-ai'"
  class="modal-ai"
>
  <div modal-header class="title">AI-генератор</div>
  <div modal-body>
    <image-ai-image-ai></image-ai-image-ai>
  </div>
  <div modal-footer class="footer">
    <button type="button" class="g-btn-link" (click)="closeModal()">
      Отмена
    </button>
    <button
      type="button"
      class="g-btn-link g-btn-link--red"
      [disabled]="imageAiComponent?.isLockedForSave"
      (click)="!imageAiComponent?.isLockedForSave && onSave()"
    >
      Сохранить
    </button>
  </div>
</design-modal-window>
