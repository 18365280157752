import { Inject, Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ROUTER_NAVIGATION, RouterNavigationAction } from '@ngrx/router-store';
import { tap } from 'rxjs/operators';
import { RouterStateUrl } from '../../constants';
import { DEFAULT_TITLE, WINDOW } from '../../providers';
import { setTitle } from './title.actions';

@Injectable()
export class TitleEffects {
  setRouteTite$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ROUTER_NAVIGATION),
        tap((action: RouterNavigationAction<RouterStateUrl>) => {
          this.window.setTimeout(() => {
            const title: string =
              (action.payload.routerState.data['title'] as string) ||
              this.defaultTitle;
            this.titleService.setTitle(title);
          });
        })
      ),
    { dispatch: false }
  );

  setTitle$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(setTitle),
        tap((action) => {
          this.window.setTimeout(() => {
            const title = action.payload || this.defaultTitle;
            this.titleService.setTitle(title);
          });
        })
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private titleService: Title,
    @Inject(WINDOW) private window: Window,
    @Inject(DEFAULT_TITLE) private defaultTitle: string
  ) {}
}
