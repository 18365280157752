import { SafeResourceUrl } from '@angular/platform-browser';
export interface IGalleryImageMap {
  [key: string]: IGalleryImage[];
}

export interface IGalleryImage {
  id: number | string;
  small?: string | SafeResourceUrl;
  medium?: string | SafeResourceUrl;
  description?: string;
  title?: string;
  alt?: string;
}
