import {
  ComponentRef,
  Directive,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { Subscription, fromEvent, tap } from 'rxjs';
import { ClickOutsideService } from '../../../services/click-outside/click-outside.service';
import { DropDownComponent } from '../drop-down.component';

@Directive({
  selector: '[designAdDropDown]',
  standalone: true,
})
export class AdDropDownDirective<T extends { dropDownLabel: string }>
  implements OnChanges, OnInit, OnDestroy
{
  @Input() dropDownAlignElement?: Element;
  @Input() dropDownItems!: T[];
  @Input() dropDownOpenTo: 'up' | 'down' = 'down';
  @Input() dropDownAlign: 'left' | 'right' = 'right';
  @Input() dropDownClass?: string;
  @Input() dropDownLabelActive?: string;
  @Input() dropDownContentTemplateRef: TemplateRef<unknown> | null = null;

  @Input() dropDownLeftShift: number = 0;

  @Output() selectItem: EventEmitter<{ index: number; item: T }> =
    new EventEmitter();

  @Output() toggleDropDown: EventEmitter<boolean> = new EventEmitter();

  componentRef?: ComponentRef<DropDownComponent<T>>;

  private _subs: Subscription[] = [];
  set subs(sub: Subscription) {
    this._subs.push(sub);
  }

  get isActive(): boolean {
    return this.componentRef?.instance.isActive ?? false;
  }

  constructor(
    private readonly elementRef: ElementRef,
    private readonly viewContainerRef: ViewContainerRef,
    private readonly clickOutsideService: ClickOutsideService
  ) {}

  ngOnChanges(simpleChanges: SimpleChanges): void {
    this.changeComponent(simpleChanges);
  }

  ngOnInit(): void {
    this.createComponent();
    this.createToggleEvent();
  }

  ngOnDestroy(): void {
    this._subs.forEach((s) => s.unsubscribe());
  }

  private createComponent() {
    const viewContainerRef = this.viewContainerRef;
    viewContainerRef.clear();

    const componentRef =
      viewContainerRef.createComponent<DropDownComponent<T>>(DropDownComponent);
    const component = componentRef.instance;

    const {
      dropDownAlignElement,
      dropDownItems,
      dropDownOpenTo,
      dropDownAlign,
      dropDownClass,
      dropDownLabelActive,
      dropDownContentTemplateRef,
      dropDownLeftShift,
    } = this;

    component.dropDownAlignElement =
      dropDownAlignElement || this.elementRef.nativeElement;
    component.dropDownItems = dropDownItems;
    component.dropDownOpenTo = dropDownOpenTo;
    component.dropDownAlign = dropDownAlign;
    component.dropDownClass = dropDownClass;
    component.dropDownLabelActive = dropDownLabelActive;
    component.dropDownContentTemplateRef = dropDownContentTemplateRef;

    component.selectItem = this.selectItem;
    component.toggleDropDown = this.toggleDropDown;

    component.dropDownLeftShift = dropDownLeftShift;

    this.componentRef = componentRef;
  }

  private changeComponent(simpleChanges: SimpleChanges) {
    if (!this.componentRef) {
      return;
    }

    const component = this.componentRef.instance;

    if (simpleChanges['dropDownAlignElement']) {
      component.dropDownAlignElement =
        this.dropDownAlignElement || this.elementRef.nativeElement;
    }

    if (simpleChanges['dropDownItems']) {
      component.dropDownItems = this.dropDownItems;
    }

    if (simpleChanges['dropDownOpenTo']) {
      component.dropDownOpenTo = this.dropDownOpenTo;
    }

    if (simpleChanges['dropDownAlign']) {
      component.dropDownAlign = this.dropDownAlign;
    }

    if (simpleChanges['dropDownClass']) {
      component.dropDownClass = this.dropDownClass;
    }

    if (simpleChanges['dropDownLabelActive']) {
      component.dropDownLabelActive = this.dropDownLabelActive;
    }

    component.cdr.detectChanges();
  }

  private createToggleEvent() {
    this.subs = fromEvent<MouseEvent>(this.elementRef.nativeElement, 'click')
      .pipe(
        tap((event: MouseEvent) => {
          if (!this.componentRef) {
            return;
          }

          event.stopPropagation();

          const component = this.componentRef.instance;

          if (!component.isActive) {
            this.clickOutsideService.trigger$.next(event);
          }

          component.toggleActive();
          component.cdr.detectChanges();
        })
      )
      .subscribe();
  }

  public hideSelf(): void {
    this.componentRef?.instance.hideSelf();
  }
}
