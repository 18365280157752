@if (publicPrice$ | async) {
<div class="product-order">
  <div class="product-order__item">
    <painter-content-product-order-input
      class="product-order__count"
      [publicPrice]="publicPrice$ | async"
      [orderCount]="orderCount$ | async"
      [minRange]="(minRange$ | async) || 1"
      [lotSize]="(lotSize$ | async) || 1"
      [isDisable]="(isDisable$ | async) || false"
      (inputCount)="setOrderCount($event)"
    ></painter-content-product-order-input>

    <painter-content-product-order-price
      class="product-order__price"
      [totalPrice]="(totalPrice$ | async) || 0"
      [perCountPrice]="(perCountPrice$ | async) || 0"
      [isDisable]="(isDisable$ | async) || false"
    ></painter-content-product-order-price>
  </div>

  <div class="product-order__item">
    <button
      type="button"
      class="product-order__btn"
      [disabled]="(isDisable$ | async) || false"
      (click)="orderProduct()"
    >
      В корзину
    </button>
  </div>
</div>
}
