<div class="header" [class.header-fixed]="scrollFlag">
  <div class="g-container">
    <shell-header-logo [fixed]="scrollFlag"></shell-header-logo>
    <div class="header-go-back">
      <shell-header-go-back></shell-header-go-back>
    </div>
    <div class="header-menu">
      <shell-header-menu
        [dictionary]="dictionary$ | async"
        [treeItems]="treeItems$ | async"
        [links]="links$ | async"
      ></shell-header-menu>
    </div>
    <div class="header-user-price" *ngIf="orderProductSource">
      <shell-header-order-control
        [source]="orderProductSource"
        [basketCounter]="basketCounter$ | async"
      ></shell-header-order-control>
    </div>
    <div class="header-user-navigation">
      <div class="header-user-navigation__user-menu">
        <shell-header-menu-user
          [user]="user$ | async"
          (signout)="onSignout()"
        ></shell-header-menu-user>
      </div>
    </div>
    <div class="header-mob-nav">
      <shell-header-menu-mobile
        [user]="user$ | async"
        [currentLocation]="currentLocation$ | async"
        (selectCity)="citySelectModal.toggleCitySelect(true)"
        (signout)="onSignout()"
      ></shell-header-menu-mobile>
    </div>
  </div>
</div>

<shell-header-city-select-modal
  #citySelectModal
  [locations]="(cities$ | async) || []"
  [currentLocation]="currentLocation$ | async"
  (submitted)="updateCurrentLocation($event)"
></shell-header-city-select-modal>
