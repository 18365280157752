import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IPublicPrice } from '../../interfaces';

@Component({
  selector: 'catalog-product-price-table',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './catalog-product-price-table.component.html',
  styleUrls: ['./catalog-product-price-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CatalogProductPriceTableComponent {
  @Input() publicPrice: IPublicPrice | null = null;
  @Input() sumOrderCount = 0;
  @Input() unitName = 'шт.';
  @Input() activeRangeIndex: number | null = -1;

  get basePrices() {
    return this.publicPrice?.baseCost.vs ?? [];
  }
}
