import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { WINDOW, decimalRound, getSvgLength } from '@inaripro-nx/common-ui';
import { BehaviorSubject } from 'rxjs';
import { IXY } from '../../interfaces/editor.interface';

@Injectable({
  providedIn: 'root',
})
export class BrowserService {
  // Has bug https://bugs.webkit.org/show_bug.cgi?id=209220
  hasBugGetScreenCTM$: BehaviorSubject<boolean | null> = new BehaviorSubject<
    boolean | null
  >(null);

  constructor(
    @Inject(WINDOW) private window: Window,
    @Inject(DOCUMENT) private document: Document
  ) {}

  testHasBugGetScreenCTM() {
    const test = this.document.createElement('div');
    test.innerHTML =
      '<div><svg id="test_svg_1"></svg></div><div style="transform: scale(2)"><svg id="test_svg_2"></svg></div>';

    this.document.body.appendChild(test);

    const svg1 = this.document.getElementById(
      'test_svg_1'
    ) as SVGGraphicsElement | null;
    const svg2 = this.document.getElementById(
      'test_svg_2'
    ) as SVGGraphicsElement | null;

    if (!svg1 || !svg2) {
      return;
    }

    const length1 = getSvgLength(svg1, { x: 10, y: 10 });
    const length2 = getSvgLength(svg2, { x: 10, y: 10 });

    this.document.body.removeChild(test);

    this.hasBugGetScreenCTM$.next(length1.x === length2.x);
  }

  fixScreenCTM(point: IXY, zoom: number, decimalPlaces?: number): IXY {
    if (!this.hasBugGetScreenCTM$.value) {
      return point;
    }

    const { x, y } = point;

    return {
      x: decimalRound(x / zoom, decimalPlaces),
      y: decimalRound(y / zoom, decimalPlaces),
    };
  }
}
