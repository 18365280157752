<div
  class="select"
  [class.select-active]="isActive"
  designClickOutside
  [isDelay]="true"
  (clickOutsideEvent)="isActive = false"
>
  <div class="select__title" (click)="isActive = !isActive">
    <ng-container *ngIf="selectedOption; else empty">
      <ng-container
        [ngTemplateOutlet]="
          optionTemplate ? optionTemplate : defaultLabelTemplate
        "
        [ngTemplateOutletContext]="{ option: selectedOption }"
      ></ng-container>
    </ng-container>
    <ng-template #empty>{{ emptyString }}</ng-template>
  </div>
  <div
    class="select__content"
    [class.select__content--up]="directionOpen === DropdownDirection.UP"
    [style.height.px]="isActive ? options.length * 37 : 0"
    [style.maxHeight]="isActive ? '25vh' : 0"
    (touchstart)="$event.stopPropagation()"
    (touchmove)="$event.stopPropagation()"
  >
    <div
      class="select__label"
      *ngFor="let option of options"
      (click)="clickOption(option)"
    >
      <ng-container
        [ngTemplateOutlet]="
          optionTemplate ? optionTemplate : defaultOptionTemplate
        "
        [ngTemplateOutletContext]="{
          option: option,
          selectedOption: selectedOption
        }"
      ></ng-container>
    </div>
  </div>
</div>

<ng-template #defaultLabelTemplate let-option="option">
  <span>{{ option?.name }}</span>
</ng-template>

<ng-template
  #defaultOptionTemplate
  let-option="option"
  let-selectedOption="selectedOption"
>
  <span [class.is-active]="option.id === selectedOption.id">
    {{ option.name }}
  </span>
</ng-template>
