<design-modal-window
  [uid]="modalUid"
  [maxWidth]="1134"
  [prefix]="'productselect'"
  (selfClose)="onSelfCloseModal()"
  #modalWindow
>
  <div modal-sidebar>
    <div class="title">Каталог</div>
    <ng-container *ngTemplateOutlet="catalogTree"></ng-container>
  </div>
  <div modal-header>
    <div class="header-menu">
      <div class="header-menu__title" (click)="toggleMenu = !toggleMenu">
        <span>Каталог</span>
      </div>
      <div class="header-menu__action" (click)="toggleMenu = !toggleMenu">
        <i class="icon icon-ic_arrow-up" *ngIf="!toggleMenu"></i>
        <i class="icon icon-ic_arrow-down" *ngIf="toggleMenu"></i>
      </div>
    </div>
    <div class="header-catalog-menu" *ngIf="toggleMenu">
      <ng-container *ngTemplateOutlet="catalogTree"></ng-container>
    </div>
  </div>
  <div modal-body>
    <div class="title">{{ title$ | async }}</div>

    <ng-container *ngIf="request$ | async">
      <design-select
        class="sort-select"
        [options]="sortOptions"
        [selectedOption]="selectedSortOption"
        [optionTemplate]="optionTemplate"
        (selectOption)="onChangeSort($event)"
      ></design-select>
      <ng-template #optionTemplate let-option="option">
        <span [class.is-active]="option.value === selectedSortOption.value">
          {{ option.name }}
        </span>
      </ng-template>
    </ng-container>

    <ngx-simplebar
      [style.height.px]="scrollbarMinSize$ | async"
      class="catalog-select-product_ngx-simplebar"
    >
      <catalog-product-list
        class="scroll-content"
        *ngIf="modal$ | async"
        [request]="request$ | async"
        [selectedProductId]="selectedProductId"
        (selectProduct)="selectProduct($event)"
      >
        <design-preloader></design-preloader>
      </catalog-product-list>
    </ngx-simplebar>
  </div>
</design-modal-window>

<ng-template #catalogTree>
  <catalog-tree
    class="scroll-content"
    *ngIf="modal$ | async"
    [dictionary]="dictionary$ | async"
    [treeItems]="treeItemsNoLink$ | async"
    [filterCatalogNomenclatureTreeItem]="filterCatalogNomenclatureTreeItem"
    [expandedMap]="expandedMap$ | async"
    [selectedItemId]="selectedItemId$ | async"
    (selectItem)="selectTreeItem($event)"
  ></catalog-tree>
</ng-template>
