export enum EPage {
  product = 'product',
  objects = 'objects',
  design = 'design',
  layers = 'layers',
}

export enum EObject {
  templates = 'templates',
  pictures = 'pictures',
  text = 'text',
  figures = 'figures',
  qrcodes = 'qrcodes',
  fill = 'fill',
}

const LOCAL_STORAGE_ELEMENTS_KEY = 'ELEMENTS_PRODUCT_';
export const getStorageNameProductElements = (productId: number) =>
  `${LOCAL_STORAGE_ELEMENTS_KEY}_${productId}`;
export const LOCAL_STORAGE_ELEMENTS_TIME = 60 * 24 * 2; // minute
export const ELEMENT_FILL_MODAL_UID = 'ELEMENT_FILL_MODAL_UID';

export const MODAL_UID_SAVE_CHANGES_REQUEST = 'MODAL_UID_SAVE_CHANGES_REQUEST';
export const MODAL_UID_SAVE_CHANGES_SUCCESS = 'MODAL_UID_SAVE_CHANGES_SUCCESS';
