import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { APP_ICONS } from '../../consts/icons';

@Component({
  standalone: true,
  imports: [CommonModule],
  selector: 'design-icons-page',
  templateUrl: './icons-page.component.html',
  styleUrls: ['./icons-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconsPageComponent {
  get APP_ICONS() {
    return APP_ICONS;
  }
}
