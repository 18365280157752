import { mapErrorMessage } from '../constants';
import { IBackendError, IBackendErrorWrapper } from '../interfaces';

export function parseUserFormErrors(
  inError: IBackendErrorWrapper
): string | null {
  if (!inError) {
    return null;
  }

  let errorMessage: string | null = null;

  try {
    let errors: IBackendError[] | undefined = undefined;

    if (inError.error) {
      errors = inError.error;

      // For fix backend wrappers of error
      if ((errors as any).error) {
        errors = (errors as any).error;
      }
    }

    if (errors && Array.isArray(errors)) {
      errors.forEach((error) => {
        Object.keys(error).forEach((key) => {
          const errorTypes = error[key];

          if (errorTypes.length > 0 && mapErrorMessage[key]) {
            errorMessage = mapErrorMessage[key][errorTypes[0]];
          }
        });
      });
    } else {
      errorMessage = 'Server Error';
    }

    return errorMessage;
  } catch (e) {
    return 'Server Error';
  }
}
