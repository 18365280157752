import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { PipesModule } from '@inaripro-nx/common-ui';

@Component({
  standalone: true,
  imports: [CommonModule, PipesModule],
  selector: 'design-card-filter',
  templateUrl: './card-filter.component.html',
  styleUrls: ['./card-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardFilterComponent {
  @Input() filter!: {
    id: number | null;
    name: string;
    html: string;
  };

  @Input() isSelect!: boolean;
}
