<div class="editor">
  <div
    class="editor__workarea"
    painterEditorWorkarea
    [painterEditorWorkareaPanel]="drawPanel"
    [painterEditorWorkareaContainer]="drawArea"
    [class.grab]="!isGrabbing && (isGrabActive$ | async)"
    [class.grabbing]="isGrabbing"
    (dragStart)="onDragStart()"
    (dragEnd)="onDragEnd()"
    (mousedown)="clearSelect()"
    (touchstart)="clearSelect()"
  >
    <div #drawArea class="editor__workarea-draw">
      <painter-editor-draw></painter-editor-draw>
    </div>
  </div>

  <div class="editor__workarea-panel" #drawPanel>
    <painter-editor-controls
      (selectSide)="selectSide($event)"
    ></painter-editor-controls>
  </div>
</div>
