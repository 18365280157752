import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DesignUiModule } from '@inaripro-nx/design-ui';
import { ProductStore } from '../../state/product/product.store';
import { TemplateFormService } from '../../services/template-form/template-form.service';
import {
  MODAL_UID_SAVE_CHANGES_REQUEST,
  MODAL_UID_SAVE_CHANGES_SUCCESS,
} from '../../interfaces/main.interface';

@Component({
  selector: 'painter-modal-save-changes',
  standalone: true,
  imports: [CommonModule, DesignUiModule],
  templateUrl: './modal-save-changes.component.html',
  styleUrl: './modal-save-changes.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalSaveChangesComponent {
  readonly MODAL_UID_SAVE_CHANGES_REQUEST = MODAL_UID_SAVE_CHANGES_REQUEST;
  readonly MODAL_UID_SAVE_CHANGES_SUCCESS = MODAL_UID_SAVE_CHANGES_SUCCESS;

  readonly currentTemplate$ = this.productStore.currentTemplate$;

  constructor(
    private productStore: ProductStore,
    private templateFormService: TemplateFormService
  ) {}

  createTemplate() {
    this.templateFormService.createOrUpdate$.next('create_with_success_modal');
  }

  updateTemplate() {
    this.templateFormService.createOrUpdate$.next('update_with_success_modal');
  }

  selectProduct() {
    this.productStore.selectProduct$.next(true);
  }
}
