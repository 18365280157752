import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import {
  catchError,
  EMPTY,
  from,
  mergeMap,
  Observable,
  tap,
  withLatestFrom,
} from 'rxjs';
import { CROP_BOX_DEFAULT_HEIGHT, CROP_BOX_DEFAULT_WIDTH } from '../constants';
import { getTextFromUrl } from '@inaripro-nx/common-ui';
import { removePreserveAspectRatio } from '../utils/crop.utils';

export interface CropState {
  originalImageUrl: string | null;
  originalImageWidth: number;
  originalImageHeight: number;
  imageScale: number;
  imageX: number;
  imageY: number;
  cropWidth: number;
  cropHeight: number;
  cropX: number;
  cropY: number;
  maskUrl: string | null;
  inlineSvgMask: string | null;
  saveProportions: boolean;
  resizing: boolean;
}

const initialState: CropState = {
  originalImageUrl: null,
  originalImageWidth: 0,
  originalImageHeight: 0,
  imageScale: 1,
  imageX: 0,
  imageY: 0,
  cropWidth: CROP_BOX_DEFAULT_WIDTH,
  cropHeight: CROP_BOX_DEFAULT_HEIGHT,
  cropX: 0,
  cropY: 0,
  maskUrl: null,
  inlineSvgMask: null,
  saveProportions: false,
  resizing: false,
};

@Injectable()
export class CropStore extends ComponentStore<CropState> {
  readonly imageX$ = this.select((state) => state.imageX);
  readonly imageY$ = this.select((state) => state.imageY);
  readonly imageScale$ = this.select((state) => state.imageScale);
  readonly cropWidth$ = this.select((state) => state.cropWidth);
  readonly cropHeight$ = this.select((state) => state.cropHeight);
  readonly cropX$ = this.select((state) => state.cropX);
  readonly cropY$ = this.select((state) => state.cropY);
  readonly maskUrl$ = this.select((state) => state.maskUrl);
  readonly inlineSvgMask$ = this.select((state) => state.inlineSvgMask);
  readonly saveProportions$ = this.select((state) => state.saveProportions);
  readonly resizing$ = this.select((state) => state.resizing);

  readonly setOriginalImage = this.updater(
    (state: CropState, payload: string | null) => {
      return {
        ...state,
        originalImage: payload,
      };
    }
  );

  readonly setOriginalImageSize = this.updater(
    (state: CropState, payload: { width: number; height: number }) => {
      return {
        ...state,
        originalImageWidth: payload.width,
        originalImageHeight: payload.height,
      };
    }
  );

  readonly setImageScale = this.updater((state: CropState, payload: number) => {
    return {
      ...state,
      imageScale: payload,
    };
  });

  readonly setImageX = this.updater((state: CropState, payload: number) => {
    return {
      ...state,
      imageX: payload,
    };
  });

  readonly setImageY = this.updater((state: CropState, payload: number) => {
    return {
      ...state,
      imageY: payload,
    };
  });

  readonly setCropSize = this.updater(
    (state: CropState, payload: { width: number; height: number }) => {
      return {
        ...state,
        cropWidth: payload.width,
        cropHeight: payload.height,
      };
    }
  );

  readonly setCropPosition = this.updater(
    (state: CropState, payload: { x: number; y: number }) => {
      return {
        ...state,
        cropX: payload.x,
        cropY: payload.y,
      };
    }
  );

  readonly setMaskUrl = this.updater(
    (state: CropState, payload: string | null) => {
      return {
        ...state,
        maskUrl: payload,
      };
    }
  );

  readonly setInlineSvgMask = this.updater(
    (state: CropState, payload: string | null) => {
      return {
        ...state,
        inlineSvgMask: payload,
      };
    }
  );

  readonly setSaveProportions = this.updater(
    (state: CropState, payload: boolean) => {
      return {
        ...state,
        saveProportions: payload,
      };
    }
  );

  readonly setResizing = this.updater((state: CropState, payload: boolean) => {
    return {
      ...state,
      resizing: payload,
    };
  });

  readonly loadMask = this.effect((svgUrl$: Observable<string | null>) =>
    svgUrl$.pipe(
      withLatestFrom(this.maskUrl$),
      mergeMap(([svgUrl, maskUrl]) => {
        if (svgUrl === null) {
          this.setInlineSvgMask(null);
          this.setMaskUrl(null);
          return EMPTY;
        }

        const svgResponseData: Promise<string> = new Promise(
          (resolve, reject) => {
            getTextFromUrl(svgUrl, (response) => {
              if (response) {
                resolve(response as string);
              } else {
                console.error('No SVG found in loaded contents');
                reject();
              }
            });
          }
        );

        return from(svgResponseData).pipe(
          tap((response) => {
            const inlineSvgMask = removePreserveAspectRatio(response);
            this.setInlineSvgMask(inlineSvgMask);

            if (maskUrl) {
              URL.revokeObjectURL(maskUrl);
            }

            const localMaskUrl =
              URL.createObjectURL(
                new Blob([inlineSvgMask], { type: 'image/svg+xml' })
              ) || svgUrl;

            this.setMaskUrl(localMaskUrl);
          }),
          catchError(() => {
            this.setInlineSvgMask(null);
            return EMPTY;
          })
        );
      })
    )
  );

  constructor() {
    super({ ...initialState });
  }
}
