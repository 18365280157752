import { Injectable } from '@angular/core';
import { linkToGlobalState, normalizeFloat } from '@inaripro-nx/common-ui';
import { ComponentStore } from '@ngrx/component-store';
import { Store } from '@ngrx/store';
import { Observable, map, shareReplay, tap, withLatestFrom } from 'rxjs';
import { IXY } from '../../interfaces/editor.interface';

export const MIN_ZOOM = 1;
export const MAX_ZOOM = 10;
export const ZOOM_STEP = 0.1;
export const ZOOM_OPTIONS: number[] = [1, 2, 3, 5, 10];

export const DEFAULT_ZOOM = 1;
export const DEFAULT_OFFSET: IXY = { x: 0, y: 0 };

export interface WorkareaState {
  zoom: number;
  offset: IXY;
  prevZoom: number;
  prevOffset: IXY;
  focusZoom: number;
  focusOffset: IXY;
  isFocusActive: boolean;
  isGrabActive: boolean;
}

const initialState: WorkareaState = {
  zoom: DEFAULT_ZOOM,
  offset: DEFAULT_OFFSET,
  prevZoom: DEFAULT_ZOOM,
  prevOffset: DEFAULT_OFFSET,
  focusZoom: DEFAULT_ZOOM,
  focusOffset: DEFAULT_OFFSET,
  isFocusActive: false,
  isGrabActive: false,
};

@Injectable({
  providedIn: 'root',
})
export class WorkareaStore extends ComponentStore<WorkareaState> {
  readonly zoom$: Observable<number> = this.select(
    (state: WorkareaState) => state.zoom
  );

  readonly offset$: Observable<IXY> = this.select(
    (state: WorkareaState) => state.offset
  );

  readonly focusZoom$: Observable<number> = this.select(
    (state: WorkareaState) => state.focusZoom
  );

  readonly focusOffset$: Observable<IXY> = this.select(
    (state: WorkareaState) => state.focusOffset
  );

  readonly isFocusActive$: Observable<boolean> = this.select(
    (state: WorkareaState) => state.isFocusActive
  );

  readonly isGrabActive$: Observable<boolean> = this.select(
    (state: WorkareaState) => state.isGrabActive
  );

  readonly zoomOutDisabled$: Observable<boolean> = this.zoom$.pipe(
    map((zoom) => zoom <= MIN_ZOOM),
    shareReplay({ refCount: false, bufferSize: 1 })
  );

  readonly zoomInDisabled$: Observable<boolean> = this.zoom$.pipe(
    map((zoom) => zoom >= MAX_ZOOM),
    shareReplay({ refCount: false, bufferSize: 1 })
  );

  readonly zoomIn = this.updater((state: WorkareaState) => {
    return {
      ...state,
      zoom: normalizeFloat(Math.min(state.zoom + ZOOM_STEP, MAX_ZOOM)),
      isFocusActive: false,
    };
  });

  readonly zoomOut = this.updater((state: WorkareaState) => {
    return {
      ...state,
      zoom: normalizeFloat(Math.max(state.zoom - ZOOM_STEP, MIN_ZOOM)),
      isFocusActive: false,
    };
  });

  readonly setZoom = this.updater((state: WorkareaState, payload: number) => {
    return {
      ...state,
      zoom: payload,
      isFocusActive: false,
    };
  });

  readonly changePosition = this.updater(
    (state: WorkareaState, payload: IXY) => {
      return {
        ...state,
        offset: payload,
        isFocusActive: false,
      };
    }
  );

  readonly setFocusZoom = this.updater(
    (state: WorkareaState, payload: number) => {
      return {
        ...state,
        focusZoom: payload,
      };
    }
  );

  readonly setFocusOffset = this.updater(
    (state: WorkareaState, payload: IXY) => {
      return {
        ...state,
        focusOffset: payload,
      };
    }
  );

  readonly removeFocus = this.updater((state: WorkareaState) => {
    return {
      ...state,
      isFocusActive: false,
    };
  });

  readonly toggleFocus = this.updater((state: WorkareaState) => {
    if (!state.isFocusActive) {
      return {
        ...state,
        zoom: state.focusZoom,
        offset: state.focusOffset,
        prevZoom: state.zoom,
        prevOffset: { ...state.offset },
        isFocusActive: !state.isFocusActive,
      };
    } else {
      return {
        ...state,
        zoom: state.prevZoom,
        offset: { ...state.prevOffset },
        isFocusActive: !state.isFocusActive,
      };
    }
  });

  readonly setIsGrabActive = this.updater(
    (state: WorkareaState, payload: boolean) => {
      return {
        ...state,
        isGrabActive: payload,
      };
    }
  );

  readonly reset = this.updater(() => {
    return {
      ...initialState,
    };
  });

  readonly removeGrab = this.effect((trigger$) =>
    trigger$.pipe(
      tap(() => {
        this.setIsGrabActive(false);
      })
    )
  );

  readonly toggleGrab = this.effect((trigger$) =>
    trigger$.pipe(
      withLatestFrom(this.isGrabActive$),
      tap(([, isGrabActive]) => {
        const active = !isGrabActive;
        this.setIsGrabActive(active);
      })
    )
  );

  constructor(private readonly globalStore: Store) {
    super({ ...initialState });
    linkToGlobalState(
      this.state$,
      'libs/painter/WorkareaStore',
      this.globalStore
    );
  }
}
