import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  FormControl,
  ReactiveFormsModule,
} from '@angular/forms';
import {
  detectChangesOnTouchEvent,
  getControlName,
  PipesModule,
} from '@inaripro-nx/common-ui';
import { Subscription, tap } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'design-form-input-string',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, PipesModule],
  templateUrl: './form-input-string.component.html',
  styleUrls: ['./form-input-string.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormInputStringComponent
  implements OnChanges, OnDestroy, AfterViewInit
{
  @Input() fControl: AbstractControl | null = null;
  @Input() classWrapper: string | null = null;
  @Input() classInput: string | null = 'g-form-control';
  @Input() label: string | null = null;
  @Input() isDisabled = false;
  @Input() autoFocus = true;
  @Input() autoSelect = true;
  @Input() autocomplete: string | null = null;
  @Input() type = 'text';
  @Input() autoFormat: null = null;
  @Input() showAlert = true;
  @Input() showEmptyError = true;

  @Output() focusEvent: EventEmitter<void> = new EventEmitter();
  @Output() clickEvent: EventEmitter<MouseEvent> = new EventEmitter();
  @Output() keydownEnter: EventEmitter<void> = new EventEmitter();
  @Output() keydownEsc: EventEmitter<void> = new EventEmitter();
  @Output() blurInputEvent: EventEmitter<void> = new EventEmitter();
  @Output() keydownTab: EventEmitter<KeyboardEvent> = new EventEmitter();

  @ViewChild('inputField', { static: false }) inputField!: ElementRef;

  get formControl() {
    return this.fControl as FormControl;
  }

  controlId: string | null = null;
  private controlSub: Subscription | null = null;

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnChanges() {
    if (!this.fControl) {
      this.controlUnsubscribe();
      return;
    }

    detectChangesOnTouchEvent(this.cdr, this.fControl);

    const controlName = getControlName(this.fControl);
    this.controlId = `${controlName}-${uuidv4()}`;
    this.controlSubscribe();
  }

  ngOnDestroy() {
    this.controlUnsubscribe();
  }

  ngAfterViewInit() {
    if (this.autoFocus && this.inputField) {
      this.inputField.nativeElement.focus();
    }
  }

  private controlSubscribe(): void {
    this.controlUnsubscribe();

    if (!this.fControl) {
      return;
    }

    this.controlSub = this.fControl.valueChanges
      .pipe(
        tap((value) => {
          this.applyAutoFormat(value);
          this.cdr.detectChanges();
        })
      )
      .subscribe();
  }

  private controlUnsubscribe() {
    if (this.controlSub) {
      this.controlSub.unsubscribe();
    }
  }

  private applyAutoFormat(value: string) {
    if (!this.fControl || !this.autoFormat) {
      return;
    }
  }

  keydown(event: KeyboardEvent) {
    if (event.code === 'Escape') {
      this.keydownEsc.emit();
    } else if (event.code === 'Enter') {
      this.keydownEnter.emit();
    } else if (event.code === 'Tab') {
      this.keydownTab.emit(event);
    }
  }

  onFocus(event: FocusEvent) {
    if (this.autoSelect) {
      (event.target as HTMLInputElement).select();
    }

    this.focusEvent.emit();
  }
}
