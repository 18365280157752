import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PipesModule } from '@inaripro-nx/common-ui';
import {
  CardQrCodeComponent,
  ModalWindowComponent,
  SearchComponent,
  TabsComponent,
} from './components';
import { ButtonComponent } from './components/button/button.component';
import { CardFigureComponent } from './components/card-figure/card-figure.component';
import { CardFilterComponent } from './components/card-filter/card-filter.component';
import { CardObjectComponent } from './components/card-object/card-object.component';
import { CardPictureComponent } from './components/card-picture/card-picture.component';
import { CardTemplateComponent } from './components/card-template/card-template.component';
import { CardTextComponent } from './components/card-text/card-text.component';
import { ColorButtonCircleComponent } from './components/color-button-circle/color-button-circle.component';
import { ColorButtonComponent } from './components/color-button/color-button.component';
import { ColorPickerComponent } from './components/colorpicker/colorpicker.component';
import { ColorPickerPageComponent } from './components/colorpicker/components/color-picker-page/color-picker-page.component';
import { ColorsPageComponent } from './components/colors-page/colors-page.component';
import { DesignLightGalleryContentNewComponent } from './components/design-light-gallery-content-new/design-light-gallery-content-new.component';
import { FileUploaderComponent } from './components/file-uploader/file-uploader.component';
import { FormInputStringComponent } from './components/form-input-string/form-input-string.component';
import { HelperWindowComponent } from './components/helper-window/helper-window.component';
import { IconsPageComponent } from './components/icons-page/icons-page.component';
import { DesignInputPlusMinusComponent } from './components/input-plus-minus/input-plus-minus.component';
import { PreloaderComponent } from './components/preloader/preloader.component';
import { SelectComponent } from './components/select/select.component';
import { UploaderContentComponent } from './components/uploader-content/uploader-content.component';
import { RangeSliderWrapperComponent } from './components/range-slider-wrapper/range-slider-wrapper.component';
import { FormInputNumberComponent } from './components/form-input-number/form-input-number.component';

@NgModule({
  imports: [
    CommonModule,
    ColorButtonComponent,
    ColorButtonCircleComponent,
    ColorsPageComponent,
    ColorPickerComponent,
    ColorPickerPageComponent,
    CardFigureComponent,
    CardFilterComponent,
    CardObjectComponent,
    CardPictureComponent,
    CardQrCodeComponent,
    CardTemplateComponent,
    CardTextComponent,
    RangeSliderWrapperComponent,
    SelectComponent,
    IconsPageComponent,
    UploaderContentComponent,
    ModalWindowComponent,
    FileUploaderComponent,
    PipesModule,
    PreloaderComponent,
    SearchComponent,
    FormInputStringComponent,
    HelperWindowComponent,
    TabsComponent,
    DesignInputPlusMinusComponent,
    DesignLightGalleryContentNewComponent,
    FormInputNumberComponent,
  ],
  declarations: [ButtonComponent],
  exports: [
    RangeSliderWrapperComponent,
    CardPictureComponent,
    CardFigureComponent,
    CardQrCodeComponent,
    CardObjectComponent,
    CardTextComponent,
    CardTemplateComponent,
    CardFilterComponent,
    ColorPickerComponent,
    ColorPickerPageComponent,
    SelectComponent,
    ColorButtonComponent,
    ColorButtonCircleComponent,
    UploaderContentComponent,
    ModalWindowComponent,
    FileUploaderComponent,
    PreloaderComponent,
    SearchComponent,
    FormInputStringComponent,
    HelperWindowComponent,
    TabsComponent,
    DesignInputPlusMinusComponent,
    DesignLightGalleryContentNewComponent,
    FormInputNumberComponent,
  ],
})
export class DesignUiModule {}
