@if (videos.length > 0) { @if (!currentVideo) {
<div class="light-gallery-content-new__video-button-play" (click)="getVideo(0)">
  <i class="icon icon-ic_play"></i>
</div>
} @else {

<div class="light-gallery-content-new__video-wrap wide">
  <div
    class="light-gallery-content-new__video-button-close"
    (click)="closeVideo()"
  >
    <i class="icon icon-ic_close"></i>
  </div>

  <div class="light-gallery-content-new__preloader">
    <design-preloader></design-preloader>
  </div>

  <div
    class="light-gallery-content-new__video"
    [class.light-gallery-content-new__video--shorts]="currentVideo.isShorts"
    [class.light-gallery-content-new__video--shorts-multi]="
      currentVideo.isShorts && videos.length > 1
    "
  >
    <design-video-player
      [url]="currentVideo.url"
      [autoplay]="true"
      [height]="currentVideo.isShorts ? '100%' : 360"
    ></design-video-player>
  </div>

  @if (videos.length > 1) {
  <div class="light-gallery-content-new__video-thumbnails">
    @for (video of videos; track video; let i = $index) {
    <div
      class="light-gallery-content-new__video-thumbnail"
      (click)="getVideo(i)"
      [class.selected]="i === playIndex"
    >
      <design-video-player
        [url]="video"
        [height]="'100%'"
      ></design-video-player>

      <div class="overlay"></div>

      <div
        class="light-gallery-content-new__video-button light-gallery-content-new__video-button--sm"
      >
        <i class="icon icon-ic_play"></i>
      </div>
    </div>
    }
  </div>
  }
</div>
} }
