import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'roundNumber',
})
export class RoundNumberPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    const digits = !args || isNullOrUndefined(args[1]) ? 2 : args[1];
    const coeff = Math.pow(10, digits);

    return Math.round(value * coeff) / coeff;
  }
}

function isNullOrUndefined(value: any) {
  return value === null || value === undefined;
}
