import { HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  map,
  Observable,
  shareReplay,
  withLatestFrom,
} from 'rxjs';
import { IPicture } from '@inaripro-nx/common-ui';
import {
  MAX_FILE_IN_LIBRARY_AUTH_USER,
  MAX_FILE_IN_LIBRARY_NOAUTH_USER,
} from '../../../../constants';
import { AuthService } from '@inaripro-nx/auth';

@Injectable({
  providedIn: 'root',
})
export class PicturesService {
  readonly pictures$: BehaviorSubject<IPicture[]> = new BehaviorSubject<
    IPicture[]
  >([]);

  readonly userPictures$ = this.pictures$.pipe(
    map((items) =>
      items ? items.filter((item) => !item.markForDelete) : items
    ),
    shareReplay({ refCount: false, bufferSize: 1 })
  );

  readonly isMaxUserFilesLimit$ = this.userPictures$.pipe(
    withLatestFrom(this.authService.user$),
    map(([userPictures, user]) => {
      return (
        userPictures.length >=
        (user ? MAX_FILE_IN_LIBRARY_AUTH_USER : MAX_FILE_IN_LIBRARY_NOAUTH_USER)
      );
    }),
    shareReplay({ refCount: false, bufferSize: 1 })
  );

  uploadPicture?: (file: File) => Observable<HttpEvent<unknown>>;
  deletePicture: (id: number) => void = (id: number) => {};
  addPicture: (picture: IPicture) => void = (picture: IPicture) => {};

  constructor(private readonly authService: AuthService) {}
}
