<div class="content-text">
  <div class="content-text-button">
    <ng-container *ngTemplateOutlet="templateButtons"></ng-container>
  </div>
  <div class="simplebar-text">
    <div class="wrappertextscroll">
      <div class="wrapper" *ngFor="let element of (elements || []); let index = index">
        <design-card-text
          *ngIf="element.type === EElementType.text"
          [text]="element.text || ''"
          (selectObject)="selectObject(element, index)"
          (cardCloseEmit)="removeObject(index)"
        ></design-card-text>
      </div>
    </div>
  </div>
  <div class="content-text-button content-text-button__mobile">
    <ng-container *ngTemplateOutlet="templateButtons"></ng-container>
  </div>
</div>

<ng-template #templateButtons>
  <button class="g-btn-enter" type="button" (click)="newText.addObject()">
    <span><i class="icon icon-ic_cross-plus"></i>Добавить текст</span>
  </button>
  <div class="opacity-0">
    <design-card-text
      #newText
      [text]="'Привет\nдорогой\nдруг'"
      [needSVG]="true"
      (createObject)="onAddObject($event)"
    ></design-card-text>
  </div>
</ng-template>
