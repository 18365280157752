import { AbstractControl, ValidationErrors } from '@angular/forms';

function requiredAndNotEmpty(
  control: AbstractControl
): ValidationErrors | null {
  const val = control.value;

  if (
    (typeof val === 'string' && val.trim() === '') ||
    val == null ||
    val.length === 0
  ) {
    return { required: true };
  }

  return null;
}

export function ValidatorsRequired(message = 'Обязательное поле') {
  return (control: AbstractControl) => {
    const result = {
      message: requiredAndNotEmpty(control) !== null ? message : null,
    };

    return result.message ? result : null;
  };
}
