import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { OrderCartStore } from '@inaripro-nx/order';
import { map } from 'rxjs';
import { EObject, EPage } from '../../interfaces/main.interface';
import { MainStore } from '../../state/main/main.store';
import { ProductStore } from '../../state/product/product.store';

@Component({
  selector: 'painter-mobile-tabs',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './mobile-tabs.component.html',
  styleUrls: ['./mobile-tabs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MobileTabsComponent {
  readonly EPage = EPage;
  readonly EObject = EObject;

  readonly page$ = this.mainStore.page$;
  readonly object$ = this.mainStore.object$;

  readonly updatingPrice$ = this.productStore.updatingPrice$;
  readonly activeDesignSide$ = this.productStore.activeDesignSide$;

  readonly isAddButtonDisabled$ = this.orderStore.isAddButtonDisabled$;

  readonly isFillTab$ = this.productStore.activeDesignSide$.pipe(
    map((activeDesignSide) => {
      return (
        activeDesignSide?.hasColorPalette ||
        activeDesignSide?.zones.some((z) => z.hasColorPalette)
      );
    })
  );

  constructor(
    private readonly mainStore: MainStore,
    private readonly productStore: ProductStore,
    private readonly orderStore: OrderCartStore
  ) {}

  isActiveObjects(page: EPage | null) {
    return page === EPage.design || page === EPage.objects;
  }

  setPage(page: EPage) {
    this.mainStore.setPage({ page });
    this.mainStore.setHideActionsSubmenu({ hideActionsSubmenu: true });
  }

  setObject(object: EObject) {
    this.mainStore.setObject({ object });
  }

  public orderProduct(): void {
    this.productStore.orderProduct$.next();
  }
}
