<ng-container *ngIf="maxFiles > 1; else oneFile">
  <input
    #upload
    style="display: none"
    type="file"
    [attr.accept]="accept"
    [attr.multiple]="multiple"
    (change)="filesChange($event)"
    [disabled]="uploadProcess.uploading"
  />
</ng-container>
<ng-template #oneFile>
  <input
    #upload
    style="display: none"
    type="file"
    [attr.accept]="accept"
    (change)="filesChange($event)"
    [disabled]="uploadProcess.uploading"
  />
</ng-template>

<design-modal-window
  [uid]="modalUid"
  [prefix]="'content_pictures_file_uploader'"
  (selfClose)="cancelUpload()"
  [maxWidth]="700"
  [fullMode]="true"
>
  <div modal-header>
    <div class="modal-upload-picture-header">
      <span>Загрузка изображений</span>
    </div>
  </div>
  <div modal-body>
    <div class="modal-upload-picture-body">
      <div class="table">
        <div
          class="table-tr"
          [style.margin-bottom.px]="i >= maxSimultaneousUploads ? 0 : 20"
          *ngFor="
            let file of uploadProcess.files;
            let i = index;
            let lastIndex = last
          "
        >
          <ng-container *ngIf="uploadProcess.progress[i] as progress">
            <ng-container *ngIf="i < maxSimultaneousUploads">
              <div class="table-tr__nomistake">
                <div class="table-tr__nomistake-icon">
                  <i class="icon icon-ic_insert_drive_file"></i>
                </div>
                <div
                  class="table-tr__nomistake-filename"
                  [ngClass]="{
                    'table-tr__nomistake-filename-active': progress.success,
                    'table-tr__nomistake-filename-no-success': !progress.success && progress.error
                  }"
                >
                  {{ file.name }}
                </div>
                <div *ngIf="!progress.success" class="table-tr__nomistake-progress">
                  <span *ngIf="progress.cancel">отменен</span>
                  <span *ngIf="!progress.error && !progress.cancel"
                    >{{ progress.percent }} %</span
                  >
                </div>
                <div
                  class="table-tr__nomistake-removefile"
                  *ngIf="
                    !progress.success && !progress.error && !progress.cancel
                  "
                  (click)="progress.cancel = true"
                >
                  <i class="icon icon-ic_close"></i>
                </div>
              </div>

              <div 
                  class="table-tr__mistake" 
                  *ngIf="progress.error">
                <div class="table-tr__mistake-icon">
                  <i class="icon icon-ic_info"></i>
                </div>
                <div class="table-tr__mistake-texterror">
                  <p>{{ progress.error }}</p>
                </div>
              </div>
            </ng-container>

            <ng-container *ngIf="i >= maxSimultaneousUploads">
              <hr *ngIf="i === maxSimultaneousUploads" />
              <div class="table-tr__nomistake">
                <div class="table-tr__nomistake-icon">
                  <ng-container *ngIf="i === maxSimultaneousUploads; else empty">
                    <i class="icon icon-ic_insert_drive_file"></i>
                  </ng-container>
                  <ng-template #empty>
                    <div style="width: 24px">&nbsp;</div>
                  </ng-template>
                </div>
                <div class="table-tr__nomistake-filename">
                  {{ file.name }}
                </div>
              </div>

              <div
                class="table-tr__mistake"
                *ngIf="progress.error && lastIndex"
              >
                <div class="table-tr__mistake-icon">
                  <i class="icon icon-ic_info"></i>
                </div>
                <div class="table-tr__mistake-texterror">
                  <p>{{ progress.error }}</p>
                </div>
              </div>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
  <div modal-footer>
    <div class="modal-upload-picture-footer">
      <div class="buttons">
        <button
          class="g-btn-link button-upload"
          (click)="cancelUpload()"
          *ngIf="uploadProcess.uploading; else closeButton"
        >
          Отмена
        </button>
        <ng-template #closeButton>
          <button class="g-btn-link button-ok" (click)="cancelUpload()">
            OK
          </button>
        </ng-template>
      </div>
    </div>
  </div>
</design-modal-window>
