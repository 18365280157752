export interface IParseVideo {
  resource: EVideoResource;
  url: string;
  id: string;
  isShorts?: boolean;
}

export enum EVideoResource {
  YUOTUBE = 'YUOTUBE',
  VIMEO = 'VIMEO',
}
