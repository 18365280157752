<design-modal-window [uid]="modalUid" [maxWidth]="1150" (selfClose)="reset()" class="crop-modal">
  <div modal-header class="title">Обрезка изображения</div>
  <div modal-body class="body" *ngIf="modalOpened$ | async">
    <div class="actions">
      <div class="actions__list-container">
        <crop-crop-figure-list
          class="actions__list"
          [figureList]="figureList"
          [selectedFigureId]="selectedFigureForList.id || null"
          (selectFigure)="onSelectFigure($event)"
        ></crop-crop-figure-list>
      </div>

      <div class="actions__proportions">
        <div class="g-checkboxblue g-checkboxblue--with-text">
          <input id="crop-proportions" type="checkbox" [formControl]="saveProportionsControl" />
          <label for="crop-proportions">
            <span>Сохранять пропорции</span>
          </label>
        </div>
      </div>
    </div>

    <crop-container
      *ngIf="image"
      [imageUrl]="image"
      [imageWidth]="imageWidth"
      [imageHeight]="imageHeight"
      [selectedFigure]="selectedFigureForMask"
      [saveProportions]="!!saveProportionsControl.value"
      class="work-area"
    ></crop-container>
  </div>
  <div modal-footer class="footer">
    <button type="button" class="g-btn-link" (click)="onCancel()">
      Отмена
    </button>
    <button type="button" class="g-btn-link g-btn-link--red" (click)="onSave()">
      Сохранить
    </button>
  </div>
</design-modal-window>
