export const ValidationPatterns = {
  COMMON: {
    EMAIL:
      '[_+A-Za-z0-9А-ЯЁа-яё\\-]+(\\.[_+A-Za-z0-9А-ЯЁа-яё\\-]+)*@[A-Za-z0-9А-ЯЁа-яё\\-]+(\\.[A-Za-z0-9А-ЯЁа-яё\\-]+)*\\.[A-Za-zА-ЯЁа-яё]{2,63}',
    SEPARATED_EMAILS:
      '^([_+A-Za-z0-9А-ЯЁа-яё\\-]+(\\.[_+A-Za-z0-9А-ЯЁа-яё\\-]+)*@[A-Za-z0-9А-ЯЁа-яё\\-]+(\\.[A-Za-z0-9А-ЯЁа-яё\\-]+)*\\.[A-Za-zА-ЯЁа-яё]{2,63}(,\\s*?)?)+$',
    FIRSTNAME: '([А-ЯЁ][а-яё]+[-\\s]?){1,}',
    LASTNAME: '[А-ЯЁ]{1}[А-ЯЁа-яё\\-\\s]*',
    AUTH_NAME: '[А-ЯЁ]{1}[А-ЯЁа-яё\\-\\s]*',
    LOGIN: '([A-Za-z0-9_\\-\\.@])+',
    URL: '^((http|https)://)?[a-zа-яёЁ0-9]+([-_.]{1}[a-zа-яёЁ0-9]+)*\\.[a-zа-яёЁ]{2,63}(:[0-9]{1,5})?(/.*)?$',
    PASSWORD:
      '(?=^.{8,}$)(?!^.*\\s)((?=.*\\d)|(?=.*\\W+))(?![.\\n])(?=.*[A-Za-z]).*$',
    PHONE: '^(\\+7|8)([\\s\\-\\(\\)]*\\d{1}){10}$',
    PHONE_STRICT: '^\\+7\\s\\(\\d{3}\\)\\s\\d{3}-\\d{2}-\\d{2}$',
    NUMBER: '^[0-9]+$',
    DATE: '(19|20)[0-9]{2}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])',
    DATE_RUS: '(0[1-9]|[12][0-9]|3[01]).(0[1-9]|1[012]).(19|20)[0-9]{2}',
  },
  COMPANY: {
    NAME: '[^"\'«»]*',
  },
};

export const ValidationMasks = {
  COMMON: {
    BANK_ACCOUNT: fillArray(/\d/, 20),
    BIK: fillArray(/\d/, 9),
    INN: fillArray(/\d/, 12),
    KPP: fillArray(/\d/, 9),
    OGRN: fillArray(/\d/, 15),
    PHONE: ['+', '7', /[1-9]/].concat(fillArray(/\d/, 9)),
    DATE: [/\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/],
    DATE_RUS: [/\d/, /\d/, '.', /\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/],
  },
};

function fillArray(value: any, len: number) {
  const arr = [];
  for (let i = 0; i < len; i++) {
    arr.push(value);
  }
  return arr;
}

export const validateINN = (value: any, needlength: number) => {
  let checkResult, sum, mask10, mask12, cnt, rem, rem1, inn11, rem2;
  checkResult = false;
  if (parseInt(value, 10)) {
    sum = 0;
    mask10 = [31, 29, 23, 19, 17, 13, 7, 5, 3];
    mask12 = [41, 37, 31, 29, 23, 19, 17, 13, 7, 5, 3];
    if (value.length == 10 && (!needlength || needlength >= value.length)) {
      for (cnt = 0; cnt < 9; cnt++) {
        sum += value.substr(cnt, 1) * mask10[cnt];
      }
      rem = 11 - (sum % 11);
      if (rem > 9) {
        rem = 0;
      }
      checkResult = value.substr(9) == rem;
    } else if (
      value.length == 12 &&
      (!needlength || needlength == value.length)
    ) {
      for (cnt = 0; cnt < 10; cnt++) {
        sum += value.substr(cnt, 1) * mask12[cnt + 1];
      }
      rem1 = 11 - (sum % 11);
      if (rem1 > 9) {
        rem1 = 0;
      }
      inn11 = value.substr(0, 10) + rem1;
      sum = 0;
      for (cnt = 0; cnt < 11; cnt++) {
        sum += inn11.substr(cnt, 1) * mask12[cnt];
      }
      rem2 = 11 - (sum % 11);
      if (rem2 > 9) {
        rem2 = 0;
      }
      checkResult = value.substr(10) == rem1.toString() + rem2.toString();
    }
  }
  return checkResult;
};
