<painter-actions-element-scale
  [element]="element"
  [designSide]="designSide"
  [minValue]="minScalePercentage"
  [sliderTemplate]="sliderTemplate"
></painter-actions-element-scale>

<ng-template
  #sliderTemplate
  let-minValue="minValue"
  let-maxValue="maxValue"
  let-value="value"
  let-changeValue="changeValue"
  let-stopChange="stopChange"
  let-reset="reset"
>
  <design-range-slider-wrapper
    [label]="'Размер'"
    [minValue]="minValue"
    [maxValue]="maxValue"
    [value]="value"
    [decimalPoints]="DECIMAL_POINTS_SCALE"
    (changeValue)="changeValue($event)"
    (stopChange)="stopChange()"
    (resetValue)="reset()"
  ></design-range-slider-wrapper>
</ng-template>
