<ng-container *ngIf="moscowContacts.length > 0">
  <div class="title">Офисы в Москве</div>
  <ul class="contacts-list">
    <li class="contacts-list__item" *ngFor="let contact of moscowContacts">
      <ng-container
        [ngTemplateOutlet]="contactLink"
        [ngTemplateOutletContext]="{ contact: contact }"
      ></ng-container>
    </li>
  </ul>
</ng-container>

<ng-container *ngIf="piterContacts.length > 0">
  <div class="title">Офисы в Санкт-Петербурге</div>
  <ul class="contacts-list">
    <li class="contacts-list__item" *ngFor="let contact of piterContacts">
      <ng-container
        [ngTemplateOutlet]="contactLink"
        [ngTemplateOutletContext]="{ contact: contact }"
      ></ng-container>
    </li>
  </ul>
</ng-container>

<ng-container *ngIf="contacts.length > 0">
  <div class="title">Наши Филиалы</div>
  <ul class="contacts-list">
    <li class="contacts-list__item" *ngFor="let contact of contacts">
      <ng-container
        [ngTemplateOutlet]="contactLink"
        [ngTemplateOutletContext]="{ contact: contact }"
      ></ng-container>
    </li>
  </ul>
</ng-container>

<ng-template #contactLink let-contact="contact">
  <shell-header-link
    *ngIf="contact?.isHeader"
    class="contacts-list__link"
    [link]="contact.slug ? '/contacts/' + contact.slug : null"
    [externalLink]="contact.externalLink ? contact.externalLink : null"
    [name]="contact.name + (contact.phone || '')"
  ></shell-header-link>
</ng-template>
