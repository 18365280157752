import {
  UserMatchTypeEnum,
  UserPermissionEnum,
  UserRoleEnum,
} from './enums/user.enum';
import { IUser, IUserCity, IUserData } from './interfaces';

export class User implements IUser {
  id: number;
  enabled: boolean;
  createDate: string;
  actionDate: string;
  data: IUserData;
  role: UserRoleEnum;
  companyId?: string;
  companyName?: string;
  permissions?: UserPermissionEnum[];
  matchType?: UserMatchTypeEnum;
  editCalculators?: boolean;
  editCatalog?: boolean;
  city?: IUserCity;
  filial?: IUserCity;

  get isPartner(): boolean {
    return this.role === UserRoleEnum.Partner;
  }

  get isCustomer(): boolean {
    return this.role === UserRoleEnum.Customer;
  }

  get isAdministrator(): boolean {
    return this.role === UserRoleEnum.Administrator;
  }

  get isModerator(): boolean {
    return this.role === UserRoleEnum.Moderator;
  }

  get accessCalculators(): boolean {
    return !this.matchType && !!this.editCalculators;
  }

  get accessCatalog(): boolean {
    return !this.matchType && !!this.editCatalog;
  }

  constructor(user: IUser) {
    this.id = user.id;
    this.data = user.data;
    this.role = user.role;
    this.enabled = user.enabled;
    this.createDate = user.createDate;
    this.actionDate = user.actionDate;

    if (user.companyId) {
      this.companyId = user.companyId;
    }

    if (user.companyName) {
      this.companyName = user.companyName;
    }

    if (user.permissions) {
      this.permissions = user.permissions;
    }

    if (user.matchType) {
      this.matchType = user.matchType;
    }

    if (user.editCalculators) {
      this.editCalculators = user.editCalculators;
    }

    if (user.editCatalog) {
      this.editCatalog = user.editCatalog;
    }

    if (user.city) {
      this.city = user.city;
    }

    if (user.filial) {
      this.filial = user.filial;
    }
  }
}
