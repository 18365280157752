import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Inject,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {
  AppShellConfig,
  AppShellLinkTypeEnum,
  WINDOW,
} from '@inaripro-nx/common-ui';
import { DesignUiModule, SearchComponent } from '@inaripro-nx/design-ui';

@Component({
  selector: 'shell-header-catalog-search',
  standalone: true,
  imports: [CommonModule, DesignUiModule],
  templateUrl: './header-catalog-search.component.html',
  styleUrls: ['./header-catalog-search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderCatalogSearchComponent {
  @Input() config: AppShellConfig | null = null;
  @Input() externalFormControl: FormControl = new FormControl();

  @Output() searchFormClosed: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild('inariDesignSearch', { static: false }) inariDesignSearch:
    | SearchComponent
    | undefined;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    @Inject(WINDOW) private window: Window
  ) {}

  search(query: string) {
    if (!this.config) {
      return;
    }

    const queryParams = {
      query,
    };

    if (this.config.publicLinkType === AppShellLinkTypeEnum.Relative) {
      this.router.navigate(['/catalog', 'search'], {
        queryParams,
        relativeTo: this.route.parent,
      });
    } else {
      this.window.location = `${this.config?.publicUrl}/catalog/search?query=${query}`;
    }
  }

  closeSearchForm() {
    this.searchFormClosed.emit();
  }
}
