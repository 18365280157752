import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'painter-actions-button',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './actions-button.component.html',
  styleUrls: ['./actions-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActionsButtonComponent {
  @Input() disabled = false;
}
