import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IPublicProductForList } from '../../interfaces';

@Component({
  selector: 'catalog-product-card-prices',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './catalog-product-card-prices.component.html',
  styleUrls: ['./catalog-product-card-prices.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CatalogProductCardPricesComponent {
  private _product!: IPublicProductForList;

  get product(): IPublicProductForList {
    return this._product;
  }

  @Input() set product(productData: IPublicProductForList) {
    this._product = productData;

    this.showDiscount =
      productData.discountPrice !== null &&
      productData.discountPrice !== undefined &&
      productData.discountPrice !== productData.basePrice;
  }

  @Input() unitName!: string;

  showDiscount = false;
}
