import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  Output,
  Renderer2,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { WINDOW } from '@inaripro-nx/common-ui';
import { PreloaderComponent } from '../../../preloader/preloader.component';
import { IGalleryImage } from '../../interfaces/design-light-gallery-content-new.interface';
import { isDesktopMode } from '../../utils/image-utils';
import { DesignLightGalleryContentNewArrowsThumbnailsComponent } from '../design-light-gallery-content-new-arrows-thumbnails/design-light-gallery-content-new-arrows-thumbnails.component';
import { DesignLightGalleryImageListDirective } from './directives/design-light-gallery-image-list.directive';
import { DesignLightGalleryImageDirective } from './directives/design-light-gallery-image.directive';

@Component({
  selector: 'design-light-gallery-content-new-image',
  standalone: true,
  templateUrl: './design-light-gallery-content-new-image.component.html',
  styleUrls: ['./design-light-gallery-content-new-image.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    PreloaderComponent,
    DesignLightGalleryImageDirective,
    DesignLightGalleryImageListDirective,
    DesignLightGalleryContentNewArrowsThumbnailsComponent,
  ],
})
export class DesignLightGalleryContentNewImageComponent
  implements OnChanges, AfterViewInit
{
  @Input() images: IGalleryImage[] = [];
  @Input() selectedIndex = 0;
  @Input() previewEnabled = true;

  @Output() activeChanged: EventEmitter<number> = new EventEmitter<number>();
  @Output() clicked: EventEmitter<number> = new EventEmitter<number>();

  @ViewChild('imageList', { static: false }) imageList?: ElementRef;

  private _imageGallery: IGalleryImage[] = [];
  get imageGallery(): IGalleryImage[] {
    return this._imageGallery;
  }

  private _currentIndex = 0;
  get currentIndex(): number {
    return this._currentIndex;
  }

  get description() {
    return this._imageGallery && this._imageGallery[this._currentIndex]
      ? this._imageGallery[this._currentIndex].description
      : null;
  }

  get showOverflow(): boolean {
    return this.previewEnabled && isDesktopMode(this.window);
  }

  constructor(
    private renderer: Renderer2,
    @Inject(WINDOW) private window: Window
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['selectedIndex']) {
      this._currentIndex = this.selectedIndex + 1;
    }

    if (changes['images']) {
      const imagesLength = this.images.length;
      this._imageGallery = [
        this.images[imagesLength],
        ...this.images,
        this.images[0],
      ];
    }

    const selectedIndexChangedAfterInit =
      changes['selectedIndex'] && !changes['selectedIndex'].firstChange;
    const imageChangedAfterInit =
      changes['images'] && !changes['images'].firstChange;

    if (selectedIndexChangedAfterInit || imageChangedAfterInit) {
      window.setTimeout(() => {
        this.preloadImagesByIndex(this._currentIndex, this._imageGallery);
      });
    }
  }

  ngAfterViewInit() {
    this.preloadImagesByIndex(this._currentIndex, this._imageGallery);
  }

  public handleClick(event: Event, i: number): void {
    event.preventDefault();
    event.stopPropagation();

    this.clicked.emit(i);
  }

  public showNext(): void {
    this._currentIndex++;

    if (this._currentIndex > this._imageGallery.length - 2) {
      this._currentIndex = 1;
    }

    this.activeChanged.emit(this._currentIndex - 1);
  }

  public showPrev(): void {
    this._currentIndex--;

    if (this._currentIndex < 1) {
      this._currentIndex = this._imageGallery.length - 2;
    }

    this.activeChanged.emit(this._currentIndex - 1);
  }

  public trackByFn(index: number, item: IGalleryImage) {
    return item ? `${item.id}-${index}` : `${index}`;
  }

  private lazyLoadImage(index: number, images: IGalleryImage[]): void {
    if (
      !images.length ||
      !images[index] ||
      !images[index].medium ||
      !this.imageList ||
      !this.imageList.nativeElement.children.length ||
      !this.imageList.nativeElement.children[index]
    ) {
      return;
    }

    const imageUrl = images[index].medium;
    const largeImageEl = this.renderer.createElement('img');
    const div = this.imageList.nativeElement.children[index];
    const imageContainer = div.children[0];

    if (imageUrl) {
      this.renderer.setAttribute(largeImageEl, 'src', imageUrl.toString());
      this.renderer.setAttribute(largeImageEl, 'itemprop', 'image');
    }

    const { alt, title } = (this.images && this.images[index]) || {
      alt: null,
      title: null,
    };

    if (alt) {
      this.renderer.setAttribute(largeImageEl, 'alt', alt);
    }

    if (title) {
      this.renderer.setAttribute(largeImageEl, 'title', title);
    }

    this.renderer.listen(largeImageEl, 'load', (event) => {
      imageContainer.classList.remove('loaded');
      imageContainer.innerHTML = '';

      this.renderer.appendChild(imageContainer, largeImageEl);
      this.renderer.addClass(imageContainer, 'loaded');
    });
  }

  private preloadImagesByIndex(index: number, images: IGalleryImage[]) {
    this.lazyLoadImage(index - 1, images);
    this.lazyLoadImage(index, images);
    this.lazyLoadImage(index + 1, images);
  }
}
