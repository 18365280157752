<svg:g>
  @if (el.textArc && el.textArc.angle) {
  <defs>
    <path
      [attr.d]="el.textArc | svgCirclePath"
      [attr.id]="'textCircle' + index"
    />
  </defs>
  <text
    x="0"
    y="0"
    [attr.font-style]="el.fontStyle"
    [attr.font-weight]="el.fontWeight"
    [attr.font-family]="el.fontFamily | fontFamily"
    [attr.font-size]="DEFAULT_TEXT_SIZE"
    [attr.letter-spacing]="el.letterSpacing"
    [attr.fill]="'inherit'"
    [attr.transform]="el.textTranslate | svgTranslate"
  >
    <textPath [attr.href]="'#textCircle' + index">
      {{ el.text }}
    </textPath>
  </text>
  } @else {
  <svg:text
    x="0"
    y="0"
    dy="0"
    [attr.font-size]="DEFAULT_TEXT_SIZE"
    [attr.text-anchor]="
      el.textAnchor === ITextAnchor.stertch ? null : el.textAnchor
    "
    [attr.transform]="el.textTranslate | svgTranslate"
    [attr.letter-spacing]="el.letterSpacing"
  >
    @for (line of el.text.split('\n'); track $index; let i = $index) {
    <tspan
      x="0"
      [attr.dy]="(i === 0 ? 1 : el.lineSpace) + 'em'"
      [attr.font-style]="el.fontStyle"
      [attr.font-weight]="el.fontWeight"
      [attr.textLength]="
        el.textAnchor === ITextAnchor.stertch ? el.textLength : null
      "
      [attr.font-family]="el.fontFamily | fontFamily"
      [innerHTML]="line || '.' | safeHtml"
      [attr.fill]="line ? 'inherit' : 'none'"
    ></tspan>
    }
  </svg:text>
  }
</svg:g>
