import { Injectable } from '@angular/core';
import { IPattern, IPatternLibrary } from '@inaripro-nx/common-ui';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PatternsService {
  libraries$!: Observable<IPatternLibrary[]>;
  currentId$!: Observable<number>;
  currentLibrary$!: Observable<IPatternLibrary | null>;
  currentPatterns$!: Observable<IPattern[] | null>;

  getPatterns!: (id: number) => void;
}
