<div class="content-layers" (click)="onselect(null)">
  <ng-container
    *ngFor="let side of activeDesignProduct?.sides; let sideIndex = index"
  >
    <div
      #card
      [attr.data-element-index]="-1"
      [attr.data-side-index]="sideIndex"
      class="card-side"
      [class.card-side--active]="openedSides[sideIndex]"
      [class.card-side--dragenter]="dragActiveSide === sideIndex"
      (dragenter)="dragenter(-1, sideIndex)"
      (dragleave)="dragActiveSide = -1"
      (click)="
        openedSides[sideIndex] = !openedSides[sideIndex];
        $event.stopPropagation()
      "
    >
      <div class="card-side-icon">
        <i
          class="icon icon-ic_keyboard_arrow_right"
          *ngIf="!openedSides[sideIndex]"
        ></i>
        <i
          class="icon icon-ic_keyboard_arrow_down"
          *ngIf="openedSides[sideIndex]"
        ></i>
      </div>
      <div class="card-side-name">{{ side.name }}</div>
    </div>
    <div
      *ngFor="let element of copyElements; let i = index; let l = count"
      #card
      [attr.data-element-index]="i"
      [attr.data-side-index]="sideIndex"
    >
      <ng-container
        *ngIf="openedSides[sideIndex] && element.sideIndex === sideIndex"
      >
        <design-card-object
          *ngIf="{ originalIndex: l - 1 - i } as card"
          [isActive]="card.originalIndex === activeElementIndex"
          [isBeforeInsert]="
            draggableIndex === -1 &&
            draggableSideIndex === sideIndex &&
            getElementIndexBySideIndex(element) === 0
          "
          [isAfterInsert]="i === draggableIndex && i !== dragStartIndex"
          [element]="element"
          (onselect)="onselect(card.originalIndex)"
          (oncopy)="oncopy(card.originalIndex)"
          (onremove)="onremove(card.originalIndex)"
          (ondragstart)="ondragstart(i, sideIndex)"
          (ondragend)="ondragend()"
          (ondragenter)="dragenter(i, sideIndex)"
          (ontouchmove)="ontouchmove($event)"
        ></design-card-object>
      </ng-container>
    </div>
  </ng-container>
</div>
