export interface IBackendErrorWrapper {
    error: IBackendError[];
}

export interface IBackendError {
    [id: string]: IBackendErrorType[];
}

export interface IBackendTranslateError {
    [id: string]: {
        [id: string]: string
    };
}

export type IBackendErrorType =
    // Ошибка уникального значения
    // Поле с датой уникально
    'DUPLICATED' |
    // Обязательное поле
    'REQUIRED' |
    // Поле не может быть пустым
    'IS_NULL' |
    // Любое не корректное значение
    // Не верное значение для поля (Есть список одобренных констант)
    // Не верное значение
    // Ожидался список
    // Ожидался объект
    'INVALID_VALUE' |
    // Поле не может быть не заполненным ("" - тоже не пройдет)
    'IS_BLANK' |
    // Превышена макс длина
    'MAX_LENGTH' |
    // Наоборот
    'MIN_LENGTH' |
    // Ожидается время и дата, пришло что-то другое
    // Пришло время без таймзоны
    // Ожидается Дата, а пришло datetime
    'NOT_DATE' |
    // Ошибка имени файла
    'NO_NAME' |
    // Для забаненного пользователя
    'USER_BANNED' |
    // Возможные значения ошибок captchaValue
    // Гугл не ответил в течении отведенного времени
    'TIMEOUT' |
    // Любая другая ошибка при запросе к гуглу
    'THIRD_PARTY_SERVICE_ERRORS' |
    // поьзователь не найден
    'NOT_FOUND' |
    // лимит запросов к dadata
    'LIMIT_EXCEEDED';

export const BACKEND_ERROR_TYPES = {
    DUPLICATED: 'DUPLICATED' as IBackendErrorType,
    REQUIRED: 'REQUIRED' as IBackendErrorType,
    IS_NULL: 'IS_NULL' as IBackendErrorType,
    INVALID_VALUE: 'INVALID_VALUE' as IBackendErrorType,
    IS_BLANK: 'IS_BLANK' as IBackendErrorType,
    MAX_LENGTH: 'MAX_LENGTH' as IBackendErrorType,
    MIN_LENGTH: 'MIN_LENGTH' as IBackendErrorType,
    NOT_DATE: 'NOT_DATE' as IBackendErrorType,
    NO_NAME: 'NO_NAME' as IBackendErrorType,
    USER_BANNED: 'USER_BANNED' as IBackendErrorType,
    TIMEOUT: 'TIMEOUT' as IBackendErrorType,
    THIRD_PARTY_SERVICE_ERRORS: 'THIRD_PARTY_SERVICE_ERRORS' as IBackendErrorType,
    NOT_FOUND: 'NOT_FOUND' as IBackendErrorType,
    LIMIT_EXCEEDED: 'LIMIT_EXCEEDED' as IBackendErrorType,
    AGREEMENT_NOT_ACCEPTED: 'AGREEMENT_NOT_ACCEPTED' as IBackendErrorType,
};

