<design-colorpicker
  [inColor]="element.fill"
  (changeModel)="editorService.fill($event || ELEMENT_COLOR_DEFAULT)"
  (changeEnd)="editorService.stopFill()"
  [eyeColor]="eyeDropperService.eyeColor$ | async"
  (startEye)="eyeDropperService.startEye($event)"
  [isDesktop]="isDesktop$ | async"
  [withModal]="withModal"
  [resizeSafari$]="actionsTextService.resizeSafari$"
  [modalUid]="ELEMENT_FILL_MODAL_UID"
  [modalTitle]="modalTitle"
  [maxWidthModal]="maxWidthModal"
  [prefix]="prefix"
  [useColors]="useColors$ | async"
  [colorDefault]="ELEMENT_COLOR_DEFAULT"
>
  <ng-content></ng-content>
</design-colorpicker>
