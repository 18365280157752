<ul class="tree" *ngIf="dictionary && treeItems">
  <li
    class="tree__el"
    *ngFor="let item of treeItemsSortedAndFiltered; trackBy: trackByFn"
  >
    <div
      class="tree__title"
      [class.tree__title--active]="expandedMap[item.id] || selectedItemId === item.id"
      [class.tree__title--partners]="dictionary[item.dictionaryId].isPartner"
      (mousedown)="toggleExpanded($event, item)"
    >
      <ng-container
        [ngTemplateOutlet]="treeItemTemplate || baseTreeItemTemplate"
        [ngTemplateOutletContext]="{ item: item, dictionary: dictionary }"
      ></ng-container>
    </div>

    <catalog-tree
      *ngIf="item.children && item.children.length"
      [hidden]="!expandedMap[item.id]"
      [treeItems]="item.children"
      [filterCatalogNomenclatureTreeItem]="filterCatalogNomenclatureTreeItem"
      [dictionary]="dictionary"
      [expandedMap]="expandedMap"
      [selectedItemId]="selectedItemId"
      (selectItem)="select($event)"
    >
    </catalog-tree>
  </li>
</ul>

<ng-template #baseTreeItemTemplate let-item="item" let-dictionary="dictionary">
  <ng-container *ngIf="item.children && !item.canAddProduct">
    <span
      class="expand-btn"
      [class.expand-btn--expanded]="expandedMap[item.id]"
    >
    </span>
  </ng-container>

  <ng-container *ngIf="dictionary[item.dictionaryId].isPartner">
    <span class="tree__icon--partners">
      <i class="icon icon-partners"></i>
    </span>
  </ng-container>

  <span
    class="tree__name"
    [class.tree__name--partners]="dictionary[item.dictionaryId].isPartner"
    (mousedown)="select(item)"
  >
    {{ dictionary[item.dictionaryId].name }}
  </span>
</ng-template>
