import { formatNumber } from '@angular/common';
import { APP_LOCALE } from '../constants';

export function appFormatNumber(
  value: number,
  locale = APP_LOCALE,
  digitsInfo = '1.0-2'
) {
  return formatNumber(value, locale, digitsInfo);
}
