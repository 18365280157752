import { DOCUMENT } from '@angular/common';
import {
  AfterViewInit,
  Directive,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  Output,
  Renderer2,
} from '@angular/core';
import { filterSingleTouch } from '@inaripro-nx/common-ui';
import { fromEvent, Subscription } from 'rxjs';
import { map, switchMap, takeUntil, tap, withLatestFrom } from 'rxjs/operators';
import { DesignLightGalleryImageService } from '../services/design-light-gallery-image.service';

const THRESHOLD = 60;
const ZOOM_IN_THRESHOLD = 120;
const GAP = 20;
const ANIMATION_DURATION = 200;

@Directive({
  selector: '[designLightGalleryImageList]',
  standalone: true,
})
export class DesignLightGalleryImageListDirective
  implements AfterViewInit, OnDestroy
{
  @Input() currentIndex = 0;

  @Output() prev = new EventEmitter();
  @Output() next = new EventEmitter();

  private _subs: Subscription[] = [];
  set subs(sub: Subscription) {
    this._subs.push(sub);
  }

  private animationTimeout: null | ReturnType<typeof setTimeout> = null;
  private animated = false;

  private readonly touchStart$ = fromEvent<TouchEvent>(
    this.elementRef.nativeElement,
    'touchstart'
  ).pipe(filterSingleTouch());

  private readonly touchEnd$ = fromEvent<TouchEvent>(this.document, 'touchend');

  private readonly touchMove$ = fromEvent<TouchEvent>(
    this.elementRef.nativeElement,
    'touchmove'
  ).pipe(filterSingleTouch());

  private readonly dragging$ = this.touchStart$.pipe(
    tap(() => this.imageService.setOffsetX(0)),
    switchMap(({ touches: startTouches }) => {
      return this.touchMove$.pipe(
        map(({ touches: moveTouches }) => {
          return {
            offsetX: moveTouches[0].clientX - startTouches[0].clientX,
            moveX: moveTouches[0].clientX,
          };
        }),
        takeUntil(this.touchEnd$)
      );
    })
  );

  constructor(
    private readonly elementRef: ElementRef,
    private readonly renderer: Renderer2,
    @Inject(DOCUMENT) private readonly document: Document,
    private readonly imageService: DesignLightGalleryImageService
  ) {}

  ngAfterViewInit() {
    this.initDraggingSubscription();
    this.initDragEndSubscription();
  }

  ngOnDestroy(): void {
    this._subs.forEach((s) => s.unsubscribe());

    if (this.animationTimeout) {
      clearTimeout(this.animationTimeout);
    }
  }

  private initDraggingSubscription() {
    let newStartX = 0;

    this.subs = this.dragging$
      .pipe(
        withLatestFrom(this.imageService.imageData$),
        map(([{ offsetX, moveX }, { x, scale, maxX }]) => {
          if (scale === 1) {
            newStartX = 0;
            return offsetX;
          }

          if ((offsetX > 0 && x < maxX) || (offsetX < 0 && x > -maxX)) {
            newStartX = 0;
            return 0;
          }

          if (offsetX > 0 && x >= maxX) {
            newStartX = !newStartX ? moveX : Math.min(newStartX, moveX);
            return moveX - newStartX;
          }

          if (offsetX < 0 && x <= -maxX) {
            newStartX = !newStartX ? moveX : Math.max(newStartX, moveX);
            return moveX - newStartX;
          }

          newStartX = 0;
          return offsetX;
        })
      )
      .subscribe((offsetX) => {
        if (!this.animated) {
          this.imageService.setOffsetX(offsetX);
          this.updateElementStyle(offsetX);
        }
      });
  }

  private initDragEndSubscription() {
    this.subs = this.touchEnd$
      .pipe(
        withLatestFrom(
          this.imageService.offsetX$,
          this.imageService.imageData$
        ),
        map(([, offsetX, { x, scale, maxX }]) => {
          return {
            offsetX,
            scale,
            canSwipeRight: scale === 1 ? true : x <= -maxX,
            canSwipeLeft: scale === 1 ? true : x >= maxX,
          };
        })
      )
      .subscribe(({ offsetX, scale, canSwipeRight, canSwipeLeft }) => {
        this.handleDragEnd(offsetX, scale, canSwipeRight, canSwipeLeft);
      });
  }

  private handleDragEnd(
    offsetX: number,
    scale: number,
    canSwipeRight: boolean,
    canSwipeLeft: boolean
  ) {
    const width = this.elementRef.nativeElement.clientWidth;

    if (canSwipeLeft && offsetX > (scale > 1 ? ZOOM_IN_THRESHOLD : THRESHOLD)) {
      this.animateTranslateX(width + GAP, () => {
        this.imageService.setOffsetX(0);
        this.imageService.setImageData({
          x: 0,
          y: 0,
          scale: 1,
          maxX: 0,
          maxY: 0,
        });

        this.updateElementStyle(0);

        this.prev.emit();
      });
    } else if (canSwipeRight && offsetX < -THRESHOLD) {
      this.animateTranslateX(-width - GAP, () => {
        this.imageService.setOffsetX(0);
        this.imageService.setImageData({
          x: 0,
          y: 0,
          scale: 1,
          maxX: 0,
          maxY: 0,
        });

        this.updateElementStyle(0);
        this.next.emit();
      });
    } else if (offsetX !== 0) {
      this.animateTranslateX(0, () => {
        this.imageService.setOffsetX(0);
      });
    }
  }

  private animateTranslateX(
    offsetX: number,
    animationEndCallback?: () => void
  ) {
    if (this.animationTimeout) {
      clearTimeout(this.animationTimeout);
    }

    this.animated = true;
    this.renderer.setStyle(
      this.elementRef.nativeElement,
      'transition',
      `transform ${ANIMATION_DURATION - 30}ms`
    );
    this.updateElementStyle(offsetX);

    this.animationTimeout = setTimeout(() => {
      this.renderer.removeStyle(this.elementRef.nativeElement, 'transition');

      if (animationEndCallback) {
        animationEndCallback();
      }

      this.animated = false;
    }, ANIMATION_DURATION + 30);
  }

  private updateElementStyle(x: number) {
    this.renderer.setStyle(
      this.elementRef.nativeElement,
      'transform',
      `translateX(${x}px)`
    );
  }
}
