export interface IListResponse<T = unknown> {
  data: T[];
  pageInfo: IPaginationResponse;
}

export interface IPaginationResponse {
  beginPage: number;
  endPage: number;
  hasNextPage: boolean;
  hasPrevPage: boolean;
  limit: number;
  offset: number;
  totalItems: number;
  totalPages: number;
  currentPage?: number;
}

export type PaginationOrderByType =
  | 'default'
  | 'name'
  | 'date'
  | 'useTotal'
  | 'name_sort'
  | 'price'
  | 'create_time'
  | 'popularity';

export type PaginationOrderDirectionType = 'asc' | 'desc';

export interface IPaginationRequest {
  offset: number;
  limit: number;
  order_by?: PaginationOrderByType;
  order_direction?: PaginationOrderDirectionType;
}
