<div
  *ngIf="controlValue !== null"
  class="range-slider-wrapper"
  [class.range-slider-wrapper__gradient-range-slider]="isGradientRangeSlider"
>
  @if (isGradientRangeSlider) {
    <design-range-gradient-slider
      [minValue]="minValue"
      [maxValue]="maxValue"
      [color]="color"
      [value]="controlValue"
      (changeValue)="onValueChange($event)"
      (stopChange)="onStopChange()"
    ></design-range-gradient-slider>
  } @else {
    <design-range-slider
      [label]="label"
      [minValue]="minValue"
      [maxValue]="maxValue"
      [value]="controlValue"
      (changeValue)="onValueChange($event)"
      (stopChange)="onStopChange()"
    ></design-range-slider>
    <div class="range-slider-wrapper__input" (click)="isAndroid ? resetValue.emit() : isFocused = true">
      <design-form-input-number
        *ngIf="isFocused"
        [fControl]="valueControl"
        [classWrapper]="'range-slider-form-group'"
        [classInput]="'range-slider-input'"
        [confirmMode]="true"
        [showAlert]="false"
        (finishInput)="onStopChange()"
      ></design-form-input-number>

      <div class="range-slider-wrapper__input-value" *ngIf="!isFocused">
        {{controlValue | number: '0.0-0'}}{{ unitmeasurement }}
      </div>
    </div>
  }
</div>
