<ng-container *ngIf="loading$ | async; else productListTemplate">
  <div class="loading"><ng-content></ng-content></div>
</ng-container>

<ng-template #productListTemplate>
  <div class="catalog-product-list">
    <catalog-product-card
      *ngFor="let product of productList$ | async"
      [product]="product"
      [isSelected]="product.id === selectedProductId"
      (click)="selectProduct.emit(product)"
    ></catalog-product-card>
  </div>
</ng-template>
