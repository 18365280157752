import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { PipesModule } from '@inaripro-nx/common-ui';

@Component({
  selector: 'crop-mask',
  standalone: true,
  template: '<div class="crop-mask" [innerHTML]="mask | safeHtml"></div>',
  styleUrls: ['./crop-mask.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, PipesModule],
})
export class CropMaskComponent {
  @Input() mask = '';
}
