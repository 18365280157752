import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';
import { IGalleryImage } from '../../interfaces/design-light-gallery-content-new.interface';
import { DesignLightGalleryContentNewSwipeService } from '../../services/design-light-gallery-content-new-swipe.service';
import { DesignLightGalleryContentNewArrowsComponent } from '../design-light-gallery-content-new-arrows/design-light-gallery-content-new-arrows.component';

@Component({
  selector: 'design-light-gallery-content-new-thumbnails-vertical',
  standalone: true,
  templateUrl:
    './design-light-gallery-content-new-thumbnails-vertical.component.html',
  styleUrls: [
    './design-light-gallery-content-new-thumbnails-vertical.component.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [DesignLightGalleryContentNewArrowsComponent],
  providers: [DesignLightGalleryContentNewSwipeService],
})
export class DesignLightGalleryContentNewThumbnailsVerticalComponent
  implements OnInit, OnChanges, AfterViewInit, OnDestroy
{
  @ViewChild('thumbnailVerticalRef', { static: false, read: ElementRef })
  thumbnailVerticalRef?: ElementRef;

  @Input() images: IGalleryImage[] = [];
  @Input() selectedIndex = 0;
  @Input() galleryHeight = 0;

  @Output() activeChanged = new EventEmitter();

  private _itemHeight = 94;
  private _itemMargin = 12;
  private _totalHeight = this.getTotalHeight();
  private _moveSize = this._itemHeight + this._itemMargin;

  thumbnailsTop = 0;
  canShowArrows = false;

  private _subs: Subscription[] = [];
  set subs(sub: Subscription) {
    this._subs.push(sub);
  }

  constructor(
    private cdr: ChangeDetectorRef,
    private elementRef: ElementRef,
    private swipeService: DesignLightGalleryContentNewSwipeService
  ) {}

  ngOnInit() {
    this.handleSwipe();
  }

  ngAfterViewInit() {
    this.thumbnailsTop = this.getNormalizedValue(
      (this._itemHeight + this._itemMargin) * this.selectedIndex
    );

    if (this.thumbnailVerticalRef?.nativeElement) {
      this.subs = fromEvent<WheelEvent>(
        this.thumbnailVerticalRef.nativeElement,
        'wheel'
      ).subscribe((e: WheelEvent) => {
        e.preventDefault();
        const delta = e.deltaY;
        if (delta > 0) {
          this.handleNextClick();
        } else if (delta < 0) {
          this.handlePrevClick();
        }
      });
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['galleryHeight'] || changes['images']) {
      this._totalHeight = this.getTotalHeight();
      this.canShowArrows = this._totalHeight > this.galleryHeight;
      this.thumbnailsTop = this.getNormalizedValue(this.thumbnailsTop);
    }
  }

  ngOnDestroy() {
    this._subs.forEach((s) => s.unsubscribe());
  }

  public handleClick(event: Event, index: number): void {
    event.stopPropagation();
    event.preventDefault();
    this.activeChanged.emit(index);
  }

  public handlePrevClick(): void {
    if (this.canMoveUp()) {
      this.moveUp();
    }
  }

  public handleNextClick(): void {
    if (this.canMoveDown()) {
      this.moveDown();
    }
  }

  public canMoveDown(): boolean {
    return this.thumbnailsTop > this.galleryHeight - this._totalHeight;
  }

  public canMoveUp(): boolean {
    return this.thumbnailsTop < 0;
  }

  private moveUp(): void {
    this.thumbnailsTop = this.getNormalizedValue(
      this.thumbnailsTop + this._moveSize
    );
    this.cdr.detectChanges();
  }

  private moveDown(): void {
    this.thumbnailsTop = this.getNormalizedValue(
      this.thumbnailsTop - this._moveSize
    );
    this.cdr.detectChanges();
  }

  private handleSwipe(): void {
    const swipeNext = () => this.handleNextClick();
    const swipePrev = () => this.handlePrevClick();

    this.swipeService.manageSwipe(
      this.elementRef,
      'thumbs',
      swipeNext,
      swipePrev
    );
  }

  private getTotalHeight() {
    return (
      (this._itemHeight + this._itemMargin) * this.images.length -
      this._itemMargin
    );
  }

  private getNormalizedValue(value: number) {
    const max = 0;
    const min = this.galleryHeight - this._totalHeight;
    return Math.min(Math.max(value, min), max);
  }
}
