<!-- https://github.com/zefoy/ngx-color-picker -->
<div class="color-box">
  <div class="design-color-box-wrapper">
    <ng-container *ngTemplateOutlet="colorMapTemplate"></ng-container>
  </div>

  <div class="color-box__info">
    <div class="color-box__picker" (click)="startEye.emit($event)">
      <i class="icon icon-ic_color-picker"></i>
    </div>
    <div class="color-box__wrapperline">
      <div
        class="color-box__transparent"
        *ngIf="hasTransparent"
        [ngStyle]="{
          'background-image': 'url(\'./assets/images/transparent2.png\')'
        }"
        (click)="selectColor(null)"
      ></div>
      <div
        class="color-box__wrapper"
        [class.color-box__wrapper-withtransparent]="hasTransparent"
        (click)="openModalChangeColor()"
      >
        <div
          class="color-box__preview"
          *ngIf="eyeColor || currentColor as color"
          [style.background]="color"
        ></div>
        <div
          class="color-box__preview"
          *ngIf="isTransparent"
          [ngStyle]="{
            'background-image': 'url(\'./assets/images/transparent2.png\')'
          }"
        ></div>
        <div class="color-box__form-element">
          <input
            type="text"
            [value]="
              currentColor ||
              (hasTransparent ? 'Без цвета' : BACKGROUND_COLOR_DEFAULT)
            "
            class="color-box__input"
            [class.color-box__input-trasparent]="isTransparent"
            [attr.readonly]="isTransparent || null"
            (change)="changeInput($event)"
            pattern="^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$"
            maxlength="7"
          />
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="useColors?.length" class="color-box_usecolors">
    <div class="color-box_usecolors-title">Используемые цвета</div>
    <div class="color-box_usecolors-wrapper">
      <div
        *ngFor="let color of useColors"
        class="color-box_usecolors-color"
        [ngStyle]="{ 'background-color': color }"
        (click)="selectColor(color)"
      ></div>
    </div>
  </div>
</div>

<ng-template #colorMapTemplate>
  <design-color-box
    [inputColorPicker]="currentColor || BACKGROUND_COLOR_DEFAULT"
    [resizeSafari$]="resizeSafari$"
    [modalUid]="modalUid"
    (changeColor)="changeColor($event)"
    (selectColor)="selectColor($event)"
  ></design-color-box>
</ng-template>

<design-modal-window
  [uid]="modalUid"
  [maxWidth]="290"
  [prefix]="'color-picker'"
  [isHideBackDropOnClick]="true"
  *ngIf="!this.isDesktop && this.withModal"
>
  <div modal-header>
    <span>{{ modalTitle }}</span>
  </div>
  <div modal-body>
    <ng-container *ngTemplateOutlet="colorMapTemplate"></ng-container>
  </div>
</design-modal-window>
