<design-modal-window
  [uid]="MODAL_REACH_FILE_LIMIT"
  [maxWidth]="500"
  [prefix]="'modal-reach-file-limit'"
  *ngIf="{
    user: user$ | async
  } as state"
>
  <div modal-header>Внимание!</div>
  <div modal-body>
    <ng-container *ngIf="state.user">
      <div class="text">
        {{ REACH_FILE_LIMIT_USER_TEXT }}
      </div>
    </ng-container>
    <ng-container *ngIf="!state.user">
      <div class="text">
        {{ REACH_FILE_LIMIT_NO_USER_SHORT_TEXT }} <a [attr.href]="loginUrl">Авторизуйтесь</a> или удалите ненужные
        файлы.
      </div>
    </ng-container>
  </div>
  <div modal-footer>
    <button type="button" class="g-btn-link" (click)="closeModal()">
      ОК
    </button>
  </div>
</design-modal-window>
