import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'painter-content-product-order-price',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './content-product-order-price.component.html',
  styleUrls: ['./content-product-order-price.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentProductOrderPriceComponent {
  @Input() totalPrice = 0;
  @Input() perCountPrice = 0;
  @Input() isDisable = false;
}
