@if (prevVisible) {
<div
  class="light-gallery-content-new__arrow-left"
  [class.is-disabled]="isPrevDisabled"
  [class.light-gallery-content-new__arrow-left--image]="modificator === 'image'"
  [class.light-gallery-content-new__arrow-left--thumbnails]="
    modificator === 'thumbnails'
  "
  [class.light-gallery-content-new__arrow-left--preview]="
    modificator === 'preview'
  "
  [class.light-gallery-content-new__arrow-left--preview-vertical]="
    modificator === 'preview-vertical'
  "
  [class.light-gallery-content-new__arrow-left--thumbnails-updown]="
    modificator === 'thumbnails-updown'
  "
  (click)="handlePrevClick()"
>
  @if (modificator === 'thumbnails-updown') {
  <i
    class="icon icon-ic_keyboard_arrow_up"
    [class.is-disabled]="isPrevDisabled"
    aria-hidden="true"
  ></i>
  } @else {
  <i class="icon icon-ic_arrow_left_slider_preview" aria-hidden="true"></i>
  }
</div>
} @if (nextVisible) {
<div
  class="light-gallery-content-new__arrow-right"
  [class.is-disabled]="isNextDisabled"
  [class.light-gallery-content-new__arrow-right--image]="
    modificator === 'image'
  "
  [class.light-gallery-content-new__arrow-right--thumbnails]="
    modificator === 'thumbnails'
  "
  [class.light-gallery-content-new__arrow-right--preview]="
    modificator === 'preview'
  "
  [class.light-gallery-content-new__arrow-right--preview-vertical]="
    modificator === 'preview-vertical'
  "
  [class.light-gallery-content-new__arrow-right--thumbnails-updown]="
    modificator === 'thumbnails-updown'
  "
  (click)="handleNextClick()"
>
  @if (modificator === 'thumbnails-updown') {
  <i
    class="icon icon-ic_keyboard_arrow_down"
    [class.is-disabled]="isNextDisabled"
    aria-hidden="true"
  ></i>
  } @else {
  <i class="icon icon-ic_arrow_rigth_slider_preview" aria-hidden="true"></i>
  }
</div>
}
