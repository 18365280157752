export const RESTRICTIONS = {
  STRING_8: 8,
  STRING_35: 35,
  STRING_40: 40,
  STRING_50: 50,
  STRING_60: 60,
  STRING_100: 100,
  STRING_255: 255,
  META_TITLE: 255,
  META_DESCRIPTION: 255,
  MEAT_KEYWORDS: 255,
};
