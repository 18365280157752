import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Observable, tap } from 'rxjs';

@Injectable()
export class ErrorsInterceptor implements HttpInterceptor {
  constructor(private injector: Injector, private toastr: ToastrService) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      tap(
        (event) => {},
        (err) => {
          if (err && err instanceof HttpErrorResponse) {
            this.toastr.error('Повторите попытку', 'Сетевая ошибка', {
              timeOut: 3000,
            });
          } else {
            this.toastr.error(undefined, 'Неизвестная ошибка', {
              timeOut: 3000,
            });
          }
        }
      )
    );
  }
}
