import { FactoryProvider, inject, InjectionToken } from '@angular/core';
import { fromEvent, map, Observable, shareReplay, startWith } from 'rxjs';
import { IWindowSize } from '../interfaces/window.interface';

export const WINDOW = new InjectionToken<Window>('window');

export const WINDOW_SIZE = new InjectionToken<Observable<IWindowSize>>(
  'window size',
  {
    factory: () => {
      const window = inject(WINDOW);

      return fromEvent(window, 'resize').pipe(
        startWith(null),
        map(() => {
          const width = Math.max(
            window.document.documentElement.clientWidth || 0,
            window.innerWidth || 0
          );

          const height = Math.max(
            window.document.documentElement.clientHeight || 0,
            window.innerHeight || 0
          );

          return { width, height };
        }),
        shareReplay({ bufferSize: 1, refCount: true })
      );
    },
  }
);

const windowProvider: FactoryProvider = {
  provide: WINDOW,
  useFactory: () => window,
};

export const WINDOW_PROVIDERS = [windowProvider];
