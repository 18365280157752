import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { IDesignSide } from '@inaripro-nx/catalog';
import { CropModalComponent, ICropData } from '@inaripro-nx/crop';
import { DesignUiModule } from '@inaripro-nx/design-ui';
import { decimalFloor, IFigure, IFilter } from '@inaripro-nx/common-ui';
import { IElement } from '../../../../interfaces/editor.interface';
import { ActionsElementCropComponent } from '../actions-element-crop/actions-element-crop.component';
import { ActionsElementFlipComponent } from '../actions-element-flip/actions-element-flip.component';
import { ActionsElementRotateComponent } from '../actions-element-rotate/actions-element-rotate.component';
import { ActionsElementScaleComponent } from '../actions-element-scale/actions-element-scale.component';
import { ActionsElementTranslateComponent } from '../actions-element-translate/actions-element-translate.component';
import { PipesModule } from '../../../../pipes/pipes.module';
import {
  getElementMaxScale,
  isRasterImage,
} from '../../../../../../utils/calculate.utils';

enum ESubRow {
  trasformations = 'trasformations',
  filters = 'filters',
}

@Component({
  selector: 'painter-actions-pictures',
  standalone: true,
  templateUrl: './actions-pictures.component.html',
  styleUrls: ['./actions-pictures.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    DesignUiModule,
    ActionsElementRotateComponent,
    ActionsElementScaleComponent,
    ActionsElementTranslateComponent,
    ActionsElementFlipComponent,
    ActionsElementCropComponent,
    CropModalComponent,
    PipesModule,
  ],
})
export class ActionsPicturesComponent implements OnChanges {
  @Input() filters!: IFilter[];
  @Input() element!: IElement;
  @Input() designSide: IDesignSide | null = null;
  @Input() figures: IFigure[] | null = null;
  @Input() isShowActionsSubmenu: boolean | null = false;
  @Input() isMaxUserFilesLimit: boolean | null = false;

  @Output() updateElement: EventEmitter<IElement> = new EventEmitter();
  @Output() cropImage: EventEmitter<ICropData> = new EventEmitter();

  readonly ESubRow = ESubRow;

  subRow: ESubRow | null = ESubRow.trasformations;
  maxScalePercentage = this.getMaxScalePercentage();

  ngOnChanges(simpleChanges: SimpleChanges) {
    if ('element' in simpleChanges || 'designSide' in simpleChanges) {
      this.maxScalePercentage = this.getMaxScalePercentage();
    }
  }

  selectFilter(id: number | null) {
    if (!this.element) {
      return;
    }

    const filterId = id && this.element.filterId === id ? null : id;

    this.updateElement.emit({
      ...this.element,
      filterId,
    });
  }

  toggleSubRow(subRow: ESubRow) {
    this.subRow = subRow === this.subRow ? null : subRow;
  }

  private getMaxScalePercentage() {
    if (!this.element || !this.designSide) {
      return 100;
    }

    const elementMaxScale = getElementMaxScale(this.element, this.designSide);

    if (!isRasterImage(this.element)) {
      return elementMaxScale * 100;
    }

    const designSideWidth = this.designSide.sizePX.x;
    const designSideHeight = this.designSide.sizePX.y;
    const imageMaxScaleWidth = this.element.size.x * elementMaxScale;
    const imageMaxScaleHeight = this.element.size.y * elementMaxScale;

    // проценты от стороны изделия
    if (
      designSideWidth / designSideHeight >
      imageMaxScaleWidth / imageMaxScaleHeight
    ) {
      return decimalFloor((imageMaxScaleHeight / designSideHeight) * 100, 0);
    } else {
      return decimalFloor((imageMaxScaleWidth / designSideWidth) * 100, 0);
    }
  }
}
