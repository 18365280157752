import { FigureTypeEnum, IFigureListItem } from '../interfaces';

export const CROP_BOX_MIN_WIDTH = 40;
export const CROP_BOX_MIN_HEIGHT = 40;
export const CROP_BOX_DEFAULT_WIDTH = 250;
export const CROP_BOX_DEFAULT_HEIGHT = 250;

export const DEFAULT_CROP_FIGURES: IFigureListItem[] = [
  {
    id: 1,
    icon: 'icon-crop',
    name: 'Свободная форма',
    type: FigureTypeEnum.Default,
  },
  {
    id: 2,
    icon: 'icon-ic_photo',
    name: 'Оригинал',
    type: FigureTypeEnum.Original,
  },
  {
    id: 3,
    rectangle: { width: 24, height: 24 },
    name: '1 : 1',
    type: FigureTypeEnum.Rectangle,
  },
  {
    id: 4,
    rectangle: { width: 30, height: 20 },
    name: '3 : 2',
    type: FigureTypeEnum.Rectangle,
  },
  {
    id: 5,
    rectangle: { width: 28, height: 21 },
    name: '4 : 3',
    type: FigureTypeEnum.Rectangle,
  },
  {
    id: 6,
    rectangle: { width: 32, height: 18 },
    name: '16 : 9',
    type: FigureTypeEnum.Rectangle,
  },
  {
    id: 7,
    rectangle: { width: 20, height: 30 },
    name: '2 : 3',
    type: FigureTypeEnum.Rectangle,
  },
  {
    id: 8,
    rectangle: { width: 21, height: 28 },
    name: '3 : 4',
    type: FigureTypeEnum.Rectangle,
  },
  {
    id: 9,
    rectangle: { width: 18, height: 32 },
    name: '9 : 16',
    type: FigureTypeEnum.Rectangle,
  },
];
