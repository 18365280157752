import { Pipe, PipeTransform } from '@angular/core';
import { sortByField } from '../../utils';

@Pipe({
  name: 'sortByPosition',
})
export class SortByPositionPipe<T extends { position: number; name?: string }>
  implements PipeTransform
{
  transform(value: T[], args?: keyof T): T[] {
    const sortKey = args || 'position';

    return ([...value] || []).sort(sortByField<T>(sortKey));
  }
}
