import { createAction, props } from '@ngrx/store';

export const setDescription = createAction(
  '[Meta] setDescription',
  props<{ payload: string }>()
);

export const setKeywords = createAction(
  '[Meta] setKeywords',
  props<{ payload: string }>()
);

export const blockRobots = createAction('[Meta] blockRobots');
