import { ChangeDetectionStrategy, Component } from '@angular/core';
import { APP_COLORS } from '../../consts/colors';
import { CommonModule } from '@angular/common';

@Component({
  standalone: true,
  imports: [CommonModule],
  selector: 'design-colors-page',
  templateUrl: './colors-page.component.html',
  styleUrls: ['./colors-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ColorsPageComponent {
  get APP_COLORS() {
    return APP_COLORS;
  }
}
