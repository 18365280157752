import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'svgRotate',
})
export class SvgRotatePipe implements PipeTransform {
  transform(value: {
    angle: number;
    point?: { x: number; y: number };
  }): string {
    return value.point
      ? ` rotate(${value.angle} ${value.point.x} ${value.point.y})`
      : ` rotate(${value.angle})`;
  }
}
