import { IPricesRange, IPublicPrice } from '@inaripro-nx/catalog';
import { Observable } from 'rxjs';

export interface IOrderProductSource {
  updatingPrice$: Observable<boolean>;
  publicPrice$: Observable<IPublicPrice | null>;
  countWithBasket$: Observable<number>;
  orderCount$: Observable<number>;
  priceRangeIndex$: Observable<number>;
  priceRange$: Observable<IPricesRange>;
  priceBaseValue$: Observable<number | null>;
  priceDiscountValue$: Observable<number | null>;
  mergeProductStatus$: Observable<boolean>;
  isAddButtonDisabled$: Observable<boolean>;
  updatingProduct$: Observable<boolean>;
  orderProduct: () => void;
}
