import {
  AfterViewInit,
  Directive,
  ElementRef,
  Inject,
  Input,
  OnDestroy,
} from '@angular/core';
import { WINDOW } from '@inaripro-nx/common-ui';

@Directive({
  selector: '[designAutoFocus]',
  standalone: true,
})
export class AutoFocusDirective implements AfterViewInit, OnDestroy {
  @Input('designAutoFocus') autoFocus = false;

  private _timeouts: number[] = [];
  set timeouts(sub: number) {
    this._timeouts.push(sub);
  }

  constructor(private el: ElementRef, @Inject(WINDOW) private window: Window) {}

  ngAfterViewInit() {
    if (!this.autoFocus) {
      return;
    }

    this.timeouts = this.window.setTimeout(() => {
      this.el.nativeElement.focus();
    });
  }

  ngOnDestroy() {
    this._timeouts.forEach((t) => this.window.clearTimeout(t));
  }
}
