import { Pipe, PipeTransform } from '@angular/core';
import { EElementTypeName, IElement } from '../../interfaces/editor.interface';

@Pipe({
  name: 'elementName',
})
export class ElementNamePipe implements PipeTransform {
  static getElementName(value: IElement): string | null {
    if (!value) {
      return null;
    }

    const getTypeName = (element: IElement): string => {
      const typeName = `${EElementTypeName[element.type]}`;
      return element.typeIndex !== null
        ? `${typeName} ${element.typeIndex}`
        : typeName;
    };

    return value.text ? value.text : getTypeName(value);
  }

  transform(value: IElement): string | null {
    return ElementNamePipe.getElementName(value);
  }
}
