import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { DesignUiModule, ModalWindowStore } from '@inaripro-nx/design-ui';
import { BASKET_SUCCESS_MODAL_UID } from '../../constants';

@Component({
  selector: 'order-product-add-to-cart-modal',
  standalone: true,
  imports: [CommonModule, DesignUiModule],
  templateUrl: './order-product-add-to-cart-modal.component.html',
  styleUrls: ['./order-product-add-to-cart-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderProductAddToCartModalComponent {
  @Input() publicUrl = '';
  @Input() name = '';

  readonly basketSuccessModalUid = BASKET_SUCCESS_MODAL_UID;

  constructor(private readonly modalWindowStore: ModalWindowStore) {}

  modalClose() {
    this.modalWindowStore.patch({ [this.basketSuccessModalUid]: false });
  }
}
