import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IFilter } from '@inaripro-nx/common-ui';
import { map, Observable, shareReplay } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FiltersService {
  filters$: Observable<IFilter[]> = this.http
    .get<{ data: IFilter[] }>('/catalog/public/design/filter/')
    .pipe(
      map((response) => response.data),
      shareReplay({ refCount: false, bufferSize: 1 })
    );

  constructor(private http: HttpClient) {}
}
