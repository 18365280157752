import { ElementRef, Injectable } from '@angular/core';
import {
  CROP_BOX_DEFAULT_HEIGHT as DEFAULT_HEIGHT,
  CROP_BOX_DEFAULT_WIDTH as DEFAULT_WIDTH,
} from '../constants';
import { getFitScale } from '../utils/crop.utils';
import { FigureTypeEnum, IFigureListItem } from '../interfaces';

@Injectable()
export class CropBoxSizeService {
  getSize(
    cropContainer: ElementRef<HTMLElement>,
    imageWidth: number,
    imageHeight: number,
    selectedFigure?: IFigureListItem
  ) {
    if (!cropContainer) {
      return this.getDefaultSize();
    }

    const type = selectedFigure?.type || FigureTypeEnum.Default;
    const containerWidth = cropContainer.nativeElement.clientWidth;
    const containerHeight = cropContainer.nativeElement.clientHeight;

    if (type === FigureTypeEnum.Default) {
      return this.getFreeScaleSize(containerWidth, containerHeight);
    }

    if (type === FigureTypeEnum.Rectangle && selectedFigure?.rectangle) {
      const { width, height } = selectedFigure.rectangle;
      return this.getFitSize(
        containerWidth,
        containerHeight,
        width * 1000,
        height * 1000
      );
    }

    if (type === FigureTypeEnum.Mask && selectedFigure?.image) {
      const size = Math.min(containerWidth, containerHeight);
      return this.getFitSize(containerWidth, containerHeight, size, size);
    }

    return this.getImageSize(
      containerWidth,
      containerHeight,
      imageWidth,
      imageHeight
    );
  }

  private getDefaultSize() {
    return {
      width: DEFAULT_WIDTH,
      height: DEFAULT_HEIGHT,
    };
  }

  private getFreeScaleSize(containerWidth: number, containerHeight: number) {
    return {
      width: containerWidth || DEFAULT_WIDTH,
      height: containerHeight || DEFAULT_HEIGHT,
    };
  }

  private getFitSize(
    containerWidth: number,
    containerHeight: number,
    width: number,
    height: number
  ) {
    const scale = getFitScale(containerWidth, containerHeight, width, height);
    return {
      width: width * scale,
      height: height * scale,
    };
  }

  private getImageSize(
    containerWidth: number,
    containerHeight: number,
    imageWidth: number,
    imageHeight: number
  ) {
    const scale = getFitScale(
      containerWidth,
      containerHeight,
      imageWidth,
      imageHeight
    );

    return {
      width: imageWidth * scale,
      height: imageHeight * scale,
    };
  }
}
