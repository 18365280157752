<ng-container *ngIf="{ modal: modal$ | async } as state">
  <div
    [class]="classes?.backdrop"
    *ngIf="hiddenMode || state.modal"
    [hidden]="hiddenMode && !state.modal"
  >
    <div
      [class]="classes?.wrapper"
      [style.maxWidth.px]="maxWidth"
      designClickOutside
      [class.full-mode]="fullMode"
      [class.full-screen]="fullScreen"
      [isDelay]="true"
      (clickOutsideEvent)="isHideBackDropOnClick && closeModal(true)"
      #wrapper
    >
      <div [class]="classes?.sidebar">
        <ng-content select="[modal-sidebar]"></ng-content>
      </div>
      <div [class]="classes?.content">
        <div [class]="classes?.header">
          <div [class]="classes?.title">
            <ng-content select="[modal-header]"></ng-content>
          </div>
          <div [class]="classes?.close" (click)="closeModal(true)">
            <i class="icon icon-ic_close"></i>
          </div>
        </div>

        <div [class]="classes?.body">
          <ng-content select="[modal-body]"></ng-content>
        </div>

        <div [class]="classes?.footer" [class.full-mode]="fullMode">
          <ng-content select="[modal-footer]"></ng-content>
        </div>
      </div>
    </div>
  </div>
</ng-container>
