import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import {
  IFileResponse,
  ValidatorsMaxLength,
  ValidatorsRequired,
} from '@inaripro-nx/common-ui';
import { ClickOutsideDirective } from '../../directives';
import { FormInputStringComponent } from '../form-input-string/form-input-string.component';

interface ITemplate {
  name: string;
  cover: IFileResponse | null;
  updateTime: string;
  isActive?: boolean;
}

@Component({
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FormInputStringComponent,
    ClickOutsideDirective,
  ],
  selector: 'design-card-template',
  templateUrl: './card-template.component.html',
  styleUrls: ['./card-template.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardTemplateComponent {
  @Input() isShowActive = false;
  @Input() isShowRemove = false;
  @Input() isShowEdit = false;

  _template!: ITemplate;
  get template(): ITemplate {
    return this._template;
  }

  @Input() set template(template: ITemplate) {
    this._template = template;
    this.templateForm = this.createTemplateForm(template);
  }

  @Output() selectTemplate: EventEmitter<void> = new EventEmitter();
  @Output() remove: EventEmitter<void> = new EventEmitter();
  @Output() editName: EventEmitter<string> = new EventEmitter();
  @Output() toggleActive: EventEmitter<boolean> = new EventEmitter();

  templateForm!: FormGroup;

  constructor(
    private sanitizer: DomSanitizer,
    private formBuilder: FormBuilder
  ) {}

  public getSafetyUrl(value: string): SafeStyle {
    return this.sanitizer.bypassSecurityTrustStyle('url(' + value + ')');
  }

  private createTemplateForm(template: ITemplate): FormGroup {
    const { name } = template;

    return this.formBuilder.group({
      name: [name, [ValidatorsRequired(), ValidatorsMaxLength(255)]],
    });
  }
}
