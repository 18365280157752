import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { AuthService } from '@inaripro-nx/auth';
import { Observable } from 'rxjs';
import { DesignerAuthService } from '../../services/designer-auth/designer-auth.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(private injector: Injector) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    request = this.addAuthorizationHeader(request);
    request = this.addDesignerAuthorizationHeader(request);

    request = request.clone({
      withCredentials: true,
    });

    return next.handle(request);
  }

  private addAuthorizationHeader(
    request: HttpRequest<unknown>
  ): HttpRequest<unknown> {
    const authService = this.injector.get(AuthService);
    const authHeader = authService.getAuthorizationHeader();

    if (authHeader) {
      request = request.clone({
        headers: request.headers.set('Authorization', authHeader),
      });
    }

    return request;
  }

  private addDesignerAuthorizationHeader(
    request: HttpRequest<unknown>
  ): HttpRequest<unknown> {
    const authService = this.injector.get(DesignerAuthService);
    const authHeader = authService.getAuthorizationHeader();

    if (authHeader) {
      request = request.clone({
        headers: request.headers.set('Design-user-uid', authHeader),
      });
    }

    return request;
  }
}
