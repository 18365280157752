import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IQRCode } from '@inaripro-nx/common-ui';
import { map, switchMap } from 'rxjs';
import { DesignerAuthLinkService } from '../designer-auth/designer-auth.service';

@Injectable({
  providedIn: 'root',
})
export class QrCodesService {
  API_URL = '/catalog/public/design/qrcode/';

  constructor(
    private http: HttpClient,
    private designerAuthLinkService: DesignerAuthLinkService
  ) {}

  all() {
    return this.designerAuthLinkService.loadDesignUser$.pipe(
      switchMap(() => {
        return this.http
          .get<{ data: IQRCode[] }>(this.API_URL)
          .pipe(map((res) => res.data));
      })
    );
  }

  add(model: Partial<IQRCode>) {
    return this.http.post<IQRCode>(this.API_URL, model);
  }

  // update(model: IQRCode) {
  //   return this.http.patch<IQRCode>(`${this.API_URL}${model.id}`, model);
  // }

  delete(modelId: number) {
    return this.http.delete(`${this.API_URL}${modelId}`);
  }
}
