import { Injectable } from '@angular/core';
import { AuthService } from '@inaripro-nx/auth';
import { ComponentStore } from '@ngrx/component-store';
import { EMPTY, catchError, map, switchMap, tap } from 'rxjs';
import { ICatalogFavorite } from '../interfaces/catalog.interface';
import { CatalogFavoriteService } from '../services';

export interface CatalogFavoriteState {
  favorite: ICatalogFavorite;
}

const initialState: CatalogFavoriteState = {
  favorite: {},
};

@Injectable({
  providedIn: 'root',
})
export class CatalogFavoriteStore extends ComponentStore<CatalogFavoriteState> {
  readonly favorite$ = this.select((state) => state.favorite);
  readonly favoriteCount$ = this.favorite$.pipe(
    map((favorite) => {
      let counter = 0;
      Object.keys(favorite || {}).forEach((key) => {
        counter += favorite[Number(key)].length;
      });
      return counter;
    })
  );

  init() {
    this.authService.token$
      .pipe(
        switchMap((token) => {
          if (token) {
            return this.catalogFavoriteService.getFavorites().pipe(
              tap((favorite) => {
                this.setState((state) => {
                  return {
                    ...state,
                    favorite,
                  };
                });
              }),
              catchError(() => EMPTY)
            );
          } else {
            this.setState(() => {
              return {
                ...initialState,
              };
            });

            return EMPTY;
          }
        })
      )
      .subscribe();
  }

  constructor(
    private readonly catalogFavoriteService: CatalogFavoriteService,
    private readonly authService: AuthService
  ) {
    super({ ...initialState });
  }
}
