@if (video) {
<div
  class="design-video-player"
  [class.design-video-player--shorts]="video.isShorts"
>
  @if (video.resource === VIDEO_RESOURCE_TYPE.YUOTUBE) {
  <iframe
    [src]="safeURL"
    frameborder="0"
    allowfullscreen
    width="{{ width }}"
    height="{{ height }}"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
  ></iframe>
  } @else if (video.resource === VIDEO_RESOURCE_TYPE.VIMEO) {
  <iframe
    [src]="safeURL"
    width="{{ width }}"
    height="{{ height }}"
    frameborder="0"
    webkitallowfullscreen
    mozallowfullscreen
    allowfullscreen
    allow="autoplay; fullscreen"
  ></iframe>
  }
</div>
}
