import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'objectKeys',
})
export class ObjectKeysPipe implements PipeTransform {
  transform(value: any): any {
    if (value && this.isAnObject(value)) {
      return Object.keys(value);
    }

    return null;
  }

  private isAnObject(value: any) {
    return Object.prototype.toString.call(value) === '[object Object]';
  }
}
