<div class="arrow-thumbnails-table">
  <div (click)="minus()" class="arrow-thumbnails-table__left">
    <i class="icon icon-ic_keyboard_arrow_left_18"></i>
  </div>
  <div class="arrow-thumbnails-table__center">
    <p>{{ currentIndex }}/{{ lengthImages }}</p>
  </div>
  <div (click)="plus()" class="arrow-thumbnails-table__right">
    <i class="icon icon-ic_keyboard_arrow_right"></i>
  </div>
</div>
