import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

@Component({
  selector: 'design-colors-view',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './colors-view.component.html',
  styleUrls: ['./colors-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ColorsViewComponent {
  @Input() colors?: string[];
  @Input() hideIcon = false;
  @Input() crashed = false;
  @Input() borderTopBottom = 20;
  @Input() borderLeftRight = 35;
  @Input() borderRadius = 3;

  @Output() clickOut: EventEmitter<void> = new EventEmitter();

  isWhite(colors: string[]) {
    const regex = /^#f{3,6}$/gim;
    return colors.findIndex((color) => regex.test(color)) > -1;
  }
}
