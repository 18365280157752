import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { DesignUiModule } from '@inaripro-nx/design-ui';
import { BehaviorSubject, filter, map, Observable, switchMap } from 'rxjs';
import { ActionsTextService } from '../../../../../../services/actions-text/actions-text.service';
import { EyeDropperService } from '../../../../../../services/eye-dropper/eye-dropper.service';
import { WindowToolsService } from '../../../../../../services/window-tools/window-tools.service';
import { HistoryStore } from '../../../../../../state/history/history.store';
import { ProductStore } from '../../../../../../state/product/product.store';

@Component({
  selector: 'painter-content-fill-color',
  standalone: true,
  imports: [CommonModule, DesignUiModule],
  templateUrl: './content-fill-color.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentFillColorComponent {
  @Input()
  set zoneKey(key: string) {
    this.zoneKeySubject$.next(key);
  }

  readonly isDesktop$: Observable<boolean> = this.windowToolsService.isDesktop$;
  readonly zoneColors$ = this.productStore.zoneColors$;
  readonly useColors$ = this.historyStore.useColors$;

  private readonly zoneKeySubject$ = new BehaviorSubject<string | null>(null);

  readonly inColor$ = this.zoneKeySubject$.asObservable().pipe(
    filter(Boolean),
    switchMap((zoneKey) =>
      this.zoneColors$.pipe(
        filter(Boolean),
        map((zoneColors) => zoneColors[zoneKey] || null)
      )
    )
  );

  readonly activeDesignSide$ = this.productStore.activeDesignSide$;
  readonly activeDesignProduct$ = this.productStore.activeDesignProduct$;

  constructor(
    private readonly productStore: ProductStore,
    private readonly historyStore: HistoryStore,
    private readonly windowToolsService: WindowToolsService,
    public actionsTextService: ActionsTextService,
    public eyeDropperService: EyeDropperService
  ) {}

  public setZoneColor(color: string | null): void {
    const key = this.zoneKeySubject$.getValue();
    if (key) {
      this.productStore.setZoneColor({
        key,
        color,
      });
    }
  }
}
