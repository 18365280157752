<div itemprop="offers" itemscope="" itemtype="http://schema.org/AggregateOffer">
  <meta
    *ngIf="basePrices.length > 1"
    itemprop="highPrice"
    [content]="basePrices[0]"
  />
  <meta
    *ngIf="basePrices.length > 0"
    itemprop="lowPrice"
    [content]="basePrices[basePrices.length - 1]"
  />
  <meta itemprop="priceCurrency" content="RUB" />
  <link itemprop="availability" href="http://schema.org/InStock" />
</div>

<div class="count" *ngIf="sumOrderCount">
  Общее количество в заказе: {{ sumOrderCount }}&nbsp;{{ unitName }}
</div>

<div
  class="price-column-table"
  itemprop="offers"
  itemscope
  itemtype="http://schema.org/Offer"
>
  <meta itemprop="priceCurrency" content="RUB" />
  <table>
    <thead>
      <tr>
        <th></th>
        <th>Базовая цена</th>
        <th *ngIf="publicPrice?.discountCost">Цена для вас</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let range of publicPrice?.ranges; let rIndex = index">
        <td [class.active]="rIndex === activeRangeIndex">
          <ng-container *ngIf="!range.upper">от</ng-container>
          {{ range.lower }}
          <ng-container *ngIf="range.upper">- {{ range.upper }}</ng-container>
          <span> {{ unitName }}</span>
        </td>
        <td
          [class.active]="rIndex === activeRangeIndex"
          *ngIf="publicPrice?.baseCost?.vs?.[rIndex] as value"
        >
          <span itemprop="price">{{( value | number:'1.0-2' ) || '-' }}</span>
          <span>&nbsp;Р</span>
          <span itemprop="priceCurrency" style="display: none">RUB</span>
        </td>
        <td
          [class.active]="rIndex === activeRangeIndex"
          *ngIf="publicPrice?.discountCost?.vs?.[rIndex] as value"
        >
          <span>{{ ( value | number:'1.0-2' ) || '-' }}</span>
          <span>&nbsp;Р</span>
        </td>
      </tr>
    </tbody>
  </table>
</div>
