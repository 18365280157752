import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import {
  PipesModule,
  RESTRICTIONS,
  ValidatorsMaxLength,
} from '@inaripro-nx/common-ui';
import { DesignUiModule, ModalWindowStore } from '@inaripro-nx/design-ui';
import { ICity, MAIN_CITY_NAMES } from '@inaripro-nx/geo';
import { debounceTime, distinctUntilChanged, Subscription } from 'rxjs';

@Component({
  selector: 'shell-header-city-select-modal',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, PipesModule, DesignUiModule],
  templateUrl: './header-city-select-modal.component.html',
  styleUrls: ['./header-city-select-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderCitySelectModalComponent implements OnInit, OnDestroy {
  @Input()
  set locations(cities: ICity[]) {
    this.cities = cities;
    this.initGeoLocatonSelectors(cities);
  }

  @Input() currentLocation: ICity | null = null;

  @Output() submitted: EventEmitter<ICity> = new EventEmitter<ICity>();

  cities: ICity[] = [];
  mainCities: ICity[] = [];
  restCities: ICity[] = [];
  mainFilteredCities: ICity[] = [];
  filteredCities: ICity[] = [];
  filteredCitiesMap: { [id: string]: ICity[] } = {};
  newCity: ICity | null = null;
  queryCities: string | null = null;
  currentLetter: string | null = null;

  citiesListForm: FormGroup = this.fb.group({
    query: ['', ValidatorsMaxLength(RESTRICTIONS.STRING_255)],
    city: '',
  });

  readonly modalUid = 'city_select';

  private _subs: Subscription[] = [];
  set subs(sub: Subscription) {
    this._subs.push(sub);
  }

  constructor(
    private readonly modalWindowStore: ModalWindowStore,
    private readonly fb: FormBuilder,
    private readonly cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.subs = this.citiesListForm.controls['query'].valueChanges
      .pipe(debounceTime(400), distinctUntilChanged())
      .subscribe((query) => {
        this.search(query);
      });
  }

  ngOnDestroy(): void {
    this._subs.forEach((s) => s.unsubscribe());
  }

  toggleCitySelect(open: boolean) {
    if (open) {
      this.newCity = null;
      this.queryCities = '';
      this.citiesListForm.setValue(
        { query: '', city: '' },
        { emitEvent: false }
      );
    }

    this.modalWindowStore.patch({ [this.modalUid]: open });
  }

  isValid() {
    return !!this.citiesListForm.controls['city'].value;
  }

  clearSearch() {
    this.citiesListForm.controls['query'].setValue('');
  }

  search(query: string) {
    this.queryCities = query;

    let filteredCities;

    if (query) {
      this.mainFilteredCities = (this.mainCities || []).filter(
        (city) => city.name.toLowerCase().indexOf(query.toLowerCase()) === 0
      );

      filteredCities = (this.restCities || []).filter(
        (city) => city.name.toLowerCase().indexOf(query.toLowerCase()) === 0
      );
    } else {
      this.mainFilteredCities = (this.mainCities || []).slice();
      filteredCities = (this.restCities || []).slice();
    }

    this.createFilterCitiesMap(filteredCities);
    this.cdr.markForCheck();
  }

  onSubmit(): void {
    const currentLocation = this.getCityById(
      this.cities || [],
      this.citiesListForm.value.city
    );
    this.submitted.emit(currentLocation);
    this.toggleCitySelect(false);
  }

  trackById(_index: number, item: ICity) {
    return item.id;
  }

  private initGeoLocatonSelectors(cities: ICity[]): void {
    this.mainCities = cities.filter(
      (city) => !!MAIN_CITY_NAMES.find((cityName) => cityName === city.name)
    );

    this.restCities = cities.filter(
      (city) => !MAIN_CITY_NAMES.find((cityName) => cityName === city.name)
    );

    this.mainFilteredCities = (this.mainCities || []).slice();

    const filteredCities = (this.restCities || []).slice();
    this.createFilterCitiesMap(filteredCities);
  }

  private createFilterCitiesMap(filteredCities: ICity[]): void {
    this.filteredCities = filteredCities;
    this.filteredCitiesMap = {};
    this.currentLetter = null;

    filteredCities.forEach((city) => {
      const letter = city.name.substring(0, 1);

      if (!this.filteredCitiesMap[letter]) {
        if (!this.currentLetter) {
          this.currentLetter = letter;
        }

        this.filteredCitiesMap[letter] = [];
      }

      this.filteredCitiesMap[letter].push(city);
    });
  }

  private getCityById(cities: ICity[], cityId: number): ICity | undefined {
    return cities.find((city) => city.id === cityId);
  }
}
