import { IDesignProduct } from '@inaripro-nx/catalog';
import { IFileResponse, IMapOfString, IPattern } from '@inaripro-nx/common-ui';
import { IElements, IMapXY } from './editor.interface';

export const VERSION_START = '0.0002';
export const VERSION_REVERSE_FILL_OPACITY = '1.0';
export const CURRENT_TEMPLATE_VERSION = VERSION_REVERSE_FILL_OPACITY;

export type ITemplateType = 'product' | 'my';

export interface IShareTemplate {
  uid: string;
  json: ITemplateJson;
  productId: number;
  version: string;
}

export interface IShareResponse {
  uid: string;
}

export interface ITemplate {
  id: number;
  name: string;
  json: ITemplateJson;
  cover: IFileResponse | null;
  updateTime: string;
  productId: number;
  version: string;
  markForDelete?: boolean; // on front
}

export interface IProductTemplate extends ITemplate {
  isActive: boolean;
}

export interface ITemplateData {
  elements: IElements;
  zoneColors: IMapOfString | null;
  zonePatterns: { [key: string]: IPattern } | null;
  zoneTranslates: IMapXY | null | undefined;
}

export interface ITemplateJson extends ITemplateData {
  activeDesignProduct: IDesignProduct | null;
}
