export function getFitScale(
  containerWidth: number,
  containerHeight: number,
  originalWidth: number,
  originalHeight: number
): number {
  let scale = 1;

  if (originalWidth > containerWidth || originalHeight > containerHeight) {
    if (originalWidth / originalHeight < containerWidth / containerHeight) {
      scale = containerHeight / originalHeight;
    } else {
      scale = containerWidth / originalWidth;
    }
  }

  return scale;
}

export function getValueInInterval(
  value: number,
  interval: { min: number; max: number }
) {
  return Math.max(interval.min, Math.min(value, interval.max));
}

export function getResizedInlineSvg(
  inlineSvg: string,
  width: number,
  height: number
): string {
  const svgWrapper = document.createElement('div');
  svgWrapper.innerHTML = inlineSvg;

  const innerSvg = svgWrapper.getElementsByTagName('svg')[0];
  innerSvg.setAttribute('width', `${width}`);
  innerSvg.setAttribute('height', `${height}`);

  return innerSvg.outerHTML;
}

export function removePreserveAspectRatio(inlineSvg: string): string {
  const svgWrapper = document.createElement('div');
  svgWrapper.innerHTML = inlineSvg;

  const innerSvg = svgWrapper.getElementsByTagName('svg')[0];
  innerSvg.setAttribute('preserveAspectRatio', 'none');

  return innerSvg.outerHTML;
}

export async function loadImage(
  url: string,
  options?: { crossOrigin: string | null }
): Promise<HTMLImageElement> {
  return new Promise((resolve, reject) => {
    const image = new Image();

    image.crossOrigin = !options?.crossOrigin
      ? 'anonymous'
      : options.crossOrigin;

    image.onload = () => resolve(image);
    image.onerror = (error) => reject(error);

    image.src = url;
  });
}
