import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { WINDOW } from '../../providers/window.providers';
import { StorageService } from '../storage/storage.service';

@Injectable({
  providedIn: 'root',
})
export class CookieService {
  private cookieEnabled: boolean;

  constructor(
    private storageService: StorageService,
    @Inject(WINDOW) private window: Window,
    @Inject(DOCUMENT) private document: Document
  ) {
    try {
      this.cookieEnabled =
        this.window.navigator && this.window.navigator.cookieEnabled;
    } catch (e) {
      this.cookieEnabled = false;
    }
  }

  getCookie(name: string) {
    if (!this.cookieEnabled) {
      return this.storageService.session.getItem(name);
    }

    const matches = this.document.cookie.match(
      new RegExp(
        '(?:^|; )' + name.replace(/([.$?*|{}()[\]\\/+^])/g, '\\$1') + '=([^;]*)'
      )
    );

    return matches ? decodeURIComponent(matches[1]) : undefined;
  }

  setCookie(name: string, value: string, options: any) {
    if (!this.cookieEnabled) {
      this.storageService.session.setItem(name, value);
      return;
    }

    options = options || {};

    if (!options.path) {
      options.path = '/';
    }

    let expires = options.expires;

    if (typeof expires === 'number' && expires) {
      const d = new Date();
      d.setTime(d.getTime() + expires * 1000);
      expires = options.expires = d;
    }
    if (expires && (expires as Date).toUTCString) {
      options.expires = (expires as Date).toUTCString();
    }

    value = encodeURIComponent(value);

    let updatedCookie = name + '=' + value;

    for (const propName in options) {
      if (propName) {
        updatedCookie += '; ' + propName;
        const propValue = options[propName];
        if (propValue !== true) {
          updatedCookie += '=' + propValue;
        }
      }
    }

    this.document.cookie = updatedCookie;
  }

  deleteCookie(name: string) {
    if (!this.cookieEnabled) {
      this.storageService.session.removeItem(name);
      return;
    }

    this.setCookie(name, '', {
      expires: -1,
    });
  }
}
