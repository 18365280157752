<div class="select">
  @if (selectedPattern$ | async) {
  <button type="button" class="select__clear" (click)="selectPattern(null)">
    <i class="icon icon-ic_delete_forever"></i>
  </button>
  }
  <design-select
    class="select__control"
    [options]="(libraries$ | async) || []"
    [selectedOption]="(currentLibrary$ | async) || null"
    (selectOption)="selectPatternLibrary($event)"
  ></design-select>
</div>

<div class="content">
  <div class="pattern-list">
    @for (pattern of currentPatterns$ | async; track pattern.id) {
    <div
      class="pattern-list__item"
      [class.pattern-list__item--selected]="
        (selectedPattern$ | async)?.id === pattern.id
      "
    >
      <img
        [lazyLoad]="pattern.preview || pattern.url"
        [width]="pattern.width || DEFAULT_PREVIEW_SIZE"
        [height]="pattern.height || DEFAULT_PREVIEW_SIZE"
        (click)="selectPattern(pattern)"
      />
    </div>
    }
  </div>
</div>
