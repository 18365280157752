import { Injectable } from '@angular/core';
import { isObjectsEqual } from '@inaripro-nx/common-ui';
import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged, shareReplay } from 'rxjs/operators';

interface IDataModel {
  x: number;
  y: number;
  scale: number;
  maxX: number;
  maxY: number;
}

@Injectable({
  providedIn: 'root',
})
export class DesignLightGalleryImageService {
  private readonly offsetXSubject$ = new BehaviorSubject(0);
  private readonly imageDataSubject$ = new BehaviorSubject<IDataModel>({
    x: 0,
    y: 0,
    scale: 0,
    maxX: 0,
    maxY: 0,
  });

  readonly offsetX$ = this.offsetXSubject$
    .asObservable()
    .pipe(
      distinctUntilChanged(),
      shareReplay({ refCount: true, bufferSize: 1 })
    );

  readonly imageData$ = this.imageDataSubject$
    .asObservable()
    .pipe(
      distinctUntilChanged(isObjectsEqual),
      shareReplay({ refCount: true, bufferSize: 1 })
    );

  setOffsetX(offsetX: number) {
    this.offsetXSubject$.next(offsetX);
  }

  setImageData(data: Partial<IDataModel>): void {
    const currentData = this.imageDataSubject$.getValue();
    this.imageDataSubject$.next({
      ...currentData,
      ...data,
    });
  }
}
