<div
  class="content-fill"
  *ngIf="{
    activeDesignSide: activeDesignSide$ | async,
    activeDesignZone: activeDesignZone$ | async,
    activeDesignZonesWithColor: activeDesignZonesWithColor$ | async
  } as state"
>
  <ng-container *ngIf="state.activeDesignSide?.hasColorPalette">
    <ng-content *ngTemplateOutlet="tabsTemplate"></ng-content>

    <ng-content
      *ngTemplateOutlet="
        colorTabTemplate;
        context: {
          key: PREFIX_SIDE_FILL + state.activeDesignSide?.id
        }
      "
    ></ng-content>

    <ng-content
      *ngTemplateOutlet="
        fillTabTemplate;
        context: {
          key: PREFIX_SIDE_FILL + state.activeDesignSide?.id
        }
      "
    ></ng-content>
  </ng-container>

  <ng-container
    *ngIf="
      state.activeDesignSide &&
      state.activeDesignZonesWithColor &&
      state.activeDesignZonesWithColor.length
    "
  >
    <ng-content
      *ngIf="{
        zone: state.activeDesignZone || state.activeDesignZonesWithColor[0]
      } as subState"
    >
      <design-select
        class="select"
        *ngIf="state.activeDesignZonesWithColor.length > 1"
        [options]="state.activeDesignZonesWithColor || []"
        [selectedOption]="subState.zone"
        (selectOption)="selectZone(state.activeDesignSide, $event)"
      ></design-select>

      <ng-content *ngTemplateOutlet="tabsTemplate"></ng-content>

      <ng-content
        *ngTemplateOutlet="
          colorTabTemplate;
          context: {
            key: '' + subState.zone?.id
          }
        "
      ></ng-content>

      <ng-content
        *ngTemplateOutlet="
          fillTabTemplate;
          context: {
            key: '' + subState.zone?.id
          }
        "
      ></ng-content>
    </ng-content>
  </ng-container>
</div>

<ng-template #tabsTemplate>
  <design-tabs
    class="color-tabs"
    [tabs]="tabs"
    [selectedTabId]="activeTab.id"
    (tabChanged)="activeTab = $event"
  ></design-tabs>
</ng-template>

<ng-template #colorTabTemplate let-key="key">
  @if (activeTab.id === COLOR_TAB_ID) {
  <painter-content-fill-color [zoneKey]="key"></painter-content-fill-color>
  }
</ng-template>

<ng-template #fillTabTemplate let-key="key">
  @if (activeTab.id === FILL_TAB_ID) {
  <painter-content-fill-pattern [zoneKey]="key"></painter-content-fill-pattern>
  }
</ng-template>
