import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import {
  ICatalogNomenclatureDictionaryItem,
  ICatalogNomenclatureListItem,
  ICatalogNomenclatureSeoListItem,
  ICategory,
} from '../../interfaces';

@Injectable({
  providedIn: 'root',
})
export class CatalogNomenclatureService {
  constructor(private readonly httpClient: HttpClient) {}

  getNomenclature(id: number): Observable<ICategory> {
    return this.httpClient.get<ICategory>(
      `/catalog/public/nomenclature/${id}/`
    );
  }

  getNomenclatureDictionary(): Observable<
    ICatalogNomenclatureDictionaryItem[]
  > {
    return this.httpClient
      .get<{ data: ICatalogNomenclatureDictionaryItem[] }>(
        '/catalog/public/nomenclature/?list=true'
      )
      .pipe(map(({ data }) => data));
  }

  getNomenclatureList(): Observable<ICatalogNomenclatureListItem[]> {
    return this.httpClient
      .get<{ data: ICatalogNomenclatureListItem[] }>(
        '/catalog/public/nomenclature/tree/'
      )
      .pipe(map(({ data }) => data));
  }

  getNomenclatureSeoList(): Observable<ICatalogNomenclatureSeoListItem[]> {
    return this.httpClient.get<ICatalogNomenclatureSeoListItem[]>(
      '/catalog/public/nomenclature/4level'
    );
  }

  getNomenclatureNoLinkList(): Observable<ICatalogNomenclatureListItem[]> {
    return this.httpClient
      .get<{ data: ICatalogNomenclatureListItem[] }>(
        '/catalog/public/nomenclature/no_link_tree'
      )
      .pipe(map(({ data }) => data));
  }
}
