import { EVideoResource, IParseVideo } from '../interfaces';

export function parseVideoURL(url: string): IParseVideo | null {
  if (!url) {
    return null;
  }

  const matchYouTube = url.match(
    /(http:|https:|)?(\/\/)?(player\.|www\.)?(youtu(be\.com|\.be|be\.googleapis\.com))\/(video\/|embed\/|shorts\/|watch\?v=|v\/)?([A-Za-z0-9._%-]*)(\S+)?/
  );
  const matchVimeo = url.match(
    /(http:|https:)?(\/\/)?(player\.|www\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/([^]*)\/videos\/|)(\d+)(?:|\/\?)/
  );

  if (matchYouTube) {
    const video_id = matchYouTube[7];

    if (video_id) {
      return <IParseVideo>{
        resource: EVideoResource.YUOTUBE,
        url: matchYouTube[0],
        id: video_id,
        isShorts: matchYouTube[6] === 'shorts/',
      };
    }
  } else if (matchVimeo) {
    const video_id = matchVimeo[5];

    if (video_id) {
      return <IParseVideo>{
        resource: EVideoResource.VIMEO,
        url: matchVimeo[0],
        id: video_id,
      };
    }
  } else {
    return null;
  }

  return null;
}
