
<design-modal-window
  *ngIf="downloadService.message$ | async as downloadMessage"
  [uid]="'download-pdf'"
  [prefix]="'download-pdf'"
  [openOnCreate]="true"
  (selfClose)="downloadService.clearMessage()"
>
  <div modal-header>
  </div>

  <div modal-body>
    <div class="g-form-group">
      {{ downloadMessage }}
    </div>
  </div>

  <div modal-footer>
    <div class="g-form-button">
      <button type="button" class="g-btn-link g-btn-link--red" (click)="downloadService.clearMessage()">
        ОК
      </button>
    </div>
  </div>

</design-modal-window>
