import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
} from '@angular/core';
import { ClickOutsideDirective } from '../../directives';
import { SelectDirectionEnum } from '../../interfaces';
@Component({
  standalone: true,
  imports: [CommonModule, ClickOutsideDirective],
  selector: 'design-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectComponent {
  @Input() optionTemplate!: TemplateRef<unknown>;
  @Input() selectedOption: unknown | null = null;
  @Input() options: unknown[] = [];
  @Input() emptyString = '-';
  @Input() directionOpen: SelectDirectionEnum = SelectDirectionEnum.DOWN;

  @Output() selectOption: EventEmitter<unknown> = new EventEmitter();

  readonly DropdownDirection = SelectDirectionEnum;

  isActive = false;

  clickOption(option: unknown) {
    this.isActive = false;
    this.selectOption.emit(option);
  }
}
