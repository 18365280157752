import { DOCUMENT } from '@angular/common';
import {
  AfterViewInit,
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  OnDestroy,
  Output,
  Renderer2
} from '@angular/core';
import { IDesignSide } from '@inaripro-nx/catalog';
import {
  getDistanceBetweenTouches,
  isCtrl,
  preventDefault,
} from '@inaripro-nx/common-ui';
import {
  BehaviorSubject,
  EMPTY,
  Subscription,
  combineLatest,
  distinctUntilChanged,
  exhaustMap,
  filter,
  fromEvent,
  map,
  merge,
  scan,
  switchMap,
  takeUntil,
  tap,
  withLatestFrom
} from 'rxjs';
import { getZonesBoxData } from '../../../../../../utils/calculate.utils';
import { IXY } from '../../../../interfaces/editor.interface';
import { ProductStore } from '../../../../state/product/product.store';
import {
  DEFAULT_OFFSET,
  DEFAULT_ZOOM,
  MAX_ZOOM,
  MIN_ZOOM,
  WorkareaStore,
} from '../../../../state/workarea/workarea.store';

const TOUCH_SENSITIVITY = 0.01;
const WHEEL_SENSITIVITY = 0.001;

@Directive({
  selector: '[painterEditorWorkarea]',
  standalone: true,
})
export class EditorWorkareaDirective implements AfterViewInit, OnDestroy {
  @Input() painterEditorWorkareaPanel!: HTMLElement;
  @Input() painterEditorWorkareaContainer!: HTMLElement;

  @Output() dragStart = new EventEmitter<void>();
  @Output() dragEnd = new EventEmitter<void>();

  private _subs: Subscription[] = [];
  set subs(sub: Subscription) {
    this._subs.push(sub);
  }

  private readonly localOffsetSubject$ = new BehaviorSubject<IXY>(
    DEFAULT_OFFSET
  );
  private readonly localOffset$ = this.localOffsetSubject$.asObservable();

  private readonly pointerDown$ = fromEvent<PointerEvent>(
    this.elementRef.nativeElement,
    'pointerdown'
  ).pipe(
    filter((e) => !this.painterEditorWorkareaPanel.contains(e.target as Node)),
    tap(() => this.dragStart.emit())
  );

  private readonly pointerUp$ = fromEvent<PointerEvent>(
    this.document,
    'pointerup'
  ).pipe(
    tap(() => {
      const position = this.localOffsetSubject$.getValue();
      this.workareaStore.changePosition(position);
      this.dragEnd.emit();
    })
  );

  private readonly pointerMove$ = fromEvent<PointerEvent>(
    this.document,
    'pointermove'
  ).pipe(filter((e) => e.isPrimary));

  private readonly dragging$ = this.pointerDown$.pipe(
    preventDefault(),
    withLatestFrom(this.workareaStore.zoom$, this.localOffset$),
    exhaustMap(([dragStartEvent, zoom, offset]) => {
      return this.pointerMove$.pipe(
        preventDefault(),
        map((mouseMoveEvent) => ({
          x: offset.x + (mouseMoveEvent.x - dragStartEvent.x) / zoom,
          y: offset.y + (mouseMoveEvent.y - dragStartEvent.y) / zoom,
        })),
        takeUntil(this.pointerUp$)
      );
    })
  );

  private readonly doubleTouchStart$ = fromEvent<TouchEvent>(
    this.elementRef.nativeElement,
    'touchstart'
  ).pipe(
    filter(({ touches }) => touches.length > 1),
  );

  private readonly touchEnd$ = fromEvent<TouchEvent>(
    this.elementRef.nativeElement,
    'touchend'
  );

  private readonly touchMove$ = fromEvent<TouchEvent>(
    this.elementRef.nativeElement,
    'touchmove'
  );

  private readonly doubleTouchZoom$ = this.doubleTouchStart$.pipe(
    switchMap((startEvent) =>
      this.touchMove$.pipe(
        preventDefault(),
        scan(
          (prev, event) => {
            const distance = getDistanceBetweenTouches(event);
            return {
              event,
              distance,
              delta: (distance - prev.distance) * TOUCH_SENSITIVITY,
            };
          },
          {
            event: startEvent,
            distance: getDistanceBetweenTouches(startEvent),
            delta: 0,
          }
        ),
        map(({ delta }) => delta),
        takeUntil(this.touchEnd$)
      )
    )
  );

  private readonly wheel$ = fromEvent<WheelEvent>(
    this.elementRef.nativeElement,
    'wheel'
  ).pipe(filter((event) => isCtrl<WheelEvent>(event)));

  @HostListener('window:keydown', ['$event'])
  onKeyPress($event: KeyboardEvent) {
    if ($event.key === 'Escape' || (isCtrl($event) && $event.key.toLowerCase() === 'z')) {
      this.workareaStore.removeGrab();
    }
  }

  constructor(
    @Inject(DOCUMENT) private readonly document: Document,
    private readonly elementRef: ElementRef,
    private readonly workareaStore: WorkareaStore,
    private readonly productStore: ProductStore,
    private readonly renderer: Renderer2,
  ) {}

  ngAfterViewInit(): void {
    this.initChangeProductSubscription();
    this.initChangeDesignSideSubscription();
    this.initChangePositionSubscription();
    this.initTransformSubscription();
    this.initZoomEventsSubscription();
  }

  ngOnDestroy(): void {
    this._subs.forEach((s) => s.unsubscribe());
  }

  private initChangeProductSubscription(): void {
    this.subs = this.productStore.product$
      .pipe(distinctUntilChanged())
      .subscribe(() => {
        this.workareaStore.reset();
      });
  }

  private initChangeDesignSideSubscription(): void {
    this.subs = this.productStore.activeDesignSide$
      .pipe(
        distinctUntilChanged(),
        map((activeDesignSide) => this.getSideFocusData(activeDesignSide))
      )
      .subscribe(({ focusZoom, focusOffset }) => {
        this.workareaStore.setFocusZoom(focusZoom);
        this.workareaStore.setFocusOffset(focusOffset);
      });
  }

  private initChangePositionSubscription(): void {
    this.subs = this.workareaStore.isGrabActive$
      .pipe(
        distinctUntilChanged(),
        switchMap((isActive) => (isActive ? this.dragging$ : EMPTY))
      )
      .subscribe((offset) => this.localOffsetSubject$.next(offset));
  }

  private initTransformSubscription(): void {
    this.subs = this.workareaStore.offset$.pipe(distinctUntilChanged())
      .subscribe(offset => {
        this.localOffsetSubject$.next(offset);
      });

    this.subs = combineLatest([this.localOffset$, this.workareaStore.zoom$])
      .subscribe(([offset, zoom]) => this.setWorkareaContainerStyle(zoom, offset));
  }

  private initZoomEventsSubscription() {
    this.subs = merge(
      this.doubleTouchZoom$,
      this.wheel$.pipe(
        preventDefault<WheelEvent>(),
        map((wheel: WheelEvent) => -wheel.deltaY * WHEEL_SENSITIVITY)
      )
    ).pipe(withLatestFrom(this.workareaStore.zoom$)).subscribe(([delta, stateZoom]) => {
      const zoom = delta > 0
        ? Math.min(stateZoom + delta, MAX_ZOOM)
        : Math.max(stateZoom + delta, MIN_ZOOM)

      this.workareaStore.setZoom(zoom);
    });
  }

  private setWorkareaContainerStyle(zoom: number, offset: IXY) {
    this.renderer.setStyle(
      this.painterEditorWorkareaContainer,
      'transform',
      `scale(${zoom}) translate(${offset.x}px, ${offset.y}px)`
    );
  }

  private getSideFocusData(designSide: IDesignSide | null): {
    focusZoom: number;
    focusOffset: IXY;
  } {
    let focusZoom = DEFAULT_ZOOM;
    let focusOffset = DEFAULT_OFFSET;

    if (designSide && !designSide.isFullPrint) {
      const { zoom, offset } = this.getSideZonesFocusData(designSide, this.painterEditorWorkareaContainer);
      focusZoom = zoom;
      focusOffset = offset;
    }

    return { focusZoom, focusOffset };
  }

  private getSideZonesFocusData(designSide: IDesignSide | null, container: HTMLElement): {
    zoom: number;
    offset: IXY;
  } {
    let imageScale = 1;
    let zoom = DEFAULT_ZOOM;

    if (!designSide) {
      return {
        zoom,
        offset: DEFAULT_OFFSET,
      };
    }

    const {
      sizePX: { x: designSideWidth, y: designSideHeight },
      zones: designSideZones,
    } = designSide;

    const { clientWidth: containerWidth, clientHeight: containerHeight } = container;

    const {
      start: zonesStart,
      width: zonesWidth,
      height: zonesHeight,
    } = getZonesBoxData({ zones: designSideZones, strokeWidth: 1 });

    if (designSideWidth / designSideHeight < containerWidth / containerHeight) {
      imageScale = containerHeight / designSideHeight;
    } else {
      imageScale = containerWidth / designSideWidth;
    }


    if (zonesWidth / zonesHeight < containerWidth / containerHeight) {
      zoom = containerHeight / (zonesHeight * imageScale);
    } else {
      zoom = containerWidth / (zonesWidth * imageScale);
    }

    const x = ((designSideWidth - zonesWidth) / 2 - zonesStart.x) * imageScale;
    const y = ((designSideHeight - zonesHeight) / 2 - zonesStart.y) * imageScale;

    return {
      zoom: zoom,
      offset: { x, y },
    };
  }
}
