import { Injectable } from '@angular/core';
import { linkToGlobalState } from '@inaripro-nx/common-ui';
import { ModalWindowStore } from '@inaripro-nx/design-ui';
import { ComponentStore } from '@ngrx/component-store';
import { Store } from '@ngrx/store';
import {
  EMPTY,
  Observable,
  catchError,
  combineLatest,
  debounceTime,
  exhaustMap,
  finalize,
  map,
  mergeMap,
  shareReplay,
  tap,
  withLatestFrom,
} from 'rxjs';
import { BASKET_SUCCESS_MODAL_UID } from '../constants';
import {
  ICalculator,
  IGetResponse,
  IProduct,
  IProductRequest,
} from '../interfaces/nbasket.interface';
import { OrderCartService } from '../services';

export interface OrderCartState {
  uid: string | null;
  products: IProduct[];
  calculators: ICalculator[];
  loadCartStatus: boolean;
  getProductSidesStatus: boolean;
  mergeProductStatus: boolean;
}

const initialState: OrderCartState = {
  uid: null,
  products: [],
  calculators: [],
  loadCartStatus: false,
  getProductSidesStatus: false,
  mergeProductStatus: false,
};

@Injectable({
  providedIn: 'root',
})
export class OrderCartStore extends ComponentStore<OrderCartState> {
  readonly uid$ = this.select((state) => state.uid);
  readonly products$ = this.select((state) => state.products);
  readonly productsCount$ = this.state$.pipe(
    map((state) => {
      return (
        (state.products || []).filter((item) => !item.crashed).length +
        (state.calculators || []).filter((item) => !item.crashed).length
      );
    })
  );

  readonly loadCartStatus$ = this.select((state) => state.loadCartStatus);

  readonly getProductSidesStatus$ = this.select(
    (state) => state.getProductSidesStatus
  );

  readonly mergeProductStatus$ = this.select(
    (state) => state.mergeProductStatus
  );

  readonly isAddButtonDisabled$ = combineLatest([
    this.loadCartStatus$,
    this.mergeProductStatus$,
    this.getProductSidesStatus$,
  ]).pipe(
    debounceTime(0),
    map(
      ([loadCartStatus, mergeProductStatus, getProductSidesStatus]) =>
        loadCartStatus || mergeProductStatus || getProductSidesStatus
    ),
    shareReplay({ refCount: false, bufferSize: 1 })
  );

  readonly setLoadCartStatus = this.updater(
    (state: OrderCartState, payload: boolean) => {
      return {
        ...state,
        loadCartStatus: payload,
      };
    }
  );

  readonly setGetProductSidesStatus = this.updater(
    (state: OrderCartState, payload: boolean) => {
      return {
        ...state,
        getProductSidesStatus: payload,
      };
    }
  );

  readonly setMergeProductStatus = this.updater(
    (state: OrderCartState, payload: boolean) => {
      return {
        ...state,
        mergeProductStatus: payload,
      };
    }
  );

  readonly loadCart = this.effect((trigger$) =>
    trigger$.pipe(
      debounceTime(0),
      withLatestFrom(this.uid$),
      exhaustMap(([, uid]) => {
        this.setLoadCartStatus(true);

        return this.orderCartService.getOrderCart(uid).pipe(
          tap((cart) => this.updateByCart(cart)),
          catchError(() => EMPTY),
          finalize(() => this.setLoadCartStatus(false))
        );
      })
    )
  );

  readonly addProductToCart = this.effect(
    (orderRequest$: Observable<IProductRequest>) =>
      orderRequest$.pipe(
        withLatestFrom(this.uid$),
        mergeMap(([orderRequest, uid]) => {
          this.setMergeProductStatus(true);

          return this.orderCartService.addProduct(uid, orderRequest).pipe(
            tap((response) => {
              this.updateByCart(response);
              this.modalWindowStore.patch({
                [BASKET_SUCCESS_MODAL_UID]: true,
              });
            }),
            catchError(() => EMPTY),
            finalize(() => this.setMergeProductStatus(false))
          );
        })
      )
  );

  init() {
    this.loadCart();
  }

  private updateByCart(cart: IGetResponse) {
    const uid = cart.uid || null;
    const products = cart.products || [];
    const calculators = cart.calculators || [];

    this.setState((state) => {
      return {
        ...state,
        uid,
        products,
        calculators,
      };
    });
  }

  constructor(
    private readonly orderCartService: OrderCartService,
    private readonly modalWindowStore: ModalWindowStore,
    private readonly globalStore: Store
  ) {
    super({ ...initialState });
    linkToGlobalState(
      this.state$,
      'libs/order/OrderCartStore',
      this.globalStore
    );
  }
}
