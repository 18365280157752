import { Pipe, PipeTransform } from '@angular/core';
import { ICatalogNomenclatureListItem } from '../../interfaces/catalog.interface';
import { getRoute } from '../../utils/catalog.util';

const CATALOG_ROOT_LINK = '/catalog';

@Pipe({
  name: 'catalogGroupLink',
  standalone: true,
})
export class CatalogGroupLinkPipe implements PipeTransform {
  transform(
    item: ICatalogNomenclatureListItem,
    rootUrl = CATALOG_ROOT_LINK
  ): string {
    return getRoute(item, rootUrl).join('/');
  }
}
