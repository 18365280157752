<div
  class="drop-down-container"
  [ngClass]="dropDownClass"
  [hidden]="!isActive"
  designClickOutside
  (clickOutsideEvent)="hideSelf()"
  designMoveToBody
  [style.top.px]="dropDownOpenTo === 'down' ? position.top : null"
  [style.bottom.px]="dropDownOpenTo === 'up' ? position.bottom : null"
  [style.left.px]="dropDownAlign === 'left' ? position.left : null"
  [style.right.px]="dropDownAlign === 'right' ? position.right : null"
>
  <div class="drop-down">
    <ng-template
      [ngTemplateOutlet]="dropDownContentTemplateRef || listTemplate"
    ></ng-template>
  </div>
</div>

<ng-template #listTemplate>
  <div
    class="drop-down-item"
    [class.drop-down-item-active]="item.dropDownLabel === dropDownLabelActive"
    *ngFor="let item of dropDownItems; let i = index"
    (click)="selectItem.emit({ index: i, item }); hideSelf()"
  >
    {{ item.dropDownLabel }}
  </div>
</ng-template>
