<design-range-slider-wrapper
  [unitmeasurement]="'%'"
  [minValue]="minValue"
  [maxValue]="maxValue"
  [color]="element.fill || '#000'"
  [isGradientRangeSlider]="true"
  [value]="element.fillOpacity || 0"
  (changeValue)="changeValue($event)"
  (stopChange)="stopChange()"
  (resetValue)="reset()"
></design-range-slider-wrapper>
