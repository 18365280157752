import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { decimalRound } from '@inaripro-nx/common-ui';
import { debounceTime, Subject, Subscription, tap } from 'rxjs';
import { IElement, IXY } from '../../../../interfaces/editor.interface';
import { EditorService } from '../../../../services/editor/editor.service';

@Component({
  selector: 'painter-actions-element-translate',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './actions-element-translate.component.html',
  styleUrls: ['./actions-element-translate.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActionsElementTranslateComponent implements OnInit, OnDestroy {
  @Input() element!: IElement;

  translate$: Subject<IXY> = new Subject();

  get translate(): IXY {
    const { x, y } = this.element.translate;

    return {
      x: decimalRound(x, 4),
      y: decimalRound(y, 4),
    };
  }

  private _subs: Subscription[] = [];
  set subs(sub: Subscription) {
    this._subs.push(sub);
  }

  constructor(public editorService: EditorService) {}

  ngOnInit(): void {
    this.subs = this.translate$
      .pipe(
        debounceTime(500),
        tap((translate) => {
          this.editorService.translate(translate);
        })
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this._subs.forEach((s) => s.unsubscribe());
  }

  changeX(event: Event) {
    this.change({
      x: +(event.target as HTMLInputElement).value,
      y: this.element.translate.y,
    });
  }

  changeY(event: Event) {
    this.change({
      x: this.element.translate.x,
      y: +(event.target as HTMLInputElement).value,
    });
  }

  change(translate: IXY) {
    this.translate$.next(translate);
  }
}
