<div
  class="list-item"
  [class.list-item--icon]="figure.icon"
  [class.list-item--rectangle]="figure.rectangle"
  [class.list-item--image]="figure.image"
  [class.list-item--active]="selectedFigureId === figure.id"
  (click)="onClick($event, figure)"
  *ngFor="let figure of figureList"
>
  <ng-container *ngIf="figure.icon">
    <ng-container [ngTemplateOutlet]="iconFigure" [ngTemplateOutletContext]="{ figure: figure }"></ng-container>
  </ng-container>

  <ng-container *ngIf="figure.rectangle">
    <ng-container [ngTemplateOutlet]="rectangleFigure" [ngTemplateOutletContext]="{ figure: figure }"></ng-container>
  </ng-container>

  <ng-container *ngIf="figure.image">
    <ng-container [ngTemplateOutlet]="imageFigure" [ngTemplateOutletContext]="{ figure: figure }"></ng-container>
  </ng-container>
</div>

<ng-template #iconFigure let-figure="figure">
  <i class="icon" [ngClass]="figure.icon"></i>
  <span class="list-item__name">{{ figure.name }}</span>
</ng-template>

<ng-template #rectangleFigure let-figure="figure">
    <div
      class="list-item__rectangle"
      [style.width.px]="figure.rectangle?.width"
      [style.height.px]="figure.rectangle?.height"
    ></div>
    <span class="list-item__name">{{ figure.name }}</span>
</ng-template>

<ng-template #imageFigure let-figure="figure">
  <img class="list-item__image" [src]="figure.image.url" [alt]="figure.image.name" />
</ng-template>
