import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { AppShellConfig } from '@inaripro-nx/common-ui';
import { EPage } from '../../interfaces/main.interface';
import { MainStore } from '../../state/main/main.store';
import { ActionsComponent } from '../actions/actions.component';
import { ContentComponent } from '../content/content.component';
import { EditorComponent } from '../editor/editor.component';
import { MobileTabsComponent } from '../mobile-tabs/mobile-tabs.component';

@Component({
  selector: 'painter-main-mobile',
  standalone: true,
  imports: [
    CommonModule,
    EditorComponent,
    MobileTabsComponent,
    ContentComponent,
    ActionsComponent,
  ],
  templateUrl: './main-mobile.component.html',
  styleUrls: ['./main-mobile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MainMobileComponent {
  @Input() config: AppShellConfig | null = null;

  readonly EPage = EPage;
  page$ = this.mainStore.page$;
  hideActions$ = this.mainStore.hideActions$;

  constructor(private mainStore: MainStore) {}
}
