<div class="product-navigation">
  @if (config) {
  <h2>
    @if (productLink$ | async; as productLink) {
    <a
      [attr.href]="config.publicUrl + productLink"
      class="product-navigation__item"
    >
      <i class="icon icon-ic_arrow"></i>
      <span>В товар</span>
    </a>
    }
  </h2>
  }

  <button
    type="button"
    class="product-navigation__item"
    (click)="openModal(MODAL_UID_DESCRIPTION)"
  >
    <h2><span>Описание</span></h2>
  </button>

  <button
    type="button"
    class="product-navigation__item"
    (click)="openModal(MODAL_UID_PROPERTIES)"
  >
    <h2><span>Характеристики</span></h2>
  </button>

  <button
    type="button"
    class="product-navigation__item"
    (click)="openModal(MODAL_UID_GALLERY)"
  >
    <h2><span>Фото</span></h2>
  </button>
</div>

@if (product$ | async; as product) {
<painter-content-product-modals
  [product]="product"
  [propertiesInfo]="propertiesInfo$ | async"
  [galleryImages]="(galleryImages$ | async) || []"
  [galleryVideos]="product.video || []"
  [currentImageId]="currentImageId$ | async"
  [MODAL_UID_DESCRIPTION]="MODAL_UID_DESCRIPTION"
  [MODAL_UID_PROPERTIES]="MODAL_UID_PROPERTIES"
  [MODAL_UID_GALLERY]="MODAL_UID_GALLERY"
></painter-content-product-modals>
}
