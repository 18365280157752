import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { DesignUiModule } from '@inaripro-nx/design-ui';
import {
  EElementType,
  IElement,
  IElements,
} from '../../../../interfaces/editor.interface';
import { HistoryStore } from '../../../../state/history/history.store';
import { ITextObject } from '../../interfaces/content.interface';
import { SimplebarAngularModule } from 'simplebar-angular';
import { IDesignProduct } from '@inaripro-nx/catalog';

@Component({
  selector: 'painter-content-text',
  standalone: true,
  imports: [CommonModule, DesignUiModule, SimplebarAngularModule],
  templateUrl: './content-text.component.html',
  styleUrls: ['./content-text.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentTextComponent {
  @Input() elements: IElements | null = null;
  @Input() activeSideIndex: number | null = null;
  @Input() activeDesignProduct: IDesignProduct | null = null;
  @Output() addObject: EventEmitter<ITextObject> = new EventEmitter();

  readonly EElementType = EElementType;

  constructor(private historyStore: HistoryStore) {}

  onAddObject(data: {
    text: string;
    bBox: { width: number; height: number };
    textLength: number;
  }) {
    const { text, bBox, textLength } = data;

    this.addObject.emit({
      type: EElementType.text,
      text,
      bBox,
      textLength,
    });
  }

  selectObject(element: IElement, index: number) {
    if (!this.activeDesignProduct || this.activeSideIndex === null) {
      return;
    }

    if (element.sideIndex > this.activeDesignProduct.sides.length - 1) {
      this.historyStore.updateElement({
        index,
        element: { ...element, sideIndex: this.activeSideIndex },
      });
    } else {
      this.historyStore.setActiveElementIndex({ activeElementIndex: index });
    }
  }

  removeObject(index: number) {
    this.historyStore.removeElement({ index });
  }
}
