import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Links } from '../interfaces/links.interface';

@Injectable({
  providedIn: 'root',
})
export class LinksService {
  constructor(private readonly httpClient: HttpClient) {}

  getLinks() {
    return this.httpClient.get<Links>('/public/footer/');
  }
}
