<input
    #inputField
    [formControl]="formControl"
    [attr.disabled]="isDisabled || null"
    type="number"
    [ngClass]="classInput || ''"
    [attr.id]="controlId"
    (keydown.enter)="inputField.blur()"
    (blur)="confirmInput(inputField)"
    (keydown.esc)="cancelInput(inputField)"
    (focus)="onFocus($event)"
/>
