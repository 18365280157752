import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { IQRCode } from '@inaripro-nx/common-ui';

@Injectable({
  providedIn: 'root',
})
export class QrCodesService {
  qrCodes$: BehaviorSubject<IQRCode[] | null> = new BehaviorSubject<
    IQRCode[] | null
  >(null);
  newQRCode$: Observable<IQRCode> | null = null;
  createQRCode?: (qrCode: Partial<IQRCode>) => unknown;
  deleteQRCode?: (id: number) => unknown;
}
