import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { CommonModule } from '@angular/common';
import { HttpResponse } from '@angular/common/http';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
} from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { blobToFile, dataURLtoBlob } from '@inaripro-nx/common-ui';
import { ICropData } from '@inaripro-nx/crop';
import {
  filter,
  map,
  Subject,
  Subscription,
  switchMap,
  withLatestFrom,
} from 'rxjs';
import { fitElementToActiveDesignSide } from '../../../../utils/calculate.utils';
import {
  EElementType,
  IElement,
  ISVGElement,
  IXYb,
} from '../../interfaces/editor.interface';
import { ActionsService } from '../../services/actions/actions.service';
import { PicturesService } from '../../services/pictures/pictures.service';
import { WindowToolsService } from '../../services/window-tools/window-tools.service';
import { HistoryStore } from '../../state/history/history.store';
import { MainStore } from '../../state/main/main.store';
import { ProductStore } from '../../state/product/product.store';
import { ActionsFiguresComponent } from './components/actions-figures/actions-figures.component';
import { ActionsPicturesComponent } from './components/actions-pictures/actions-pictures.component';
import { ActionsQrcodesComponent } from './components/actions-qrcodes/actions-qrcodes.component';
import { ActionsTextComponent } from './components/actions-text/actions-text.component';
import { ActionElementTitlePipe } from './pipes/actions-element-title.pipe';

@Component({
  selector: 'painter-actions',
  standalone: true,
  imports: [
    CommonModule,
    ActionsFiguresComponent,
    ActionsPicturesComponent,
    ActionsTextComponent,
    ActionsQrcodesComponent,
    ActionElementTitlePipe,
    BrowserAnimationsModule,
  ],
  templateUrl: './actions.component.html',
  styleUrls: ['./actions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('animateDestroyAndOnInit', [
      state('void', style({ opacity: '0', height: 0 })),
      transition(':leave', animate('200ms ease')),
      transition(':enter', [style({}), animate('200ms ease')]),
    ]),
  ],
})
export class ActionsComponent implements AfterViewInit, OnDestroy {
  filters$ = this.productStore.filters$;
  activeDesignSide$ = this.productStore.activeDesignSide$;
  figures$ = this.productStore.figures$;
  activeElementIndex$ = this.historyStore.activeElementIndex$;
  activeElement$ = this.historyStore.activeElement$;
  isDesktop$ = this.windowToolsService.isDesktop$;

  readonly EElementType = EElementType;

  private _subs: Subscription[] = [];
  set subs(sub: Subscription) {
    this._subs.push(sub);
  }

  startTouchCoordY: number | null = null;
  hideActionsSubmenu$ = this.mainStore.hideActionsSubmenu$;

  readonly isMaxUserFilesLimit$ = this.picturesService.isMaxUserFilesLimit$;

  private readonly cropImageSubject$ = new Subject<{
    cropData: ICropData;
    elementData: { index: number; element: IElement };
  }>();

  constructor(
    private historyStore: HistoryStore,
    private productStore: ProductStore,
    private actionsService: ActionsService,
    private mainStore: MainStore,
    private windowToolsService: WindowToolsService,
    private readonly picturesService: PicturesService
  ) {}

  ngAfterViewInit() {
    this.initCropImageSubscription();
  }

  ngOnDestroy() {
    this._subs.forEach((s) => s.unsubscribe());
  }

  updateElement(data: { index: number; element: IElement }) {
    this.historyStore.updateElement(data);
  }

  centerElement(data: { index: number; element: IElement; center: IXYb }) {
    const element = this.actionsService.centerElement(data);

    if (!element) {
      return;
    }

    const { index } = data;

    this.updateElement({
      index,
      element,
    });
  }

  public headMobileMenuClick(): void {
    this.mainStore.toggleHideActionsSubmenu();
  }

  public headMobileMenuTouchStart(event: TouchEvent): void {
    if (!(window.TouchEvent && event instanceof TouchEvent)) {
      return;
    }

    this.startTouchCoordY = event.changedTouches[0].clientY;
  }

  public headMobileMenuTouchMove(event: TouchEvent): void {
    if (!(window.TouchEvent && event instanceof TouchEvent)) {
      return;
    }

    if (this.startTouchCoordY === null) {
      return;
    }

    const currentstartTouchCoordY = event.changedTouches[0].clientY;
    const deltaY = this.startTouchCoordY - currentstartTouchCoordY;

    if (deltaY > 50) {
      this.mainStore.setHideActionsSubmenu({ hideActionsSubmenu: false });
      this.startTouchCoordY = null;
    } else if (deltaY < -20) {
      this.mainStore.setHideActionsSubmenu({ hideActionsSubmenu: true });
      this.startTouchCoordY = null;
    }
  }

  headMobileMenuTouchEnd() {
    this.startTouchCoordY = null;
  }

  cropImage(
    cropData: ICropData,
    elementData: { index: number; element: IElement }
  ) {
    this.cropImageSubject$.next({ cropData, elementData });
  }

  private initCropImageSubscription() {
    const uploadPicture = this.picturesService.uploadPicture;
    if (!uploadPicture) {
      return;
    }

    this.subs = this.cropImageSubject$
      .asObservable()
      .pipe(
        switchMap(({ cropData, elementData }) => {
          const fileName = `cropped_image_${Date.now().toString()}.png`;
          const file = blobToFile(dataURLtoBlob(cropData.dataURL), fileName);

          return uploadPicture(file).pipe(
            map(
              (response) =>
                (response as HttpResponse<{ url: string }>).body?.url
            ),
            filter(Boolean),
            map((url) => ({ url, cropData, elementData }))
          );
        }),
        withLatestFrom(this.activeDesignSide$)
      )
      .subscribe(
        ([
          {
            url,
            cropData: { width, height },
            elementData,
          },
          activeDesignSide,
        ]) => {
          const updateCenter = { x: 0, y: 0 };

          const element: ISVGElement = {
            ...(elementData.element as ISVGElement),
            type: EElementType.image,
            svg: `<image xlink:href="${url}"></image>`,
            size: {
              x: width,
              y: height,
            },
            translate: {
              x: updateCenter.x - width / 2,
              y: updateCenter.y - height / 2,
            },
            scale: { x: 1, y: 1 },
          };

          this.updateElement({
            index: elementData.index,
            element: activeDesignSide
              ? fitElementToActiveDesignSide(
                  element,
                  activeDesignSide,
                  updateCenter
                )
              : element,
          });
        }
      );
  }
}
