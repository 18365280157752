import { NgTemplateOutlet } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  ViewEncapsulation,
} from '@angular/core';
import { PipesModule as CommonPipesModule } from '@inaripro-nx/common-ui';
import {
  DEFAULT_TEXT_SIZE,
  ITextAnchor,
  ITextElement,
} from '../../../../interfaces/editor.interface';
import { PipesModule } from '../../../../pipes/pipes.module';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[data-painter-editor-element-text-content]',
  standalone: true,
  imports: [NgTemplateOutlet, CommonPipesModule, PipesModule],
  templateUrl: './editor-element-text-content.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditorElementTextContentComponent {
  @Input() el!: ITextElement;
  @Input() index!: number;

  readonly ITextAnchor = ITextAnchor;
  readonly DEFAULT_TEXT_SIZE = DEFAULT_TEXT_SIZE;
}
