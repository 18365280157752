import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from '@inaripro-nx/auth';
import { StorageService } from '@inaripro-nx/common-ui';
import { Observable, shareReplay, switchMap, tap } from 'rxjs';

const TOKEN_STORAGE_KEY = 'designer_token_id';

@Injectable({
  providedIn: 'root',
})
export class DesignerAuthService {
  constructor(private storageService: StorageService) {}

  getAuthorizationHeader() {
    const token = this.getTokenFromStorage();
    return token ? `${token}` : null;
  }

  private getTokenFromStorage() {
    return this.storageService.local.getItem(TOKEN_STORAGE_KEY);
  }
}

@Injectable({
  providedIn: 'root',
})
export class DesignerAuthLinkService {
  loadDesignUser$: Observable<{ uid: string }> = this.authService.token$.pipe(
    switchMap(() => {
      const uid = this.designerAuthService.getAuthorizationHeader();
      return this.httpClient.post<{ uid: string }>(
        '/catalog/public/design/user',
        { uid }
      );
    }),
    tap((response) => {
      this.storageService.local.setItem(TOKEN_STORAGE_KEY, response.uid);
    }),
    shareReplay({ refCount: false, bufferSize: 1 })
  );

  constructor(
    private httpClient: HttpClient,
    private storageService: StorageService,
    private designerAuthService: DesignerAuthService,
    private authService: AuthService
  ) {}
}
